import { IDestopTableHeader } from '../../utils/Interface/CommonInterface';

const DesktopTableHeader = ({ tableConstants }: IDestopTableHeader) => {
    const tableHeaders = Object.keys(tableConstants.headers);

    const mapHeaders = () => {
        return tableHeaders.map(
            (headerFieldName: string, headerIndex: number) => (
                <th
                    key={headerIndex}
                    className={`${tableConstants.headerAlign}`}
                >
                    {tableConstants.headers[headerFieldName].label}
                </th>
            ),
        );
    };

    return (
        <thead>
            <tr>{mapHeaders()}</tr>
        </thead>
    );
};

export default DesktopTableHeader;
