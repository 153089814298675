import React, { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useLoading } from '../../commonHooks/useLoading';
import { useAppDispatch } from '../../Redux/Store';
import { CommonConstants } from '../../utils/constants/CommonConstants';
import { validateAccordingType } from '../../utils/validation';
import { locationConstants } from '../../utils/constants/LocationDetailConstant';
import {
    ILocationDetail,
    ILocationDetailsError,
    ILocationModal,
} from '../../utils/Interface/LocationInterface';
import {
    createLocation,
    fetchInactiveLocationInfo,
    updateLocationDetail,
} from '../../Redux/Actions/locationActions';
import FullPageLoader from '../common/FullPageLoader';
import { useModalStatus } from '../../commonHooks/useModalStatus';
import { ActivateItemModal } from '../../apps/shippingTracker/modules/BuyPo/components/ActivateItemModal';
import { FmSettingsConstant } from '../../utils/constants/FmSettingsConstants';
import { StateList } from '../../utils/constants/StateListConstants';
import { LOCATION_INITIAL_STATE } from '../../Redux/Constants/locationInitialState';

const LocationModal = ({
    showModal,
    closeModalWithChanges,
    editLocationDetails,
    showActiveField,
}: ILocationModal) => {
    const [locationDetail, setLocationDetail] =
        useState<ILocationDetail>(editLocationDetails);
    const [locationDetailsError, setLocationDetailsError] =
        useState<ILocationDetailsError>(
            locationConstants.initialLocationDetailsError,
        );
    const [inActiveLocation, setInActiveLocation] = useState<ILocationDetail>();
    const {
        isOpen: isActivateModalOpen,
        open: openActivateModal,
        close: closeActivateModal,
    } = useModalStatus();
    const { loading, setLoading } = useLoading();
    const dispatch = useAppDispatch();

    const validateForm = (locationErrorCopy: ILocationDetailsError) => {
        let isFormValid = true;
        const validateFields = ['postalCode', 'coordinates'];
        const validateTypes = ['zipcode', 'coordinates'];

        validateFields.forEach((validateField, index) => {
            const isValid = validateAccordingType(
                validateTypes[index],
                locationDetail[validateField],
            );
            locationErrorCopy[validateField] = !isValid;
            if (!isValid) {
                isFormValid = false;
            }
        });

        setLocationDetailsError(locationErrorCopy);

        return isFormValid;
    };

    const validateLocationDetails = (): boolean => {
        let isValid = true;
        const { name } = locationDetail;
        const updatedLocationDetailErrors = {
            ...locationDetailsError,
            name: !name,
        };
        setLocationDetailsError(updatedLocationDetailErrors);
        const isFormValid = validateForm(updatedLocationDetailErrors);
        if (
            Object.values(updatedLocationDetailErrors).includes(true) ||
            !isFormValid
        ) {
            isValid = false;
        }
        return isValid;
    };

    const handleActivateLocation = async () => {
        const inactiveLocationInfo = await dispatch(
            fetchInactiveLocationInfo(locationDetail.name?.trim()),
        );
        if (inactiveLocationInfo) {
            setInActiveLocation(inactiveLocationInfo[0]);
            openActivateModal();
        }
    };

    const handleUpdateLocationDetail = (): void => {
        const isValid = validateLocationDetails();
        if (isValid) {
            dispatch(
                updateLocationDetail(
                    locationDetail,
                    undefined,
                    closeModalWithChanges,
                    setLoading,
                    handleActivateLocation,
                ),
            );
        }
    };

    const handleCreateLocation = (): void => {
        const isValid = validateLocationDetails();
        if (isValid) {
            dispatch(
                createLocation(
                    locationDetail,
                    closeModalWithChanges,
                    setLoading,
                    handleActivateLocation,
                ),
            );
        }
    };

    const handleDetailChange = (
        fieldValue: string | number | boolean,
        fieldName: string,
        type?: string,
    ): void => {
        const isValid = validateAccordingType(type, fieldValue);
        if (isValid) {
            setLocationDetail(prevLocationDetail => ({
                ...prevLocationDetail,
                [fieldName]: fieldValue,
            }));
        }
    };

    const handleCloseActivateModal = (isActivated: boolean) => {
        if (isActivated) {
            if (inActiveLocation) {
                const inActiveLocationCopy = {
                    ...inActiveLocation,
                    active: true,
                };
                dispatch(
                    updateLocationDetail(
                        inActiveLocationCopy,
                        undefined,
                        closeModalWithChanges,
                        setLoading,
                    ),
                );
                closeActivateModal();
            }
        } else {
            closeActivateModal();
        }
    };

    useEffect(() => {
        if (!showModal) {
            setLocationDetail({
                ...LOCATION_INITIAL_STATE.createLocationInitial,
            });
        }
    }, [showModal]);

    return (
        <>
            <ActivateItemModal
                showModal={isActivateModalOpen}
                closeModalHandler={handleCloseActivateModal}
                itemName={locationDetail.name?.trim()}
                item={locationConstants.activateModalConstant}
            />
            <Modal
                className="px-2 pt-5 fm-settings-modal"
                size="lg"
                show={showModal}
                centered
                animation
                onHide={() => closeModalWithChanges(false)}
            >
                {loading && <FullPageLoader />}
                <Modal.Header closeButton className="border-bottom-0">
                    <Modal.Title className="text-dark">
                        {editLocationDetails.name
                            ? `${locationConstants.editLocation} ${editLocationDetails?.name}`
                            : locationConstants.createNewLocations.heading}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex justify-content-between">
                        <div className="col-xl-6 col-lg-6 start-top mb-4 ">
                            <Form.Label className="text-secondary">
                                {
                                    locationConstants.createNewLocations.form
                                        .name.label
                                }
                                <span className="necessaryFields">*</span>
                            </Form.Label>
                            <Form.Control
                                className={`text-secondary border-${
                                    locationDetailsError.name ? 'danger' : ''
                                }`}
                                value={locationDetail?.name}
                                name={
                                    locationConstants.createNewLocations.form
                                        .name.name
                                }
                                placeholder={
                                    locationConstants.createNewLocations.form
                                        .name.placeholder
                                }
                                onChange={e =>
                                    handleDetailChange(
                                        e.target.value,
                                        e.target.name,
                                        'string',
                                    )
                                }
                                maxLength={
                                    locationConstants.createNewLocations.form
                                        .name.maxLength
                                }
                                size="sm"
                                type="text"
                            />
                        </div>
                        {showActiveField && (
                            <div className="custom-checkbox col-5">
                                <input
                                    type="checkbox"
                                    checked={locationDetail.active}
                                    className="mx-2"
                                    onChange={e =>
                                        handleDetailChange(
                                            !locationDetail.active,
                                            'active',
                                        )
                                    }
                                />
                                <label className="mx-2">
                                    {FmSettingsConstant.active.label}
                                </label>
                            </div>
                        )}
                    </div>
                    <div className="d-flex justify-content-between">
                        <div className="col-xl-6 col-lg-6 start-top mb-4 ">
                            <Form.Label className="text-secondary">
                                {
                                    locationConstants.createNewLocations.form
                                        .address1.label
                                }
                            </Form.Label>
                            <Form.Control
                                className="text-secondary"
                                value={locationDetail?.address1}
                                name={
                                    locationConstants.createNewLocations.form
                                        .address1.name
                                }
                                onChange={e =>
                                    handleDetailChange(
                                        e.target.value,
                                        e.target.name,
                                    )
                                }
                                maxLength={
                                    locationConstants.createNewLocations.form
                                        .address1.maxLength
                                }
                                size="sm"
                                type="text"
                            />
                        </div>
                        <div className="col-xl-5 col-lg-5 start-top mb-4 ">
                            <Form.Label className="text-secondary">
                                {
                                    locationConstants.createNewLocations.form
                                        .coordinates.label
                                }
                            </Form.Label>
                            <Form.Control
                                className={`text-secondary border-${
                                    locationDetailsError.coordinates
                                        ? 'danger'
                                        : ''
                                }`}
                                value={locationDetail?.coordinates}
                                name={
                                    locationConstants.createNewLocations.form
                                        .coordinates.name
                                }
                                placeholder={
                                    locationConstants.createNewLocations.form
                                        .coordinates.placeholder
                                }
                                onChange={e =>
                                    handleDetailChange(
                                        e.target.value,
                                        e.target.name,
                                    )
                                }
                                maxLength={
                                    locationConstants.createNewLocations.form
                                        .coordinates.maxLength
                                }
                                size="sm"
                                type="text"
                            />
                        </div>
                    </div>
                    <div className="d-flex justify-content-between">
                        <div className="col-xl-6 col-lg-6 start-top mb-4 ">
                            <Form.Label className="text-secondary">
                                {
                                    locationConstants.createNewLocations.form
                                        .address2.label
                                }
                            </Form.Label>
                            <Form.Control
                                className="text-secondary"
                                value={locationDetail?.address2}
                                name={
                                    locationConstants.createNewLocations.form
                                        .address2.name
                                }
                                onChange={e =>
                                    handleDetailChange(
                                        e.target.value,
                                        e.target.name,
                                    )
                                }
                                maxLength={
                                    locationConstants.createNewLocations.form
                                        .address2.maxLength
                                }
                                size="sm"
                                type="text"
                            />
                        </div>
                        <div className="col-xl-5 col-lg-5 start-top mb-4 ">
                            <Form.Label className="text-secondary">
                                {
                                    locationConstants.createNewLocations.form
                                        .city.label
                                }
                            </Form.Label>
                            <Form.Control
                                className="text-secondary"
                                value={locationDetail?.city}
                                name={
                                    locationConstants.createNewLocations.form
                                        .city.name
                                }
                                onChange={e =>
                                    handleDetailChange(
                                        e.target.value,
                                        e.target.name,
                                    )
                                }
                                maxLength={
                                    locationConstants.createNewLocations.form
                                        .city.maxLength
                                }
                                size="sm"
                                type="text"
                            />
                        </div>
                    </div>
                    <div className="d-flex justify-content-between">
                        <div className="col-3 mb-4 start-top pe-0">
                            <Form.Label className="text-secondary">
                                {
                                    locationConstants.createNewLocations.form
                                        .state.label
                                }
                            </Form.Label>
                            <Form.Control
                                className="text-secondary"
                                name={
                                    locationConstants.createNewLocations.form
                                        .state.name
                                }
                                onChange={e =>
                                    handleDetailChange(
                                        e.target.value,
                                        e.target.name,
                                    )
                                }
                                value={locationDetail.state}
                                as="select"
                                size="sm"
                                type="select"
                            >
                                {StateList.map((state, index) => (
                                    <option key={index} value={state.value}>
                                        {state.name}
                                    </option>
                                ))}
                            </Form.Control>
                        </div>
                        <div className="col-3 mb-4 start-top">
                            <Form.Label className="text-secondary">
                                {
                                    locationConstants.createNewLocations.form
                                        .zipCode.label
                                }
                            </Form.Label>
                            <Form.Control
                                className={`text-secondary border-${
                                    locationDetailsError.postalCode
                                        ? 'danger'
                                        : ''
                                }`}
                                placeholder={
                                    locationConstants.createNewLocations.form
                                        .zipCode.placeholder
                                }
                                value={locationDetail.postalCode}
                                maxLength={
                                    locationConstants.createNewLocations.form
                                        .zipCode.maxLength
                                }
                                name={
                                    locationConstants.createNewLocations.form
                                        .zipCode.name
                                }
                                onChange={e =>
                                    handleDetailChange(
                                        e.target.value,
                                        e.target.name,
                                    )
                                }
                                size="sm"
                                type="text"
                            />
                        </div>
                        <div className="col-xl-5 col-lg-5 start-top mb-4 ">
                            <Form.Label className="text-secondary">
                                {
                                    locationConstants.createNewLocations.form
                                        .country.label
                                }
                            </Form.Label>
                            <Form.Control
                                value={
                                    locationDetail.country ||
                                    locationConstants.createNewLocations.form
                                        .country.defaultValue
                                }
                                disabled
                                className="text-secondary"
                                size="sm"
                                type="text"
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="border-top-0">
                    <Button
                        className="secondary"
                        onClick={() => closeModalWithChanges(false)}
                    >
                        {CommonConstants.en.cancel}
                    </Button>
                    <Button
                        onClick={
                            !editLocationDetails.name
                                ? handleCreateLocation
                                : handleUpdateLocationDetail
                        }
                    >
                        {CommonConstants.en.save}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default LocationModal;
