import { AnyAction } from '@reduxjs/toolkit';

import { FeedReportsActionTypes } from '../../../../Redux/Actions/index';
import { FEED_REPORT_INITIAL_STATE } from '../constants/feedReportInitialState';

const FeedReportReducer = (
    state = FEED_REPORT_INITIAL_STATE,
    action: AnyAction,
) => {
    const stateCopy = { ...state };

    switch (action.type) {
        case FeedReportsActionTypes.SET_SEVEN_DAY_FEED_REPORT:
            stateCopy.sevenDayFeedReport = action.payload;
            return stateCopy;
        case FeedReportsActionTypes.SET_MANUAL_FEEDING_SHEET_REPORT:
            stateCopy.manualFeedSheetReport = action.payload;
            return stateCopy;
        case FeedReportsActionTypes.SET_AVERAGE_AS_FED_REPORT:
            stateCopy.averageAsFedReport = action.payload;
            return stateCopy;
        case FeedReportsActionTypes.SET_SCHEDULE_RATION_CHANGE_REPORT:
            stateCopy.scheduleRationChangeReport = action.payload;
            return stateCopy;
        default:
            return stateCopy;
    }
};

export default FeedReportReducer;
