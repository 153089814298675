import { createSlice } from '@reduxjs/toolkit';

const LotPageSorting = createSlice({
    name: 'headCount',
    initialState: '',
    reducers: {
        setHeadCountSorting: (state, action) => {
            return action.payload;
        },
    },
});

export const { setHeadCountSorting } = LotPageSorting.actions;
export default LotPageSorting.reducer;
