import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICsvData } from '../../../../utils/Interface/CommonInterface';
import { COMMODITY_INVENTORY_REPORT_INITIAL_STATE } from '../constants/commodityInventoryReportInitialState';

const CommodityInventoryReportReducer = createSlice({
    name: 'CommodityInventoryReport',
    initialState: { ...COMMODITY_INVENTORY_REPORT_INITIAL_STATE },
    reducers: {
        setCommodityUsageReport: (state, action: PayloadAction<ICsvData[]>) => {
            state.commodityUsageReport = action.payload;
        },
        setCommodityReportLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
    },
});
export const { setCommodityUsageReport, setCommodityReportLoading } =
    CommodityInventoryReportReducer.actions;

const commodityInventoryReport = CommodityInventoryReportReducer.reducer;

export default commodityInventoryReport;
