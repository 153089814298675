const CoreCattleManagementIcon = (props: any) => {
    return (
        <img
            {...props}
            alt="core_cattle_management_icon.png"
            src={require('./core_cattle_management_icon.png')}
        />
    );
};

export default CoreCattleManagementIcon;
