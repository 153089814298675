import { ScaleTicketModalConstants } from '../../scaleTickets/constants/ScaleTicketConstants';
import { useAppSelector } from '../../../../Redux/Store';

const ContractFormRemainingAmounts = () => {
    const { contractModalDetails } = useAppSelector(state => state.scaleTicket);

    return (
        <>
            <div>
                <label>
                    {
                        ScaleTicketModalConstants.contractModal.labels
                            .contractTotal
                    }
                    {': '}
                    {(+contractModalDetails.totalAmount).toLocaleString()}
                </label>
            </div>
            <div>
                <label>
                    {
                        ScaleTicketModalConstants.contractModal.labels
                            .scaleTicketsTotal
                    }
                    {': '}
                    {(
                        contractModalDetails.scaleTicketAmount ?? 0
                    ).toLocaleString()}
                </label>
            </div>
            <div>
                <label>
                    {
                        ScaleTicketModalConstants.contractModal.labels
                            .totalRemaining
                    }
                    {': '}
                    {(
                        +contractModalDetails.totalAmount -
                        (contractModalDetails.scaleTicketAmount ?? 0)
                    ).toLocaleString()}
                </label>
            </div>
        </>
    );
};

export default ContractFormRemainingAmounts;
