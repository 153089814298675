import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../Redux/Store';
import Constants from '../../utils/Constants';
import { setSelectedLanguage } from '../../Redux/Reducer/common';

export default function LanguageDropdown() {
    const dispatch = useAppDispatch();
    const { i18n } = useTranslation();
    const { selectedLanguage } = useAppSelector(state => state.common);

    const updateSelectedLanguage = (language: {
        name: string;
        value: string;
    }) => {
        dispatch(setSelectedLanguage(language));
        i18n.changeLanguage(language.value);
    };

    return (
        <div className="mx-3">
            <Dropdown className="top-header-language-dropdown top-header-dropdown">
                <Dropdown.Toggle className="p-0">
                    {selectedLanguage.name || ''}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {Constants.Languages.map((language, index: number) => (
                        <Dropdown.Item
                            key={index}
                            onClick={() => updateSelectedLanguage(language)}
                        >
                            {language.name}
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
}
