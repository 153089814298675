import './nav/nav.scss';
import { useLocation } from 'react-router-dom';
import Constants from '../utils/Constants';
import useAuthContext from '../apps/common/modules/auth/hooks/useAuthContext';
import BreadCrumbs from '../utils/BreadCrumbs';
import OwnerDropdown from './nav/OwnerDropdown';
import FeedlotDropdown from './nav/FeedlotDropdown';
import UserProfileDropdown from './nav/UserProfileDropdown';
import MobileNav from './nav/MobileNav';
import { INav } from '../utils/Interface/NavInterface';
import LanguageDropdown from './nav/LanguageDropdown';
import { financeManagerFeatures } from '../apps/financeManager/featureFlags/financeManagerFeatures';
import NetworkDisplay from './nav/NetworkDisplay';
import { Button } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../Redux/Store';
import { commonActions } from '../Redux/Reducer/common';
import SupportModal from './nav/SupportModal';
import { ApplicationTabNames } from '../utils/Interface/CommonInterface';

const Nav = ({ sideNavTab }: INav) => {
    const location = useLocation();
    let headerPath = location.pathname.split('/')[1].toLowerCase();
    const dispatch = useAppDispatch();
    const { isAuthenticated } = useAuthContext();
    const { isFA536UiOptimization } = financeManagerFeatures;
    const { currentSelectedTab } = useAppSelector(state => state.common);

    if (['buypos', 'shippos'].includes(headerPath)) {
        headerPath = location.pathname.split('/')[2]?.toLowerCase();
    }

    const handleClick = () => {
        dispatch(commonActions.setShowSupportModal(true));
    };

    return !isAuthenticated() ? null : (
        <>
            <div
                id="sidebar"
                className="top-navigation-bar d-flex justify-content-between align-items-center"
            >
                <div className="d-flex">
                    {isFA536UiOptimization ? (
                        headerPath !== 'feedcall' &&
                        `/${headerPath}` !==
                            Constants.Routes.NEWFEEDCALLV1DOT1 &&
                        headerPath !== 'yardlayout' ? (
                            <h6 className="block-heading m-0">
                                <BreadCrumbs />
                            </h6>
                        ) : (
                            <h5 className="block-heading m-0">
                                {Constants.headerConstants[headerPath] ?? null}
                            </h5>
                        )
                    ) : (
                        <h5 className="block-heading m-0">
                            {Constants.headerConstants[headerPath] ?? null}
                        </h5>
                    )}
                </div>
                <div className="d-flex">
                    <NetworkDisplay />
                </div>
                <div className="d-flex align-items-center">
                    {sideNavTab === 'fm' ? (
                        <>
                            <Button
                                className="support-modal"
                                onClick={handleClick}
                            >
                                {Constants.supportModal.buttonName}
                            </Button>
                            <LanguageDropdown />
                            <FeedlotDropdown />
                        </>
                    ) : sideNavTab === 'financial' &&
                      Constants.showOwnerDropdown.includes(
                          Constants.headerConstants[headerPath],
                      ) ? (
                        <OwnerDropdown />
                    ) : null}
                    <UserProfileDropdown />
                </div>
            </div>
            <SupportModal />
            {currentSelectedTab.toString().toLowerCase() !==
                ApplicationTabNames.FeedlotManager && <MobileNav />}
            {!isFA536UiOptimization &&
                headerPath !== 'feedcall' &&
                `/${headerPath}` !== Constants.Routes.NEWFEEDCALLV1DOT1 &&
                headerPath !== 'yardlayout' &&
                headerPath !== 'calendar' && <BreadCrumbs />}
        </>
    );
};

export default Nav;
