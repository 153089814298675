import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PEN_INITIAL_STATE } from '../Constants/penInitialState';
import { IPenFilter } from '../../utils/Interface/PenInterface';

const penSlice = createSlice({
    name: 'pen',
    initialState: { ...PEN_INITIAL_STATE },
    reducers: {
        setPenFilter: (state, action: PayloadAction<IPenFilter>) => {
            state.filter = action.payload;
        },
        setPenDetailsLoader: (state, action: PayloadAction<boolean>) => {
            state.loader = action.payload;
        },
    },
});

export const { setPenFilter, setPenDetailsLoader } = penSlice.actions;
export const penReducer = penSlice.reducer;
