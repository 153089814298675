import { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useAppDispatch } from '../../Redux/Store';
import { useLoading } from '../../commonHooks/useLoading';
import { useFeedlotId } from '../../commonHooks/useFeedlotId';
import FullPageLoader from '../common/FullPageLoader';
import { validateAccordingType } from '../../utils/validation';
import {
    IYardageDetail,
    IYardageModal,
    IYardageDetailsError,
} from '../../utils/Interface/YardageInterface';
import { formatDate } from '../../helpers';
import { createYardage } from '../../Redux/Actions/yardageActions';
import { CommonConstants } from '../../utils/constants/CommonConstants';
import { yardageConstants } from '../../utils/constants/YardageConstants';
import dayjs from '../../utils/dayjs';

const YardageModal = ({
    showModal,
    closeModalWithChanges,
    yardageDetails,
}: IYardageModal) => {
    const [yardageDetail, setYardageDetail] =
        useState<IYardageDetail>(yardageDetails);
    const [yardageDetailsError, setYardageDetailsError] =
        useState<IYardageDetailsError>(
            yardageConstants.initialYardageDetailsError,
        );
    const { loading, setLoading } = useLoading();
    const dispatch = useAppDispatch();
    const feedlotId = useFeedlotId();

    const validateYardageDetails = (): boolean => {
        let isValid = true;
        const { yardageRate, effectiveDate } = yardageDetail;
        const updatedYardageDetailErrors = {
            yardageRate: !yardageRate,
            effectiveDate: !effectiveDate,
        };
        setYardageDetailsError(updatedYardageDetailErrors);
        if (Object.values(updatedYardageDetailErrors).includes(true)) {
            isValid = false;
        }
        return isValid;
    };

    const handleCreateYardage = (): void => {
        const isValid = validateYardageDetails();

        if (feedlotId && isValid) {
            dispatch(
                createYardage(
                    feedlotId,
                    yardageDetail,
                    closeModalWithChanges,
                    setLoading,
                ),
            );
        }
    };

    const handleDetailChange = (
        fieldValue: string | number,
        fieldName: string,
        type?: string,
    ): void => {
        const isValid = validateAccordingType(type, fieldValue);
        if (isValid) {
            setYardageDetail(prevYardageDetail => ({
                ...prevYardageDetail,
                [fieldName]:
                    fieldName === yardageConstants.yardageRate
                        ? Number(fieldValue)
                        : fieldValue,
            }));
        }
    };

    return (
        <Modal
            className="px-2 pt-5"
            size="lg"
            show={showModal}
            centered
            animation
            onHide={() => closeModalWithChanges(false)}
        >
            {loading && <FullPageLoader />}
            <Modal.Header closeButton className="border-bottom-0">
                <Modal.Title className="text-dark">
                    {yardageConstants.createNewYardage.heading}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="border-bottom">
                    <div className="d-flex justify-content-between start-top mb-4">
                        <div className="col-5">
                            <Form.Label className="text-secondary">
                                {
                                    yardageConstants.createNewYardage.form
                                        .yardage.label
                                }
                                <span className="necessaryFields">*</span>
                            </Form.Label>
                            <Form.Control
                                className={`text-secondary border-${
                                    yardageDetailsError.yardageRate
                                        ? 'danger'
                                        : ''
                                }`}
                                value={yardageDetail?.yardageRate}
                                name={
                                    yardageConstants.createNewYardage.form
                                        .yardage.name
                                }
                                placeholder={
                                    yardageConstants.createNewYardage.form
                                        .yardage.placeholder
                                }
                                onChange={e =>
                                    handleDetailChange(
                                        e.target.value,
                                        e.target.name,
                                    )
                                }
                                maxLength={
                                    yardageConstants.createNewYardage.form
                                        .yardage.maxLength
                                }
                                size="sm"
                                type="number"
                            />
                        </div>
                        <div className="col-5">
                            <Form.Label className="text-secondary">
                                {
                                    yardageConstants.createNewYardage.form.date
                                        .label
                                }
                            </Form.Label>
                            <span className="necessaryFields">*</span>
                            <Form.Control
                                className={`text-secondary px-2 py-1 w-100 form-item border border-${
                                    yardageDetailsError.effectiveDate
                                        ? 'danger'
                                        : ''
                                }`}
                                value={formatDate(
                                    yardageDetail?.effectiveDate,
                                    'YYYY-MM-DD',
                                )}
                                min={formatDate(dayjs(), 'YYYY-MM-DD')}
                                name={
                                    yardageConstants.createNewYardage.form.date
                                        .name
                                }
                                onChange={e =>
                                    handleDetailChange(
                                        e.target.value,
                                        e.target.name,
                                    )
                                }
                                size="sm"
                                type="date"
                            />
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className="border-top-0">
                <Button
                    className="secondary"
                    onClick={() => closeModalWithChanges(false)}
                >
                    {CommonConstants.en.cancel}
                </Button>
                <Button onClick={handleCreateYardage}>
                    {CommonConstants.en.save}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default YardageModal;
