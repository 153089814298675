import { AnyAction } from '@reduxjs/toolkit';
import {
    IHealthProtocolInitialState,
    ITreatmentProtocol,
    ITreatmentProtocolFilter,
} from '../../utils/Interface/HealthProtocolInterface';
import { IMedicalItem } from '../../utils/Interface/HealthSection/MedicalItemInterface';
import { ActionTypes } from '../Actions';
import { TREATMENT_PROTOCOL_INITIAL_STATE } from '../Constants/treatmentProtocolInitialState';

const TreatmentProtocolReducer = (
    state: IHealthProtocolInitialState = TREATMENT_PROTOCOL_INITIAL_STATE,
    action: AnyAction,
) => {
    switch (action.type) {
        case ActionTypes.SET_TREATMENT_PROTOCOLS:
            return {
                ...state,
                treatmentProtocols: action.payload as ITreatmentProtocol[],
            };
        case ActionTypes.APPEND_TREATMENT_PROTOCOLS:
            return {
                ...state,
                treatmentProtocols: [
                    ...state.treatmentProtocols,
                    ...action.payload,
                ] as ITreatmentProtocol[],
            };
        //remove setting single treatment protocol from redux - use react state instead
        case ActionTypes.SET_NEW_TREATMENT_PROTOCOL:
            return {
                ...state,
                newTreatmentProtocol: action.payload,
            };
        case ActionTypes.SET_TREATMENT_PROTOCOL_MEDICINES:
            return {
                ...state,
                treatmentProtocolMedicines: action.payload as IMedicalItem[],
            };
        case ActionTypes.SET_TREATMENT_PROTOCOLS_FILTER:
            return {
                ...state,
                treatmentProtocolFilter: {
                    ...state.treatmentProtocolFilter,
                    ...action.payload,
                } as ITreatmentProtocolFilter,
            };
        case ActionTypes.SET_TREATMENT_PROTOCOL_SUBMIT_PENDING:
            return {
                ...state,
                treatmentProtocolSubmitPending: action.payload,
            };
        default:
            return state;
    }
};

export default TreatmentProtocolReducer;
