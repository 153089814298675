import { useState } from 'react';

const useModalStatus = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [id, setId] = useState<number>(0);

    const handleOpen = () => {
        setId(0);
        setIsOpen(true);
    };

    const handleClose = (Id?: number) => {
        setId(Id || 0);
        setIsOpen(false);
    };

    return Object.freeze({
        open: handleOpen,
        close: handleClose,
        isOpen,
        id,
    });
};

export { useModalStatus };
