import * as genericReports from './genericReports.json';
import * as hotlist from './hotlist.json';
import * as mortalityReport from './mortalityReport.json';
import * as treatments from './treatments.json';
import * as receiveCattle from './receiveCattle.json';
import * as realizer from './realizer.json';
import * as hospital from './hospital.json';
import * as lots from './lots.json';
import * as shipCattle from './shipCattle.json';
import * as common from './common.json';
import * as userManagement from './userManagement.json';
import * as financeApp from './financeApp.json';
import * as auth from './auth.json';

const en = {
    genericReports,
    hotlist,
    mortalityReport,
    treatments,
    receiveCattle,
    realizer,
    hospital,
    lots,
    shipCattle,
    common,
    userManagement,
    financeApp,
    auth,
};

export default en;
