export const riderConstants = {
    createNewRiders: {
        heading: 'Create Rider',
        form: {
            name: {
                name: 'name',
                label: 'Rider Name',
                maxLength: 50,
                placeholder: 'Enter Rider Name',
            },
        },
    },
    riderDetails: {
        placeholder: 'Search',
        rider: 'Rider',
        heading: 'Manage Riders',
        maxLength: 50,
        name: 'Rider',
    },
    riderColumnDetail: [
        { label: 'Rider Name', key: 'name' },
        { label: 'Status', key: 'active' },
    ],
    clearFilter: 'Clear Filter',
    editRider: 'Edit Rider - ',
    activateModalConstant: {
        heading: 'Activate Rider',
        text: ' is currently Inactive. Activate and use this Rider.',
    },
    riderFilter: 'riderFilter',
};
