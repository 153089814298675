import { useNavigate } from 'react-router-dom';
import { setRoute, setShowModal } from '../../Redux/Reducer/feedBillUpdate';
import { useAppDispatch, useAppSelector } from '../../Redux/Store';

const SidebarLogo = (props: any) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { lastUpdate } = useAppSelector(state => state.feedBillUpdate);
    const handleClick = () => {
        if (lastUpdate) {
            dispatch(setShowModal(true));
            dispatch(setRoute('/'));
        } else {
            navigate('/');
        }
    };

    return (
        <img
            {...props}
            alt="./sidebar_financial.png"
            src={require(`./sidebar_financial.png`)}
            title={`CC v${process.env.REACT_APP_VERSION || '0.0.0.0'}`}
            onClick={handleClick}
        />
    );
};

export default SidebarLogo;
