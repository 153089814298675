import { Spinner } from 'react-bootstrap';

const TableRowLoading = ({
    colspan,
    className,
    spinnerColor,
}: {
    colspan?: number;
    className?: string;
    spinnerColor?: string;
}) => {
    return (
        <tr
            className={`justify-content-center buypo-table-row mt-4 ${
                className || ''
            }`}
        >
            <td colSpan={colspan ? colspan : 11} className="text-center">
                <Spinner
                    animation="border"
                    data-size="lg"
                    className={`text-center ${spinnerColor || ''}`}
                />
            </td>
        </tr>
    );
};

export default TableRowLoading;
