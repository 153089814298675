import { IHotListInitialState } from '../../utils/Interface/HotListInterface';

export const CREATE_HOTLIST_INITIAL_STATE: IHotListInitialState = {
    filter: {
        lotId: 0,
        penId: 0,
        limit: 20,
        offset: 0,
        include:
            'Rider,Animal,Lot,Diagnosis,TreatmentProtocol,MedicalItems,CleanDate,Pen,Treatment',
    },
    lots: [],
    loading: true,
    canCallApi: true,
    hotListDetail: [],
    hotListFilterItems: [],
    completeHotList: [],
};
