import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { OWNERS_INITIAL_STATE, createOwnerOption } from './ownerConstants';
import { IOwner } from './ownerInterfaces';

const ownerSlice = createSlice({
    name: 'owner',
    initialState: OWNERS_INITIAL_STATE,
    reducers: {
        setOwners: (state, action: PayloadAction<IOwner[]>) => {
            state.owners = action.payload;
            state.ownersWithCreate = [createOwnerOption, ...action.payload];
        },
    },
});

export const ownerActions = ownerSlice.actions;
export const ownerReducer = ownerSlice.reducer;
