export const TANK_INITIAL_STATE = {
    filter: {
        name: '',
        show: 'active',
    },
    createTankInitial: {
        name: '',
        active: true,
        preload: true,
        feedLotId: '',
    },
    loader: true,
};
