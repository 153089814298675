import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PEN_HISTORY_INITIAL_STATE } from '../Constants/penHistoryInitialState';
import {
    IPen,
    IPenHistory,
    IPenHistoryInitialState,
} from '../../utils/Interface/PenHistoryInterface';

const penHistorySlice = createSlice({
    name: 'penHistory',
    initialState: PEN_HISTORY_INITIAL_STATE as IPenHistoryInitialState,
    reducers: {
        setPens: (state, action: PayloadAction<IPen[]>) => {
            state.pens = action.payload;
        },
        setSelectedPen: (state, action: PayloadAction<number>) => {
            state.selectedPen = action.payload;
        },
        setPenHistory: (state, action: PayloadAction<IPenHistory[]>) => {
            state.penHistory = action.payload;
        },
        setIsPenDropdownDisabled: (state, action: PayloadAction<boolean>) => {
            state.isPenDropDownDisabled = action.payload;
        },
        setPenHistoryDate: (state, action: PayloadAction<string>) => {
            state.penHistoryDate = action.payload;
        },
    },
});

export const {
    setPens,
    setSelectedPen,
    setPenHistory,
    setIsPenDropdownDisabled,
    setPenHistoryDate,
} = penHistorySlice.actions;

export const penHistoryReducer = penHistorySlice.reducer;
export const penHistoryActions = penHistorySlice.actions;
