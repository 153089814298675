import { FC } from 'react';

const LoginIcon: FC = props => {
    return (
        <img
            width={180}
            {...props}
            src={require(`./login_icon.png`)}
            alt="login_icon.png"
        />
    );
};

export default LoginIcon;
