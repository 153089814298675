interface IUsCurrencyFormatter {
    formatAsUSCurrency: (input: string | number) => string;
}

const useUSCurrencyFormatter = (): IUsCurrencyFormatter => {
    const formatAsUSCurrency = (input: string | number): string => {
        const numberValue =
            typeof input === 'string' ? parseFloat(input) : input;
        if (isNaN(numberValue)) {
            return '';
        }
        const formattedCurrency = numberValue.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
        });

        return formattedCurrency.replace('$', '');
    };

    return { formatAsUSCurrency };
};

export default useUSCurrencyFormatter;
