import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { DESTINATION_INITIAL_STATE } from '../Constants/destinationInitialState';
import { IFilter } from '../../utils/Interface/DestinationInterface';

const destinationSlice = createSlice({
    name: 'destination',
    initialState: { ...DESTINATION_INITIAL_STATE },
    reducers: {
        setDestinationFilter: (state, action: PayloadAction<IFilter>) => {
            state.filter = action.payload;
        },
        setDestinationDetailsLoader: (
            state,
            action: PayloadAction<boolean>,
        ) => {
            state.loader = action.payload;
        },
    },
});

export const { setDestinationFilter, setDestinationDetailsLoader } =
    destinationSlice.actions;
export const DestinationSlice = destinationSlice.reducer;
