const HealthIcon = (props: any) => {
    return (
        <img
            {...props}
            alt="health_icon.png"
            src={require('./health_icon.png')}
        />
    );
};

export default HealthIcon;
