import { Button, Col } from 'react-bootstrap';
import SettingsItemDetail from './FMSettings/SettingsItemDetail';
import Constants from '../../utils/Constants';
import { useEffect, useState, useMemo } from 'react';
import { useModalStatus } from '../../commonHooks/useModalStatus';
import { useFeedlotId } from '../../commonHooks/useFeedlotId';
import { useAppDispatch, useAppSelector } from '../../Redux/Store';
import StorageService from '../../Services/storage/storage.service';
import { useDebouncedEffect } from '../useDebounceEffect';
import VendorModal from './VendorModal';
import { DeleteItemModal } from './FMSettings/DeleteItemModal';
import { FmSettingsConstant } from '../../utils/constants/FmSettingsConstants';
import { VENDOR_INITIAL_STATE } from '../../Redux/Constants/vendorInitialState';
import { IFilter, IVendorDetail } from '../../utils/Interface/VendorInterface';
import {
    deleteVendor,
    fetchVendorDetails,
    updateVendorDetail,
} from '../../Redux/Actions/vendorActions';
import { setVendorFilter } from '../../Redux/Reducer/vendorSlice';
import { vendorConstants } from '../../utils/constants/VendorConstants';

const VendorDetails = () => {
    const { filter, loader } = useAppSelector(state => state.vendor);
    const [searchQuery, setSearchQuery] = useState<string | undefined>(
        undefined,
    );
    const [vendorDetails, setVendorDetails] = useState<IVendorDetail[]>([]);
    const [selectedVendor, setSelectedVendor] = useState<IVendorDetail>(
        VENDOR_INITIAL_STATE.createVendorInitial,
    );
    const storage = useMemo(() => new StorageService(), []);
    const {
        isOpen: isVendorModalOpen,
        open: openVendorModal,
        close: closeVendorModal,
    } = useModalStatus();
    const {
        isOpen: isDeleteModalOpen,
        open: openDeleteModal,
        close: closeDeleteModal,
    } = useModalStatus();
    const feedlotId = useFeedlotId();
    const dispatch = useAppDispatch();

    const handleEditVendorDetail = (vendor: IVendorDetail): void => {
        setSelectedVendor(vendor);
        openVendorModal();
    };

    const fetchVendorData = (vendorFilter: IFilter = filter): void => {
        if (feedlotId) {
            dispatch(fetchVendorDetails(setVendorDetails, vendorFilter));
        }
    };

    const setVendorFilterInStorage = (filterValues: IFilter) => {
        storage.setValue(
            vendorConstants.vendorFilter,
            filterValues,
            Constants.Storage.LOCAL,
        );
    };
    const handleFilter = (filterName: string, filterValue: string): void => {
        const updatedFilter = { ...filter, [filterName]: filterValue };
        fetchVendorData(updatedFilter);
        dispatch(setVendorFilter(updatedFilter));
        setVendorFilterInStorage(updatedFilter);
    };

    const closeModalWithChanges = (isEdited: boolean): void => {
        if (isEdited && feedlotId) {
            fetchVendorData(filter);
        }
        closeVendorModal();
    };

    const resetFilter = (): void => {
        const updatedFilter = VENDOR_INITIAL_STATE.filter;
        fetchVendorData(updatedFilter);
        setSearchQuery('');
        dispatch(setVendorFilter(updatedFilter));
        setVendorFilterInStorage(updatedFilter);
    };

    const handleCreateVendor = (): void => {
        if (feedlotId) {
            setSelectedVendor({
                ...VENDOR_INITIAL_STATE.createVendorInitial,
            });
        }
        openVendorModal();
    };

    const handleDeleteModalHandler = (vendor: IVendorDetail) => {
        setSelectedVendor(vendor);
        openDeleteModal();
    };

    const handleDeleteVendor = (shouldTakeAction: boolean) => {
        if (feedlotId && shouldTakeAction) {
            if (!selectedVendor.isAssociated || selectedVendor.active) {
                const isActiveAndAssociated =
                    selectedVendor.isAssociated && selectedVendor.active;
                const action = isActiveAndAssociated
                    ? updateVendorDetail
                    : deleteVendor;
                const updatedVendorDetail = isActiveAndAssociated
                    ? { ...selectedVendor, active: false }
                    : selectedVendor;
                dispatch(action(updatedVendorDetail, { fetchVendorData }));
            }
        }
        closeDeleteModal();
    };

    useDebouncedEffect(
        () => {
            if (searchQuery !== undefined) {
                handleFilter('name', searchQuery);
            }
        },
        [searchQuery],
        1000,
    );

    useEffect(() => {
        if (feedlotId) {
            const vendorFilter = storage.getValue(vendorConstants.vendorFilter);
            if (Object.keys(vendorFilter).length > 0) {
                dispatch(setVendorFilter(vendorFilter));
            }
            if (!vendorFilter.name) {
                if (vendorFilter.show) {
                    fetchVendorData(vendorFilter);
                } else {
                    dispatch(fetchVendorDetails(setVendorDetails, filter));
                }
            } else {
                setSearchQuery(vendorFilter?.name || filter.name);
            }
        }
    }, [feedlotId, dispatch, storage]);

    return (
        <div className="pens-container">
            <h5 className="my-3">{vendorConstants.vendorDetails.heading}</h5>
            <div className="d-flex align-items-end mb-4">
                <Col xs={3} className="d-flex flex-column">
                    <label>{vendorConstants.vendorDetails.vendor}</label>
                    <input
                        className="text-secondary my-2"
                        type="text"
                        placeholder={
                            vendorConstants.vendorDetails.vendorPlaceholder
                        }
                        maxLength={vendorConstants.vendorDetails.maxLength}
                        value={searchQuery ? searchQuery : ''}
                        onChange={e => setSearchQuery(e.target.value)}
                    />
                </Col>
                <Col xs={3}>
                    <Button
                        className="secondary my-2 mx-4"
                        onClick={resetFilter}
                    >
                        {vendorConstants.clearFilter}
                    </Button>
                </Col>
                <Col xs={6} className="d-flex justify-content-end">
                    <Button className="my-2" onClick={handleCreateVendor}>
                        {vendorConstants.createVendors}
                    </Button>
                </Col>
            </div>
            <div
                className="o-switch btn-group show-toggle my-1"
                data-toggle="buttons"
                role="group"
            >
                {FmSettingsConstant.filterOptions.map(option => (
                    <label
                        key={option.value}
                        className={`btn btn-secondary py-1 ${
                            filter.show === option.value
                                ? FmSettingsConstant.active.value
                                : ''
                        }`}
                    >
                        <input
                            type="radio"
                            onChange={() =>
                                handleFilter(option.name, option.value)
                            }
                            checked={filter.show === option.value}
                        />
                        {option.label}
                    </label>
                ))}
            </div>
            <SettingsItemDetail<IVendorDetail>
                columnDetail={vendorConstants.vendorColumnDetail}
                itemDetail={vendorDetails}
                handleEditDetail={handleEditVendorDetail}
                handleDeleteModalHandler={handleDeleteModalHandler}
                loader={loader}
                columnSize={1}
                margin={3}
            />
            {isVendorModalOpen && (
                <VendorModal
                    showModal={isVendorModalOpen}
                    closeModalWithChanges={closeModalWithChanges}
                    editVendorDetails={selectedVendor}
                    showActiveField={true}
                />
            )}
            {isDeleteModalOpen && (
                <DeleteItemModal
                    item={selectedVendor}
                    handleDeleteItem={handleDeleteVendor}
                    showModal={isDeleteModalOpen}
                    itemName={vendorConstants.vendorDetails.name}
                ></DeleteItemModal>
            )}
        </div>
    );
};

export default VendorDetails;
