import { currentDate_deprecated } from '../../helpers';
import { IManualFeedEntryInitialState } from '../../utils/Interface/ManualFeedEntryInterface';
import { manualFeedEntryLabelConstants } from '../../utils/LabelConstant';

export const MANUAL_FEED_ENTRY_INITIAL_STATE: IManualFeedEntryInitialState = {
    loading: false,
    feedEntryRationDetails: {
        date: currentDate_deprecated('YYYY-MM-DD'),
        rationId: 0,
    },
    feedEntryRationDetailsError: {
        date: false,
        rationId: false,
    },
    lots: [],
    rations: [],
    pens: [{ name: manualFeedEntryLabelConstants.Pen.SELECT_PEN, id: 0 }],
    feedEntryPenDetail: {
        penId: 0,
        penInfo: [], //need to remove on clearing the flag REACT_APP_FEATURE_CA10333_REDUCE_INFO_IN_REQUEST_RESPONSE_FOR_MFE
        weight: '',
        totalHeadCount: '',
        estimatedHeadCount: '',
        penSnapshot: {},
        existingEstHeadCountModalLotDetailsCache: [],
        existingEstHeadCountModalLotDetails: [],
        estHeadCountModalLotDetailsCache: [],
        estHeadCountModalLotDetails: [],
        estHeadCountModalLotDetailsError: [],
    },
    feedEntryPensDetails: [
        {
            penId: 0,
            penInfo: [], //need to remove on clearing the flag REACT_APP_FEATURE_CA10333_REDUCE_INFO_IN_REQUEST_RESPONSE_FOR_MFE
            weight: '',
            totalHeadCount: '',
            estimatedHeadCount: '',
            penSnapshot: {},
            existingEstHeadCountModalLotDetailsCache: [],
            existingEstHeadCountModalLotDetails: [],
            estHeadCountModalLotDetails: [],
            estHeadCountModalLotDetailsCache: [],
            estHeadCountModalLotDetailsError: [],
        },
    ],
    existingEstHeadCountModalLotDetail: {
        lotName: '',
        name: '',
        lotId: '',
        lotPenId: '',
        hdCount: '',
        penId: '',
        estimatedHeadCount: '',
        createdDateTime: '',
    },
    estHeadCountModalLotDetail: {
        lotName: '',
        name: '',
        lotId: '',
        lotPenId: '',
        hdCount: '',
        penId: '',
        estimatedHeadCount: '',
        createdDateTime: '',
    },
    estHeadCountModalLotDetailError: {
        estimatedHeadCount: false,
    },
    existingFeedEntryPensDetail: {
        penId: 0,
        penName: '', //need to remove on clearing the flag REACT_APP_FEATURE_CA10333_REDUCE_INFO_IN_REQUEST_RESPONSE_FOR_MFE
        totalHeadCount: 0,
        estimatedHeadCount: 0,
        penInfo: [], //need to remove on clearing the flag REACT_APP_FEATURE_CA10333_REDUCE_INFO_IN_REQUEST_RESPONSE_FOR_MFE
        penSnapshot: undefined,
        totalExistingEntryEstHeadCount: 0,
        estimatedHeadTracker: {
            estimatedHeads: [
                {
                    createdDateTime: '',
                    hdCount: 0,
                    lotId: 0,
                    lotPenId: 0,
                    penId: 0,
                    lotName: '',
                    isNewRow: false,
                },
            ],
        },
        existingEstimatedHeads: [],
        existingEstimatedHeadsCache: [],
        existingEstimatedHeadsError: [],
        feed1: 0,
        feed2: 0,
        feed3: 0,
        feed4: 0,
        totalFeed: 0,
        feedCall: 0,
        generatedInvoiceCutoffDate: '',
        isReadOnlyFeed1: false,
        isReadOnlyFeed2: false,
        isReadOnlyFeed3: false,
        isReadOnlyFeed4: false,
    },
    existingFeedEntryPensDetails: [
        {
            penId: 0,
            penName: '', //need to remove on clearing the flag REACT_APP_FEATURE_CA10333_REDUCE_INFO_IN_REQUEST_RESPONSE_FOR_MFE
            totalHeadCount: '0',
            estimatedHeadCount: '0',
            penInfo: [], //need to remove on clearing the flag REACT_APP_FEATURE_CA10333_REDUCE_INFO_IN_REQUEST_RESPONSE_FOR_MFE
            penSnapshot: undefined,
            totalExistingEntryEstHeadCount: 0,
            estimatedHeadTracker: {
                estimatedHeads: [
                    {
                        createdDateTime: '',
                        hdCount: 0,
                        lotId: 0,
                        lotPenId: 0,
                        penId: 0,
                        lotName: '',
                        isNewRow: false,
                    },
                ],
            },
            existingEstimatedHeads: [],
            existingEstimatedHeadsCache: [],
            existingEstimatedHeadsError: [],
            feed1: 0,
            feed2: 0,
            feed3: 0,
            feed4: 0,
            totalFeed: 0,
            feedCall: 0,
            generatedInvoiceCutoffDate: '',
            isReadOnlyFeed1: false,
            isReadOnlyFeed2: false,
            isReadOnlyFeed3: false,
            isReadOnlyFeed4: false,
        },
    ],
    fetchedExistingFeedEntryPensDetails: [],
    existingFeedEntryPensDetailError: {
        penId: false,
        estimatedHeadCount: false,
    },
    existingFeedEntryPensDetailsError: [
        {
            penId: false,
            estimatedHeadCount: false,
        },
    ],
    feedEntryPenDetailError: {
        penId: false,
        estimatedHeadCount: false,
    },
    feedEntryPensDetailsError: [
        {
            penId: false,
            estimatedHeadCount: false,
        },
    ],
    showEstimatedHdCountField: true,
    isExistingEstHdCountModalReadonly: true,
    selectedPenIndex: 0,
    isRecordSubmitted: false,
    submitPending: false,
};
