import { Button, Spinner } from 'react-bootstrap';
import { ISpinnerSubmitButton } from '../../utils/Interface/CommonInterface';

const SpinnerSubmitButton = ({
    disabled = false,
    variant = 'primary',
    submitPending = false,
    className = '',
    buttonContent = '',
    hidden = false,
    type = 'button',
    size,
    onClick,
}: ISpinnerSubmitButton) => (
    <Button
        type={type}
        variant={variant}
        className={
            submitPending
                ? `loading-button ${className || ''}`
                : `${className || ''}`
        }
        hidden={hidden}
        onClick={onClick}
        disabled={disabled}
        size={size}
    >
        <span className="pt-2 align-items-center justify-content-center">
            {submitPending && <Spinner size="sm" />} {buttonContent}
        </span>
    </Button>
);

export default SpinnerSubmitButton;
