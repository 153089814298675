import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import en from './locales/en';
import es from './locales/es';
import { commonFeatures } from '../apps/common/featureFlags/commonFeatures';

const { isCA10248_i18nBug } = commonFeatures;

//delete me with feature flag isCA10248_i18nBug
interface Translations {
    [moduleName: string]: {
        [key: string]: string;
    };
}

//delete me with feature flag isCA10248_i18nBug
const moduleNames = [
    'hotlist',
    'mortalityReport',
    'treatments',
    'receiveCattle',
    'realizer',
    'hospital',
    'lots',
    'shipCattle',
    'common',
    'userManagement',
    'financeApp',
    'auth',
];

//delete me with feature flag isCA10248_i18nBug
async function getTranslations(language: string): Promise<Translations> {
    const translations: Translations = {};
    await Promise.all(
        moduleNames.map(async (moduleName: string | number) => {
            const translation = await import(
                `./locales/${language}/${moduleName}.json`
            );
            translations[moduleName] = translation;
        }),
    );
    return translations;
}

if (isCA10248_i18nBug) {
    //when removing feature flag isCA10248_i18nBug everything EXCEPT this block of code and imports should be deleted
    i18n.use(LanguageDetector).use(initReactI18next).init({
        fallbackLng: 'en',
        resources: {
            en,
            es,
        },
    });
} else {
    //delete me with feature flag isCA10248_i18nBug
    (async function initI18n() {
        const enTranslations = await getTranslations('en');
        const esTranslations = await getTranslations('es');
        i18n.use(LanguageDetector)
            .use(initReactI18next)
            .init({
                lng: 'en',
                fallbackLng: 'en',
                resources: {
                    en: enTranslations,
                    es: esTranslations,
                },
            });
        return i18n;
    })();
}

export default i18n;
