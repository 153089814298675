import {
    IProcessingProtocolFilter,
    IWorksheetProcessing,
} from './../../utils/Interface/HealthSection/MedicalItemInterface';
import { IMedicalItem } from '../../utils/Interface/HealthSection/MedicalItemInterface';
import { IProcessingProtocol } from '../../utils/Interface/ProcessingInterface';

export const PROCESSING_PROTOCOL_INITIAL_STATE = {
    processingProtocolMedicalItems: [] as IMedicalItem[],
    processingProtocols: [] as IProcessingProtocol[],
    generatedInvoiceCutoffDate: '',
    worksheetProcessing: {
        processing: [],
        reprocessing: [],
    } as IWorksheetProcessing,
    processingFilter: {
        processingName: '',
        medicalName: '',
    } as IProcessingProtocolFilter,
    insufficientInventory: [] as number[],
    processingProtocolSubmitPending: false,
    snapshotIsLoading: false,
};
