export const ShawnaSpreadSheetConstants = {
    monthsInitialState: {
        jan: 0,
        feb: 0,
        march: 0,
        april: 0,
        may: 0,
        june: 0,
        july: 0,
        aug: 0,
        sep: 0,
        oct: 0,
        nov: 0,
        dec: 0,
    },
    feedBillInitialState: {
        purchase: 0,
        feed: 0,
        trucking: 0,
        brands: 0,
        processing: 0,
    },
    puchaseDataInitialState: {
        price: 0,
        headCountChange: 0,
        weight: 0,
        weightPerHd: 0,
    },
    soldDataInitialState: {
        price: 0,
        headCountChange: 0,
        payWeight: 0,
    },
    feedBillDetail: [
        ' ',
        'Purchase',
        'Feed',
        'Freight',
        'Brands/Health',
        'Meds/Processing',
        'Totals',
    ],
    feedBillCategories: [
        { category: 'Purchase', key: 'purchase' },
        { category: 'Feed', key: 'feed' },
        { category: 'Trucking', key: 'trucking' },
        { category: 'Brands', key: 'brands' },
        { category: 'Processing', key: 'processing' },
    ],
    heading: {
        purchase: 'Purchases',
        sold: 'Sales',
        total: 'Total',
        expenss: 'Expenses',
        lotNumber: 'Lot Number',
        sex: 'Sex',
        death: 'Deads',
        costPerHead: 'Cost Per Head',
        currentHeadCount: 'Current Head Count',
        purchaseEntry: [
            'Purchase Date',
            'Origin',
            'Head',
            'Wt/Hd',
            'Weight',
            'Price',
            'Price/Lb',
        ],
        SoldEntry: [
            'Sale Date',
            'Buyers',
            'Head',
            'Price/Hd',
            'Price',
            'Weight',
        ],
    },
    propertiesToSumIn: {
        FeedBillData: ['purchase', 'feed', 'trucking', 'brands', 'processing'],
        SoldData: ['price', 'headCountChange', 'payWeight'],
        PurchaseData: ['price', 'headCountChange', 'weight', 'weightPerHd'],
    },
    other: {
        selectLot: 'Select Lot',
    },
};
