import { Navigate, Outlet } from 'react-router-dom';
import useAuthContext from '../../apps/common/modules/auth/hooks/useAuthContext';
import { AppRole } from '../../apps/common/modules/auth/constants/AuthConstants';
import { useAppSelector } from '../../Redux/Store';
import Constants from '../Constants';
import { RestrictedUrls } from '../constants/RoleConstants';

const PrivateRoute = ({
    allowedRoles,
    appRole,
    path,
}: {
    allowedRoles: string[];
    appRole: AppRole;
    path: string;
}) => {
    const {
        getUsersRoles,
        isAuthenticated,
        getUsersAppSubscriptions,
        hasTenantId,
    } = useAuthContext();
    const { windowSize } = useAppSelector(state => state.common);

    const usersRoles = getUsersRoles();
    const hasRequiredRole = allowedRoles.some(allowedRole =>
        usersRoles.includes(allowedRole),
    );

    const usersAppSubscriptions = getUsersAppSubscriptions();
    const isSubscribedToApp = usersAppSubscriptions.includes(appRole);

    return !isAuthenticated() ||
        !hasTenantId() ||
        !hasRequiredRole ||
        (windowSize.innerWidth <= Constants.GRID_VIEW_MEDIUM_SIZE &&
            RestrictedUrls.includes(path)) ? (
        <Navigate to="/" />
    ) : !isSubscribedToApp ? (
        <Navigate to="/products" />
    ) : (
        <Outlet />
    );
};

export default PrivateRoute;
