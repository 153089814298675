import { date } from '../../helpers';
import {
    IExpectedDateRange,
    IInvoiceCharge,
    IInvoicePDFRequestModel,
    IToDateSummary,
} from './BillingInterface';
import { IGenericFilter } from './CommonInterface';
import { IFMLot } from './FeedlotInterface';
import { IDestinationPens, ISourcePens } from './MoveCattleInterface';

export interface IFilter extends IGenericFilter {
    lotName: string;
    ownerName: string;
    offset: number;
    limit: number;
}

export enum LotSummaryTab {
    ReceivingAndShippingHistory = 'receivingAndShippingHistory',
    PenMovementHistory = 'penMovementHistory',
    TreatmentHistory = 'treatmentHistory',
    ProcessingReprocessing = 'processingReprocessing',
    Invoices = 'invoices',
    DailyCharges = 'dailyCharges',
}

export type IFilterField = 'lot' | 'owner' | 'offset' | 'limit';

export interface IHandleScrollEvent {
    target: EventTarget;
}

export interface ILotSummaryDetails {
    lotId: number;
    lotName: string;
    ownerName: string;
    sexName: string;
    headCount: number;
    penNames: string[];
    estimatedCurrentWeight: number;
    averageDaysOnFeed: number;
}

export interface ILotSummaryDetailsTableBodyRow {
    lotSummaryDetails: ILotSummaryDetails;
}

export interface IPen {
    date: Date | string;
    hdCount: number;
    moveCattleDestinations: IDestinationPens[];
    moveCattleId: number;
    moveCattleSources: ISourcePens[];
}

export interface ILotSummaryTableBodyRow {
    penBodyDetails: IPen;
}

export interface IYardLayoutPen {
    pen: string;
    designation: string;
    lot: Date | string;
    owner: string;
    sex: string;
    hdCount: string;
}

export interface IYardLayout {
    totalHeadCount: number;
    date: Date | null;
    pens: IYardLayoutPen[];
}

export interface IYardLayoutResponseLots {
    lotId: number;
    lotName: string;
    ownerId: number;
    ownerName: string;
    sex: string;
}

export interface IYardLayoutResponsePenInfo {
    penId: number;
    name: string;
    hdCount: number;
    kind: number;
    lots: IYardLayoutResponseLots[];
}

export interface IYardLayoutResponse {
    penInfo: IYardLayoutResponsePenInfo[];
    totalHeadCount: number;
}

export interface ILotSummary {
    currentHeadCount: {
        dead: number;
        periodDead?: number;
        purchased: number;
        realizers: number;
        remaining: number;
        sold: number;
    };
    dateIn: Date | string;
    fmFeedLot: {
        feedLotId: number;
        label: string;
        manager: string;
        location: {
            address1: string;
            address2: string;
            altContactNumber: string;
            altEmail: string;
            city: string;
            contactName: string;
            contactNumber: string;
            coordinates: string;
            country: string;
            email: string;
            name: string;
            postalCode: string;
            state: string;
        };
    };
    fmLot: IFMLot;
    owner: {
        address1?: string;
        address2?: string;
        altContactNumber?: string;
        city?: string;
        contactName?: string;
        contactNumber?: string;
        coordinates?: string;
        country?: string;
        email?: string;
        name?: string;
        postalCode?: string;
        state?: string;
    };
    statementDate: Date | string;
    yardageCharge: number | null;
    charges?: IInvoiceCharge;
    dateRange?: IExpectedDateRange;
    periodToDate?: IToDateSummary;
    lifetimeToDate?: IToDateSummary;
}

export interface IPreviewDetailsBody {
    previewData: ILotSummary;
    showWaterMark: boolean;
    expectedDateRange?: IExpectedDateRange;
}
export interface IInvoicePreviewParams {
    previewData: IInvoicePDFRequestModel;
    showWaterMark: boolean;
}

export interface ILotTreatment {
    id: number;
    date: date;
    owner: string;
    lot: string;
    eid: string;
    animalTag: string;
    DOF: number;
    diagnosis: string;
    treatmentProtocol: string;
    rider: string;
    chargeout: number;
}

export interface ILotCloseStepValidation {
    openShipCattle: number[];
    openReceiveCattle: number[];
    openRealizer: number[];
    openProcessing: number[];
    openReprocessing: number[];
}

export interface ILotCloseoutValidation {
    openShipCattle: number[];
    openReceiveCattle: number[];
    openRealizer: number[];
}

export interface ILotCloseBillingValidation {
    openProcessing: number[];
    openReprocessing: number[];
}

export interface ILotCloseoutMessage {
    lotName: string;
    unfinishedRecords: ILotCloseoutValidation | ILotCloseBillingValidation;
}
