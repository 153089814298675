import { useEffect, useState, useMemo } from 'react';
import { Button, Col } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../Redux/Store';
import { useModalStatus } from '../../commonHooks/useModalStatus';
import { useFeedlotId } from '../../commonHooks/useFeedlotId';
import { useDebouncedEffect } from '../useDebounceEffect';
import {
    deleteTech,
    fetchTechDetails,
    updateTechDetail,
} from '../../Redux/Actions/techActions';
import { setTechFilter } from '../../Redux/Reducer/techSlice';
import { TECH_INITIAL_STATE } from '../../Redux/Constants/techInitialState';
import TechModal from './TechModal';
import SettingsItemDetail from './FMSettings/SettingsItemDetail';
import { DeleteItemModal } from './FMSettings/DeleteItemModal';
import Constants from '../../utils/Constants';
import StorageService from '../../Services/storage/storage.service';
import { techConstants } from '../../utils/constants/TechConstants';
import { FmSettingsConstant } from '../../utils/constants/FmSettingsConstants';
import { IFilter, ITechDetail } from '../../utils/Interface/TechInterface';

const TechDetails = () => {
    const { filter, loader } = useAppSelector(state => state.tech);
    const storage = useMemo(() => new StorageService(), []);
    const feedlotId = useFeedlotId();
    const dispatch = useAppDispatch();
    const {
        isOpen: isTechModalOpen,
        open: openTechModal,
        close: closeTechModal,
    } = useModalStatus();
    const {
        isOpen: isDeleteModalOpen,
        open: openDeleteModal,
        close: closeDeleteModal,
    } = useModalStatus();
    const [searchQuery, setSearchQuery] = useState<string | undefined>(
        undefined,
    );
    const [techDetails, setTechDetails] = useState<ITechDetail[]>([]);
    const [selectedTech, setSelectedTech] = useState<ITechDetail>(
        TECH_INITIAL_STATE.createTechInitial,
    );

    const fetchTechData = (techFilter: IFilter = filter): void => {
        if (feedlotId) {
            dispatch(fetchTechDetails(feedlotId, setTechDetails, techFilter));
        }
    };

    const handleEditTechDetail = (tech: ITechDetail): void => {
        setSelectedTech(tech);
        openTechModal();
    };

    const setTechFilterInStorage = (filterValues: IFilter) => {
        storage.setValue(
            techConstants.techFilter,
            filterValues,
            Constants.Storage.LOCAL,
        );
    };

    const handleFilter = (filterName: string, filterValue: string) => {
        const updatedFilter = { ...filter, [filterName]: filterValue };
        fetchTechData(updatedFilter);
        dispatch(setTechFilter(updatedFilter));
        setTechFilterInStorage(updatedFilter);
    };

    const closeModalWithChanges = (isEdited: boolean): void => {
        if (isEdited) {
            fetchTechData(filter);
        }
        closeTechModal();
    };

    const resetFilter = (): void => {
        const updatedFilter = TECH_INITIAL_STATE.filter;
        fetchTechData(updatedFilter);
        setSearchQuery('');
        dispatch(setTechFilter(updatedFilter));
        setTechFilterInStorage(updatedFilter);
    };

    const handleCreateTech = (): void => {
        if (feedlotId) {
            setSelectedTech({
                ...TECH_INITIAL_STATE.createTechInitial,
                feedLotId: feedlotId,
            });
        }
        openTechModal();
    };

    const handleDeleteModalHandler = (tech: ITechDetail) => {
        setSelectedTech(tech);
        openDeleteModal();
    };

    const handleDeleteTech = (shouldTakeAction: boolean) => {
        if (feedlotId && shouldTakeAction) {
            if (!selectedTech.isAssociated || selectedTech.active) {
                const isActiveAndAssociated =
                    selectedTech.isAssociated && selectedTech.active;
                const action = isActiveAndAssociated
                    ? updateTechDetail
                    : deleteTech;
                const updatedTechDetail = isActiveAndAssociated
                    ? { ...selectedTech, active: false }
                    : selectedTech;
                dispatch(
                    action(feedlotId, updatedTechDetail, { fetchTechData }),
                );
            }
        }
        closeDeleteModal();
    };

    useDebouncedEffect(
        () => {
            if (searchQuery !== undefined) {
                handleFilter('name', searchQuery);
            }
        },
        [searchQuery],
        1000,
    );

    useEffect(() => {
        if (feedlotId) {
            const techFilter = storage.getValue(techConstants.techFilter);
            if (Object.keys(techFilter).length > 0) {
                dispatch(setTechFilter(techFilter));
            }
            if (!techFilter.name) {
                if (techFilter.show) {
                    fetchTechData(techFilter);
                } else {
                    dispatch(
                        fetchTechDetails(feedlotId, setTechDetails, filter),
                    );
                }
            } else {
                setSearchQuery(techFilter?.name || filter.name);
            }
        }
    }, [feedlotId, dispatch, storage]);

    return (
        <div className="pens-container">
            <h5 className="my-3">{techConstants.techDetails.heading}</h5>
            <div className="d-flex align-items-end">
                <Col xs={3} className="d-flex flex-column">
                    <label>{techConstants.techDetails.tech}</label>
                    <input
                        className="text-secondary my-2"
                        type="text"
                        placeholder={techConstants.techDetails.placeholder}
                        maxLength={techConstants.techDetails.maxLength}
                        value={searchQuery ? searchQuery : ''}
                        onChange={e => setSearchQuery(e.target.value)}
                    />
                </Col>
                <Col xs={3}>
                    <Button
                        className="secondary my-2 mx-4"
                        onClick={resetFilter}
                    >
                        {techConstants.clearFilter}
                    </Button>
                </Col>
                <Col xs={6} className="d-flex justify-content-end">
                    <Button className="my-2" onClick={handleCreateTech}>
                        {techConstants.createNewTechs.heading}
                    </Button>
                </Col>
            </div>
            <div
                className="o-switch btn-group show-toggle my-2"
                data-toggle="buttons"
                role="group"
            >
                {FmSettingsConstant.filterOptions.map(option => (
                    <label
                        key={option.value}
                        className={`btn btn-secondary py-1 ${
                            filter.show === option.value
                                ? FmSettingsConstant.active.value
                                : ''
                        }`}
                    >
                        <input
                            type="radio"
                            onChange={() =>
                                handleFilter(option.name, option.value)
                            }
                            checked={filter.show === option.value}
                        />
                        {option.label}
                    </label>
                ))}
            </div>
            <SettingsItemDetail<ITechDetail>
                columnDetail={techConstants.techColumnDetail}
                itemDetail={techDetails}
                handleEditDetail={handleEditTechDetail}
                handleDeleteModalHandler={handleDeleteModalHandler}
                loader={loader}
            />
            {isTechModalOpen && (
                <TechModal
                    showModal={isTechModalOpen}
                    closeModalWithChanges={closeModalWithChanges}
                    editTechDetails={selectedTech}
                    showActiveField={true}
                />
            )}
            {isDeleteModalOpen && (
                <DeleteItemModal
                    item={selectedTech}
                    handleDeleteItem={handleDeleteTech}
                    showModal={isDeleteModalOpen}
                    itemName={techConstants.techDetails.name}
                ></DeleteItemModal>
            )}
        </div>
    );
};

export default TechDetails;
