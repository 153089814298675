import { Button, Form, Modal } from 'react-bootstrap';
import {
    ITruckDetail,
    ITruckDetailsError,
    ITruckModal,
} from '../../utils/Interface/TruckInterface';
import { truckConstants } from '../../utils/constants/TruckConstant';
import { CommonConstants } from '../../utils/constants/CommonConstants';
import { validateAccordingType } from '../../utils/validation';
import { useState } from 'react';
import AutocompleteDropdown from '../common/autocompleteDropdown/AutocompleteDropdown';
import { useLoading } from '../../commonHooks/useLoading';
import FullPageLoader from '../common/FullPageLoader';
import {
    createTruck,
    fetchInactiveTruckInfo,
    updateTruckDetail,
} from '../../Redux/Actions/truckActions';
import { useAppDispatch } from '../../Redux/Store';
import { useFeedlotId } from '../../commonHooks/useFeedlotId';
import { useModalStatus } from '../../commonHooks/useModalStatus';
import { ActivateItemModal } from '../../apps/shippingTracker/modules/BuyPo/components/ActivateItemModal';
import { FmSettingsConstant } from '../../utils/constants/FmSettingsConstants';

const TruckModal = ({
    showModal,
    closeModalWithChanges,
    editTruckDetails,
}: ITruckModal) => {
    const [truckDetail, setTruckDetail] =
        useState<ITruckDetail>(editTruckDetails);
    const [truckDetailsError, setTruckDetailsError] =
        useState<ITruckDetailsError>(truckConstants.initialTruckDetailsError);
    const [inActiveTruck, setInActiveTruck] = useState<ITruckDetail>();
    const {
        isOpen: isActivateModalOpen,
        open: openActivateModal,
        close: closeActivateModal,
    } = useModalStatus();
    const { loading, setLoading } = useLoading();
    const dispatch = useAppDispatch();
    const feedlotId = useFeedlotId();

    const handleActivateTruck = async () => {
        if (feedlotId) {
            const inactiveTruckInfo = await dispatch(
                fetchInactiveTruckInfo(feedlotId, truckDetail.name?.trim()),
            );
            if (inactiveTruckInfo) {
                setInActiveTruck(inactiveTruckInfo[0]);
                openActivateModal();
            }
        }
    };

    const handleUpdateTruckDetail = (): void => {
        const isValid = validateTruckDetails();
        if (feedlotId && isValid) {
            dispatch(
                updateTruckDetail(
                    feedlotId,
                    truckDetail,
                    undefined,
                    closeModalWithChanges,
                    setLoading,
                    handleActivateTruck,
                ),
            );
        }
    };

    const validateTruckDetails = (): boolean => {
        let isValid = true;
        const { name, type, capacity } = truckDetail;
        const updatedTruckDetailErrors = {
            name: !name,
            type: !type,
            capacity: !capacity,
        };
        setTruckDetailsError(updatedTruckDetailErrors);
        if (Object.values(updatedTruckDetailErrors).includes(true)) {
            isValid = false;
        }

        return isValid;
    };

    const handleCreateTruck = (): void => {
        const isValid = validateTruckDetails();
        if (feedlotId && isValid) {
            dispatch(
                createTruck(
                    feedlotId,
                    truckDetail,
                    closeModalWithChanges,
                    setLoading,
                    handleActivateTruck,
                ),
            );
        }
    };

    const handleDetailChange = (
        fieldValue: string | number | boolean,
        fieldName: string,
        type?: string,
    ): void => {
        const isValid = validateAccordingType(type, fieldValue);
        if (isValid) {
            setTruckDetail(prevTruckDetail => ({
                ...prevTruckDetail,
                [fieldName]:
                    fieldName === 'capacity' ? Number(fieldValue) : fieldValue,
            }));
        }
    };

    const handleCloseActivateModal = (isActivated: boolean) => {
        if (isActivated && feedlotId) {
            if (inActiveTruck) {
                const inActiveTruckCopy = {
                    ...inActiveTruck,
                    active: true,
                };
                dispatch(
                    updateTruckDetail(
                        feedlotId,
                        inActiveTruckCopy,
                        undefined,
                        closeModalWithChanges,
                        setLoading,
                    ),
                );
                closeActivateModal();
            }
        } else {
            closeActivateModal();
        }
    };

    return (
        <>
            <ActivateItemModal
                showModal={isActivateModalOpen}
                closeModalHandler={handleCloseActivateModal}
                itemName={truckDetail.name?.trim()}
                item={truckConstants.activateModalConstant}
            />
            <Modal
                className="px-2 pt-5 fm-settings-modal"
                size="lg"
                show={showModal}
                centered
                animation
                onHide={() => closeModalWithChanges(false)}
            >
                {loading && <FullPageLoader />}
                <Modal.Header closeButton className="border-bottom-0">
                    <Modal.Title className="text-dark">
                        {editTruckDetails.name
                            ? `${truckConstants.editTruck} ${editTruckDetails?.name}`
                            : truckConstants.createTrucks}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="border-bottom">
                        <div className="d-flex justify-content-between mb-2">
                            <div className="col-5">
                                <Form.Label className="text-secondary">
                                    {
                                        truckConstants.createNewTrucks.form.name
                                            .label
                                    }
                                    <span className="necessaryFields">*</span>
                                </Form.Label>
                                <Form.Control
                                    className={`text-secondary border-${
                                        truckDetailsError.name ? 'danger' : ''
                                    }`}
                                    value={truckDetail?.name}
                                    name={
                                        truckConstants.createNewTrucks.form.name
                                            .name
                                    }
                                    placeholder={
                                        truckConstants.createNewTrucks.form.name
                                            .placeholder
                                    }
                                    onChange={e =>
                                        handleDetailChange(
                                            e.target.value,
                                            e.target.name,
                                            'string',
                                        )
                                    }
                                    maxLength={
                                        truckConstants.createNewTrucks.form.name
                                            .maxLength
                                    }
                                    size="sm"
                                    type="text"
                                />
                            </div>
                            <div className="col-5">
                                <Form.Label className="text-secondary">
                                    {
                                        truckConstants.createNewTrucks.form
                                            .truckType.label
                                    }
                                    <span className="necessaryFields">*</span>
                                </Form.Label>
                                <AutocompleteDropdown
                                    options={
                                        truckConstants.createNewTrucks.form
                                            .truckType.options
                                    }
                                    nameKey={
                                        truckConstants.createNewTrucks.form
                                            .truckType.name
                                    }
                                    valueKey={
                                        truckConstants.createNewTrucks.form
                                            .truckType.name
                                    }
                                    selected={truckDetail?.type}
                                    placeholder={
                                        truckConstants.createNewTrucks.form
                                            .truckType.placeholder
                                    }
                                    onChange={e =>
                                        handleDetailChange(
                                            e.target.value,
                                            'type',
                                        )
                                    }
                                    styleError={
                                        truckDetailsError.type &&
                                        truckConstants.createNewTrucks.form
                                            .truckType.name
                                    }
                                />
                            </div>
                        </div>
                        <div className="d-flex justify-content-between mb-4">
                            <div className="col-5">
                                <Form.Label className="text-secondary">
                                    {
                                        truckConstants.createNewTrucks.form
                                            .capacity.label
                                    }
                                </Form.Label>
                                <span className="necessaryFields">*</span>
                                <Form.Control
                                    className={`text-secondary border-${
                                        truckDetailsError.capacity
                                            ? 'danger'
                                            : ''
                                    }`}
                                    value={truckDetail?.capacity}
                                    name={
                                        truckConstants.createNewTrucks.form
                                            .capacity.name
                                    }
                                    placeholder={
                                        truckConstants.createNewTrucks.form
                                            .capacity.placeholder
                                    }
                                    onChange={e =>
                                        handleDetailChange(
                                            e.target.value,
                                            e.target.name,
                                            'number',
                                        )
                                    }
                                    size="sm"
                                    type="text"
                                />
                            </div>

                            <div className="custom-checkbox col-5 ">
                                <input
                                    type="checkbox"
                                    checked={truckDetail.active}
                                    className="mx-2"
                                    onChange={e =>
                                        handleDetailChange(
                                            !truckDetail.active,
                                            'active',
                                        )
                                    }
                                />
                                <label className="mx-2">
                                    {FmSettingsConstant.active.label}
                                </label>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="border-top-0">
                    <Button
                        className="secondary"
                        onClick={() => closeModalWithChanges(false)}
                    >
                        {CommonConstants.en.cancel}
                    </Button>
                    <Button
                        onClick={
                            !editTruckDetails.name
                                ? handleCreateTruck
                                : handleUpdateTruckDetail
                        }
                    >
                        {CommonConstants.en.save}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default TruckModal;
