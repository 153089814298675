import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    IFilter,
    ILots,
    IYardLayoutDetail,
} from '../../utils/Interface/YardLayoutInterface';
import { YARD_LAYOUT_INITIAL_STATE } from '../Constants/yardLayoutInitialState';
import { IGenericObject } from '../../utils/Interface/CommonInterface';

export const yardLayoutReducer = createSlice({
    name: 'yardLayoutDetail',
    initialState: { ...YARD_LAYOUT_INITIAL_STATE },
    reducers: {
        setYardLayoutDetail: (
            state,
            action: PayloadAction<IYardLayoutDetail[]>,
        ) => {
            state.yardLayoutDetail = action.payload;
        },
        setYardLayoutFilter: (state, action: PayloadAction<IFilter>) => {
            state.filter = action.payload;
        },
        setYardLayoutLots: (state, action: PayloadAction<ILots[]>) => {
            state.lots = action.payload;
        },
        setYardLayoutOwners: (
            state,
            action: PayloadAction<IGenericObject[]>,
        ) => {
            state.owners = action.payload;
        },
        setYardLayoutRations: (
            state,
            action: PayloadAction<IGenericObject[]>,
        ) => {
            state.rations = action.payload;
        },
        setYardLayoutLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setYardLayoutTotalHeadCount: (state, action: PayloadAction<number>) => {
            state.totalHeadCount = action.payload;
        },
    },
});

export const {
    setYardLayoutDetail,
    setYardLayoutLots,
    setYardLayoutFilter,
    setYardLayoutOwners,
    setYardLayoutRations,
    setYardLayoutLoading,
    setYardLayoutTotalHeadCount,
} = yardLayoutReducer.actions;

const yardLayout = yardLayoutReducer.reducer;

export default yardLayout;
