import { useAppDispatch, useAppSelector } from '../../Redux/Store';
import {
    fetchSex,
    fetchLots,
    fetchTechs,
    fetchRiders,
    fetchDiagnosis,
    fetchMedicalItems,
    fetchTreatmentDetailsService,
    fetchMovePens_deprecated,
    fetchHospitalPens,
} from '../../Redux/Actions/treatmentsActions';
import { useEffect } from 'react';
import { useFeedlotId } from '../../commonHooks/useFeedlotId';
import { useParams } from 'react-router-dom';
import {
    getGeneratedInvoiceCutoffDate,
    handleFindPenInformation,
    thisDateWithinCutoff_deprecated,
} from '../../helpers';
import { IGenericSetState } from '../../utils/Interface/CommonInterface';
import { IMedicalItem } from '../../utils/Interface/TreatmentsInterface';
import { feedlotManagerFeatures } from '../../apps/feedlotManager/featureFlags/feedlotManagerFeatures';
import { treatmentActions } from '../../Redux/Reducer/treatmentsSlice';

const { isCA9498On_ResolveTreatmentLotSelectionLag } = feedlotManagerFeatures;

export const useTreatmentHook = (
    setInvoiceAlreadyGeneratedForThisRecord: IGenericSetState<boolean>,
) => {
    const dispatch = useAppDispatch();
    const feedlotId = useFeedlotId();
    const { cattleTreatmentId } = useParams();
    const { editTreatmentsDetails, generatedInvoiceCutoffDate, lotPens } =
        useAppSelector(state => state.treatments);

    useEffect(() => {
        const onTreatmentFormOpened = () => {
            if (feedlotId) {
                dispatch(fetchSex());
                dispatch(fetchLots(feedlotId));
                dispatch(fetchTechs(feedlotId));
                dispatch(fetchRiders(feedlotId));
                dispatch(fetchDiagnosis(feedlotId));
                if (isCA9498On_ResolveTreatmentLotSelectionLag) {
                    dispatch(fetchHospitalPens(feedlotId));
                }
                if (!cattleTreatmentId) {
                    dispatch(fetchMedicalItems(feedlotId));
                }
            }
        };
        onTreatmentFormOpened();
    }, [feedlotId, dispatch, cattleTreatmentId]);

    useEffect(() => {
        const onUpdatingTreatment = () => {
            if (cattleTreatmentId && feedlotId) {
                dispatch(
                    fetchTreatmentDetailsService(+cattleTreatmentId, feedlotId),
                );
            }
        };
        onUpdatingTreatment();
    }, [cattleTreatmentId, feedlotId, dispatch]);

    useEffect(() => {
        if (feedlotId && cattleTreatmentId) {
            (async () => {
                const cutoffDate = await getGeneratedInvoiceCutoffDate(
                    +feedlotId,
                    editTreatmentsDetails.lotId,
                );
                dispatch(
                    treatmentActions.setGeneratedInvoiceCutoffDate(cutoffDate),
                );
            })();
        }
    }, [
        dispatch,
        feedlotId,
        editTreatmentsDetails.lotId,
        editTreatmentsDetails.date,
        cattleTreatmentId,
    ]);

    useEffect(() => {
        const isDateValid = thisDateWithinCutoff_deprecated(
            editTreatmentsDetails.date,
            generatedInvoiceCutoffDate,
        );
        setInvoiceAlreadyGeneratedForThisRecord(isDateValid);
    }, [
        generatedInvoiceCutoffDate,
        editTreatmentsDetails.date,
        setInvoiceAlreadyGeneratedForThisRecord,
    ]);

    useEffect(() => {
        return () => {
            dispatch(treatmentActions.resetState());
        };
    }, [dispatch]);

    useEffect(() => {
        const getPensByLot = async () => {
            let pens: any[] = [];
            if (
                feedlotId &&
                editTreatmentsDetails.lotId &&
                editTreatmentsDetails.date
            ) {
                pens = await dispatch(
                    handleFindPenInformation(
                        feedlotId,
                        editTreatmentsDetails.lotId,
                        editTreatmentsDetails.date,
                        true,
                        true,
                    ),
                );
            }
            dispatch(treatmentActions.setLotPens(pens));
        };

        getPensByLot();
    }, [
        dispatch,
        editTreatmentsDetails.date,
        editTreatmentsDetails.lotId,
        feedlotId,
    ]);

    useEffect(() => {
        if (!isCA9498On_ResolveTreatmentLotSelectionLag) {
            if (feedlotId && editTreatmentsDetails.lotId && lotPens) {
                dispatch(
                    fetchMovePens_deprecated(
                        feedlotId,
                        editTreatmentsDetails.lotId,
                        lotPens,
                    ),
                );
            } else {
                dispatch(treatmentActions.setMovePens([]));
            }
        }
    }, [dispatch, editTreatmentsDetails.lotId, feedlotId, lotPens]);
};

export const calculateDose = (medicalItem: IMedicalItem, weight: number) => {
    const dose =
        medicalItem.dosageType === 'CC'
            ? Math.ceil((weight / 100) * medicalItem.dosageRate)
            : medicalItem.dosageRate;
    return dose;
};

export const calculateTotalPrice = (
    medicalItem: IMedicalItem,
    dose: number,
) => {
    const totalPrice =
        Math.ceil(
            dose *
                (medicalItem.cost || 0) *
                (1 + (medicalItem.markup || 0) / 100) *
                100,
        ) / 100;
    return totalPrice;
};
