import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { TANK_INITIAL_STATE } from '../Constants/tankInitialState';
import { IFilter } from '../../utils/Interface/TankInterface';

const tankSlice = createSlice({
    name: 'tank',
    initialState: { ...TANK_INITIAL_STATE },
    reducers: {
        setTankFilter: (state, action: PayloadAction<IFilter>) => {
            state.filter = action.payload;
        },
        setTankDetailsLoader: (state, action: PayloadAction<boolean>) => {
            state.loader = action.payload;
        },
    },
});

export const { setTankFilter, setTankDetailsLoader } = tankSlice.actions;
export const TankSlice = tankSlice.reducer;
