import { Link } from 'react-router-dom';
import useAuthContext from '../../apps/common/modules/auth/hooks/useAuthContext';
import { authActions } from '../../apps/common/modules/auth/authSlice';
import { useAppDispatch } from '../../Redux/Store';
import { commonFeatures } from '../../apps/common/featureFlags/commonFeatures';
import Constants from '../../utils/Constants';

const { isCA9685_localAuthLoginScreen } = commonFeatures;

export default function LogInLogOutComponent() {
    const dispatch = useAppDispatch();
    const { isAuthenticated, login, logout } = useAuthContext();

    const loggingOut = () => {
        if (isAuthenticated()) {
            dispatch(authActions.setLogout());
        } else {
            login();
        }
    };

    return (
        <li
            onClick={
                isCA9685_localAuthLoginScreen &&
                localStorage.getItem(Constants.StorageItems.AUTH_TYPE) ===
                    Constants.AuthType.LOCAL
                    ? loggingOut
                    : isAuthenticated()
                      ? logout
                      : login
            }
        >
            <Link to={`/`} className="nav-link">
                {isAuthenticated() ? 'Log Out' : 'Log In'}
            </Link>
        </li>
    );
}
