import Constants from '../../../../../utils/Constants';
import { Link } from 'react-router-dom';
import { IProductDropdown } from '../interfaces/ProductsDropdownInterface';
import { ProductConstants } from '../constants/ProductConstants';
import useAuthContext from '../../auth/hooks/useAuthContext';

export default function ProductsDropdown(props: IProductDropdown) {
    const { hasSubscriptionServiceAccess } = useAuthContext();

    return (
        <>
            {hasSubscriptionServiceAccess() ? (
                <li onClick={() => props.setIsActive(!props.isActive)}>
                    <Link to={Constants.Routes.PRODUCTS} className="nav-link">
                        {ProductConstants.labels.products}
                    </Link>
                </li>
            ) : null}
        </>
    );
}
