import { useNavigate } from 'react-router-dom';

const SidebarLogoFM = (props: any) => {
    const navigate = useNavigate();
    const handleClick = () => navigate(`/`);
    return (
        <img
            {...props}
            alt="sidebar_fm.png"
            src={require(`./sidebar_fm.png`)}
            title={`CC v${process.env.REACT_APP_VERSION || '0.0.0.0'}`}
            onClick={handleClick}
        />
    );
};

export default SidebarLogoFM;
