import { IFeedRationOrderInitialState } from './../../utils/Interface/RationFeedOrderInterface';
/**
 * Create Feed Ration Order initial states
 */

export const FEED_RATION_ORDER_ROUTES_INITIAL_STATE: IFeedRationOrderInitialState =
    {
        rations: [{ name: 'Select Ration', id: 0 }],
        mixers: [],
        loading: true,
        canResetTime: false,
    };
