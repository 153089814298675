import { apiCall_v2 } from '../../Services/AxiosService';
import Constants from '../../utils/Constants';
import {
    ICreateMixingBin,
    IDeleteMixingBin,
    IFetchMixingBinDetails,
    IFilter,
    IUpdateMixingBinDetail,
} from '../../utils/Interface/MixingBinInterface';
import { getToastContent } from '../../utils/ToastContent';
import { toastMessage } from '../Reducer/common';
import { setMixingBinDetailsLoader } from '../Reducer/mixingBinReducer';
import { AppDispatch } from '../Store';

const getFilterUrl = (filter: IFilter) => {
    let filterUrl = `&filter=contains(Name, '${filter.name}')`;
    if (filter?.show && filter?.show !== 'all') {
        const isActive = filter.show === 'active';
        filterUrl += ` and active eq ${isActive}`;
    }
    return filterUrl;
};

export const fetchMixingBinDetails: IFetchMixingBinDetails =
    (feedlotId, setMixingBinDetail, filter) =>
    async (dispatch: AppDispatch) => {
        dispatch(setMixingBinDetailsLoader(true));
        const filterUrl = filter ? getFilterUrl(filter) : '';
        const response = await apiCall_v2({
            method: 'get',
            url: `${Constants.apiUrls.FEED_LOT_MANAGER}/${feedlotId}${Constants.apiUrls.MIXER}/odata?flid=${feedlotId}${filterUrl}&orderBy=Name`,
        });
        setMixingBinDetail(response);
        dispatch(setMixingBinDetailsLoader(false));
    };

export const updateMixingBinDetail: IUpdateMixingBinDetail =
    (
        feedlotId,
        mixingBinDetail,
        fetchMixingBinData?,
        closeModalWithChanges?,
        setLoading?,
        handleActivateMixingBin?,
    ) =>
    async (dispatch: AppDispatch) => {
        setLoading?.(true);
        const response = await apiCall_v2({
            method: 'put',
            url: `${Constants.apiUrls.FEED_LOT_MANAGER}/${feedlotId}${Constants.apiUrls.MIXER}/${mixingBinDetail.binTypeId}`,
            payload: mixingBinDetail,
            isResRequired: true,
        });

        setLoading?.(false);
        if (!response) return;
        const status = Number(response.status);

        if (status === Constants.responseCode.DUPLICATE) {
            dispatch(
                toastMessage(
                    getToastContent(
                        response.status,
                        '',
                        Constants.customMessage.MIXING_BIN_DUPLICATE,
                    ),
                ),
            );
        } else if (status === Constants.responseCode.CONFLICT) {
            handleActivateMixingBin?.();
        } else {
            dispatch(toastMessage(getToastContent(response.status)));
            closeModalWithChanges?.(true);
        }
        fetchMixingBinData?.();
    };

export const createMixingBin: ICreateMixingBin =
    (
        feedlotId,
        mixingBin,
        closeModalWithChanges,
        setLoading,
        handleActivateMixingBin,
    ) =>
    async (dispatch: AppDispatch) => {
        setLoading(true);
        const response = await apiCall_v2({
            method: 'post',
            url: `${Constants.apiUrls.FEED_LOT_MANAGER}/${feedlotId}${Constants.apiUrls.MIXER}`,
            payload: mixingBin,
            isResRequired: true,
        });
        setLoading(false);
        if (!response) return;
        const status = Number(response.status);

        if (status === Constants.responseCode.DUPLICATE) {
            dispatch(
                toastMessage(
                    getToastContent(
                        status,
                        '',
                        Constants.customMessage.MIXING_BIN_DUPLICATE,
                    ),
                ),
            );
        } else if (status === Constants.responseCode.CONFLICT) {
            handleActivateMixingBin();
        } else {
            dispatch(toastMessage(getToastContent(status)));
            closeModalWithChanges(true);
        }
    };

export const fetchInactiveBinInfo =
    (feedlotId: number, mixingBin?: string) =>
    async (dispatch: AppDispatch) => {
        const response = await apiCall_v2({
            method: 'get',
            url: `${Constants.apiUrls.FEED_LOT_MANAGER}/${feedlotId}${Constants.apiUrls.MIXER}/odata?filter=name eq '${mixingBin}'`,
            isResRequired: true,
        });

        if (!response) return;
        const status = Number(response.status);

        if (
            response.status >= Constants.responseCode.SUCCESS &&
            response.status < Constants.responseCode.MULTIPLE_CHOICES
        ) {
            return response.data;
        } else {
            dispatch(toastMessage(getToastContent(status)));
        }
    };

export const deleteMixingBin: IDeleteMixingBin =
    (feedlotId, mixingBin, fetchMixingBinData?) =>
    async (dispatch: AppDispatch) => {
        dispatch(setMixingBinDetailsLoader(true));
        const response = await apiCall_v2({
            method: 'delete',
            url: `${Constants.apiUrls.FEED_LOT_MANAGER}/${feedlotId}${Constants.apiUrls.MIXER}/${mixingBin.binTypeId}`,
            payload: mixingBin,
            isResRequired: true,
        });
        dispatch(setMixingBinDetailsLoader(false));
        if (!response) return;
        dispatch(toastMessage(getToastContent(Number(response.status))));
        fetchMixingBinData?.();
    };
