import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CALENDAR_INITIAL_STATE } from '../interfaces/calendarEventsInititalState';
import { ICalendarGroupedEvents } from '../interfaces/CattlePoCalendarInterface';

const CalendarReducer = createSlice({
    name: 'cattlePOCalendar',
    initialState: { ...CALENDAR_INITIAL_STATE },
    reducers: {
        setEvents: (state, action: PayloadAction<ICalendarGroupedEvents>) => {
            state.events = action.payload;
        },
    },
});

export const { setEvents } = CalendarReducer.actions;

const cattlePOCalendar = CalendarReducer.reducer;

export default cattlePOCalendar;
