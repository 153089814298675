import { useEffect } from 'react';

export const useDebouncedEffect = (
    effect,
    deps,
    interval = 2000,
    immediateEffect?: () => void,
    immediateEffectCleanup?: () => void,
    ...args
) => {
    useEffect(() => {
        if (immediateEffect) {
            immediateEffect();
        }

        const handler = setTimeout(() => effect(...args), interval);

        const cleanup = () => {
            clearTimeout(handler);
            if (immediateEffectCleanup) {
                immediateEffectCleanup();
            }
        };

        return cleanup;
        //eslint-disable-next-line
    }, [...(deps || []), interval]);
};

export const debounceDefault = (
    effect,
    interval = 2000,
    setTimeIds: (previousIds) => void,
    ...args
) => {
    const handler = setTimeout(() => effect(...args), interval);

    setTimeIds((pre: number[]) => [...pre, handler]);
};
