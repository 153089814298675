import { currentDate_deprecated } from '../../helpers';
import {
    IMedicalItem,
    INewMedicalItemErrors,
} from '../../utils/Interface/HealthSection/MedicalItemInterface';

export const MEDICAL_ITEM_INITIAL_STATE = {
    medicalItems: [] as IMedicalItem[],
    newMedicalItem: {
        name: '',
        category: '',
        subCategory: '',
        dosageType: '',
        dosageRate: '',
        withdrawal: '',
        cost: '',
        markup: '',
        totalCost: 0,
        inEffect: currentDate_deprecated('YYYY-MM-DD'),
        isActive: true,
    } as IMedicalItem,
    newMedicalItemErrors: {
        name: false,
        category: false,
        subCategory: false,
        dosageType: false,
        dosageRate: false,
        withdrawal: false,
        cost: false,
        markup: false,
    } as INewMedicalItemErrors,
    dropdownMedicalItems: [] as IMedicalItem[],
    earliestGeneratedInvoiceCutoffDate: '',
    medicalItemsFilter: '',
    medicalItemsTableLoading: false,
    submitPending: false,
};
