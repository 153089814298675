import { Button, Col } from 'react-bootstrap';
import SettingsItemDetail from './FMSettings/SettingsItemDetail';
import Constants from '../../utils/Constants';
import { useEffect, useState, useMemo, ChangeEvent } from 'react';
import { useModalStatus } from '../../commonHooks/useModalStatus';
import { useFeedlotId } from '../../commonHooks/useFeedlotId';
import { useAppDispatch, useAppSelector } from '../../Redux/Store';
import StorageService from '../../Services/storage/storage.service';
import { useDebouncedEffect } from '../useDebounceEffect';
import {
    IFilter,
    ILot,
    IOwnerDetail,
    IFormattedOwnerDetail,
} from '../../utils/Interface/OwnerInterface';
import { setOwnerFilter } from '../../Redux/Reducer/ownerSettingSlice';
import OwnerModal from './OwnerModal';
import { OWNER_INITIAL_STATE } from '../../Redux/Constants/ownerInitialState';
import { DeleteItemModal } from './FMSettings/DeleteItemModal';
import { FmSettingsConstant } from '../../utils/constants/FmSettingsConstants';
import { ownerConstants } from '../../utils/constants/OwnerConstants';
import {
    deleteOwner,
    fetchLots,
    fetchOwnerDetails,
    updateOwnerDetail,
} from '../../Redux/Actions/ownerActions';
import AutocompleteDropdown from '../common/autocompleteDropdown/AutocompleteDropdown';

const OwnerDetails = () => {
    const { filter, loader } = useAppSelector(state => state.ownerSetting);
    const [searchQuery, setSearchQuery] = useState<string | undefined>(
        undefined,
    );
    const [lot, setLot] = useState<ILot[]>([]);
    const [ownerDetails, setOwnerDetails] = useState<IOwnerDetail[]>([]);
    const [selectedOwner, setSelectedOwner] = useState<IOwnerDetail>(
        OWNER_INITIAL_STATE.createOwnerInitial,
    );
    const storage = useMemo(() => new StorageService(), []);
    const {
        isOpen: isOwnerModalOpen,
        open: openOwnerModal,
        close: closeOwnerModal,
    } = useModalStatus();
    const {
        isOpen: isDeleteModalOpen,
        open: openDeleteModal,
        close: closeDeleteModal,
    } = useModalStatus();
    const feedlotId = useFeedlotId();
    const dispatch = useAppDispatch();

    const formattedOwnerDetails: IFormattedOwnerDetail[] = ownerDetails.map(
        owner => ({
            ...owner,
            totalHdCount: (+owner.totalHdCount)?.toLocaleString(),
        }),
    );

    const handleEditOwnerDetail = (owner: IOwnerDetail): void => {
        setSelectedOwner(owner);
        openOwnerModal();
    };

    const fetchOwnerData = (ownerFilter: IFilter = filter): void => {
        if (feedlotId) {
            dispatch(fetchOwnerDetails(setOwnerDetails, ownerFilter));
        }
    };

    const setOwnerFilterInStorage = (filterValues: IFilter) => {
        storage.setValue(
            ownerConstants.ownerFilter,
            filterValues,
            Constants.Storage.LOCAL,
        );
    };
    const handleFilter = (filterName: string, filterValue: string): void => {
        const updatedFilter = { ...filter, [filterName]: filterValue };
        fetchOwnerData(updatedFilter);
        dispatch(setOwnerFilter(updatedFilter));
        setOwnerFilterInStorage(updatedFilter);
    };

    const closeModalWithChanges = (isEdited: boolean): void => {
        if (isEdited && feedlotId) {
            fetchOwnerData(filter);
        }
        closeOwnerModal();
    };

    const resetFilter = (): void => {
        const updatedFilter = OWNER_INITIAL_STATE.filter;
        fetchOwnerData(updatedFilter);
        setSearchQuery('');
        dispatch(setOwnerFilter(updatedFilter));
        setOwnerFilterInStorage(updatedFilter);
    };

    const handleCreateOwner = (): void => {
        if (feedlotId) {
            setSelectedOwner({
                ...OWNER_INITIAL_STATE.createOwnerInitial,
            });
        }
        openOwnerModal();
    };

    const handleDeleteModalHandler = (owner: IOwnerDetail) => {
        setSelectedOwner(owner);
        openDeleteModal();
    };

    const handleDeleteOwner = (shouldTakeAction: boolean) => {
        if (feedlotId && shouldTakeAction) {
            if (!selectedOwner.isAssociated || selectedOwner.active) {
                const isActiveAndAssociated =
                    selectedOwner.isAssociated && selectedOwner.active;
                const action = isActiveAndAssociated
                    ? updateOwnerDetail
                    : deleteOwner;
                const updatedOwnerDetail = isActiveAndAssociated
                    ? { ...selectedOwner, active: false }
                    : selectedOwner;
                dispatch(action(updatedOwnerDetail, { fetchOwnerData }));
            }
        }
        closeDeleteModal();
    };

    const handleLotChange = (e: ChangeEvent<HTMLInputElement>) => {
        handleFilter('ownerId', e.target.value);
    };

    useDebouncedEffect(
        () => {
            if (searchQuery !== undefined) {
                handleFilter('name', searchQuery);
            }
        },
        [searchQuery],
        1000,
    );

    useEffect(() => {
        if (feedlotId) {
            const ownerFilter = storage.getValue(ownerConstants.ownerFilter);
            if (Object.keys(ownerFilter).length > 0) {
                dispatch(setOwnerFilter(ownerFilter));
            }
            if (!ownerFilter.name) {
                if (ownerFilter.show) {
                    fetchOwnerData(ownerFilter);
                } else {
                    dispatch(fetchOwnerDetails(setOwnerDetails, filter));
                }
            } else {
                setSearchQuery(ownerFilter?.name || filter.name);
            }
            fetchLots(setLot, feedlotId);
        }
    }, [feedlotId, dispatch, storage]);

    return (
        <div className="pens-container">
            <h5 className="my-3">{ownerConstants.ownerDetails.heading}</h5>
            <div className="d-flex align-items-end mb-3">
                <Col xs={2} className="d-flex flex-column">
                    <label>{ownerConstants.ownerDetails.owner}</label>
                    <input
                        className="text-secondary my-1"
                        type="text"
                        placeholder={
                            ownerConstants.ownerDetails.ownerPlaceholder
                        }
                        maxLength={ownerConstants.ownerDetails.maxLength}
                        value={searchQuery ? searchQuery : ''}
                        onChange={e => setSearchQuery(e.target.value)}
                    />
                </Col>
                <Col xs={2} className="d-flex flex-column mx-3">
                    <label>{ownerConstants.ownerDetails.lot}</label>
                    <AutocompleteDropdown
                        options={lot}
                        nameKey="name"
                        valueKey="ownerId"
                        onChange={handleLotChange}
                        placeholder={ownerConstants.ownerDetails.lotPlaceholder}
                        selected={filter.ownerId}
                    />
                </Col>
                <Col xs={2}>
                    <Button className="secondary my-1" onClick={resetFilter}>
                        {ownerConstants.clearFilter}
                    </Button>
                </Col>
                <Col xs={6} className="d-flex justify-content-end">
                    <Button className="m-4" onClick={handleCreateOwner}>
                        {ownerConstants.createOwners}
                    </Button>
                </Col>
            </div>
            <div
                className="o-switch btn-group show-toggle my-1"
                data-toggle="buttons"
                role="group"
            >
                {FmSettingsConstant.filterOptions.map(option => (
                    <label
                        key={option.value}
                        className={`btn btn-secondary py-1 ${
                            filter.show === option.value
                                ? FmSettingsConstant.active.value
                                : ''
                        }`}
                    >
                        <input
                            type="radio"
                            onChange={() =>
                                handleFilter(option.name, option.value)
                            }
                            checked={filter.show === option.value}
                        />
                        {option.label}
                    </label>
                ))}
            </div>
            <SettingsItemDetail<IOwnerDetail>
                columnDetail={ownerConstants.ownerColumnDetail}
                itemDetail={formattedOwnerDetails}
                handleEditDetail={handleEditOwnerDetail}
                handleDeleteModalHandler={handleDeleteModalHandler}
                loader={loader}
                columnSize={2}
                margin={2}
            />
            {isOwnerModalOpen && (
                <OwnerModal
                    showModal={isOwnerModalOpen}
                    closeModalWithChanges={closeModalWithChanges}
                    editOwnerDetails={selectedOwner}
                    showActiveField={true}
                />
            )}
            {isDeleteModalOpen && (
                <DeleteItemModal
                    item={selectedOwner}
                    handleDeleteItem={handleDeleteOwner}
                    showModal={isDeleteModalOpen}
                    itemName={ownerConstants.ownerDetails.name}
                ></DeleteItemModal>
            )}
        </div>
    );
};

export default OwnerDetails;
