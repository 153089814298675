import {
    baseAuthApi,
    GetApiTmAuthAccountApiArg,
    GetApiTmAuthAccountApiResponse,
    GetApiTmAuthApiArg,
    GetApiTmAuthApiResponse,
} from './baseAuthApi';

export const authApi = baseAuthApi
    .injectEndpoints({
        endpoints: build => ({
            getApiTmAuth: build.query<
                GetApiTmAuthApiResponse,
                GetApiTmAuthApiArg
            >({
                query: queryArg => ({
                    url: `/api/tm/auth`,
                    params: { account: queryArg.account },
                }),
                providesTags: ['Auth'],
            }),
            getApiTmAuthAccount: build.query<
                GetApiTmAuthAccountApiResponse,
                GetApiTmAuthAccountApiArg
            >({
                query: queryArg => ({
                    url: `/api/tm/auth/account`,
                    params: { accountId: queryArg.accountId },
                }),
                providesTags: ['Auth'],
            }),
        }),
        overrideExisting: true,
    })
    .enhanceEndpoints({
        addTagTypes: [],
        endpoints: {},
    });
