const ResetPasswordIcon = props => {
    return (
        <img
            className="cursor-pointer"
            {...props}
            alt="resetPassword.png"
            src={require('./resetPassword.png')}
        />
    );
};

export default ResetPasswordIcon;
