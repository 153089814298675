export const VENDOR_INITIAL_STATE = {
    filter: {
        name: '',
        show: 'active',
    },
    createVendorInitial: {
        name: '',
        contactNumber: '',
        contactName: '',
        address: '',
        city: '',
        state: '',
        zipCode: '',
        active: true,
    },
    loader: true,
};
