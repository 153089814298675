import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { setBearer } from '../../../../Services/AxiosService';
import { commonFeatures } from '../../featureFlags/commonFeatures';

const { isCA9685_localAuthLoginScreen } = commonFeatures;

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        accessToken: '',
        logout: false,
    },
    reducers: {
        setAccessToken: (state, action: PayloadAction<string>) => {
            state.accessToken = action.payload;
            if (isCA9685_localAuthLoginScreen) {
                setBearer(action.payload);
            }
        },
        setLogout: state => {
            state.logout = true;
        },
    },
});

export const authActions = authSlice.actions;
export const authReducer = authSlice.reducer;
