import * as hotlist from './hotlist.json';
import * as mortalityReport from './mortalityReport.json';
import * as treatments from './treatments.json';
import * as receiveCattle from './receiveCattle.json';
import * as realizer from './realizer.json';
import * as hospital from './hospital.json';
import * as lots from './lots.json';
import * as shipCattle from './shipCattle.json';
import * as common from './common.json';
import * as userManagement from './userManagement.json';
import * as financeApp from './financeApp.json';

const es = {
    hotlist,
    mortalityReport,
    treatments,
    receiveCattle,
    realizer,
    hospital,
    lots,
    shipCattle,
    common,
    userManagement,
    financeApp,
};

export default es;
