export const locationConstants = {
    createNewLocations: {
        heading: 'Create New Location',
        form: {
            name: {
                name: 'name',
                label: 'Location Name',
                maxLength: 50,
                placeholder: 'Enter Location Name',
            },
            address1: {
                name: 'address1',
                label: 'Address Line 1',
                maxLength: 50,
            },
            address2: {
                name: 'address2',
                label: 'Address Line 2',
                maxLength: 50,
            },
            coordinates: {
                name: 'coordinates',
                label: 'GPS Coordinates',
                maxLength: 50,
                placeholder: 'EX: +00.0000,-00.0000',
            },
            country: {
                name: 'country',
                label: 'Country',
                maxLength: 50,
                defaultValue: 'US',
            },
            state: {
                name: 'state',
                label: 'State',
            },
            zipCode: {
                name: 'postalCode',
                label: 'Zip Code',
                maxLength: 10,
                placeholder: '98786 or 98768-9876',
            },
            city: {
                name: 'city',
                label: 'City',
                maxLength: 50,
            },
        },
    },
    locationDetails: {
        placeholder: 'Search',
        location: 'Location',
        heading: 'Manage Buy Locations',
        maxLength: 50,
        name: 'Location',
    },
    locationColumnDetail: [
        { label: 'Buy Location', key: 'name' },
        { label: 'Status', key: 'active' },
        { label: 'City', key: 'city' },
        { label: 'State', key: 'state' },
    ],
    initialLocationDetailsError: {
        name: false,
        coordinates: false,
        postalCode: false,
    },
    clearFilter: 'Clear Filter',
    editLocation: 'Edit Location - ',
    activateModalConstant: {
        heading: 'Activate Location',
        text: ' is currently Inactive. Activate and use this Location.',
    },
    locationFilter: 'locationFilter',
};
