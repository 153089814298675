import { OverlayTrigger, Form, Tooltip } from 'react-bootstrap';
import {
    ScaleTicketModalConstants,
    scaleTicketLabelConstants,
} from '../../scaleTickets/constants/ScaleTicketConstants';
import { useAppDispatch, useAppSelector } from '../../../../Redux/Store';
import { IFilterFieldBasicDetails } from '../../scaleTickets/interfaces/ScaleTicketInterface';
import { validateAccordingType } from '../../../../utils/validation';
import {
    fetchVendor,
    setContractModalDetails,
    setContractModalDetailsError,
} from '../../scaleTickets/actions/ScaleTicketActions';
import { feedlotManagerFeatures } from '../../../../apps/feedlotManager/featureFlags/feedlotManagerFeatures';
import { useParams } from 'react-router-dom';
import ContractFormRemainingAmounts from './ContractFormRemainingAmounts';
import StaticOption from '../../../../components/common/StaticOption';
import InfoIcon from '../../../../Assets/InfoIcon';
import { useModalStatus } from '../../../../commonHooks/useModalStatus';
import { VENDOR_INITIAL_STATE } from '../../../../Redux/Constants/vendorInitialState';
import VendorModal from '../../../../components/pages/VendorModal';
import AutocompleteDropdown from '../../../../components/common/autocompleteDropdown/AutocompleteDropdown';

const { isCA9800On_ContractsMVP } = feedlotManagerFeatures;

const ContractFormBody = () => {
    const {
        contractModalDetailsError,
        contractModalDetails,
        createScaleTicketDetails,
        commodities,
        vendors,
        freights,
    } = useAppSelector(state => state.scaleTicket);
    const { contractHasScaleTickets } = useAppSelector(
        state => state.manageCommodities,
    );
    const vendorModal = useModalStatus();

    const isDisabled =
        !!createScaleTicketDetails.scaleTicketBasicDetails.vendor &&
        !!createScaleTicketDetails.scaleTicketBasicDetails.commodity;

    const dispatch = useAppDispatch();
    const { contractId: isUpdate } = useParams();

    const handleCloseVendorModal = (
        isVendorCreate = false,
        vendorId?: number,
    ) => {
        vendorModal.close();
        if (isVendorCreate && vendorId) {
            contractModalDetailsHandler('vendorId', vendorId);
            dispatch(fetchVendor());
        }
    };

    const contractModalDetailsHandler = (
        fieldName: IFilterFieldBasicDetails,
        fieldValue: string | number | Date | null | boolean,
        type?: string,
    ) => {
        if (fieldValue === scaleTicketLabelConstants.createVendor) {
            vendorModal.open();
        } else {
            const isValid: boolean = validateAccordingType(type, fieldValue);

            if (isValid) {
                const contractModalDetailsCopy = {
                    ...contractModalDetails,
                    [fieldName]: fieldValue,
                };
                contractModalDetailsErrorHandler(fieldName);
                dispatch(setContractModalDetails(contractModalDetailsCopy));
                if (isCA9800On_ContractsMVP) {
                    handleClearingPricedError(fieldName);
                }
            }
        }
    };

    const handleClearingPricedError = (fieldName: string) => {
        if (
            fieldName ===
                ScaleTicketModalConstants.contractModal.apiFields.price ||
            fieldName ===
                ScaleTicketModalConstants.contractModal.apiFields.notPriced
        ) {
            dispatch(
                setContractModalDetailsError({
                    ...contractModalDetailsError,
                    [ScaleTicketModalConstants.contractModal.apiFields.price]:
                        false,
                    [ScaleTicketModalConstants.contractModal.apiFields
                        .notPriced]: false,
                }),
            );
        }
    };

    const renderPriceTooltip = props => (
        <Tooltip {...props}>
            {ScaleTicketModalConstants.contractModal.selectOnePriceTooltip}
        </Tooltip>
    );

    const contractModalDetailsErrorHandler = (fieldName: string) => {
        const contractModalDetailsErrorCopy = {
            ...contractModalDetailsError,
            [fieldName]: false,
        };

        dispatch(setContractModalDetailsError(contractModalDetailsErrorCopy));
    };

    return (
        <>
            <VendorModal
                showModal={vendorModal.isOpen}
                closeModalWithChanges={handleCloseVendorModal}
                editVendorDetails={VENDOR_INITIAL_STATE.createVendorInitial}
            />
            <div className="row mx-0 mb-lg-2">
                <div className="col-xl-4 col-lg-4 start-top mb-2">
                    <Form.Label>
                        {
                            ScaleTicketModalConstants.contractModal.labels
                                .creationDate
                        }
                        <span className="necessaryFields">*</span>
                    </Form.Label>
                    <Form.Control
                        className={`text-secondary form-item border ${
                            contractModalDetailsError.contractCreationDate
                                ? 'border-danger'
                                : ''
                        } rounded`}
                        name={
                            ScaleTicketModalConstants.contractModal.apiFields
                                .creationDate
                        }
                        value={contractModalDetails.contractCreationDate}
                        onChange={e =>
                            contractModalDetailsHandler(
                                e.target.name,
                                e.target.value,
                            )
                        }
                        type="date"
                        size="sm"
                    />
                </div>
                <div className="col-xl-4 col-lg-4 start-top mb-2">
                    <Form.Label>
                        {
                            ScaleTicketModalConstants.contractModal.labels
                                .contractName
                        }
                        <span className="necessaryFields">*</span>
                    </Form.Label>
                    <Form.Control
                        name={
                            ScaleTicketModalConstants.contractModal.apiFields
                                .contractName
                        }
                        value={contractModalDetails.contractName}
                        placeholder={
                            ScaleTicketModalConstants.contractModal.placeholders
                                .contractName
                        }
                        className={`text-secondary form-item border border-${
                            contractModalDetailsError.contractName
                                ? 'danger'
                                : ''
                        } rounded`}
                        size="sm"
                        type="text"
                        onChange={e => {
                            contractModalDetailsHandler(
                                e.target.name,
                                e.target.value,
                            );
                        }}
                    />
                </div>
                <div className="col-xl-4 col-lg-4 start-top mb-2">
                    <Form.Label>
                        {
                            ScaleTicketModalConstants.contractModal.labels
                                .commodity
                        }
                        <span className="necessaryFields">*</span>
                    </Form.Label>
                    <Form.Control
                        name={
                            ScaleTicketModalConstants.contractModal.apiFields
                                .commodityId
                        }
                        value={contractModalDetails.commodityId}
                        className={`text-secondary form-item border border-${
                            contractModalDetailsError.commodityId
                                ? 'danger'
                                : ''
                        } rounded`}
                        size="sm"
                        as="select"
                        onChange={e => {
                            contractModalDetailsHandler(
                                e.target.name,
                                e.target.value,
                            );
                        }}
                        disabled={isDisabled}
                    >
                        {commodities?.map((commodity, index) => (
                            <option key={index} value={commodity.id}>
                                {commodity.name}
                            </option>
                        ))}
                    </Form.Control>
                </div>
            </div>
            <div className="row mx-0 mb-lg-2">
                <div className="col-xl-4 col-lg-4 start-top mb-2">
                    <Form.Group>
                        <Form.Label>
                            {
                                ScaleTicketModalConstants.contractModal.labels
                                    .vendor
                            }
                            <span className="necessaryFields">*</span>
                        </Form.Label>
                        <AutocompleteDropdown
                            options={vendors}
                            nameKey="name"
                            valueKey="id"
                            onChange={e =>
                                contractModalDetailsHandler(
                                    e.target.name,
                                    e.target.value,
                                )
                            }
                            inputName="vendorId"
                            placeholder={scaleTicketLabelConstants.selectVendor}
                            selected={contractModalDetails.vendorId}
                            styleError={contractModalDetailsError.vendorId}
                            disabled={isDisabled}
                        />
                    </Form.Group>
                </div>
                {isCA9800On_ContractsMVP && (
                    <div className="col-xl-4 col-lg-4 start-top mb-2">
                        <Form.Label>
                            {
                                ScaleTicketModalConstants.contractModal.labels
                                    .payee
                            }
                        </Form.Label>
                        <Form.Control
                            name={
                                ScaleTicketModalConstants.contractModal
                                    .apiFields.payee
                            }
                            value={contractModalDetails.payee}
                            placeholder={
                                ScaleTicketModalConstants.contractModal
                                    .placeholders.payee
                            }
                            className={`text-secondary form-item border border-${
                                contractModalDetailsError.payee ? 'danger' : ''
                            } rounded`}
                            size="sm"
                            type="text"
                            onChange={e => {
                                contractModalDetailsHandler(
                                    e.target.name,
                                    e.target.value,
                                );
                            }}
                        />
                    </div>
                )}
                <div className="col-xl-4 col-lg-4 start-top mb-2 no-arrow-input">
                    <Form.Label>
                        {
                            ScaleTicketModalConstants.contractModal.labels
                                .totalAmount
                        }
                        <span className="necessaryFields">*</span>
                    </Form.Label>
                    <Form.Control
                        name={
                            ScaleTicketModalConstants.contractModal.apiFields
                                .totalAmount
                        }
                        value={contractModalDetails.totalAmount}
                        placeholder={
                            ScaleTicketModalConstants.contractModal.placeholders
                                .totalAmount
                        }
                        className={` text-secondary form-item border border-${
                            contractModalDetailsError.totalAmount
                                ? 'danger'
                                : ''
                        } rounded`}
                        onChange={e => {
                            contractModalDetailsHandler(
                                e.target.name,
                                e.target.value,
                            );
                        }}
                        size="sm"
                        type="number"
                        min="0"
                    />
                </div>
            </div>
            <div className="row mx-0 mb-lg-2">
                {isCA9800On_ContractsMVP && (
                    <div className="col-xl-4 col-lg-4 start-top mb-2">
                        <Form.Group>
                            <Form.Label>
                                {
                                    ScaleTicketModalConstants.contractModal
                                        .labels.dmPercentage
                                }
                            </Form.Label>
                            <Form.Control
                                name={
                                    ScaleTicketModalConstants.contractModal
                                        .apiFields.dmPercentage
                                }
                                value={contractModalDetails.dmPercentage}
                                placeholder={
                                    ScaleTicketModalConstants.contractModal
                                        .placeholders.dmPercentage
                                }
                                className={`text-secondary form-item border border-${
                                    contractModalDetailsError.dmPercentage
                                        ? 'danger'
                                        : ''
                                } rounded`}
                                onChange={e => {
                                    contractModalDetailsHandler(
                                        e.target.name,
                                        e.target.value,
                                        'percentage',
                                    );
                                }}
                                type="text"
                                size="sm"
                            />
                        </Form.Group>
                    </div>
                )}
                <div className="col-xl-4 col-lg-4 start-top mb-2 no-arrow-input">
                    <Form.Label>
                        {ScaleTicketModalConstants.contractModal.labels.price}
                        {isCA9800On_ContractsMVP && (
                            <OverlayTrigger
                                placement="top"
                                overlay={renderPriceTooltip}
                            >
                                <span className="ms-1 my-1">
                                    <InfoIcon
                                        className="mb-2"
                                        style={{ width: '14px' }}
                                    />
                                </span>
                            </OverlayTrigger>
                        )}
                        <span className="necessaryFields">*</span>
                    </Form.Label>
                    <Form.Control
                        name={
                            ScaleTicketModalConstants.contractModal.apiFields
                                .price
                        }
                        value={contractModalDetails.price}
                        placeholder={
                            ScaleTicketModalConstants.contractModal.placeholders
                                .price
                        }
                        className={`text-secondary form-item border border-${
                            contractModalDetailsError.price ? 'danger' : ''
                        } rounded`}
                        onChange={e => {
                            contractModalDetailsHandler(
                                e.target.name,
                                e.target.value,
                            );
                        }}
                        size="sm"
                        disabled={contractModalDetails.notPriced}
                        type="number"
                    />
                </div>
                {isCA9800On_ContractsMVP && (
                    <div className="col-xl-4 d-flex col-lg-4 mb-2 align-self-end align-items-center">
                        <input
                            className="contracts-checkbox-input"
                            onChange={e => {
                                contractModalDetailsHandler(
                                    e.target.name,
                                    !contractModalDetails.notPriced,
                                );
                            }}
                            name={
                                ScaleTicketModalConstants.contractModal
                                    .apiFields.notPriced
                            }
                            type="checkbox"
                            disabled={
                                !!contractModalDetails.price ||
                                contractModalDetails.price === 0
                            }
                            checked={contractModalDetails.notPriced}
                        />{' '}
                        <label
                            className={`m-0 ms-2 px-1 ${
                                contractModalDetails.price ||
                                contractModalDetails.price === 0
                                    ? 'text-muted'
                                    : ''
                            } ${
                                contractModalDetailsError.notPriced
                                    ? 'border border-danger rounded'
                                    : ''
                            }`}
                        >
                            {
                                ScaleTicketModalConstants.contractModal.labels
                                    .notPriced
                            }
                        </label>
                    </div>
                )}
            </div>
            <div className="row mx-0 mb-lg-2">
                <div className="col-xl-4 col-lg-4 start-top mb-2">
                    <Form.Label>
                        {ScaleTicketModalConstants.contractModal.labels.freight}
                        <span className="necessaryFields">*</span>
                    </Form.Label>
                    <Form.Control
                        name={
                            ScaleTicketModalConstants.contractModal.apiFields
                                .freightType
                        }
                        value={contractModalDetails.freightType}
                        className={`text-secondary form-item border border-${
                            contractModalDetailsError.freightType
                                ? 'danger'
                                : 'rounded'
                        }`}
                        size="sm"
                        as="select"
                        onChange={e => {
                            contractModalDetailsHandler(
                                e.target.name,
                                e.target.value,
                            );
                        }}
                    >
                        <StaticOption
                            options={[
                                {
                                    id: 0,
                                    name: scaleTicketLabelConstants.selectFreight,
                                },
                            ]}
                        />
                        {freights?.map((freight, index) => (
                            <option key={index} value={freight}>
                                {freight}
                            </option>
                        ))}
                    </Form.Control>
                </div>
                <div className="col-xl-4 col-lg-4 start-top mb-2 no-arrow-input">
                    <Form.Label>
                        {ScaleTicketModalConstants.contractModal.labels.rate}
                        <span className="necessaryFields">*</span>
                    </Form.Label>
                    <Form.Control
                        name={
                            ScaleTicketModalConstants.contractModal.apiFields
                                .rate
                        }
                        value={contractModalDetails.rate}
                        placeholder={
                            ScaleTicketModalConstants.contractModal.placeholders
                                .rate
                        }
                        className={`text-secondary form-item border border-${
                            contractModalDetailsError.rate ? 'danger' : ''
                        } rounded`}
                        size="sm"
                        type="number"
                        onChange={e => {
                            contractModalDetailsHandler(
                                e.target.name,
                                e.target.value,
                            );
                        }}
                    />
                </div>
                <div className="col-xl-4 col-lg-4 start-top mb-2">
                    {contractHasScaleTickets && isUpdate && (
                        <ContractFormRemainingAmounts />
                    )}
                </div>
            </div>
            <div className="row mx-0 mb-lg-2">
                <div className="col-xl-12 col-lg-12 start-top mb-2">
                    <Form.Label>
                        {
                            ScaleTicketModalConstants.contractModal.labels
                                .comments
                        }
                    </Form.Label>
                    <Form.Control
                        name={
                            ScaleTicketModalConstants.contractModal.apiFields
                                .comment
                        }
                        value={contractModalDetails.comment}
                        placeholder={
                            ScaleTicketModalConstants.contractModal.placeholders
                                .comments
                        }
                        className="form-control"
                        as="textarea"
                        rows={4}
                        onChange={e => {
                            contractModalDetailsHandler(
                                e.target.name,
                                e.target.value,
                            );
                        }}
                    />
                </div>
            </div>
        </>
    );
};

export default ContractFormBody;
