import { Dropdown } from 'react-bootstrap';

import { useOwnerContext } from '../../Context/OwnerContext/OwnerContext';

//potentially create financialAppContext
//this will store selected owner and be used when dispatching any requests involving feedlots/lots/owners - we will only retrieve those that belong to selected owner.
//if you implement financialAppContext, you could remove many instances of getting statements and interest rates, and instead do it once and store in financial app context.
//This context would only fire those requests if the user has access to financial app and they have selected financial app in left hand nav.
//I think this is a good middle ground - I do not want to implement redux in the financial app, but some sort of global state would improve the financial app.
export default function OwnerDropdown() {
    const { owners, selectedOwner, updateSelectedOwner } = useOwnerContext();

    return !owners.length ? null : (
        <div className="mx-3">
            <Dropdown className="top-header-dropdown">
                {owners.length > 1 ? (
                    <>
                        <Dropdown.Toggle className="p-0">
                            Owner: {selectedOwner.name}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {owners.map((owner, index) => (
                                <Dropdown.Item
                                    key={index}
                                    onClick={() =>
                                        updateSelectedOwner(owner.id)
                                    }
                                >
                                    {owner.name}
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </>
                ) : (
                    <Dropdown.Toggle
                        className="p-0 cursor-default"
                        bsPrefix="p-0"
                    >
                        {selectedOwner.name}
                    </Dropdown.Toggle>
                )}
            </Dropdown>
        </div>
    );
}
