import { Button, Form, Modal } from 'react-bootstrap';

import { CommonConstants } from '../../utils/constants/CommonConstants';
import { validateAccordingType } from '../../utils/validation';
import { useEffect, useState } from 'react';
import { useLoading } from '../../commonHooks/useLoading';
import FullPageLoader from '../common/FullPageLoader';
import {
    createVendor,
    fetchInactiveVendorInfo,
    updateVendorDetail,
} from '../../Redux/Actions/vendorActions';
import { useAppDispatch } from '../../Redux/Store';
import { useFeedlotId } from '../../commonHooks/useFeedlotId';
import { useModalStatus } from '../../commonHooks/useModalStatus';
import { ActivateItemModal } from '../../apps/shippingTracker/modules/BuyPo/components/ActivateItemModal';
import { FmSettingsConstant } from '../../utils/constants/FmSettingsConstants';
import { VENDOR_INITIAL_STATE } from '../../Redux/Constants/vendorInitialState';
import { vendorConstants } from '../../utils/constants/VendorConstants';
import {
    IVendorDetail,
    IVendorDetailsError,
    IVendorModal,
} from '../../utils/Interface/VendorInterface';

const VendorModal = ({
    showModal,
    closeModalWithChanges,
    editVendorDetails,
    showActiveField,
}: IVendorModal) => {
    const [vendorDetail, setVendorDetail] =
        useState<IVendorDetail>(editVendorDetails);
    const [vendorDetailsError, setVendorDetailsError] =
        useState<IVendorDetailsError>(
            vendorConstants.initialVendorDetailsError,
        );
    const [inActiveVendor, setInActiveVendor] = useState<IVendorDetail>();
    const {
        isOpen: isActivateModalOpen,
        open: openActivateModal,
        close: closeActivateModal,
    } = useModalStatus();
    const { loading, setLoading } = useLoading();
    const dispatch = useAppDispatch();
    const feedlotId = useFeedlotId();

    const handleActivateVendor = async () => {
        if (feedlotId && vendorDetail.name) {
            const inactiveVendorInfo = await dispatch(
                fetchInactiveVendorInfo(vendorDetail.name.trim()),
            );
            if (inactiveVendorInfo) {
                setInActiveVendor(inactiveVendorInfo[0]);
                openActivateModal();
            }
        }
    };

    const handleUpdateVendorDetail = (): void => {
        const isValid = validateVendorDetails();
        if (feedlotId && isValid) {
            dispatch(
                updateVendorDetail(vendorDetail, {
                    closeModalWithChanges,
                    setLoading,
                    handleActivateVendor,
                }),
            );
        }
    };

    const validateForm = (vendorErrorCopy: IVendorDetailsError) => {
        let isFormValid = true;
        const validateFields = ['contactNumber'];
        const validateTypes = ['mobileLength'];

        validateFields.forEach((validateField, index) => {
            const isValid = validateAccordingType(
                validateTypes[index],
                vendorDetail[validateField],
            );
            if (!vendorErrorCopy[validateField] && !isValid) {
                vendorErrorCopy[validateField] = true;
            }

            if (!isValid) {
                isFormValid = false;
            }
        });

        setVendorDetailsError(vendorErrorCopy);

        return isFormValid;
    };

    const validateVendorDetails = (): boolean => {
        let isValid = true;
        const { name, contactName, address, city, state, zipCode } =
            vendorDetail;
        const updatedVendorDetailErrors = {
            name: !name,
            contactNumber: false,
            contactName: !contactName,
            address: !address,
            city: !city,
            state: !state,
            zipCode: !zipCode,
        };
        setVendorDetailsError(updatedVendorDetailErrors);
        const isFormValid = validateForm(updatedVendorDetailErrors);
        if (
            Object.values(updatedVendorDetailErrors).includes(true) ||
            !isFormValid
        ) {
            isValid = false;
        }

        return isValid;
    };

    const handleCreateVendor = (): void => {
        const isValid = validateVendorDetails();
        if (feedlotId && isValid) {
            dispatch(
                createVendor(
                    vendorDetail,
                    closeModalWithChanges,
                    setLoading,
                    handleActivateVendor,
                ),
            );
        }
    };

    const handleDetailChange = (
        fieldValue: string | number | boolean,
        fieldName: string,
        type?: string,
    ): void => {
        const isValid = validateAccordingType(type, fieldValue);
        if (isValid) {
            setVendorDetail(prevVendorDetail => ({
                ...prevVendorDetail,
                [fieldName]: fieldValue,
            }));
        }
    };

    const handleCloseActivateModal = (isActivated: boolean) => {
        if (isActivated && feedlotId) {
            if (inActiveVendor) {
                const inActiveVendorCopy = {
                    ...inActiveVendor,
                    active: true,
                };
                dispatch(
                    updateVendorDetail(inActiveVendorCopy, {
                        closeModalWithChanges,
                        setLoading,
                    }),
                );
                closeActivateModal();
            }
        } else {
            closeActivateModal();
        }
    };

    useEffect(() => {
        if (!showModal) {
            setVendorDetail({ ...VENDOR_INITIAL_STATE.createVendorInitial });
        }
    }, [showModal]);

    return (
        <>
            <ActivateItemModal
                showModal={isActivateModalOpen}
                closeModalHandler={handleCloseActivateModal}
                itemName={vendorDetail.name?.trim()}
                item={vendorConstants.activateModalConstant}
            />
            <Modal
                className="px-2 pt-5 fm-settings-modal"
                size="lg"
                show={showModal}
                centered
                animation
                onHide={() => closeModalWithChanges(false)}
            >
                {loading && <FullPageLoader />}
                <Modal.Header closeButton className="border-bottom-0">
                    <Modal.Title className="text-dark">
                        {editVendorDetails.name
                            ? `${vendorConstants.editVendor} ${editVendorDetails?.name}`
                            : vendorConstants.createVendors}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="border-bottom">
                        <div className="d-flex justify-content-between mb-4">
                            <div className="col-6">
                                <Form.Label className="text-secondary">
                                    {
                                        vendorConstants.createNewVendors.form
                                            .name.label
                                    }
                                    <span className="necessaryFields">*</span>
                                </Form.Label>
                                <Form.Control
                                    className={`text-secondary border-${
                                        vendorDetailsError.name ? 'danger' : ''
                                    }`}
                                    value={vendorDetail?.name}
                                    name={
                                        vendorConstants.createNewVendors.form
                                            .name.name
                                    }
                                    placeholder={
                                        vendorConstants.createNewVendors.form
                                            .name.placeholder
                                    }
                                    onChange={e =>
                                        handleDetailChange(
                                            e.target.value,
                                            e.target.name,
                                            'string',
                                        )
                                    }
                                    maxLength={
                                        vendorConstants.createNewVendors.form
                                            .name.maxLength
                                    }
                                    size="sm"
                                    type="text"
                                />
                            </div>
                            {showActiveField && (
                                <div className="custom-checkbox col-5 ">
                                    <input
                                        type="checkbox"
                                        checked={vendorDetail.active}
                                        className="mx-2"
                                        onChange={e =>
                                            handleDetailChange(
                                                !vendorDetail.active,
                                                'active',
                                            )
                                        }
                                    />
                                    <label className="mx-2">
                                        {FmSettingsConstant.active.label}
                                    </label>
                                </div>
                            )}
                        </div>
                        <div className="d-flex justify-content-between mb-4">
                            <div className="col-6">
                                <Form.Label className="text-secondary">
                                    {
                                        vendorConstants.createNewVendors.form
                                            .contactName.label
                                    }
                                </Form.Label>
                                <span className="necessaryFields">*</span>
                                <Form.Control
                                    className={`text-secondary border-${
                                        vendorDetailsError.contactName
                                            ? 'danger'
                                            : ''
                                    }`}
                                    value={vendorDetail?.contactName}
                                    name={
                                        vendorConstants.createNewVendors.form
                                            .contactName.name
                                    }
                                    placeholder={
                                        vendorConstants.createNewVendors.form
                                            .contactName.placeholder
                                    }
                                    onChange={e =>
                                        handleDetailChange(
                                            e.target.value,
                                            e.target.name,
                                        )
                                    }
                                    size="sm"
                                    type="text"
                                />
                            </div>
                            <div className="col-5">
                                <Form.Label className="text-secondary">
                                    {
                                        vendorConstants.createNewVendors.form
                                            .contactNumber.label
                                    }
                                </Form.Label>
                                <Form.Control
                                    className={`text-secondary border-${
                                        vendorDetailsError.contactNumber
                                            ? 'danger'
                                            : ''
                                    }`}
                                    value={vendorDetail?.contactNumber}
                                    name={
                                        vendorConstants.createNewVendors.form
                                            .contactNumber.name
                                    }
                                    placeholder={
                                        vendorConstants.createNewVendors.form
                                            .contactNumber.placeholder
                                    }
                                    onChange={e =>
                                        handleDetailChange(
                                            e.target.value,
                                            e.target.name,
                                        )
                                    }
                                    size="sm"
                                    type="text"
                                />
                            </div>
                        </div>
                        <div className="d-flex justify-content-between mb-4">
                            <div className="col-6">
                                <Form.Label className="text-secondary">
                                    {
                                        vendorConstants.createNewVendors.form
                                            .address.label
                                    }
                                </Form.Label>
                                <span className="necessaryFields">*</span>
                                <Form.Control
                                    className={`text-secondary border-${
                                        vendorDetailsError.address
                                            ? 'danger'
                                            : ''
                                    }`}
                                    value={vendorDetail?.address}
                                    name={
                                        vendorConstants.createNewVendors.form
                                            .address.name
                                    }
                                    placeholder={
                                        vendorConstants.createNewVendors.form
                                            .address.placeholder
                                    }
                                    onChange={e =>
                                        handleDetailChange(
                                            e.target.value,
                                            e.target.name,
                                        )
                                    }
                                    size="sm"
                                    type="text"
                                />
                            </div>
                            <div className="col-5">
                                <Form.Label className="text-secondary">
                                    {
                                        vendorConstants.createNewVendors.form
                                            .city.label
                                    }
                                </Form.Label>
                                <span className="necessaryFields">*</span>
                                <Form.Control
                                    className={`text-secondary border-${
                                        vendorDetailsError.city ? 'danger' : ''
                                    }`}
                                    value={vendorDetail?.city}
                                    name={
                                        vendorConstants.createNewVendors.form
                                            .city.name
                                    }
                                    placeholder={
                                        vendorConstants.createNewVendors.form
                                            .city.placeholder
                                    }
                                    onChange={e =>
                                        handleDetailChange(
                                            e.target.value,
                                            e.target.name,
                                        )
                                    }
                                    size="sm"
                                    type="text"
                                />
                            </div>
                        </div>
                        <div className="d-flex justify-content-between mb-4">
                            <div className="col-6">
                                <Form.Label className="text-secondary">
                                    {
                                        vendorConstants.createNewVendors.form
                                            .state.label
                                    }
                                </Form.Label>
                                <span className="necessaryFields">*</span>
                                <Form.Control
                                    className={`text-secondary border-${
                                        vendorDetailsError.state ? 'danger' : ''
                                    }`}
                                    value={vendorDetail?.state}
                                    name={
                                        vendorConstants.createNewVendors.form
                                            .state.name
                                    }
                                    placeholder={
                                        vendorConstants.createNewVendors.form
                                            .state.placeholder
                                    }
                                    onChange={e =>
                                        handleDetailChange(
                                            e.target.value,
                                            e.target.name,
                                        )
                                    }
                                    size="sm"
                                    type="text"
                                />
                            </div>
                            <div className="col-5">
                                <Form.Label className="text-secondary">
                                    {
                                        vendorConstants.createNewVendors.form
                                            .zipCode.label
                                    }
                                </Form.Label>
                                <span className="necessaryFields">*</span>
                                <Form.Control
                                    className={`text-secondary border-${
                                        vendorDetailsError.zipCode
                                            ? 'danger'
                                            : ''
                                    }`}
                                    value={vendorDetail?.zipCode}
                                    name={
                                        vendorConstants.createNewVendors.form
                                            .zipCode.name
                                    }
                                    placeholder={
                                        vendorConstants.createNewVendors.form
                                            .zipCode.placeholder
                                    }
                                    onChange={e =>
                                        handleDetailChange(
                                            e.target.value,
                                            e.target.name,
                                        )
                                    }
                                    size="sm"
                                    type="text"
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="border-top-0">
                    <Button
                        className="secondary"
                        onClick={() => closeModalWithChanges(false)}
                    >
                        {CommonConstants.en.cancel}
                    </Button>
                    <Button
                        onClick={
                            !editVendorDetails.name
                                ? handleCreateVendor
                                : handleUpdateVendorDetail
                        }
                    >
                        {CommonConstants.en.save}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default VendorModal;
