import { useEffect, useState, useMemo } from 'react';
import { Button, Col } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../Redux/Store';
import { useModalStatus } from '../../commonHooks/useModalStatus';
import { useFeedlotId } from '../../commonHooks/useFeedlotId';
import { useDebouncedEffect } from '../useDebounceEffect';
import {
    deleteMixingBin,
    fetchMixingBinDetails,
    updateMixingBinDetail,
} from '../../Redux/Actions/mixingBinActions';
import { setMixingBinFilter } from '../../Redux/Reducer/mixingBinReducer';
import { MIXING_BIN_INITIAL_STATE } from '../../Redux/Constants/mixingBinInitialState';
import MixingBinModal from './MixingBinModal';
import SettingsItemDetail from './FMSettings/SettingsItemDetail';
import { DeleteItemModal } from './FMSettings/DeleteItemModal';
import Constants from '../../utils/Constants';
import StorageService from '../../Services/storage/storage.service';
import { mixingBinConstants } from '../../utils/constants/MixingBinConstants';
import { FmSettingsConstant } from '../../utils/constants/FmSettingsConstants';
import {
    IFilter,
    IMixingBinDetail,
} from '../../utils/Interface/MixingBinInterface';

const MixingBinDetails = () => {
    const { filter, loader } = useAppSelector(state => state.mixingBin);
    const storage = useMemo(() => new StorageService(), []);
    const feedlotId = useFeedlotId();
    const dispatch = useAppDispatch();
    const {
        isOpen: isMixingBinModalOpen,
        open: openMixingBinModal,
        close: closeMixingBinModal,
    } = useModalStatus();
    const {
        isOpen: isDeleteModalOpen,
        open: openDeleteModal,
        close: closeDeleteModal,
    } = useModalStatus();
    const [searchQuery, setSearchQuery] = useState<string | undefined>(
        undefined,
    );
    const [mixingBinDetails, setMixingBinDetails] = useState<
        IMixingBinDetail[]
    >([]);
    const [selectedMixingBin, setSelectedMixingBin] =
        useState<IMixingBinDetail>(
            MIXING_BIN_INITIAL_STATE.createMixingBinInitial,
        );

    const fetchMixingBinData = (binFilter: IFilter = filter): void => {
        if (feedlotId) {
            dispatch(
                fetchMixingBinDetails(
                    feedlotId,
                    setMixingBinDetails,
                    binFilter,
                ),
            );
        }
    };

    const handleEditMixingBinDetail = (mixingBin: IMixingBinDetail): void => {
        setSelectedMixingBin(mixingBin);
        openMixingBinModal();
    };

    const setMixingBinFilterInStorage = (filterValues: IFilter) => {
        storage.setValue(
            mixingBinConstants.mixingBinFilter,
            filterValues,
            Constants.Storage.LOCAL,
        );
    };

    const handleFilter = (filterName: string, filterValue: string) => {
        const updatedFilter = { ...filter, [filterName]: filterValue };
        fetchMixingBinData(updatedFilter);
        dispatch(setMixingBinFilter(updatedFilter));
        setMixingBinFilterInStorage(updatedFilter);
    };

    const closeModalWithChanges = (isEdited: boolean): void => {
        if (isEdited) {
            fetchMixingBinData(filter);
        }
        closeMixingBinModal();
    };

    const resetFilter = (): void => {
        const updatedFilter = MIXING_BIN_INITIAL_STATE.filter;
        fetchMixingBinData(updatedFilter);
        setSearchQuery('');
        dispatch(setMixingBinFilter(updatedFilter));
        setMixingBinFilterInStorage(updatedFilter);
    };

    const handleCreateMixingBin = (): void => {
        if (feedlotId) {
            setSelectedMixingBin({
                ...MIXING_BIN_INITIAL_STATE.createMixingBinInitial,
                feedLotId: feedlotId,
            });
        }
        openMixingBinModal();
    };

    const handleDeleteModalHandler = (mixingBin: IMixingBinDetail) => {
        setSelectedMixingBin(mixingBin);
        openDeleteModal();
    };

    const handleDeleteMixingBin = (shouldTakeAction: boolean) => {
        if (feedlotId && shouldTakeAction) {
            if (
                !selectedMixingBin.isFeedTaskAssociated ||
                selectedMixingBin.active
            ) {
                const isActiveAndAssociated =
                    selectedMixingBin.isFeedTaskAssociated &&
                    selectedMixingBin.active;
                const action = isActiveAndAssociated
                    ? updateMixingBinDetail
                    : deleteMixingBin;
                const updatedMixingBinDetail = isActiveAndAssociated
                    ? { ...selectedMixingBin, active: false }
                    : selectedMixingBin;
                dispatch(
                    action(
                        feedlotId,
                        updatedMixingBinDetail,
                        fetchMixingBinData,
                    ),
                );
            }
        }
        closeDeleteModal();
    };

    useDebouncedEffect(
        () => {
            if (searchQuery !== undefined) {
                handleFilter('name', searchQuery);
            }
        },
        [searchQuery],
        1000,
    );

    useEffect(() => {
        if (feedlotId) {
            const mixingBinFilter = storage.getValue(
                mixingBinConstants.mixingBinFilter,
            );
            if (Object.keys(mixingBinFilter).length > 0) {
                dispatch(setMixingBinFilter(mixingBinFilter));
            }
            if (!mixingBinFilter.name) {
                if (mixingBinFilter.show) {
                    fetchMixingBinData(mixingBinFilter);
                } else {
                    dispatch(
                        fetchMixingBinDetails(
                            feedlotId,
                            setMixingBinDetails,
                            filter,
                        ),
                    );
                }
            } else {
                setSearchQuery(mixingBinFilter?.name || filter.name);
            }
        }
    }, [feedlotId, dispatch, storage]);

    return (
        <div className="pens-container">
            <h5 className="my-3">
                {mixingBinConstants.mixingBinDetails.heading}
            </h5>
            <div className="d-flex align-items-end">
                <Col xs={3} className="d-flex flex-column">
                    <label>
                        {mixingBinConstants.mixingBinDetails.mixingBin}
                    </label>
                    <input
                        className="text-secondary my-2"
                        type="text"
                        placeholder={
                            mixingBinConstants.mixingBinDetails.placeholder
                        }
                        maxLength={
                            mixingBinConstants.mixingBinDetails.maxLength
                        }
                        value={searchQuery ? searchQuery : ''}
                        onChange={e => setSearchQuery(e.target.value)}
                    />
                </Col>
                <Col xs={3}>
                    <Button
                        className="secondary my-2 mx-4"
                        onClick={resetFilter}
                    >
                        {mixingBinConstants.clearFilter}
                    </Button>
                </Col>
                <Col xs={6} className="d-flex justify-content-end">
                    <Button className="my-2" onClick={handleCreateMixingBin}>
                        {mixingBinConstants.createNewMixingBins.heading}
                    </Button>
                </Col>
            </div>
            <div
                className="o-switch btn-group show-toggle my-2"
                data-toggle="buttons"
                role="group"
            >
                {FmSettingsConstant.filterOptions.map(option => (
                    <label
                        key={option.value}
                        className={`btn btn-secondary py-1 ${
                            filter.show === option.value
                                ? FmSettingsConstant.active.value
                                : ''
                        }`}
                    >
                        <input
                            type="radio"
                            onChange={() =>
                                handleFilter(option.name, option.value)
                            }
                            checked={filter.show === option.value}
                        />
                        {option.label}
                    </label>
                ))}
            </div>
            <SettingsItemDetail<IMixingBinDetail>
                columnDetail={mixingBinConstants.mixingBinColumnDetail}
                itemDetail={mixingBinDetails}
                handleEditDetail={handleEditMixingBinDetail}
                handleDeleteModalHandler={handleDeleteModalHandler}
                loader={loader}
            />
            {isMixingBinModalOpen && (
                <MixingBinModal
                    showModal={isMixingBinModalOpen}
                    closeModalWithChanges={closeModalWithChanges}
                    editMixingBinDetails={selectedMixingBin}
                />
            )}
            {isDeleteModalOpen && (
                <DeleteItemModal
                    item={selectedMixingBin}
                    handleDeleteItem={handleDeleteMixingBin}
                    showModal={isDeleteModalOpen}
                    itemName={mixingBinConstants.mixingBinDetails.name}
                ></DeleteItemModal>
            )}
        </div>
    );
};

export default MixingBinDetails;
