import { useEffect, useState, useMemo } from 'react';
import { Button, Col } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../Redux/Store';
import { useModalStatus } from '../../commonHooks/useModalStatus';
import { useFeedlotId } from '../../commonHooks/useFeedlotId';
import { useDebouncedEffect } from '../useDebounceEffect';
import StorageService from '../../Services/storage/storage.service';
import {
    deleteTruckingCompany,
    fetchTruckingCompanyDetails,
    updateTruckingCompanyDetail,
} from '../../Redux/Actions/truckingCompanyActions';
import { TRUCKING_COMPANY_INITIAL_STATE } from '../../Redux/Constants/truckingCompanyInitialState';
import {
    ITruckingCompanyDetail,
    IFilter,
} from '../../utils/Interface/TruckingCompanyInterface';
import SettingsItemDetail from './FMSettings/SettingsItemDetail';
import TruckingCompanyModal from './TruckingCompanyModal';
import Constants from '../../utils/Constants';
import { setTruckingCompanyFilter } from '../../Redux/Reducer/truckingCompanySlice';
import { DeleteItemModal } from './FMSettings/DeleteItemModal';
import { FmSettingsConstant } from '../../utils/constants/FmSettingsConstants';
import { truckingCompanyConstants } from '../../utils/constants/TruckingCompanyConstant';

const TruckingCompanyDetails = () => {
    const { filter, loader } = useAppSelector(state => state.truckingCompany);
    const storage = useMemo(() => new StorageService(), []);
    const feedlotId = useFeedlotId();
    const dispatch = useAppDispatch();
    const {
        isOpen: isTruckingCompanyModalOpen,
        open: openTruckingCompanyModal,
        close: closeTruckingCompanyModal,
    } = useModalStatus();
    const {
        isOpen: isDeleteModalOpen,
        open: openDeleteModal,
        close: closeDeleteModal,
    } = useModalStatus();
    const [searchQuery, setSearchQuery] = useState<string | undefined>(
        undefined,
    );
    const [truckingCompanyDetails, setTruckingCompanyDetails] = useState<
        ITruckingCompanyDetail[]
    >([]);
    const [selectedTruckingCompany, setSelectedTruckingCompany] =
        useState<ITruckingCompanyDetail>(
            TRUCKING_COMPANY_INITIAL_STATE.createTruckingCompanyInitial,
        );

    const fetchTruckingCompanyData = (
        truckingCompanyFilter: IFilter = filter,
    ): void => {
        dispatch(
            fetchTruckingCompanyDetails(
                setTruckingCompanyDetails,
                truckingCompanyFilter,
            ),
        );
    };

    const handleEditTruckingCompanyDetail = (
        truckingCompany: ITruckingCompanyDetail,
    ): void => {
        setSelectedTruckingCompany(truckingCompany);
        openTruckingCompanyModal();
    };

    const resetFilter = (): void => {
        const updatedFilter = TRUCKING_COMPANY_INITIAL_STATE.filter;
        fetchTruckingCompanyData(updatedFilter);
        setSearchQuery('');
        dispatch(setTruckingCompanyFilter(updatedFilter));
        setTruckingCompanyFilterInStorage(updatedFilter);
    };

    const setTruckingCompanyFilterInStorage = (filterValues: IFilter) => {
        storage.setValue(
            truckingCompanyConstants.truckingCompanyFilter,
            filterValues,
            Constants.Storage.LOCAL,
        );
    };

    const handleFilter = (filterName: string, filterValue: string) => {
        const updatedFilter = { ...filter, [filterName]: filterValue };
        fetchTruckingCompanyData(updatedFilter);
        dispatch(setTruckingCompanyFilter(updatedFilter));
        setTruckingCompanyFilterInStorage(updatedFilter);
    };

    const closeModalWithChanges = (isEdited: boolean): void => {
        if (isEdited) {
            fetchTruckingCompanyData(filter);
        }
        closeTruckingCompanyModal();
    };

    const handleCreateTruckingCompany = (): void => {
        if (feedlotId) {
            setSelectedTruckingCompany({
                ...TRUCKING_COMPANY_INITIAL_STATE.createTruckingCompanyInitial,
                feedLotId: feedlotId,
            });
        }
        openTruckingCompanyModal();
    };

    const handleDeleteModalHandler = (
        truckingCompany: ITruckingCompanyDetail,
    ) => {
        setSelectedTruckingCompany(truckingCompany);
        openDeleteModal();
    };

    const handleDeleteTruckingCompany = (shouldTakeAction: boolean) => {
        if (shouldTakeAction) {
            if (
                !selectedTruckingCompany.isAssociated ||
                selectedTruckingCompany.active
            ) {
                const isActiveAndAssociated =
                    selectedTruckingCompany.isAssociated &&
                    selectedTruckingCompany.active;
                const action = isActiveAndAssociated
                    ? updateTruckingCompanyDetail
                    : deleteTruckingCompany;
                const updatedTruckingCompanyDetail = isActiveAndAssociated
                    ? { ...selectedTruckingCompany, active: false }
                    : selectedTruckingCompany;
                dispatch(
                    action(
                        updatedTruckingCompanyDetail,
                        fetchTruckingCompanyData,
                    ),
                );
            }
        }
        closeDeleteModal();
    };

    useDebouncedEffect(
        () => {
            if (searchQuery !== undefined) {
                handleFilter('name', searchQuery);
            }
        },
        [searchQuery],
        1000,
    );

    useEffect(() => {
        if (feedlotId) {
            const truckingCompanyFilter = storage.getValue(
                truckingCompanyConstants.truckingCompanyFilter,
            );
            if (Object.keys(truckingCompanyFilter).length > 0) {
                dispatch(setTruckingCompanyFilter(truckingCompanyFilter));
            }
            if (!truckingCompanyFilter.name) {
                if (truckingCompanyFilter.show) {
                    fetchTruckingCompanyData(truckingCompanyFilter);
                } else {
                    dispatch(
                        fetchTruckingCompanyDetails(
                            setTruckingCompanyDetails,
                            filter,
                        ),
                    );
                }
            } else {
                setSearchQuery(truckingCompanyFilter?.name || filter.name);
            }
        }
    }, [feedlotId, dispatch, storage]);

    return (
        <div className="pens-container">
            <h5 className="my-3">
                {truckingCompanyConstants.truckingCompanyDetails.heading}
            </h5>
            <div className="d-flex align-items-end">
                <Col xs={3} className="d-flex flex-column">
                    <label>
                        {
                            truckingCompanyConstants.truckingCompanyDetails
                                .truckingCompany
                        }
                    </label>
                    <input
                        className="text-secondary my-2"
                        type="text"
                        placeholder={
                            truckingCompanyConstants.truckingCompanyDetails
                                .placeholder
                        }
                        maxLength={
                            truckingCompanyConstants.truckingCompanyDetails
                                .maxLength
                        }
                        value={searchQuery ? searchQuery : ''}
                        onChange={e => setSearchQuery(e.target.value)}
                    />
                </Col>
                <Col xs={3}>
                    <Button
                        className="secondary my-2 mx-4"
                        onClick={resetFilter}
                    >
                        {truckingCompanyConstants.clearFilter}
                    </Button>
                </Col>
                <Col className="d-flex justify-content-end">
                    <Button
                        className="my-2"
                        onClick={handleCreateTruckingCompany}
                    >
                        {
                            truckingCompanyConstants.createNewTruckingCompany
                                .heading
                        }
                    </Button>
                </Col>
            </div>
            <div
                className="o-switch btn-group show-toggle my-2"
                data-toggle="buttons"
                role="group"
            >
                {FmSettingsConstant.filterOptions.map(option => (
                    <label
                        key={option.value}
                        className={`btn btn-secondary py-1 ${
                            filter.show === option.value
                                ? FmSettingsConstant.active.value
                                : ''
                        }`}
                    >
                        <input
                            type="radio"
                            onChange={() =>
                                handleFilter(option.name, option.value)
                            }
                            checked={filter.show === option.value}
                        />
                        {option.label}
                    </label>
                ))}
            </div>
            <SettingsItemDetail<ITruckingCompanyDetail>
                columnDetail={
                    truckingCompanyConstants.truckingCompanyColumnDetail
                }
                itemDetail={truckingCompanyDetails}
                handleEditDetail={handleEditTruckingCompanyDetail}
                handleDeleteModalHandler={handleDeleteModalHandler}
                loader={loader}
                columnSize={1}
            />
            {isTruckingCompanyModalOpen && (
                <TruckingCompanyModal
                    showModal={isTruckingCompanyModalOpen}
                    closeModalWithChanges={closeModalWithChanges}
                    editTruckingCompanyDetails={selectedTruckingCompany}
                    showActiveField={true}
                />
            )}
            {isDeleteModalOpen && (
                <DeleteItemModal
                    item={selectedTruckingCompany}
                    handleDeleteItem={handleDeleteTruckingCompany}
                    showModal={isDeleteModalOpen}
                    itemName={
                        truckingCompanyConstants.truckingCompanyDetails
                            .truckingCompany
                    }
                ></DeleteItemModal>
            )}
        </div>
    );
};

export default TruckingCompanyDetails;
