import { Button } from 'react-bootstrap';
import { EditScaleTicketConstants } from '../constants/ScaleTicketConstants';
import { IEditScaleTicketFooter } from '../interfaces/ScaleTicketInterface';
import { useAppSelector } from '../../../../Redux/Store';
import SpinnerSubmitButton from '../../../../components/common/SpinnerSubmitButton';

const EditScaleTicketFooter = ({
    onCancel,
    onSubmit,
    isDisabled,
}: IEditScaleTicketFooter) => {
    const { loading, submitPending } = useAppSelector(
        state => state.scaleTicket,
    );

    return (
        <div className="row submit-buttons d-flex align-items-center justify-content-end m-0 mt-3">
            <div className="d-flex justify-content-end">
                <Button onClick={onCancel} className="secondary mx-2">
                    {EditScaleTicketConstants.cancel}
                </Button>
                <SpinnerSubmitButton
                    disabled={submitPending || loading || (isDisabled ?? false)}
                    submitPending={submitPending}
                    className="ml-3 mr-3"
                    buttonContent={EditScaleTicketConstants.submit}
                    onClick={onSubmit}
                />
            </div>
        </div>
    );
};

export default EditScaleTicketFooter;
