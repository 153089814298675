import { AnyAction } from '@reduxjs/toolkit';
import { ActionTypes } from '../Actions';
import { CREATE_HOSPITAL_INITIAL_STATE } from '../Constants/hospitalInitialState';

const HospitalReducer = (
    state = CREATE_HOSPITAL_INITIAL_STATE,
    action: AnyAction,
) => {
    let stateCopy = { ...state };

    switch (action.type) {
        case ActionTypes.SET_HOSPITAL_LOADING:
            stateCopy = {
                ...stateCopy,
                loading: action.payload,
            };
            return stateCopy;
        case ActionTypes.SET_HOSPITAL_LOADING_HISTORY:
            stateCopy = {
                ...stateCopy,
                loadingHistory: action.payload,
            };
            return stateCopy;
        case ActionTypes.SET_HOSPITAL_ANIMALS_LIST:
            stateCopy = {
                ...stateCopy,
                hospitalAnimalsList: action.payload,
            };
            return stateCopy;
        case ActionTypes.SET_HOSPITAL_ANIMALS_REPORTS:
            stateCopy = {
                ...stateCopy,
                hospitalAnimalsReports: action.payload,
            };
            return stateCopy;
        case ActionTypes.SET_HOSPITAL_PENS:
            stateCopy = {
                ...stateCopy,
                hospitalPens: action.payload,
            };
            return stateCopy;
        case ActionTypes.SET_HOSPITAL_ANIMAL_ADMIN_HISTORY:
            stateCopy = {
                ...stateCopy,
                animalAdminHistory: action.payload,
            };
            return stateCopy;
        default:
            return stateCopy;
    }
};

export default HospitalReducer;
