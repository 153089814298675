export const tankConstants = {
    createNewTanks: {
        heading: 'Create Tank',
        form: {
            name: {
                name: 'name',
                label: 'Tank Name',
                maxLength: 50,
                placeholder: 'Enter Tank Name',
            },
            preload: {
                name: 'preload',
                label: 'Pre- Weigh',
            },
        },
    },
    tankDetails: {
        placeholder: 'Search',
        tank: 'Tank',
        heading: 'Manage Tanks',
        maxLength: 50,
        name: 'Tank',
    },
    tankColumnDetail: [
        { label: 'Tank Name', key: 'name' },
        { label: 'Status', key: 'active' },
    ],
    clearFilter: 'Clear Filter',
    editTank: 'Edit Tank - ',
    activateModalConstant: {
        heading: 'Activate Tank',
        text: ' is currently Inactive. Activate and use this Tank.',
    },
    tankFilter: 'tankFilter',
};
