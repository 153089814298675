import { AnyAction } from '@reduxjs/toolkit';
import { ActionTypes } from '../Actions';
import { PEN_RATION_TEMPLATES_INITIAL_STATE } from '../Constants/penRationTemplatesInitialState';

const PenRationTemplatesReducer = (
    state = PEN_RATION_TEMPLATES_INITIAL_STATE,
    action: AnyAction,
) => {
    switch (action.type) {
        case ActionTypes.SET_PEN_RATION_TEMPLATES:
            return {
                ...state,
                rationTemplates: action.payload,
            };
        case ActionTypes.APPEND_PEN_RATION_TEMPLATES:
            return {
                ...state,
                rationTemplates: [...state.rationTemplates, ...action.payload],
            };
        case ActionTypes.SET_EXISTING_PEN_RATION_TEMPLATES:
            return {
                ...state,
                existingRationTemplates: action.payload,
            };
        case ActionTypes.SET_EXISTING_PEN_RATION_TEMPLATES_DETAILS:
            return {
                ...state,
                existingRationTemplatesDetails: action.payload,
            };
        case ActionTypes.SET_SCHEDULE_TYPE:
            return {
                ...state,
                scheduleTypes: action.payload,
            };
        case ActionTypes.SET_EDIT_PEN_RATION_TEMPLATE_BASIC_DETAIL:
            return {
                ...state,
                editPenRationTemplateBasicDetail: action.payload,
            };
        case ActionTypes.SET_EDIT_PEN_RATION_TEMPLATE_BASIC_DETAIL_ERROR:
            return {
                ...state,
                editPenRationTemplateBasicDetailError: action.payload,
            };
        case ActionTypes.SET_EDIT_PEN_RATION_TEMPLATE_TABLE_RATIONS:
            return {
                ...state,
                rations: action.payload,
            };
        case ActionTypes.SET_EDIT_PEN_RATION_TEMPLATES:
            return {
                ...state,
                editPenRationTemplates: action.payload,
            };
        case ActionTypes.SET_DELETED_PEN_RATION_TEMPLATES:
            return {
                ...state,
                deletedPenRationTemplates: [
                    ...state.deletedPenRationTemplates,
                    ...action.payload,
                ],
            };
        case ActionTypes.SET_EDIT_PEN_RATION_TEMPLATES_ERROR:
            return {
                ...state,
                editPenRationTemplatesError: action.payload,
            };
        case ActionTypes.RESET_PEN_RATION_SCHEDULE_TEMPLATES:
            return {
                ...state,
                ...action.payload,
            };
        case ActionTypes.SET_EDIT_PEN_RATION_TEMPLATE:
            return {
                ...state,
                editPenRationTemplates: action.payload.editPenRationTemplates,
                editPenRationTemplatesError:
                    action.payload.editPenRationTemplatesError,
            };
        case ActionTypes.SET_VARIABLE_SPLIT_RATION_TEMPLATE:
            return {
                ...state,
                variableSplitRationTemplate: action.payload,
            };
        case ActionTypes.SET_VARIABLE_SPLIT_RATION_TEMPLATES: {
            return {
                ...state,
                variableSplitRationTemplates: action.payload,
            };
        }
        case ActionTypes.SET_VARIABLE_SPLIT_TEMPLATE_ERROR:
            return {
                ...state,
                variableSplitTemplateError: action.payload,
            };
        case ActionTypes.SET_VARIABLE_SPLIT_TEMPLATES_ERROR:
            return {
                ...state,
                variableSplitTemplatesError: action.payload,
            };
        case ActionTypes.SET_SELECTED_SCHEDULE_TYPE:
            return {
                ...state,
                selectedScheduleType: action.payload,
            };
        case ActionTypes.SET_PEN_RATION_SCHEDULE_TEMPLATES_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        default:
            return state;
    }
};

export default PenRationTemplatesReducer;
