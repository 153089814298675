import { useEffect } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../../../Redux/Store';
import {
    createVendorService,
    setEditScaleTicketDetails,
    setVendorModalDetails,
    setVendorModalDetailsError,
} from '../actions/ScaleTicketActions';
import { SCALE_TICKET_INITIAL_STATE } from '../constants/scaleTicketsInitialState';
import { ScaleTicketModalConstants } from '../constants/ScaleTicketConstants';
import {
    IFilterFieldBasicDetails,
    IScaleTicketModal,
} from '../interfaces/ScaleTicketInterface';
import { validateAccordingType } from '../../../../utils/validation';

const CreateVendorModal = ({ show, onHide }: IScaleTicketModal) => {
    const {
        vendorModalDetails,
        vendorModalDetailsError,
        editScaleTicketDetails,
    } = useAppSelector(state => state.scaleTicket);

    const dispatch = useAppDispatch();

    const validateMandatoryDetails = () => {
        const vendorModalDetailsErrorCopy = {
            ...vendorModalDetailsError,
        };
        const vendorDetailsFields = Object.keys(vendorModalDetailsErrorCopy);
        let isValid = true;

        vendorDetailsFields.forEach(fieldName => {
            if (
                !vendorModalDetails[fieldName] &&
                ScaleTicketModalConstants.vendorModal.mandatoryFields.includes(
                    fieldName,
                )
            ) {
                vendorModalDetailsErrorCopy[fieldName] = true;
                isValid = false;
            }
        });

        if (!isValid) {
            dispatch(setVendorModalDetailsError(vendorModalDetailsErrorCopy));
        }

        return isValid;
    };

    const resetAllStates = () => {
        dispatch(
            setVendorModalDetails({
                ...SCALE_TICKET_INITIAL_STATE.vendorModalDetails,
            }),
        );
        dispatch(
            setVendorModalDetailsError({
                ...SCALE_TICKET_INITIAL_STATE.vendorModalDetailsError,
            }),
        );
    };

    const onCancel = () => {
        onHide();
    };

    const updateEditScaleTicketDetails = (vendorId: number) => {
        const editScaleTicketDetailsCopy = {
            ...editScaleTicketDetails,
            vendor: vendorId,
        };

        dispatch(setEditScaleTicketDetails(editScaleTicketDetailsCopy));
    };

    const onSubmit = () => {
        const isValidMandatoryDetails = validateMandatoryDetails();

        if (isValidMandatoryDetails) {
            dispatch(
                createVendorService(
                    vendorServicePayload(),
                    updateEditScaleTicketDetails,
                    onHide,
                ),
            );
        }
    };

    const vendorServicePayload = () => {
        return {
            name: vendorModalDetails.name,
            contactName: vendorModalDetails.contactName,
            contactNumber: vendorModalDetails.contactNumber,
            address: vendorModalDetails.address,
            city: vendorModalDetails.city,
            state: vendorModalDetails.state,
            zipCode: vendorModalDetails.zipCode,
        };
    };

    const vendorModalDetailsErrorHandler = (fieldName: string) => {
        const vendorModalDetailsErrorCopy = {
            ...vendorModalDetailsError,
            [fieldName]: false,
        };

        dispatch(setVendorModalDetailsError(vendorModalDetailsErrorCopy));
    };

    const vendorModalDetailsHandler = (
        fieldName: IFilterFieldBasicDetails,
        fieldValue: string | number | Date | null,
        type?: string,
    ) => {
        const isValid: boolean = validateAccordingType(type, fieldValue);

        if (isValid) {
            const vendorModalDetailsCopy = {
                ...vendorModalDetails,
                [fieldName]: fieldValue,
            };

            vendorModalDetailsErrorHandler(fieldName);
            dispatch(setVendorModalDetails(vendorModalDetailsCopy));
        }
    };

    useEffect(() => {
        resetAllStates();
    }, [show]);

    return (
        <Modal
            className="px-2 pt-5"
            data-size="md"
            show={show}
            centered
            animation
            onHide={onHide}
        >
            <Modal.Header closeButton>
                <Modal.Title className="text-secondary">
                    <h5 className="modal-title">
                        {ScaleTicketModalConstants.vendorModal.title}
                    </h5>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row mx-0 mb-lg-2">
                    <div className="col-xl-5 col-lg-5 start-top mb-2">
                        <Form.Label>
                            {
                                ScaleTicketModalConstants.vendorModal.labels
                                    .vendorName
                            }
                            <span className="necessaryFields">*</span>
                        </Form.Label>
                        <Form.Control
                            name={
                                ScaleTicketModalConstants.vendorModal.apiFields
                                    .name
                            }
                            value={vendorModalDetails.name}
                            placeholder={
                                ScaleTicketModalConstants.vendorModal
                                    .placeholders.vendorName
                            }
                            className={`text-secondary form-item border border-${
                                vendorModalDetailsError.name ? 'danger' : ''
                            } rounded`}
                            size="sm"
                            type="text"
                            onChange={e => {
                                vendorModalDetailsHandler(
                                    e.target.name,
                                    e.target.value,
                                );
                            }}
                        />
                    </div>
                </div>
                <div className="row mx-0 mb-lg-2">
                    <div className="col-xl-5 col-lg-5 mb-2 start-top">
                        <Form.Label>
                            {
                                ScaleTicketModalConstants.vendorModal.labels
                                    .contactName
                            }
                            <span className="necessaryFields">*</span>
                        </Form.Label>
                        <Form.Control
                            name={
                                ScaleTicketModalConstants.vendorModal.apiFields
                                    .contactName
                            }
                            value={vendorModalDetails.contactName}
                            placeholder={
                                ScaleTicketModalConstants.vendorModal
                                    .placeholders.contactName
                            }
                            className={`text-secondary form-item border border-${
                                vendorModalDetailsError.contactName
                                    ? 'danger'
                                    : ''
                            } rounded`}
                            size="sm"
                            type="text"
                            onChange={e => {
                                vendorModalDetailsHandler(
                                    e.target.name,
                                    e.target.value,
                                );
                            }}
                        />
                    </div>
                    <div className="col-xl-5 col-lg-5 mb-2 start-top pe-0">
                        <Form.Label>
                            {
                                ScaleTicketModalConstants.vendorModal.labels
                                    .phoneNumber
                            }
                        </Form.Label>
                        <span className="necessaryFields">*</span>
                        <Form.Control
                            name={
                                ScaleTicketModalConstants.vendorModal.apiFields
                                    .contactNumber
                            }
                            value={vendorModalDetails.contactNumber}
                            placeholder={
                                ScaleTicketModalConstants.vendorModal
                                    .placeholders.phoneNumber
                            }
                            className={`text-secondary form-item border border-${
                                vendorModalDetailsError.contactNumber
                                    ? 'danger'
                                    : ''
                            } rounded`}
                            onChange={e => {
                                vendorModalDetailsHandler(
                                    e.target.name,
                                    e.target.value,
                                );
                            }}
                            size="sm"
                            type="text"
                            maxLength={10}
                        />
                    </div>
                </div>
                <div className="row mx-0 mb-lg-2">
                    <div className="col-xl-5 col-lg-5 mb-2 start-top">
                        <Form.Label>
                            {
                                ScaleTicketModalConstants.vendorModal.labels
                                    .address
                            }
                            <span className="necessaryFields">*</span>
                        </Form.Label>
                        <Form.Control
                            name={
                                ScaleTicketModalConstants.vendorModal.apiFields
                                    .address
                            }
                            value={vendorModalDetails.address}
                            placeholder={
                                ScaleTicketModalConstants.vendorModal
                                    .placeholders.address
                            }
                            className={`text-secondary form-item border border-${
                                vendorModalDetailsError.address ? 'danger' : ''
                            } rounded`}
                            size="sm"
                            type="text"
                            onChange={e => {
                                vendorModalDetailsHandler(
                                    e.target.name,
                                    e.target.value,
                                );
                            }}
                        />
                    </div>
                    <div className="col-xl-5 col-lg-5 mb-2 start-top pe-0">
                        <Form.Label>
                            {ScaleTicketModalConstants.vendorModal.labels.city}
                        </Form.Label>
                        <span className="necessaryFields">*</span>
                        <Form.Control
                            name={
                                ScaleTicketModalConstants.vendorModal.apiFields
                                    .city
                            }
                            value={vendorModalDetails.city}
                            placeholder={
                                ScaleTicketModalConstants.vendorModal
                                    .placeholders.city
                            }
                            className={`text-secondary form-item border border-${
                                vendorModalDetailsError.city ? 'danger' : ''
                            } rounded`}
                            onChange={e => {
                                vendorModalDetailsHandler(
                                    e.target.name,
                                    e.target.value,
                                );
                            }}
                            size="sm"
                            type="text"
                        />
                    </div>
                </div>
                <div className="row mx-0 mb-lg-2">
                    <div className="col-xl-5 col-lg-5 mb-2 start-top">
                        <Form.Label>
                            {ScaleTicketModalConstants.vendorModal.labels.state}
                            <span className="necessaryFields">*</span>
                        </Form.Label>
                        <Form.Control
                            name={
                                ScaleTicketModalConstants.vendorModal.apiFields
                                    .state
                            }
                            value={vendorModalDetails.state}
                            placeholder={
                                ScaleTicketModalConstants.vendorModal
                                    .placeholders.state
                            }
                            className={`text-secondary form-item border border-${
                                vendorModalDetailsError.state ? 'danger' : ''
                            } rounded`}
                            size="sm"
                            type="text"
                            onChange={e => {
                                vendorModalDetailsHandler(
                                    e.target.name,
                                    e.target.value,
                                );
                            }}
                        />
                    </div>
                    <div className="col-xl-5 col-lg-5 mb-2 start-top pe-0">
                        <Form.Label>
                            {
                                ScaleTicketModalConstants.vendorModal.labels
                                    .zipcode
                            }
                        </Form.Label>
                        <span className="necessaryFields">*</span>
                        <Form.Control
                            name={
                                ScaleTicketModalConstants.vendorModal.apiFields
                                    .zipCode
                            }
                            value={vendorModalDetails.zipCode}
                            placeholder={
                                ScaleTicketModalConstants.vendorModal
                                    .placeholders.zipcode
                            }
                            className={`text-secondary form-item border border-${
                                vendorModalDetailsError.zipCode ? 'danger' : ''
                            } rounded`}
                            onChange={e => {
                                vendorModalDetailsHandler(
                                    e.target.name,
                                    e.target.value,
                                );
                            }}
                            size="sm"
                            type="text"
                            maxLength={10}
                        />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button
                    type="button"
                    className="secondary"
                    data-bs-dismiss="modal"
                    onClick={() => {
                        onCancel();
                    }}
                >
                    {ScaleTicketModalConstants.vendorModal.button.cancel}
                </button>
                <button
                    type="button"
                    onClick={() => {
                        onSubmit();
                    }}
                >
                    {ScaleTicketModalConstants.vendorModal.button.submit}
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default CreateVendorModal;
