import Constants from '../../utils/Constants';

//IRFTODO we need a list of accepted storage keys instead of passing strings around willy nilly. What would be really cool is to have a given storage key string tied to a storage value type so that there is no guessing what the retrieved value looks like.
// type StorageKey = 'FeedCallPageSelectedPen';
export default class StorageService {
    /**
     * Method to get value from storage
     * @param key
     * @param storageType
     */
    getValue(key: string, storageType: string = Constants.Storage.LOCAL) {
        switch (storageType) {
            case Constants.Storage.LOCAL:
                return JSON.parse(localStorage.getItem(key) || '{}');
            case Constants.Storage.SESSION:
                return JSON.parse(sessionStorage.getItem(key) || '{}');
        }
    }

    /**
     * Method to set value for provided key in storage
     * @param key
     * @param value
     * @param storageType
     */
    setValue(
        key: string,
        value: string | number | boolean | unknown,
        storageType: string = Constants.Storage.SESSION,
    ) {
        switch (storageType) {
            case Constants.Storage.LOCAL:
                localStorage.setItem(key, JSON.stringify(value));
                break;
            case Constants.Storage.SESSION:
                sessionStorage.setItem(key, JSON.stringify(value));
                break;
        }
    }

    /**
     * Method to remove key from storage
     * @param key
     * @param storageType
     */
    removeKey(key: string, storageType: string = Constants.Storage.SESSION) {
        switch (storageType) {
            case Constants.Storage.LOCAL:
                localStorage.removeItem(key);
                break;
            case Constants.Storage.SESSION:
                sessionStorage.removeItem(key);
                break;
        }
    }

    /**
     * Method to remove keys from storage
     * @param keys
     * @param storageType
     */
    removeKeys(
        keys: string[],
        storageType: string = Constants.Storage.SESSION,
    ): void {
        for (const key of keys) {
            this.removeKey(key, storageType);
        }
    }

    /**
     * Method to clear storage
     * @param storageType
     */
    clear(storageType: string = Constants.Storage.SESSION) {
        switch (storageType) {
            case Constants.Storage.LOCAL:
                localStorage.clear();
                break;
            case Constants.Storage.SESSION:
                sessionStorage.clear();
                break;
        }
    }

    /**
     * Method to clear both local and session storage
     */
    clearAll() {
        localStorage.clear();
        sessionStorage.clear();
    }
}
