export const buyerConstants = {
    createNewBuyers: {
        heading: 'Create New Buyer',
        form: {
            name: {
                name: 'name',
                label: 'Buyer',
                maxLength: 50,
                placeholder: 'Enter Buyer Name',
            },
            contactNumber: {
                name: 'contactNumber',
                label: 'Phone Number',
                maxLength: 50,
                placeholder: 'Enter Phone Number',
            },
            cancel: 'Cancel',
            saveBuyers: 'Save Buyers',
        },
    },
    buyerDetails: {
        placeholder: 'Search',
        maxLength: 50,
        buyer: 'Buyer',
        contactNumber: 'Phone Number',
        heading: 'Manage Buyers',
        name: 'buyer',
    },
    buyerColumnDetail: [
        { label: 'Buyer', key: 'name' },
        { label: 'Status', key: 'active' },
        { label: 'Phone Number', key: 'contactNumber' },
    ],
    initialBuyerDetailsError: {
        name: false,
        contactNumber: false,
    },
    activateModalConstant: {
        heading: 'Activate Buyer',
        text: ' is currently Inactive. Activate and use this Buyer.',
    },
    createBuyers: 'Create New Buyer',
    clearFilter: 'Clear Filter',
    editBuyer: 'Edit Buyer - ',
    buyerFilter: 'buyerFilter',
};
