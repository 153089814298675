import { Button, Col } from 'react-bootstrap';
import SettingsItemDetail from './FMSettings/SettingsItemDetail';
import Constants from '../../utils/Constants';
import { useEffect, useState, useMemo } from 'react';
import { useModalStatus } from '../../commonHooks/useModalStatus';
import { useFeedlotId } from '../../commonHooks/useFeedlotId';
import { useAppDispatch, useAppSelector } from '../../Redux/Store';
import StorageService from '../../Services/storage/storage.service';
import { useDebouncedEffect } from '../useDebounceEffect';
import {
    deleteBuyer,
    fetchBuyerDetails,
    updateBuyerDetail,
} from '../../Redux/Actions/buyerActions';
import { IFilter, IBuyerDetail } from '../../utils/Interface/BuyerInterface';
import { setBuyerFilter } from '../../Redux/Reducer/buyerSlice';
import BuyerModal from './BuyerModal';
import { BUYER_INITIAL_STATE } from '../../Redux/Constants/buyerInitialState';
import { DeleteItemModal } from './FMSettings/DeleteItemModal';
import { FmSettingsConstant } from '../../utils/constants/FmSettingsConstants';
import { buyerConstants } from '../../utils/constants/BuyerConstants';

const BuyerDetails = () => {
    const { filter, loader } = useAppSelector(state => state.buyer);
    const [searchQuery, setSearchQuery] = useState<string | undefined>(
        undefined,
    );
    const [buyerDetails, setBuyerDetails] = useState<IBuyerDetail[]>([]);
    const [selectedBuyer, setSelectedBuyer] = useState<IBuyerDetail>(
        BUYER_INITIAL_STATE.createBuyerInitial,
    );
    const storage = useMemo(() => new StorageService(), []);
    const {
        isOpen: isBuyerModalOpen,
        open: openBuyerModal,
        close: closeBuyerModal,
    } = useModalStatus();
    const {
        isOpen: isDeleteModalOpen,
        open: openDeleteModal,
        close: closeDeleteModal,
    } = useModalStatus();
    const feedlotId = useFeedlotId();
    const dispatch = useAppDispatch();

    const handleEditBuyerDetail = (buyer: IBuyerDetail): void => {
        setSelectedBuyer(buyer);
        openBuyerModal();
    };

    const fetchBuyerData = (buyerFilter: IFilter = filter): void => {
        if (feedlotId) {
            dispatch(fetchBuyerDetails(setBuyerDetails, buyerFilter));
        }
    };

    const setBuyerFilterInStorage = (filterValues: IFilter) => {
        storage.setValue(
            buyerConstants.buyerFilter,
            filterValues,
            Constants.Storage.LOCAL,
        );
    };
    const handleFilter = (filterName: string, filterValue: string): void => {
        const updatedFilter = { ...filter, [filterName]: filterValue };
        fetchBuyerData(updatedFilter);
        dispatch(setBuyerFilter(updatedFilter));
        setBuyerFilterInStorage(updatedFilter);
    };

    const closeModalWithChanges = (isEdited: boolean): void => {
        if (isEdited && feedlotId) {
            fetchBuyerData(filter);
        }
        closeBuyerModal();
    };

    const resetFilter = (): void => {
        const updatedFilter = BUYER_INITIAL_STATE.filter;
        fetchBuyerData(updatedFilter);
        setSearchQuery('');
        dispatch(setBuyerFilter(updatedFilter));
        setBuyerFilterInStorage(updatedFilter);
    };

    const handleCreateBuyer = (): void => {
        if (feedlotId) {
            setSelectedBuyer({
                ...BUYER_INITIAL_STATE.createBuyerInitial,
            });
        }
        openBuyerModal();
    };

    const handleDeleteModalHandler = (buyer: IBuyerDetail) => {
        setSelectedBuyer(buyer);
        openDeleteModal();
    };

    const handleDeleteBuyer = (shouldTakeAction: boolean) => {
        if (feedlotId && shouldTakeAction) {
            if (!selectedBuyer.isAssociated || selectedBuyer.active) {
                const isActiveAndAssociated =
                    selectedBuyer.isAssociated && selectedBuyer.active;
                const action = isActiveAndAssociated
                    ? updateBuyerDetail
                    : deleteBuyer;
                const updatedBuyerDetail = isActiveAndAssociated
                    ? { ...selectedBuyer, active: false }
                    : selectedBuyer;
                dispatch(action(updatedBuyerDetail, fetchBuyerData));
            }
        }
        closeDeleteModal();
    };

    useDebouncedEffect(
        () => {
            if (searchQuery !== undefined) {
                handleFilter('name', searchQuery);
            }
        },
        [searchQuery],
        1000,
    );

    useEffect(() => {
        if (feedlotId) {
            const buyerFilter = storage.getValue(buyerConstants.buyerFilter);
            if (Object.keys(buyerFilter).length > 0) {
                dispatch(setBuyerFilter(buyerFilter));
            }
            if (!buyerFilter.name) {
                if (buyerFilter.show) {
                    fetchBuyerData(buyerFilter);
                } else {
                    dispatch(fetchBuyerDetails(setBuyerDetails, filter));
                }
            } else {
                setSearchQuery(buyerFilter?.name || filter.name);
            }
        }
    }, [feedlotId, dispatch, storage]);

    return (
        <div className="pens-container">
            <h5 className="my-3">{buyerConstants.buyerDetails.heading}</h5>
            <div className="d-flex align-items-end mb-4">
                <Col xs={3} className="d-flex flex-column">
                    <label>{buyerConstants.buyerDetails.buyer}</label>
                    <input
                        className="text-secondary my-2"
                        type="text"
                        placeholder={buyerConstants.buyerDetails.placeholder}
                        maxLength={buyerConstants.buyerDetails.maxLength}
                        value={searchQuery ? searchQuery : ''}
                        onChange={e => setSearchQuery(e.target.value)}
                    />
                </Col>
                <Col xs={3}>
                    <Button
                        className="secondary my-2 mx-4"
                        onClick={resetFilter}
                    >
                        {buyerConstants.clearFilter}
                    </Button>
                </Col>
                <Col xs={6} className="d-flex justify-content-end">
                    <Button className="my-2" onClick={handleCreateBuyer}>
                        {buyerConstants.createBuyers}
                    </Button>
                </Col>
            </div>
            <div
                className="o-switch btn-group show-toggle my-2"
                data-toggle="buttons"
                role="group"
            >
                {FmSettingsConstant.filterOptions.map(option => (
                    <label
                        key={option.value}
                        className={`btn btn-secondary py-1 ${
                            filter.show === option.value
                                ? FmSettingsConstant.active.value
                                : ''
                        }`}
                    >
                        <input
                            type="radio"
                            onChange={() =>
                                handleFilter(option.name, option.value)
                            }
                            checked={filter.show === option.value}
                        />
                        {option.label}
                    </label>
                ))}
            </div>
            <SettingsItemDetail<IBuyerDetail>
                columnDetail={buyerConstants.buyerColumnDetail}
                itemDetail={buyerDetails}
                handleEditDetail={handleEditBuyerDetail}
                handleDeleteModalHandler={handleDeleteModalHandler}
                loader={loader}
            />
            {isBuyerModalOpen && (
                <BuyerModal
                    showModal={isBuyerModalOpen}
                    closeModalWithChanges={closeModalWithChanges}
                    editBuyerDetails={selectedBuyer}
                    showActiveField={true}
                />
            )}
            {isDeleteModalOpen && (
                <DeleteItemModal
                    item={selectedBuyer}
                    handleDeleteItem={handleDeleteBuyer}
                    showModal={isDeleteModalOpen}
                    itemName={buyerConstants.buyerDetails.name}
                ></DeleteItemModal>
            )}
        </div>
    );
};

export default BuyerDetails;
