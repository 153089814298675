import { CommonConstants } from '../../utils/constants/CommonConstants';
import { IAddRowButton } from '../../utils/Interface/CommonInterface';

const AddRowButton = (props: IAddRowButton) => {
    return (
        <button className="add-row-btn" {...props}>
            <i className="far fa-plus" />
            {CommonConstants.en.addRow}
        </button>
    );
};

export default AddRowButton;
