import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PROCESSING_LOT_INITIAL_STATE } from '../Constants/processingLotInitialState';
import {
    IProcessingLot,
    ProcessingSubmitButtonOptions,
    ProcessingWorksheetStatus,
} from '../../utils/Interface/HealthSection/LotProcessingWorksheet';

const processingLotSlice = createSlice({
    name: 'processingLot',
    initialState: PROCESSING_LOT_INITIAL_STATE,
    reducers: {
        setLots: (state, action: PayloadAction<IProcessingLot[]>) => {
            state.lots = action.payload;
        },
        setLot: (state, action: PayloadAction<IProcessingLot>) => {
            state.lot = action.payload;
        },
        resetState: () => {
            return PROCESSING_LOT_INITIAL_STATE;
        },
        updateOneLotWorksets: (
            state,
            action: PayloadAction<IProcessingLot>,
        ) => {
            const foundLot = state.lots.find(
                lot => lot.lotId === action.payload.lotId,
            );
            if (foundLot) {
                const newWorksets = action.payload.processingWorksets;
                foundLot.processingWorksets = newWorksets;
            }
        },
        setActiveButton: (
            state,
            action: PayloadAction<ProcessingSubmitButtonOptions>,
        ) => {
            state.activeButton = action.payload;
        },
        setFilter: (
            state,
            action: PayloadAction<ProcessingWorksheetStatus>,
        ) => {
            state.worksheetFilter = action.payload;
        },
    },
});

export const processingLotActions = processingLotSlice.actions;
export const processingLotReducer = processingLotSlice.reducer;
