import { FC } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { CommonConstants } from '../../utils/constants/CommonConstants';
import { IErrorModal } from '../../utils/Interface/CommonInterface';
import { LabelConstant } from '../../utils/LabelConstant';

const ErrorModal: FC<IErrorModal> = ({
    showModal,
    closeModalHandler,
    errorMessage,
    language = LabelConstant.Languages.ENGLISH,
    buttonText,
}) => {
    return (
        <Modal
            className="px-2 pt-5 error-modal"
            data-size="md"
            show={showModal}
            centered
            animation
            onHide={() => closeModalHandler && closeModalHandler(false)}
        >
            <Modal.Body>
                <Modal.Title className="text-secondary">
                    {errorMessage}
                </Modal.Title>
            </Modal.Body>

            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={() =>
                        closeModalHandler && closeModalHandler(false)
                    }
                >
                    {buttonText ? buttonText : CommonConstants[language].close}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ErrorModal;
