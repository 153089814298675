import Constants from '../Constants';

export const yardageConstants = {
    createNewYardage: {
        heading: 'Create New Yardage',
        form: {
            yardage: {
                name: 'yardageRate',
                label: 'Yardage',
                maxLength: 50,
                placeholder: 'Enter Yardage',
            },
            date: {
                name: 'effectiveDate',
                label: 'Date',
                maxLength: 50,
            },
            cancel: 'Cancel',
        },
    },
    yardageDetails: {
        heading: 'Manage Yardage',
    },
    yardageColumnDetail: [
        { label: 'Yardage', key: 'yardageRate' },
        { label: 'Effective Date', key: 'effectiveDate' },
    ],
    initialYardageDetailsError: {
        yardageRate: false,
        effectiveDate: false,
    },
    toasterMessage: {
        showMessage: true,
        type: Constants.message.type.SUCCESS,
        message:
            'The change in Yardage will be effective for the all feedlots under this tenant.',
    },
    yardageRate: 'yardageRate',
    effectiveDate: 'effectiveDate',
    clearFilter: 'Clear Filter',
    editYardage: 'Edit yardage - ',
};
