import { IMedicalItem } from '../../utils/Interface/HealthSection/MedicalItemInterface';
import { ActionTypes } from '../Actions';
import { MEDICAL_ITEM_INITIAL_STATE } from '../Constants/medicalItemInitialState';

const MedicalItemsReducer = (state = MEDICAL_ITEM_INITIAL_STATE, action) => {
    switch (action.type) {
        //remove setting single new medical item and associated errors from redux - use react state instead
        case ActionTypes.SET_NEW_MEDICAL_ITEM:
            return {
                ...state,
                newMedicalItem: action.payload,
            };
        //remove
        case ActionTypes.SET_NEW_MEDICAL_ITEM_ERRORS:
            return {
                ...state,
                newMedicalItemErrors: action.payload,
            };
        case ActionTypes.SET_MEDICAL_ITEMS:
            return {
                ...state,
                medicalItems: action.payload as IMedicalItem[],
            };
        case ActionTypes.SET_DROPDOWN_MEDICAL_ITEMS:
            return {
                ...state,
                dropdownMedicalItems: action.payload as IMedicalItem[],
            };
        case ActionTypes.SET_EARLIEST_GENERATED_INVOICE_CUTOFF_DATE:
            return {
                ...state,
                earliestGeneratedInvoiceCutoffDate: action.payload,
            };
        case ActionTypes.SET_MEDICAL_ITEMS_FILTER:
            return {
                ...state,
                medicalItemsFilter: action.payload,
            };
        case ActionTypes.SET_MEDICAL_ITEMS_TABLE_LOADING:
            return {
                ...state,
                medicalItemsTableLoading: action.payload,
            };
        case ActionTypes.SET_MEDICAL_ITEMS_SUBMIT_PENDING:
            return {
                ...state,
                submitPending: action.payload,
            };
        default:
            return state;
    }
};

export default MedicalItemsReducer;
