import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { OWNER_INITIAL_STATE } from '../Constants/ownerInitialState';
import { IFilter } from '../../utils/Interface/OwnerInterface';

const ownerSettingSlice = createSlice({
    name: 'Owner',
    initialState: { ...OWNER_INITIAL_STATE },
    reducers: {
        setOwnerFilter: (state, action: PayloadAction<IFilter>) => {
            state.filter = action.payload;
        },
        setOwnerDetailsLoader: (state, action: PayloadAction<boolean>) => {
            state.loader = action.payload;
        },
    },
});

export const { setOwnerFilter, setOwnerDetailsLoader } =
    ownerSettingSlice.actions;
export const OwnerSettingSlice = ownerSettingSlice.reducer;
