export enum penFilterByRationDefaultIds {
    all = 'all',
    cattleNoFeedCall = 'cattleNoFeedCall',
    unassignedSchedules = 'unassignedSchedules',
}

export const penFilterByRationDefaultOptions = [
    { name: 'All', id: penFilterByRationDefaultIds.all },
    {
        name: 'CATTLE NO FEED CALL',
        id: penFilterByRationDefaultIds.cattleNoFeedCall,
    },
    {
        name: 'UNASSIGNED SCHEDULES',
        id: penFilterByRationDefaultIds.unassignedSchedules,
    },
];
