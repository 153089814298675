import { Navigate, Outlet } from 'react-router-dom';
import useAuthContext from '../../apps/common/modules/auth/hooks/useAuthContext';

const AuthenticatedRoute = () => {
    const { isAuthenticated, hasTenantId } = useAuthContext();

    return isAuthenticated() && hasTenantId() ? (
        <Outlet />
    ) : (
        <Navigate to="/" />
    );
};

export default AuthenticatedRoute;
