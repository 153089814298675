import { Button, Form, Modal } from 'react-bootstrap';
import {
    IBuyerDetail,
    IBuyerDetailsError,
    IBuyerModal,
} from '../../utils/Interface/BuyerInterface';
import { buyerConstants } from '../../utils/constants/BuyerConstants';
import { CommonConstants } from '../../utils/constants/CommonConstants';
import { validateAccordingType } from '../../utils/validation';
import { useEffect, useState } from 'react';
import { useLoading } from '../../commonHooks/useLoading';
import FullPageLoader from '../common/FullPageLoader';
import {
    createBuyer,
    fetchInactiveBuyerInfo,
    updateBuyerDetail,
} from '../../Redux/Actions/buyerActions';
import { useAppDispatch } from '../../Redux/Store';
import { useFeedlotId } from '../../commonHooks/useFeedlotId';
import { useModalStatus } from '../../commonHooks/useModalStatus';
import { ActivateItemModal } from '../../apps/shippingTracker/modules/BuyPo/components/ActivateItemModal';
import { FmSettingsConstant } from '../../utils/constants/FmSettingsConstants';
import { BUYER_INITIAL_STATE } from '../../Redux/Constants/buyerInitialState';

const BuyerModal = ({
    showModal,
    closeModalWithChanges,
    editBuyerDetails,
    showActiveField,
}: IBuyerModal) => {
    const [buyerDetail, setBuyerDetail] =
        useState<IBuyerDetail>(editBuyerDetails);
    const [buyerDetailsError, setBuyerDetailsError] =
        useState<IBuyerDetailsError>(buyerConstants.initialBuyerDetailsError);
    const [inActiveBuyer, setInActiveBuyer] = useState<IBuyerDetail>();
    const {
        isOpen: isActivateModalOpen,
        open: openActivateModal,
        close: closeActivateModal,
    } = useModalStatus();
    const { loading, setLoading } = useLoading();
    const dispatch = useAppDispatch();
    const feedlotId = useFeedlotId();

    const handleActivateBuyer = async () => {
        if (feedlotId) {
            const inactiveBuyerInfo = await dispatch(
                fetchInactiveBuyerInfo(buyerDetail.name?.trim()),
            );
            if (inactiveBuyerInfo) {
                setInActiveBuyer(inactiveBuyerInfo[0]);
                openActivateModal();
            }
        }
    };

    const handleUpdateBuyerDetail = (): void => {
        const isValid = validateBuyerDetails();
        if (feedlotId && isValid) {
            dispatch(
                updateBuyerDetail(
                    buyerDetail,
                    undefined,
                    closeModalWithChanges,
                    setLoading,
                    handleActivateBuyer,
                ),
            );
        }
    };

    const validateForm = (buyerErrorCopy: IBuyerDetailsError) => {
        let isFormValid = true;
        const validateFields = ['contactNumber'];
        const validateTypes = ['mobileLength'];

        validateFields.forEach((validateField, index) => {
            const isValid = validateAccordingType(
                validateTypes[index],
                buyerDetail[validateField],
            );
            if (!buyerErrorCopy[validateField] && !isValid) {
                buyerErrorCopy[validateField] = true;
            }

            if (!isValid) {
                isFormValid = false;
            }
        });

        setBuyerDetailsError(buyerErrorCopy);

        return isFormValid;
    };

    const validateBuyerDetails = (): boolean => {
        let isValid = true;
        const { name, contactNumber } = buyerDetail;
        const updatedBuyerDetailErrors = {
            name: !name,
            contactNumber: !contactNumber,
        };
        setBuyerDetailsError(updatedBuyerDetailErrors);
        const isFormValid = validateForm(updatedBuyerDetailErrors);
        if (
            Object.values(updatedBuyerDetailErrors).includes(true) ||
            !isFormValid
        ) {
            isValid = false;
        }

        return isValid;
    };

    const handleCreateBuyer = (): void => {
        const isValid = validateBuyerDetails();
        if (feedlotId && isValid) {
            dispatch(
                createBuyer(
                    buyerDetail,
                    closeModalWithChanges,
                    setLoading,
                    handleActivateBuyer,
                ),
            );
        }
    };

    const handleDetailChange = (
        fieldValue: string | number | boolean,
        fieldName: string,
        type?: string,
    ): void => {
        const isValid = validateAccordingType(type, fieldValue);
        if (isValid) {
            setBuyerDetail(prevBuyerDetail => ({
                ...prevBuyerDetail,
                [fieldName]: fieldValue,
            }));
        }
    };

    const handleCloseActivateModal = (isActivated: boolean) => {
        if (isActivated && feedlotId) {
            if (inActiveBuyer) {
                const inActiveBuyerCopy = {
                    ...inActiveBuyer,
                    active: true,
                };
                dispatch(
                    updateBuyerDetail(
                        inActiveBuyerCopy,
                        undefined,
                        closeModalWithChanges,
                        setLoading,
                    ),
                );
                closeActivateModal();
            }
        } else {
            closeActivateModal();
        }
    };

    useEffect(() => {
        if (!showModal) {
            setBuyerDetail({ ...BUYER_INITIAL_STATE.createBuyerInitial });
        }
    }, [showModal]);

    return (
        <>
            <ActivateItemModal
                showModal={isActivateModalOpen}
                closeModalHandler={handleCloseActivateModal}
                itemName={buyerDetail.name?.trim()}
                item={buyerConstants.activateModalConstant}
            />
            <Modal
                className="px-2 pt-5 fm-settings-modal"
                size="lg"
                show={showModal}
                centered
                animation
                onHide={() => closeModalWithChanges(false)}
            >
                {loading && <FullPageLoader />}
                <Modal.Header closeButton className="border-bottom-0">
                    <Modal.Title className="text-dark">
                        {editBuyerDetails.name
                            ? `${buyerConstants.editBuyer} ${editBuyerDetails?.name}`
                            : buyerConstants.createBuyers}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="border-bottom">
                        <div className="d-flex justify-content-between mb-2">
                            <div className="col-5">
                                <Form.Label className="text-secondary">
                                    {
                                        buyerConstants.createNewBuyers.form.name
                                            .label
                                    }
                                    <span className="necessaryFields">*</span>
                                </Form.Label>
                                <Form.Control
                                    className={`text-secondary border-${
                                        buyerDetailsError.name ? 'danger' : ''
                                    }`}
                                    value={buyerDetail?.name}
                                    name={
                                        buyerConstants.createNewBuyers.form.name
                                            .name
                                    }
                                    placeholder={
                                        buyerConstants.createNewBuyers.form.name
                                            .placeholder
                                    }
                                    onChange={e =>
                                        handleDetailChange(
                                            e.target.value,
                                            e.target.name,
                                            'string',
                                        )
                                    }
                                    maxLength={
                                        buyerConstants.createNewBuyers.form.name
                                            .maxLength
                                    }
                                    size="sm"
                                    type="text"
                                />
                            </div>
                            <div className="col-5">
                                <Form.Label className="text-secondary">
                                    {
                                        buyerConstants.createNewBuyers.form
                                            .contactNumber.label
                                    }
                                </Form.Label>
                                <span className="necessaryFields">*</span>
                                <Form.Control
                                    className={`text-secondary border-${
                                        buyerDetailsError.contactNumber
                                            ? 'danger'
                                            : ''
                                    }`}
                                    value={buyerDetail?.contactNumber}
                                    name={
                                        buyerConstants.createNewBuyers.form
                                            .contactNumber.name
                                    }
                                    placeholder={
                                        buyerConstants.createNewBuyers.form
                                            .contactNumber.placeholder
                                    }
                                    onChange={e =>
                                        handleDetailChange(
                                            e.target.value,
                                            e.target.name,
                                        )
                                    }
                                    size="sm"
                                    type="text"
                                />
                            </div>
                        </div>
                        {showActiveField && (
                            <div className="d-flex justify-content-between mb-4">
                                <div className="custom-checkbox col-5 ">
                                    <input
                                        type="checkbox"
                                        checked={buyerDetail.active}
                                        className="mx-2"
                                        onChange={e =>
                                            handleDetailChange(
                                                !buyerDetail.active,
                                                'active',
                                            )
                                        }
                                    />
                                    <label className="mx-2">
                                        {FmSettingsConstant.active.label}
                                    </label>
                                </div>
                            </div>
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer className="border-top-0">
                    <Button
                        className="secondary"
                        onClick={() => closeModalWithChanges(false)}
                    >
                        {CommonConstants.en.cancel}
                    </Button>
                    <Button
                        onClick={
                            !editBuyerDetails.name
                                ? handleCreateBuyer
                                : handleUpdateBuyerDetail
                        }
                    >
                        {CommonConstants.en.save}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default BuyerModal;
