import React from 'react';
import { IFeedBillInfo } from '../../utils/Interface/FinancialApp/ShawnaSpreadSheetInterface';
import { ShawnaSpreadSheetConstants } from '../../utils/constants/FinancialApp/ShawnaSpreadSheetConstants';
import ValueWithTooltip from './ValueWithToolKit';
interface parameterType {
    month: number;
    feedBill: IFeedBillInfo[];
}

const ShawnaFeedBillDetailCard = ({ feedBill, month }: parameterType) => {
    const isMonthFound = feedBill.some(
        feedBillEntry => feedBillEntry.month === month,
    );

    return (
        <>
            {isMonthFound ? (
                feedBill.map((feedBillEntry, index) => (
                    <React.Fragment key={index}>
                        {feedBillEntry.month === month && (
                            <div className="spreadsheet-month-div">
                                {ShawnaSpreadSheetConstants.feedBillCategories.map(
                                    ({ key }: { key: string }) => (
                                        <ValueWithTooltip
                                            key={key}
                                            value={feedBillEntry[key]}
                                            classNames="my-3"
                                        />
                                    ),
                                )}
                            </div>
                        )}
                    </React.Fragment>
                ))
            ) : (
                <div className="spreadsheet-month-div">
                    {ShawnaSpreadSheetConstants.feedBillCategories.map(
                        ({ key }) => (
                            <ValueWithTooltip key={key} classNames="my-3" />
                        ),
                    )}
                </div>
            )}
        </>
    );
};

export default ShawnaFeedBillDetailCard;
