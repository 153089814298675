import { currentDate } from '../../helpers';

export const YARDAGE_INITIAL_STATE = {
    createYardageInitial: {
        effectiveDate: currentDate('YYYY-MM-DD'),
        yardageRate: '',
        feedLotId: '',
    },
    loader: true,
};
