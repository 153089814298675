import { FC } from 'react';
import { formatDate_deprecated } from '../../helpers';
import { CommonConstants } from '../../utils/constants/CommonConstants';

const InvoiceAlreadyGeneratedError: FC<{
    cutoffDate?;
    isMiscChargesError?;
}> = ({ cutoffDate, isMiscChargesError }) => {
    const formattedCutoffDate = formatDate_deprecated(cutoffDate, 'MMMM D');

    return (
        <>
            <h2>
                {CommonConstants.backdateIntoGeneratedInvoiceError.headingText}
                {cutoffDate
                    ? `${CommonConstants.backdateIntoGeneratedInvoiceError.updateErrorMessage} ${formattedCutoffDate}.`
                    : `${CommonConstants.backdateIntoGeneratedInvoiceError.deleteErrorMessage}`}
            </h2>
            {!isMiscChargesError ? (
                <h5>
                    {CommonConstants.backdateIntoGeneratedInvoiceError.bodyText}
                </h5>
            ) : (
                ''
            )}
        </>
    );
};

export default InvoiceAlreadyGeneratedError;
