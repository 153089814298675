import Constants from '../../utils/Constants';
import { IHealthProtocolInitialState } from '../../utils/Interface/HealthProtocolInterface';

export const TREATMENT_PROTOCOL_INITIAL_STATE: IHealthProtocolInitialState = {
    treatmentProtocols: [],
    treatmentProtocolMedicines: [],
    newTreatmentProtocol: {
        diagnosisId: '',
        name: '',
        medicalItemIds: [],
    },
    filter: {
        $top: Constants.listLimit.MEDICAL_SECTION,
        $skip: 0,
        $orderby: 'Name',
        include: 'diagnosis,medicalItems',
    },
    treatmentProtocolFilter: {
        diagnosisName: '',
        treatmentName: '',
        medicalName: '',
    },
    treatmentProtocolSubmitPending: false,
};
