import { AnyAction } from '@reduxjs/toolkit';
import { ActionTypes } from '../../../../Redux/Actions';
import { SCALE_TICKET_INITIAL_STATE } from '../constants/scaleTicketsInitialState';
import { deepClone } from '../../../../helpers';

const ScaleTicketReducer = (
    state = SCALE_TICKET_INITIAL_STATE,
    action: AnyAction,
) => {
    const stateCopy = { ...state };

    switch (action.type) {
        case ActionTypes.SET_SCALE_TICKET_LOADING:
            stateCopy.loading = action.payload;
            return stateCopy;
        case ActionTypes.SET_SCALE_TICKET_CAN_CALL_API:
            stateCopy.canCallApi = action.payload;
            return stateCopy;
        case ActionTypes.SET_SCALE_TICKET_FILTER:
            stateCopy.scaleTicketsFilter = action.payload;
            return stateCopy;
        case ActionTypes.SET_SCALE_TICKETS:
            stateCopy.scaleTickets = action.payload;
            return stateCopy;
        case ActionTypes.SET_COMMODITIES:
            stateCopy.commodities = action.payload;
            return stateCopy;
        case ActionTypes.SET_VENDOR:
            stateCopy.vendors = action.payload;
            return stateCopy;
        case ActionTypes.SET_CONTRACT:
            stateCopy.contracts = action.payload;
            return stateCopy;
        case ActionTypes.SET_COMPLETE_CONTRACTS:
            stateCopy.completeContracts = action.payload;
            return stateCopy;
        case ActionTypes.SET_TRUCKING_COMPANIES:
            stateCopy.truckingCompanies = action.payload;
            return stateCopy;
        case ActionTypes.SET_EDIT_TICKET_DETAIL:
            stateCopy.editScaleTicketDetails = action.payload;
            return stateCopy;
        case ActionTypes.SET_CREATE_TICKET_DETAIL:
            stateCopy.createScaleTicketDetails = action.payload;
            return stateCopy;
        case ActionTypes.SET_EDIT_TICKET_DETAIL_ERROR:
            stateCopy.editScaleTicketDetailsError = action.payload;
            return stateCopy;
        case ActionTypes.SET_CREATE_TICKET_DETAIL_ERROR:
            return {
                ...state,
                createScaleTicketDetailsError: deepClone(action.payload),
            };
        case ActionTypes.SET_VENDOR_MODAL_DETAILS:
            stateCopy.vendorModalDetails = action.payload;
            return stateCopy;
        case ActionTypes.SET_VENDOR_MODAL_DETAILS_ERROR:
            stateCopy.vendorModalDetailsError = action.payload;
            return stateCopy;
        case ActionTypes.SET_CONTRACT_MODAL_DETAILS:
            stateCopy.contractModalDetails = action.payload;
            return stateCopy;
        case ActionTypes.SET_CONTRACT_MODAL_DETAILS_ERROR:
            stateCopy.contractModalDetailsError = action.payload;
            return stateCopy;
        case ActionTypes.SET_TRUCKING_COMPANY_MODAL_DETAILS:
            stateCopy.truckingCompanyModalDetails = action.payload;
            return stateCopy;
        case ActionTypes.SET_TRUCKING_COMPANY_MODAL_DETAILS_ERROR:
            stateCopy.truckingCompanyModalDetailsError = action.payload;
            return stateCopy;
        case ActionTypes.SET_SELECTED_SCALE_TICKET_TAB:
            stateCopy.selectedScaleTicketTab = action.payload;
            return stateCopy;
        case ActionTypes.UPDATE_EDIT_TICKET_DETAIL:
            stateCopy.editScaleTicketDetails = {
                ...state.editScaleTicketDetails,
                ...action.payload,
            };
            return stateCopy;
        case ActionTypes.SET_SCALE_TICKETS_EXCEL_DATA:
            stateCopy.scaleTicketsExcelData = action.payload;
            return stateCopy;
        case ActionTypes.SET_SCALE_TICKET_SUBMIT_PENDING:
            stateCopy.submitPending = action.payload;
            return stateCopy;
        default:
            return stateCopy;
    }
};

export default ScaleTicketReducer;
