import { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '../../Assets/DeleteIcon';
import { formatValue } from '../../helpers';
import { useAppDispatch } from '../../Redux/Store';
import Constants from '../../utils/Constants';
import {
    IDesktopTableRow,
    IGenericObject,
} from '../../utils/Interface/CommonInterface';
import { getToastContent } from '../../utils/ToastContent';
import ConfirmationModal from './ConfirmationModal';
import OverlayTooltip from './OverlayTrigger';
import EditIcon from '../../Assets/EditIcon';
import ResetPasswordIcon from '../../Assets/ResetPasswordIcon';
import { feedlotManagerFeatures } from '../../apps/feedlotManager/featureFlags/feedlotManagerFeatures';
import { toastMessage } from '../../Redux/Reducer/common';

const { isCA9919On_ManageUsersPage } = feedlotManagerFeatures;

const DesktopTableRow = ({
    tableRow,
    rowIndex,
    tableConstants,
    actions,
    permission,
}: IDesktopTableRow) => {
    const navigate = useNavigate();
    const rowFields = Object.keys(tableConstants.headers);
    const dispatch = useAppDispatch();
    const [confirmationModalDetail, setConfirmationModalDetail] = useState({
        canShowModal: false,
        confirmationMessage: '',
    });

    const showConfirmationModal = (message: string) => {
        setConfirmationModalDetail({
            canShowModal: true,
            confirmationMessage: message,
        });
    };

    const confirmationModalHandler = (confirmationSelection: boolean) => {
        if (confirmationSelection) {
            actions?.deleteAction?.[tableConstants.headers.delete.actionName](
                tableRow?.[tableConstants.headers.delete.apiResName],
            );
        }
        setConfirmationModalDetail({
            canShowModal: false,
            confirmationMessage: '',
        });
    };

    const handleClick = () => {
        if (permission) {
            if (
                tableConstants?.routeParameter &&
                tableRow[tableConstants.routeParameter]
            ) {
                navigate(
                    `${tableConstants.navigation}/${
                        tableRow[tableConstants.routeParameter || '']
                    }`,
                    tableConstants.isStateEnable
                        ? { state: { ...tableRow } }
                        : {},
                );
            } else {
                dispatch(
                    toastMessage(
                        getToastContent(
                            Constants.responseCode.BAD_REQUEST,
                            '',
                            Constants.message.commonLog.NO_RECORDS_FOUND,
                        ),
                    ),
                );
            }
        }
    };

    const checkCanShowIcon = (objFiled: IGenericObject) => {
        return Object.prototype.hasOwnProperty.call(
            objFiled,
            'canShowIconField',
        )
            ? tableRow[objFiled.canShowIconField]
            : true;
    };

    const tableData = (fieldName: string, index: number) => {
        const rowFieldDetail = tableConstants.headers[fieldName];

        if (rowFieldDetail.type === 'checkbox') {
            return (
                <td
                    key={index}
                    className={`cursor-pointer ${tableConstants.headerAlign} text-nowrap`}
                >
                    {tableRow[rowFieldDetail.apiResName] ? (
                        <i className="fas fa-check-circle" />
                    ) : (
                        <i className="far fa-circle" />
                    )}
                </td>
            );
        } else if (rowFieldDetail.type === 'deleteButton') {
            const canShowDeleteIcon =
                rowFieldDetail.canShowIconField === 'isInGeneratedPeriod'
                    ? !checkCanShowIcon(rowFieldDetail)
                    : checkCanShowIcon(rowFieldDetail);

            return (
                <td
                    key={index}
                    className={`cursor-pointer ${tableConstants.headerAlign} text-nowrap`}
                >
                    {canShowDeleteIcon && (
                        <>
                            {rowFieldDetail.editActionName && (
                                <EditIcon
                                    className={
                                        ' mx-2 cursor-pointer delete-icon'
                                    }
                                    onClick={() => {
                                        actions.editAction?.[
                                            rowFieldDetail.editActionName
                                        ](
                                            tableRow?.[
                                                rowFieldDetail.apiResName
                                            ],
                                        );
                                    }}
                                />
                            )}
                            <DeleteIcon
                                onClick={() => {
                                    rowFieldDetail.canShowConfirmationModal
                                        ? showConfirmationModal(
                                              rowFieldDetail.confirmationMessage,
                                          )
                                        : actions?.deleteAction?.[
                                              rowFieldDetail.actionName
                                          ](
                                              tableRow?.[
                                                  rowFieldDetail.apiResName
                                              ],
                                          );
                                }}
                            />
                        </>
                    )}
                </td>
            );
        } else if (rowFieldDetail.type === Constants.INPUT_TYPES.DROPDOWN) {
            return (
                <td
                    key={index}
                    className={`cursor-pointer ${tableConstants.headerAlign} text-nowrap`}
                >
                    <Form.Control
                        className="m-auto"
                        as="select"
                        type={rowFieldDetail.type}
                        size="sm"
                        disabled={rowFieldDetail?.isDisable || false}
                        name={rowFieldDetail.apiResName}
                        value={tableRow[rowFieldDetail.apiResName]}
                        onChange={e =>
                            actions?.dropdownAction?.[
                                rowFieldDetail.actionName
                            ](e.target.name, e.target.value, rowIndex)
                        }
                    >
                        {tableRow?.[rowFieldDetail.options]?.map(
                            (
                                optionDetail: { id: number; name: string },
                                key: number,
                            ) => (
                                <option key={key} value={optionDetail.id}>
                                    {optionDetail.name}
                                </option>
                            ),
                        )}
                    </Form.Control>
                </td>
            );
        } else if (rowFieldDetail.type === Constants.INPUT_TYPES.TOOLTIP) {
            return (
                <>
                    <td
                        key={index}
                        className={`${permission ? 'cursor-pointer' : ''} ${
                            tableConstants.headerAlign
                        } text-nowrap`}
                        onClick={handleClick}
                    >
                        <OverlayTooltip
                            tooltipValue={formatValue(
                                tableRow[rowFieldDetail.apiResName],
                                rowFieldDetail.type,
                                rowFieldDetail.prefix,
                                rowFieldDetail.suffix,
                            )}
                            endStringLength={rowFieldDetail.endStringLength}
                        />{' '}
                    </td>
                </>
            );
        } else if (rowFieldDetail.type === Constants.INPUT_TYPES.EDIT) {
            return (
                <td
                    key={index}
                    className={`cursor-pointer ${tableConstants.headerAlign} text-nowrap`}
                >
                    <EditIcon className={' mx-2 cursor-pointer delete-icon'} />
                </td>
            );
        } else if (
            rowFieldDetail.type === Constants.INPUT_TYPES.RESET_PASSWORD
        ) {
            return (
                <td
                    key={index}
                    className={`cursor-pointer ${tableConstants.headerAlign} text-nowrap`}
                >
                    <ResetPasswordIcon
                        className={' mx-2 cursor-pointer delete-icon'}
                    />
                </td>
            );
        } else {
            return (
                <td
                    key={index}
                    className={`${permission ? 'cursor-pointer' : ''} ${
                        tableConstants.headerAlign
                    } text-nowrap`}
                    onClick={handleClick}
                >
                    {isCA9919On_ManageUsersPage
                        ? [tableRow[rowFieldDetail.apiResName]]
                              .flat(2)
                              .map((columnValue, index) => (
                                  <div key={index}>
                                      {formatValue(
                                          columnValue,
                                          rowFieldDetail.type,
                                          rowFieldDetail.prefix,
                                          rowFieldDetail.suffix,
                                      )}
                                  </div>
                              ))
                        : formatValue(
                              tableRow[rowFieldDetail.apiResName],
                              rowFieldDetail.type,
                              rowFieldDetail.prefix,
                              rowFieldDetail.suffix,
                          )}
                </td>
            );
        }
    };

    return (
        <>
            {confirmationModalDetail.canShowModal ? (
                <ConfirmationModal
                    showModal={confirmationModalDetail.canShowModal}
                    confirmationMessage={
                        confirmationModalDetail.confirmationMessage
                    }
                    confirmationModalHandler={confirmationModalHandler}
                />
            ) : (
                <></>
            )}

            <tr>
                {rowFields.map((fieldName: string, index: number) =>
                    tableData(fieldName, index),
                )}
            </tr>
        </>
    );
};

export default DesktopTableRow;
