import { Spinner } from 'react-bootstrap';

export default function Loading(props: { className?: string }) {
    return (
        <div className="bg-light mt-5" {...props}>
            <div className="my-5">
                <Spinner
                    animation="border"
                    data-size="lg"
                    className="text-center"
                />
            </div>
        </div>
    );
}
