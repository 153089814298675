import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { TECH_INITIAL_STATE } from '../Constants/techInitialState';
import { IFilter } from '../../utils/Interface/TechInterface';

const techSlice = createSlice({
    name: 'tech',
    initialState: { ...TECH_INITIAL_STATE },
    reducers: {
        setTechFilter: (state, action: PayloadAction<IFilter>) => {
            state.filter = action.payload;
        },
        setTechDetailsLoader: (state, action: PayloadAction<boolean>) => {
            state.loader = action.payload;
        },
    },
});

export const { setTechFilter, setTechDetailsLoader } = techSlice.actions;
export const TechSlice = techSlice.reducer;
