import { FeedRationOrderActionTypes } from '../Actions';
import { FEED_RATION_ORDER_ROUTES_INITIAL_STATE } from '../Constants/rationFeedOrderInitialState';

const FeedRationOrderReducer = (
    state = FEED_RATION_ORDER_ROUTES_INITIAL_STATE,
    action,
) => {
    switch (action.type) {
        case FeedRationOrderActionTypes.SET_FEED_RATION_ORDER_RATIONS:
            return {
                ...state,
                rations: action.payload,
            };
        case FeedRationOrderActionTypes.SET_FEED_RATION_ORDER_MIXERS:
            return {
                ...state,
                mixers: action.payload,
            };
        case FeedRationOrderActionTypes.SET_FEED_RATION_ORDER_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case FeedRationOrderActionTypes.SET_FEED_RATION_ORDER_CAN_RESET_TIME:
            return {
                ...state,
                canResetTime: action.payload,
            };
        case FeedRationOrderActionTypes.SET_FEED_RATION_ORDER_RESET_STATE:
            return {
                ...action.payload,
            };
        default:
            return state;
    }
};

export default FeedRationOrderReducer;
