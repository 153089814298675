import './index.scss';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import Store from './Redux/Store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import './i18n/i18nlnit';

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
    <Provider store={Store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
