import TableRowLoading from './TableRowLoading';

const AccordionLoading = ({ ...args }) => {
    return (
        <table>
            <tbody>
                <TableRowLoading {...args} />
            </tbody>
        </table>
    );
};

export default AccordionLoading;
