import { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useLoading } from '../../commonHooks/useLoading';
import { useAppDispatch } from '../../Redux/Store';
import { useFeedlotId } from '../../commonHooks/useFeedlotId';
import { CommonConstants } from '../../utils/constants/CommonConstants';
import { validateAccordingType } from '../../utils/validation';
import { tankConstants } from '../../utils/constants/TankConstants';
import { ITankDetail, ITankModal } from '../../utils/Interface/TankInterface';
import {
    createTank,
    fetchInactiveTankInfo,
    updateTankDetail,
} from '../../Redux/Actions/tankActions';
import FullPageLoader from '../common/FullPageLoader';
import { useModalStatus } from '../../commonHooks/useModalStatus';
import { ActivateItemModal } from '../../apps/shippingTracker/modules/BuyPo/components/ActivateItemModal';
import { FmSettingsConstant } from '../../utils/constants/FmSettingsConstants';

const TankModal = ({
    showModal,
    closeModalWithChanges,
    editTankDetails,
    showActiveField,
}: ITankModal) => {
    const [tankDetail, setTankDetail] = useState<ITankDetail>(editTankDetails);
    const [tankDetailNameError, setTankDetailNameError] =
        useState<boolean>(false);
    const [inActiveTank, setInActiveTank] = useState<ITankDetail>();
    const {
        isOpen: isActivateModalOpen,
        open: openActivateModal,
        close: closeActivateModal,
    } = useModalStatus();
    const { loading, setLoading } = useLoading();
    const dispatch = useAppDispatch();
    const feedlotId = useFeedlotId();

    const validateTankDetails = (): boolean => {
        if (!tankDetail.name) {
            setTankDetailNameError(true);
            return false;
        }
        return true;
    };

    const handleActivateTank = async () => {
        if (feedlotId && tankDetail.name) {
            const inactiveTankInfo = await dispatch(
                fetchInactiveTankInfo(feedlotId, tankDetail.name.trim()),
            );
            if (inactiveTankInfo) {
                setInActiveTank(inactiveTankInfo[0]);
                openActivateModal();
            }
        }
    };

    const handleUpdateTankDetail = (): void => {
        const isValid = validateTankDetails();
        if (feedlotId && isValid) {
            dispatch(
                updateTankDetail(feedlotId, tankDetail, {
                    closeModalWithChanges,
                    setLoading,
                    handleActivateTank,
                }),
            );
        }
    };

    const handleCreateTank = (): void => {
        const isValid = validateTankDetails();
        if (feedlotId && isValid) {
            dispatch(
                createTank(
                    feedlotId,
                    tankDetail,
                    closeModalWithChanges,
                    setLoading,
                    handleActivateTank,
                ),
            );
        }
    };

    const handleDetailChange = (
        fieldValue: string | number | boolean,
        fieldName: string,
        type?: string,
    ): void => {
        const isValid = validateAccordingType(type, fieldValue);
        if (isValid) {
            setTankDetail(prevTankDetail => ({
                ...prevTankDetail,
                [fieldName]: fieldValue,
            }));
        }
    };

    const handleCloseActivateModal = (isActivated: boolean) => {
        if (isActivated && feedlotId) {
            if (inActiveTank) {
                const inActiveTankCopy = {
                    ...inActiveTank,
                    active: true,
                };
                dispatch(
                    updateTankDetail(feedlotId, inActiveTankCopy, {
                        closeModalWithChanges,
                        setLoading,
                    }),
                );
                closeActivateModal();
            }
        } else {
            closeActivateModal();
        }
    };

    return (
        <>
            <ActivateItemModal
                showModal={isActivateModalOpen}
                closeModalHandler={handleCloseActivateModal}
                itemName={tankDetail.name?.trim()}
                item={tankConstants.activateModalConstant}
            />
            <Modal
                className="px-2 pt-5 fm-settings-modal"
                size="lg"
                show={showModal}
                centered
                animation
                onHide={() => closeModalWithChanges(false)}
            >
                {loading && <FullPageLoader />}
                <Modal.Header closeButton className="border-bottom-0">
                    <Modal.Title className="text-dark">
                        {editTankDetails.name
                            ? `${tankConstants.editTank} ${editTankDetails?.name}`
                            : tankConstants.createNewTanks.heading}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-6 mb-4">
                        <Form.Label className="text-secondary">
                            {tankConstants.createNewTanks.form.name.label}
                            <span className="necessaryFields">*</span>
                        </Form.Label>
                        <Form.Control
                            className={`text-secondary border-${
                                tankDetailNameError ? 'danger' : ''
                            }`}
                            value={tankDetail?.name}
                            name={tankConstants.createNewTanks.form.name.name}
                            placeholder={
                                tankConstants.createNewTanks.form.name
                                    .placeholder
                            }
                            onChange={e =>
                                handleDetailChange(
                                    e.target.value,
                                    e.target.name,
                                    'string',
                                )
                            }
                            maxLength={
                                tankConstants.createNewTanks.form.name.maxLength
                            }
                            size="sm"
                            type="text"
                        />
                    </div>
                    {showActiveField && (
                        <div className="custom-checkbox col-6 ">
                            <input
                                type="checkbox"
                                checked={tankDetail.active}
                                className="mx-2"
                                onChange={e =>
                                    handleDetailChange(
                                        !tankDetail.active,
                                        'active',
                                    )
                                }
                            />
                            <label className="mx-2">
                                {FmSettingsConstant.active.label}
                            </label>
                        </div>
                    )}
                    <div className="custom-checkbox col-6 ">
                        <input
                            type="checkbox"
                            checked={tankDetail.preload}
                            className="mx-2"
                            onChange={e =>
                                handleDetailChange(
                                    !tankDetail.preload,
                                    tankConstants.createNewTanks.form.preload
                                        .name,
                                )
                            }
                        />
                        <label className="mx-2">
                            {tankConstants.createNewTanks.form.preload.label}
                        </label>
                    </div>
                </Modal.Body>
                <Modal.Footer className="border-top-0">
                    <Button
                        className="secondary"
                        onClick={() => closeModalWithChanges(false)}
                    >
                        {CommonConstants.en.cancel}
                    </Button>
                    <Button
                        onClick={
                            !editTankDetails.name
                                ? handleCreateTank
                                : handleUpdateTankDetail
                        }
                    >
                        {CommonConstants.en.save}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default TankModal;
