import { ChangeEvent } from 'react';
import { IGenericFilter } from '../../../../utils/Interface/CommonInterface';
import { Range } from 'react-date-range';
import { IVendorModalDetails } from '../../scaleTickets/interfaces/ScaleTicketInterface';

export interface IManageCommoditiesInitialState {
    adjustmentsLoading: boolean;
    commoditiesLoading: boolean;
    adjustmentsCanCallApi: boolean;
    commoditiesCanCallApi: boolean;
    commoditiesUnit: CommodityUnit;
    commodityAction: string;
    commodityId: number;
    commoditiesFilter: IFilter;
    adjustmentsFilter: IFilter;
    contractsFilter: IContractFilter;
    contractScaleTicketFilter: IContractScaleTicketFilter;
    openScaleTicketFilter: IOpenScaleTicketFilter;
    openScaleTicketsSelectAll: boolean;
    commoditiesList: ICommoditiesResponse[];
    fetchedCommodityDetails: ICommodityDetails;
    commodityDetails: ICommodityDetails;
    commodityDetailsError: ICommodityDetailsError;
    commodityAdjustmentHistoryList: ICommodityAdjustmentHistoryResponse[];
    adjustmentHistoryList: IAdjustmentHistoryResponse[];
    adjustmentHistoryListUnit: string;
    adjustmentCommoditiesDetails: IAdjustmentCommoditiesResponse[];
    adjustmentCommoditiesDetailsError: IAdjustmentCommoditiesDetailsError;
    fetchedAdjustmentCommoditiesDetails: IAdjustmentCommoditiesResponse[];
    earliestGeneratedInvoiceCutoffDate: string;
    contracts: IContractsResponse[];
    selectedContract: { id: string; name: string };
    selectedContractError: boolean;
    contractsLoading: boolean;
    openScaleTicketLoading: boolean;
    openScaleTickets: IOpenScaleTicket[];
    contractScaleTickets: IContractScaleTicket[];
    contractHasScaleTickets: boolean;
    contractScaleTicketsLoading: boolean;
    tanks: ITank[];
    submitPending: boolean;
}

export interface ICreateCommodityModal {
    show: boolean;
    onHide: () => void;
    validateMandatoryDetails: () => boolean;
    commodityDetailsHandler: (
        { target }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        type?: string,
    ) => void;
    commodityStatusHandler: ({ target }: ChangeEvent<HTMLInputElement>) => void;
    commodityServicePayload: () => ICommodityServicePayload;
}

export interface ILbsTonsToggleSwitch {
    headerName: string;
    changeUnit: () => void;
    spacingLeft?: boolean;
    grayBackground?: boolean;
    bottomPadding?: boolean;
}

export interface IUpdateCommodityModal {
    show: boolean;
    onHide: () => void;
    commodityId: number;
    validateMandatoryDetails: () => boolean;
    commodityDetailsHandler: (
        { target }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        type?: string,
    ) => void;
    commodityStatusHandler: ({ target }: ChangeEvent<HTMLInputElement>) => void;
    commodityServicePayload: () => ICommodityServicePayload;
}

export interface IFilter extends IGenericFilter {
    limit: number;
    offset: number;
    commodityStatus: string;
}

export enum ManageCommoditiesTab {
    ManageCommodities = 'commoditiesList',
    AdjustmentList = 'adjustmentList',
    ContractManagement = 'contractManagement',
    OpenScaleTickets = 'openScaleTickets',
}

export interface IAdjustmentHistoryFilter extends IGenericFilter {
    limit: number;
    offset: number;
}

export interface IContractFilter extends IGenericFilter {
    limit: number;
    offset: number;
    contractName?: string;
    commodity?: string;
    vendor?: string;
    payee?: string;
    status?: ContractStatus;
}

export interface IContractScaleTicketFilter {
    limit: number;
    offset: number;
    contractScaleTicketFromDate?: string;
    contractScaleTicketToDate?: string;
    scaleTicket?: string;
    billOfLading?: string;
    selectedContractScaleTicketDateRange: Range;
    dmPercentageStart?: string;
    dmPercentageEnd?: string;
}

export interface IOpenScaleTicketFilter {
    limit: number;
    offset: number;
    vendor?: string;
    commodity?: string;
    openScaleTicketFromDate?: string;
    openScaleTicketToDate?: string;
    selectedOpenScaleTicketDateRange: Range;
    scaleTicket?: string;
    billOfLading?: string;
    contractStatus?: OpenScaleTicketContractStatus;
}

export interface ICommoditiesResponse {
    commodityId: number;
    name: string;
    dryMatterPercentage: number;
    price: number;
    inventoryAmount: number;
    status: string;
    priceEffectiveDate: string;
    unit?: CommodityUnit;
    type?: string;
    tankId?: number | null;
}

export interface ICommodityAdjustmentHistoryResponse {
    priceEffectiveDate: Date | null;
    dryMatterPercentage: string;
    price: number;
    modifiedBy: string;
    modifiedDate: Date | null;
    unit?: CommodityUnit;
}

export interface IContractsResponse {
    totalAmount: number;
    contractCreationDate: string;
    freight: string;
    rate: number;
    comment: string;
    feedLotId: number;
    feedLot: null;
    status: ContractStatus;
    createdDatetime: string;
    modifiedDatetime: string;
    createdBy: string;
    modifiedBy: string;
    contractId: number;
    contractName: string;
    price: number;
    payee: string;
    dmPercentage: number;
    commodity: ICommodityDetails;
    commodityId: number;
    vendor: IVendorModalDetails;
    vendorId: number;
    remainingAmount?: number;
}

export enum ContractStatus {
    Open = 'Open',
    Full = 'Full',
    Unknown = 'Unknown',
}

export enum OpenScaleTicketContractStatus {
    NoContract = 'No Contract',
    Unassigned = 'Unassigned Contract',
    AssignedContract = 'Assigned Contract',
    All = 'All',
}

export interface IContractScaleTicket {
    scaleTicketId: number;
    scaleTicketNumber: string;
    date: string;
    billOfLading: string | null;
    dmPercentage: number | null;
    price: number | null;
    netWeight: number;
    weightInTons: number;
}

export interface IOpenScaleTicket {
    scaleTicketId: number;
    vendor?: any;
    commodity?: string;
    commodityName: string;
    commodityIdRoot: number;
    vendorIdRoot: number;
    tDate: string;
    scaleTicketNumber: string | null;
    billOfLading: string | null;
    net: number;
    scaleType: string;
    contract?: IContractsResponse;
    contractStatus: string;
    checked?: boolean;
}

export interface IAdjustmentHistoryResponse {
    date: string;
    commodity: string;
    commodityId: number;
    dryMatterPercentage: number;
    totalWeight: number;
    actualWeight: number;
    variance: number;
    cost: number;
    comments: string;
    unit?: CommodityUnit;
}

export interface IAdjustmentCommoditiesResponse {
    date?: string;
    name: string;
    commodityId: number;
    dryMatterPercentage: number;
    inventoryAmount: number;
    actualWeight?: number;
    historicWeight?: number;
    variance?: number;
    cost?: number;
    comments?: string;
    unit?: CommodityUnit;
}
export interface IAdjustmentCommoditiesDetailsError {
    [commodityIdKey: number]: {
        dateError: boolean;
    };
}

export interface ICommodityAdjustmentServicePayload {
    commodityId?: number;
    amount?: number;
    comments?: string;
    actionDate?: string;
}

export interface ICommodityAdjustmentRow {
    adjustmentCommodityItem: IAdjustmentCommoditiesResponse;
    commoditiesAdjustmentDetailsHandler: (
        { target }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        commodityId: number | undefined,
        type?: string,
    ) => void;
}

export type ICommodityAdjustmentHistoryResponseHandlerFn = (
    response: ICommodityAdjustmentHistoryResponse[],
) => void;

export interface ICommodityDetails {
    name: string;
    dryMatterPercentage: number | string;
    priceEffectiveDate: string;
    price: number | string;
    status: number;
    version?: number;
    type?: string;
    unit?: CommodityUnit;
    tankId?: number | null;
}

export interface ICommodityDetailsError {
    name: boolean;
    dryMatterPercentage: boolean;
    priceEffectiveDate: boolean;
    price: boolean;
    tankId?: boolean;
}

export type ICommoditiesResponseHandlerFn = (
    response: ICommoditiesResponse[],
) => void;

export type IAdjustmentHistoryResponseHandlerFn = (
    response: IAdjustmentHistoryResponse[],
) => void;

export interface ICommodityServicePayload {
    name: string;
    dryMatterPercentage: number;
    price: number;
    priceEffectiveDate: string;
    status: number;
    type?: string;
    tankId?: number;
}

export interface ICommodityAdjustmentFooter {
    onCancel: () => void;
    onSubmit: () => void;
}

export enum CommodityUnit {
    Lbs = 'Lbs',
    Tons = 'Tons',
}

export enum CommodityType {
    Dry = 'Dry',
    Liquid = 'Liquid',
    Microingredient = 'Microingredient',
}

export interface ITank {
    tankId: number;
    name: string;
    description: string;
    inactivated: boolean;
    commodityId?: number;
    commodity: ICommodityDetails;
}
