import { FC } from 'react';

const OfflineIcon: FC = props => {
    return (
        <img
            width={15}
            {...props}
            src={require(`./offline_icon.png`)}
            alt="offline_icon.png"
        />
    );
};

export default OfflineIcon;
