import {
    IProcessingLot,
    ProcessingSubmitButtonOptions,
    ProcessingWorksheetStatus,
} from '../../utils/Interface/HealthSection/LotProcessingWorksheet';

interface IProcessingLotInitialState {
    lots: IProcessingLot[];
    lot: IProcessingLot;
    activeButton: ProcessingSubmitButtonOptions;
    worksheetFilter: ProcessingWorksheetStatus;
}

export const PROCESSING_LOT_INITIAL_STATE: IProcessingLotInitialState = {
    lots: [],
    lot: {
        lotId: 0,
        name: '',
        hdCount: 0,
        hdCountReprocessed: 0,
        hdCountProcessed: 0,
        totalHdCount: 0,
        averageDailyGain: 0,
        feedLotId: 0,
        lotAverageWeight: 0,
        owner: {
            id: 0,
            name: '',
        },
        processingWorksets: [],
        ownerId: 0,
        ownerName: '',
        cattleTypeId: 0,
        sexId: 0,
        pens: [],
    },
    activeButton: ProcessingSubmitButtonOptions.NoneSelected,
    worksheetFilter: ProcessingWorksheetStatus.All,
};
