import {
    configureStore,
    //eslint-disable-next-line @typescript-eslint/no-restricted-imports
    // createDraftSafeSelector,
    //eslint-disable-next-line @typescript-eslint/no-restricted-imports
    // createSelector,
} from '@reduxjs/toolkit';
//eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import rootReducer from '../Reducer/index';
import { rootApi } from '../Apis/rootApi';

const liveEnvironments = ['prod', 'test', 'beta', 'betatest', 'dev', 'sec'];
const currentEnvironment =
    process.env.REACT_APP_ENVIRONMENT?.toLowerCase() ?? 'local';
const isLocalEnvironment = !liveEnvironments.includes(currentEnvironment);

const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: isLocalEnvironment,
            immutableCheck: isLocalEnvironment,
            actionCreatorCheck: isLocalEnvironment,
        }).concat(rootApi.middleware),
    devTools: isLocalEnvironment,
});

export default store;

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;

export type RootState = ReturnType<typeof store.getState>;
export type GetState = () => RootState;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

//IRFTODO upgrade to rtk 2.0 - these require rtk 2.0
// export const createTypedSelector = createSelector.withTypes<RootState>();
// export const createTypedDraftSafeSelector =
//     createDraftSafeSelector.withTypes<RootState>();
