const StaticOption = ({
    options,
}: {
    options: { id: string | number; name: string }[];
}) => {
    return (
        <>
            {options.map((item, index) => (
                <option value={item.id} key={`${JSON.stringify(item)}${index}`}>
                    {item.name}
                </option>
            ))}
        </>
    );
};

export default StaticOption;
