import { IFeedRouteCreationInitialState } from '../../utils/Interface/FeedRouteCreationInterface';

/**
 * Create Feed Routes Creation initial states
 */
export const FEED_ROUTES_CREATION_INITIAL_STATE: IFeedRouteCreationInitialState =
    {
        subRoutesList: [],
        defaultSubRoutes: [],
        defaultStaticSubRoutes: {
            feedRouteId: 0,
            ration: {
                category: null,
                categoryId: 0,
                comments: '',
                corporatePrice: null,
                customPrice: null,
                effectiveDate: '',
                effectiveTime: '',
                feedLot: {
                    feedLotId: 0,
                    label: '',
                    location: null,
                    locationId: 0,
                    manager: '',
                    yardageRate: 0,
                },
                feedLotId: 0,
                loadSize: null,
                markUp: null,
                name: '',
                neg: null,
                nem: null,
                rationIngredients: [],
                rationVersion: 0,
                rationId: 0,
            },
            rationId: 0,
            subRoutes: [
                {
                    feedRouteId: 0,
                    penId: 0,
                    penStart: {
                        feedLotId: 0,
                        feedlot: {
                            label: '',
                            location: '',
                            locationId: 0,
                            manager: '',
                            yardageRate: 0,
                        },
                        hdCount: 0,
                        name: '',
                        penId: 0,
                        penRationSchedule: '',
                        penRationScheduleId: 0,
                        type: 0,
                    },
                    subRouteId: 0,
                    subRouteName: '',
                },
                {
                    feedRouteId: 0,
                    penId: 0,
                    penStart: {
                        feedLotId: 0,
                        feedlot: {
                            label: '',
                            location: '',
                            locationId: 0,
                            manager: '',
                            yardageRate: 0,
                        },
                        hdCount: 0,
                        name: '',
                        penId: 0,
                        penRationSchedule: '',
                        penRationScheduleId: 0,
                        type: 0,
                    },
                    subRouteId: 0,
                    subRouteName: '',
                },
            ],
        },
        staticSubRoutes: {
            feedRouteId: 0,
            penId: 0,
            penStart: {
                feedLotId: 0,
                feedlot: {
                    label: '',
                    location: '',
                    locationId: 0,
                    manager: '',
                    yardageRate: 0,
                },
                hdCount: 0,
                name: '',
                penId: 0,
                penRationSchedule: '',
                penRationScheduleId: 0,
                type: 0,
            },
            subRouteId: 0,
            subRouteName: '',
        },
        subRoutesRations: [
            {
                lastChanged: '',
                name: 'Select Ration',
                rationId: 0,
            },
        ],
        subRoutesPens: [
            {
                penId: 0,
                pen: {
                    feedLotId: 0,
                    feedlot: null,
                    hdCount: 0,
                    name: 'Select Pen',
                    penId: 0,
                    penRationSchedule: null,
                    penRationScheduleId: null,
                    type: 0,
                },
                ordinal: 0,
            },
        ],
    };
