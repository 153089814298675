export const LOCATION_INITIAL_STATE = {
    filter: {
        name: '',
        show: 'active',
    },
    createLocationInitial: {
        name: '',
        active: true,
        feedLotId: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        country: 'US',
        postalCode: '',
        coordinates: '',
    },
    loader: true,
};
