export const FeedCallListConstants = {
    tableHeader: [
        'Date',
        'day',
        'Rat',
        'Head Count',
        'B',
        'Complete',
        'Chg',
        'Call',
        'Feed LB',
        'Yar',
        'AF',
        'DM',
    ],

    table: {
        headerAlign: 'text-center',
        headers: {
            date: {
                label: 'Date',
                apiResName: 'date',
                type: 'date',
            },
            day: {
                label: 'Day',
                apiResName: 'day',
                type: 'prefix',
                prefix: 'S',
            },
            Rat: {
                label: 'Rat',
                apiResName: 'rat',
            },
            headCount: {
                label: 'Hd',
                apiResName: 'hdCount',
            },
            B: {
                label: 'B',
                apiResName: 'b',
            },
            Chg: {
                label: 'Chg',
                apiResName: 'chg',
            },
            Call: {
                label: 'Call',
                apiResName: 'call',
            },
            FeedLB: {
                label: 'Feed LB',
                apiResName: 'feedlb',
            },
            Yar: {
                label: 'Yar',
                apiResName: 'yar',
            },
            AF: {
                label: 'AF',
                apiResName: 'af',
            },
            DM: {
                label: 'DM',
                apiResName: 'dm',
            },
        },
    },
};

export const FeedCallConstants = {
    list: {
        limit: 20,
        offset: 0,
    },
    feedCallTable: {
        headers: {
            date: 'Date',
            day: 'Day',
            rat: 'Rat',
            hd: 'Hd',
            b: 'B',
            chg: 'AF Chg',
            dmChg: 'DM Chg',
            call: 'Call',
            fedLb: 'Fed',
            var: 'Var %',
            af: 'AF',
            dm: 'DM',
            wtDm: '%WT/DM',
        },
    },
    button: {
        Shipping: 'Shipping',
        RationSchedule: 'Ration Schedule',
        FeedCallTableAllRecord: 'All Records',
        previousPen: 'Save and Previous',
        nextPen: 'Save and Next',
        previous: 'Previous',
        next: 'Next',
    },
    totalCallErrorMessage: {
        mandatoryFields:
            'Total Call Is Negative. Please Adjust Before Moving To Another Pen.',
    },
    feedCallShippingModal: {
        title: 'Shipping',
        bodyTitle: 'Enter number of feedings made to this pen before shipment',
        button: {
            Cancel: 'Cancel',
            Done: 'Done',
        },
    },
    rationSchedulesChangeReminderModal: {
        reminder: 'Reminder:',
        scheduleChangeHeaderMessage: (penName: string) =>
            `Pen ${penName} Ration Schedule was set to change today`,
        manuallyAdjustMessage:
            "If you do not want these changes, select Manually Adjust to edit the Pen's Ration Schedule.",
        button: {
            manuallyAdjust: 'Manually Adjust',
            ok: 'OK',
        },
    },
    pen: 'Pen',
    filterPenList: 'Filter Pen List',
    totalHdCount: 'Total Hd Cnt',
    estimatedHdCountButton: 'Est. Head Count',
    estimateTotalHd: 'Est. Total Hd',
    totalCallField: 'totalCall',
    hdCount: 'Total Head Count',
    noCattle: 'No Cattle',
    noPens: 'No Records Found',
    noPenRationScheduleCreated: 'Create a Pen Ration Schedule For This Pen',
    estimatedHeadCountInfoHeader: ['Lot#', 'Hd Count', 'Estimated Hd Count'],
    totalHeadCount: 'Total Head Count',
    penInfoHeader: 'Pen Info',
    feedCallRouteHeader: 'Manage Routes',
    feedInfoHeader: 'Feed Info',
    comments: 'Comments',
    bunkScoreHeader: 'Bunk Score',
    hayPerHeadHeader: '2nd/Hay Per Head',
    addRow: 'Add Row',
    bunkScoreLabel: { bunkScore: 'Bunk Score', afChangePerHd: 'AF Change/Hd' },
    afChangePerHd: 'AF Change/Hd',
    dmChangePerHd: 'DM Change/Hd',
    estimatedHdCountHeader: {
        pen: 'Pen #',
        totalEstHeadCount: 'Est. Total Head Count',
    },
    apiFields: {
        estimatedHeadCount: 'estimatedHeadCount',
        lotId: 'lotId',
    },
    staticOptions: {
        lots: [
            {
                name: 'Select Lot',
                id: 0,
            },
        ],
    },
    penInfoItem: [
        'Lot #',
        'Hd Ct',
        'Sex',
        'Breed',
        'Owner',
        'Origin',
        'In Hosp.',
        'Lot Avg Pay Weight',
        'Pen Cur. Weight',
        'Cur. Ration %',
        'Days Cur. Ration',
        'Avg. Days Feed',
    ],
    feedInfoItem: [
        'Pay Weight',
        'Current Weight',
        'Current Ration',
        'Days Current Ration',
        'Average Days Feed',
    ],
    bunkOption: 'Select Bunk',
    penOption: 'Select Pen',
    selectLot: 'Select Lot',
    noOfFeedings: 'No Of Feedings',
    successMessage: {
        create: 'Feed Call Done For The Day',
        update: 'Feed Call Updated For the Day',
    },
    errorMessage: {
        fail: 'Duplicate Entry',
        updateDetail: 'Unable To Update',
    },
    bunkTotalColumnFields: {
        totalCallLabel: 'Total call',
        afHdLabel: 'AF/Hd',
        dmHdLabel: 'DM/Hd',
        wTDmLabel: '%WT/DM',
        adgLabel: 'ADG',
        estHeadLabel: 'Est. Head',
    },
    bunkHayHandColumnFields: {
        feeding1Label: 'F1',
        feeding2Label: 'F2',
        totalLabel: 'Total Hay',
    },
    textFields: {
        pens: 'pens',
        feedings: 'feedings',
        bunkScore: 'bunkScore',
        afChangePerHd: 'afChangePerHd',
        dmChangePerHd: 'dmChangePerHd',
        estimatedHdCount: 'estimatedHdCount',
        adg: 'adg',
        wtPerDM: 'wtPerDM',
        dmPerHd: 'dmPerHd',
        afPerHd: 'afPerHd',
        totalCall: 'totalCall',
        hayFeeding1: 'hayFeeding1',
        hayFeeding2: 'hayFeeding2',
        comments: 'comments',
        noOfFeedingsBeforeShipment: 'noOfFeedingsBeforeShipment',
    },
    placeholders: {
        estimatedHeadCount: 'Enter Est. Hd Count',
    },
    successEstimateHeadCount: 'Saved Estimated Head Count',
    successFeedCall: {
        create: 'Pen Feed Call Created',
    },
    feedCallDays: {
        days14: '14 Days',
        days30: '30 Days',
        days60: '60 Days',
    },
    feedCallInitialState: {
        feedCallInfo: {
            feedCall: {
                adg: '',
                afChangePerHd: '',
                dmChangePerHd: '',
                afPerHd: 0,
                bunkScore: '',
                comments: '',
                createdDateTime: '',
                daysOnCurrentRation: '',
                daysOnFeed: '',
                dmPerHd: '',
                endRation: '',
                estimatedHdCount: '',
                feedCallId: '',
                hayFeeding1: '',
                hayFeeding2: '',
                hdCount: '',
                noOfFeedingsBeforeShipment: '',
                pen: null,
                penId: '',
                penRationSchedule: null,
                penRationScheduleId: '',
                startRation: '',
                totalCall: '',
                wtPerDM: '',
                extendRationSchedule: false,
            },
            lotInformation: [],
            rations: [],
        },
    },
    feedCallLocalStorage: {
        feedCallInfo: 'feedCallInfo',
        penRationActiveStates: 'penRationActiveStates',
        rationId: 'rationId',
    },
    selectedGraphDays: 'selectedGraphDays',
    confirmRationChangeTitle: 'Confirm Scheduled Ration Change?',
    afChangeIncreaseConfirmation:
        'Are you sure you want to increase feed call by 5.0 pounds per head?',
    afChangeDecreaseConfirmation:
        'Are you sure you want to decrease feed call by 5.0 pounds per head?',

    fromLabel: 'From',
    toLabel: 'To',
    noButtonLabel: 'No, extend by 1 day',
    yesButtonLabel: 'Yes',
    penKinds: ['Hospital', 'Buller'],
    increment: 'increment',
    decrement: 'decrement',
    lotSummaryDetailUrl: '/lotsummary/lotsummarydetail/',
    dropDownName: {
        rations: { id: 'id', name: 'name' },
    },
    timeZones: {
        USTimeZone: 'America/Denver',
    },
    afChangePerHdLimit: 5,
    rationIds: {
        all: 'all',
        cattleNoFeedCall: 'cattleNoFeedCall',
    },
};

export const FeedCallGraphConstants = {
    options: {
        responsive: true,
        maintainAspectRatio: false, // responsible for height of graph
        plugins: {
            legend: {
                position: 'top' as const,
                display: false, // for removal of checkbox
            },
            title: {
                display: true,
            },
        },
        scales: {
            y: {
                title: {
                    display: true,
                    text: 'Lbs per Head',
                    font: {
                        size: 15,
                    },
                },
                suggestedMax: 60,
                beginAtZero: true,
                ticks: {
                    stepSize: 4,
                },
            },
            x: {
                title: {
                    display: true,
                    text: 'Days on Feed',
                    font: {
                        size: 15,
                    },
                },
            },
        },
    },
    datasets: [
        {
            label: 'AF/Hd',
            fill: false,
            borderColor: '#242748',
            data: [] as Array<string | number>,
            borderWidth: 2,
        },
        {
            label: 'DM/Hd',
            fill: false,
            borderColor: '#FFA630',
            data: [] as Array<string | number>,
            borderWidth: 2,
        },
    ],
    //IRFTODO clean this up
    graphValues: {
        day14: 'days14',
        day30: 'days30',
        day60: 'days60',
    },
};
