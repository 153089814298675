export const FmSettingsConstant = {
    filterOptions: [
        {
            label: 'Active',
            name: 'show',
            value: 'active',
        },
        {
            label: 'Inactive',
            name: 'show',
            value: 'inactive',
        },
        {
            label: 'View All',
            name: 'show',
            value: 'all',
        },
    ],
    active: {
        label: 'Active',
        value: 'active',
    },
    deleteConfirmationModal: {
        header: {
            associationHeader: 'Confirm:',
            importantNoticeHeader: 'Important Notice:',
        },
        message: {
            confirmationMessage: 'Are you sure you want to delete this record?',
            associationMessage:
                'Cannot delete the {Item name} because there are records associated with this {Item name}. You can deactivate or rename the {Item name}.',
            importantNoticeMessage:
                'The {Item name} is connected to records and has already been deactivated. \n\nAs a result you cannot delete it. ',
            associationMessageForCompany:
                'Cannot delete the Trucking Company because there are Scale Tickets associated with this Trucking Company. You can deactivate or rename the Trucking Company.',
            importantNoticeMessageForPen:
                'The Pen is connected to records. As a result you cannot delete it. ',
        },
        footer: {
            cancel: 'Cancel',
            ok: 'Ok',
            yes: 'Yes',
            no: 'No',
        },
    },
};
