import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { LOCATION_INITIAL_STATE } from '../Constants/locationInitialState';
import { IFilter } from '../../utils/Interface/LocationInterface';

const locationSlice = createSlice({
    name: 'location',
    initialState: { ...LOCATION_INITIAL_STATE },
    reducers: {
        setLocationFilter: (state, action: PayloadAction<IFilter>) => {
            state.filter = action.payload;
        },
        setLocationDetailsLoader: (state, action: PayloadAction<boolean>) => {
            state.loader = action.payload;
        },
    },
});

export const { setLocationFilter, setLocationDetailsLoader } =
    locationSlice.actions;
export const LocationSlice = locationSlice.reducer;
