import { apiCall_v2 } from '../../Services/AxiosService';
import Constants from '../../utils/Constants';
import {
    ICreateRider,
    IDeleteRider,
    IFetchRiderDetails,
    IFilter,
    IUpdateRiderDetail,
    IUpdateRiderDetailOptions,
} from '../../utils/Interface/RiderInterface';
import { getToastContent } from '../../utils/ToastContent';
import { toastMessage } from '../Reducer/common';
import { setRiderDetailsLoader } from '../Reducer/riderSlice';
import { AppDispatch } from '../Store';

const getFilterUrl = (filter: IFilter) => {
    let filterUrl = `&filter=contains(Name, '${filter.name}')`;
    if (filter?.show && filter?.show !== 'all') {
        const isActive = filter.show === 'active';
        filterUrl += ` and active eq ${isActive}`;
    }
    return filterUrl;
};

export const fetchRiderDetails: IFetchRiderDetails =
    (feedlotId, setRiderDetail, filter) => async (dispatch: AppDispatch) => {
        dispatch(setRiderDetailsLoader(true));
        const filterUrl = filter ? getFilterUrl(filter) : '';
        const response = await apiCall_v2({
            method: 'get',
            url: `${Constants.apiUrls.FEED_LOT_MANAGER}/${feedlotId}${Constants.apiUrls.RIDER}/odata?flid=${feedlotId}${filterUrl}&orderBy=Name`,
        });
        setRiderDetail(response ?? []);
        dispatch(setRiderDetailsLoader(false));
    };

export const updateRiderDetail: IUpdateRiderDetail =
    (feedlotId, riderDetail, options?: IUpdateRiderDetailOptions) =>
    async (dispatch: AppDispatch) => {
        options?.setLoading?.(true);
        const response = await apiCall_v2({
            method: 'put',
            url: `${Constants.apiUrls.FEED_LOT_MANAGER}/${feedlotId}${Constants.apiUrls.RIDER}/${riderDetail.riderId}`,
            payload: riderDetail,
            isResRequired: true,
        });

        options?.setLoading?.(false);
        if (!response) return;
        const status = Number(response.status);

        if (status === Constants.responseCode.DUPLICATE) {
            dispatch(
                toastMessage(
                    getToastContent(
                        response.status,
                        '',
                        Constants.customMessage.RIDER_DUPLICATE,
                    ),
                ),
            );
        } else if (status === Constants.responseCode.CONFLICT) {
            options?.handleActivateRider?.();
        } else {
            dispatch(toastMessage(getToastContent(response.status)));
            options?.closeModalWithChanges?.(true, riderDetail.riderId);
        }
        options?.fetchRiderData?.();
    };

export const createRider: ICreateRider =
    (
        feedlotId,
        rider,
        closeModalWithChanges,
        setLoading,
        handleActivateRider,
    ) =>
    async (dispatch: AppDispatch) => {
        setLoading(true);
        const response = await apiCall_v2({
            method: 'post',
            url: `${Constants.apiUrls.FEED_LOT_MANAGER}/${feedlotId}${Constants.apiUrls.RIDER}`,
            payload: rider,
            isResRequired: true,
        });
        setLoading(false);
        if (!response) return;
        const status = Number(response.status);

        if (status === Constants.responseCode.DUPLICATE) {
            dispatch(
                toastMessage(
                    getToastContent(
                        status,
                        '',
                        Constants.customMessage.RIDER_DUPLICATE,
                    ),
                ),
            );
        } else if (status === Constants.responseCode.CONFLICT) {
            handleActivateRider();
        } else {
            dispatch(toastMessage(getToastContent(status)));
            closeModalWithChanges(true, response.data);
        }
    };

export const fetchInactiveRiderInfo =
    (feedlotId: number, rider: string) => async (dispatch: AppDispatch) => {
        const response = await apiCall_v2({
            method: 'get',
            url: `${Constants.apiUrls.FEED_LOT_MANAGER}/${feedlotId}${Constants.apiUrls.RIDER}/odata?filter=name eq '${rider}'`,
            isResRequired: true,
        });

        if (!response) return;
        const status = Number(response.status);

        if (
            response.status >= Constants.responseCode.SUCCESS &&
            response.status < Constants.responseCode.MULTIPLE_CHOICES
        ) {
            return response.data;
        } else {
            dispatch(toastMessage(getToastContent(status)));
        }
    };

export const deleteRider: IDeleteRider =
    (feedlotId, rider, options?: IUpdateRiderDetailOptions) =>
    async (dispatch: AppDispatch) => {
        dispatch(setRiderDetailsLoader(true));
        const response = await apiCall_v2({
            method: 'delete',
            url: `${Constants.apiUrls.FEED_LOT_MANAGER}/${feedlotId}${Constants.apiUrls.RIDER}/${rider.riderId}`,
            payload: rider,
            isResRequired: true,
        });
        dispatch(setRiderDetailsLoader(false));
        if (!response) return;
        dispatch(toastMessage(getToastContent(Number(response.status))));
        options?.fetchRiderData?.();
    };
