import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import useUSCurrencyFormatter from '../../commonHooks/useUSCurrencyFormatter';

interface ValueWithTooltipProps {
    value?: number;
    keyName?: string;
    classNames?: string;
}

const ValueWithTooltip = ({
    value = 0,
    keyName,
    classNames,
}: ValueWithTooltipProps) => {
    const { formatAsUSCurrency } = useUSCurrencyFormatter();

    return (
        <OverlayTrigger
            placement="top"
            overlay={
                <Tooltip id={`tooltip-${keyName}`}>
                    {value !== 0
                        ? formatAsUSCurrency(value.toFixed(2))
                        : '$ 0.00'}
                </Tooltip>
            }
        >
            <p className={`${classNames} amount-align`}>
                <p className="dollor-sign">$</p>
                <p className="text-right">
                    {value !== 0
                        ? formatAsUSCurrency(value.toFixed(2))
                        : '0.00'}
                </p>
            </p>
        </OverlayTrigger>
    );
};

export default ValueWithTooltip;
