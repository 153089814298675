import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { TRUCK_INITIAL_STATE } from '../Constants/truckInitialState';
import { IFilter } from '../../utils/Interface/TruckInterface';

const truckSlice = createSlice({
    name: 'truck',
    initialState: { ...TRUCK_INITIAL_STATE },
    reducers: {
        setTruckFilter: (state, action: PayloadAction<IFilter>) => {
            state.filter = action.payload;
        },
        setTruckDetailsLoader: (state, action: PayloadAction<boolean>) => {
            state.loader = action.payload;
        },
    },
});

export const { setTruckFilter, setTruckDetailsLoader } = truckSlice.actions;
export const TruckReducer = truckSlice.reducer;
