export const TRUCK_INITIAL_STATE = {
    filter: {
        name: '',
        show: 'active',
    },
    createTruckInitial: {
        name: '',
        type: '',
        capacity: '',
        active: true,
        feedLotId: '',
    },
    loader: true,
};
