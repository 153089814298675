const InterestRateIcon = (props: any) => {
    return (
        <img
            {...props}
            alt="interest_rates.png"
            src={require('./interest_rates.png')}
        />
    );
};

export default InterestRateIcon;
