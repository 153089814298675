import LoginIcon from '../assets/loginLogo/LoginIcon';
import { FC, PropsWithChildren } from 'react';

interface IAuthPageContainer extends PropsWithChildren {
    showNgatLogo?: boolean;
    widthInPx?: number;
}

const AuthPageContainer: FC<IAuthPageContainer> = ({
    children,
    showNgatLogo = true,
    widthInPx,
}) => {
    return (
        <div className="login-container auth-container">
            <div
                className="login-block"
                style={widthInPx ? { width: `${widthInPx}px` } : {}}
            >
                <div className="ccc-logo text-center">
                    {showNgatLogo && <LoginIcon />}
                </div>
                {children}
            </div>
        </div>
    );
};

export default AuthPageContainer;
