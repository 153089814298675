import { penFilterByRationDefaultIds } from '../constants/penFilterByRationDefaultOptions';
import { IFeedCallPageSliceInitialState } from './interfaces';

export const feedCallPageSliceInitialState: IFeedCallPageSliceInitialState = {
    selectedPenId: undefined,
    showRationChangeReminderModal: false,
    showAFPerHeadLimitModal: false,
    isAFPerHeadLimitModalConfirmedForPen: false,
    localAssessmentValues: {
        afChangePerHd: 0,
        dmChangePerHd: 0,
        totalCall: 0,
    },
    feedCallPayload: {
        feedCallId: 0,
        penId: 0,
        hdCount: 0,
        afChangePerHd: 0,
        dmChangePerHd: 0,
        bunkScore: 0,
        totalCall: 0,
        afPerHd: 0,
        dmPerHd: 0,
        wtPerDM: 0,
        adg: 0,
        estimatedHdCount: null,
        hayFeeding1: null,
        hayFeeding2: null,
        comments: null,
        noOfFeedingsBeforeShipment: null,
        estimatedHeadTracker: undefined,
        daysOnFeed: 0,
        daysOnCurrentRation: 0,
        penRationScheduleId: 0,
        startRationName: '',
        startRationId: 0,
        startRationPercentage: 0,
        endRationName: '',
        endRationId: 0,
        endRationPercentage: 0,
        createdDateTime: '',
        dayOnFeedCall: 0,
        rationName: '',
        isScheduledRationChangeValid: false,
        rationSortOrder: 0,
        pen: undefined,
        variancePercentage: 0,
        penRationSchedule: undefined,
        fedToday: 0,
        endRation: undefined,
        startRation: undefined,
    },
    assessmentPendingConfirmation: null,
    daysOfHistoricalTableData: 'days14',
    daysOfHistoricalGraphData: 'days14',
    penFilterByRation: penFilterByRationDefaultIds.all,
    showPenRationScheduleModal: false,
    isAssessmentProcessing: false,
    shouldCallSaveAndNextPen: false,
    canRefetchData: true,
    pensList: [],
    filteredPensList: [],
};
