const TruckingCompanyIcon = (props: any) => {
    return (
        <img
            {...props}
            alt="buy_ship_po.png"
            src={require('./buy_ship_po.png')}
        />
    );
};

export default TruckingCompanyIcon;
