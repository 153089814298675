export const TRUCKING_COMPANY_INITIAL_STATE = {
    filter: {
        name: '',
        show: 'active',
    },
    createTruckingCompanyInitial: {
        name: '',
        active: true,
        feedLotId: '',
        contactNumber: '',
        altContactNumber: '',
        email: '',
        altEmail: '',
        contactName: '',
        altContactName: '',
    },
    loader: true,
};
