export const loaderConstants = {
    createNewLoaders: {
        heading: 'Create New Loader',
        form: {
            name: {
                name: 'name',
                label: 'Loader Name',
                maxLength: 50,
                placeholder: 'Enter Loader Name',
            },
        },
    },
    loaderDetails: {
        placeholder: 'Search',
        loader: 'Loader',
        heading: 'Manage Loaders',
        maxLength: 50,
    },
    loaderColumnDetail: [
        { label: 'Loader Name', key: 'name' },
        { label: 'Status', key: 'active' },
    ],
    activateModalConstant: {
        heading: 'Activate Loader',
        text: ' is currently Inactive. Activate and use this Loader.',
    },
    clearFilter: 'Clear Filter',
    editLoader: 'Edit Loader - ',
    loaderFilter: 'loaderFilter',
};
