import { FC, FormEventHandler, PropsWithChildren } from 'react';
import { Button, Form } from 'react-bootstrap';
import AuthPageContainer from './AuthPageContainer';
import SpinnerSubmitButton from '../../../../../components/common/SpinnerSubmitButton';

interface IAuthFormContainer extends PropsWithChildren {
    heading: string;
    handleSubmit: FormEventHandler<HTMLFormElement>;
    submitButtonLabel: string;
    isSubmitPending: boolean;
    isSubmitButtonDisabled: boolean;
    handleCancel: () => void;
    cancelButtonLabel: string;
}

export const AuthFormContainer: FC<IAuthFormContainer> = ({
    children,
    heading,
    handleSubmit,
    submitButtonLabel,
    isSubmitPending,
    isSubmitButtonDisabled,
    handleCancel,
    cancelButtonLabel,
}) => {
    return (
        <AuthPageContainer showNgatLogo={false} widthInPx={614}>
            <Form onSubmit={handleSubmit} className="px-4">
                <div className="px-1">
                    <h3
                        className="block-heading fw-bold mb-4"
                        style={{ fontSize: '30px' }}
                    >
                        {heading}
                    </h3>
                    {children}
                    <div className="d-flex justify-content-end mt-4 mb-1">
                        <Button
                            size="lg"
                            className="me-2 secondary"
                            onClick={handleCancel}
                        >
                            {cancelButtonLabel}
                        </Button>
                        <SpinnerSubmitButton
                            type="submit"
                            buttonContent={submitButtonLabel}
                            onClick={undefined}
                            submitPending={isSubmitPending}
                            disabled={isSubmitButtonDisabled}
                            size="lg"
                        />
                    </div>
                </div>
            </Form>
        </AuthPageContainer>
    );
};
