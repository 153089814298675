import React, { useEffect, useState, useMemo } from 'react';
import { Button, Col } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../Redux/Store';
import { useModalStatus } from '../../commonHooks/useModalStatus';
import { useFeedlotId } from '../../commonHooks/useFeedlotId';
import { useDebouncedEffect } from '../useDebounceEffect';
import {
    deleteLocation,
    fetchLocationDetails,
    updateLocationDetail,
} from '../../Redux/Actions/locationActions';
import { setLocationFilter } from '../../Redux/Reducer/locationSlice';
import { LOCATION_INITIAL_STATE } from '../../Redux/Constants/locationInitialState';
import LocationModal from './LocationModal';
import SettingsItemDetail from './FMSettings/SettingsItemDetail';
import { DeleteItemModal } from './FMSettings/DeleteItemModal';
import Constants from '../../utils/Constants';
import StorageService from '../../Services/storage/storage.service';
import { locationConstants } from '../../utils/constants/LocationDetailConstant';
import { FmSettingsConstant } from '../../utils/constants/FmSettingsConstants';
import {
    IFilter,
    ILocationDetail,
} from '../../utils/Interface/LocationInterface';

const LocationDetails = () => {
    const { filter, loader } = useAppSelector(state => state.location);
    const storage = useMemo(() => new StorageService(), []);
    const feedlotId = useFeedlotId();
    const dispatch = useAppDispatch();
    const {
        isOpen: isLocationModalOpen,
        open: openLocationModal,
        close: closeLocationModal,
    } = useModalStatus();
    const {
        isOpen: isDeleteModalOpen,
        open: openDeleteModal,
        close: closeDeleteModal,
    } = useModalStatus();
    const [searchQuery, setSearchQuery] = useState<string | undefined>(
        undefined,
    );
    const [locationDetails, setLocationDetails] = useState<ILocationDetail[]>(
        [],
    );
    const [selectedLocation, setSelectedLocation] = useState<ILocationDetail>(
        LOCATION_INITIAL_STATE.createLocationInitial,
    );

    const fetchLocationData = (locationFilter: IFilter = filter): void => {
        dispatch(fetchLocationDetails(setLocationDetails, locationFilter));
    };

    const handleEditLocationDetail = (location: ILocationDetail): void => {
        setSelectedLocation(location);
        openLocationModal();
    };

    const setLocationFilterInStorage = (filterValues: IFilter) => {
        storage.setValue(
            locationConstants.locationFilter,
            filterValues,
            Constants.Storage.LOCAL,
        );
    };

    const handleFilter = (filterName: string, filterValue: string) => {
        const updatedFilter = { ...filter, [filterName]: filterValue };
        fetchLocationData(updatedFilter);
        dispatch(setLocationFilter(updatedFilter));
        setLocationFilterInStorage(updatedFilter);
    };

    const closeModalWithChanges = (isEdited: boolean): void => {
        if (isEdited) {
            fetchLocationData(filter);
        }
        closeLocationModal();
    };

    const resetFilter = (): void => {
        const updatedFilter = LOCATION_INITIAL_STATE.filter;
        fetchLocationData(updatedFilter);
        setSearchQuery('');
        dispatch(setLocationFilter(updatedFilter));
        setLocationFilterInStorage(updatedFilter);
    };

    const handleCreateLocation = (): void => {
        if (feedlotId) {
            setSelectedLocation({
                ...LOCATION_INITIAL_STATE.createLocationInitial,
                feedLotId: feedlotId,
            });
        }
        openLocationModal();
    };

    const handleDeleteModalHandler = (location: ILocationDetail) => {
        setSelectedLocation(location);
        openDeleteModal();
    };

    const handleDeleteLocation = (shouldTakeAction: boolean) => {
        if (shouldTakeAction) {
            if (!selectedLocation.isAssociated || selectedLocation.active) {
                const isActiveAndAssociated =
                    selectedLocation.isAssociated && selectedLocation.active;
                const action = isActiveAndAssociated
                    ? updateLocationDetail
                    : deleteLocation;
                const updatedLocationDetail = isActiveAndAssociated
                    ? { ...selectedLocation, active: false }
                    : selectedLocation;
                dispatch(action(updatedLocationDetail, fetchLocationData));
            }
        }
        closeDeleteModal();
    };

    useDebouncedEffect(
        () => {
            if (searchQuery !== undefined) {
                handleFilter('name', searchQuery);
            }
        },
        [searchQuery],
        1000,
    );

    useEffect(() => {
        if (feedlotId) {
            const locationFilter = storage.getValue(
                locationConstants.locationFilter,
            );
            if (Object.keys(locationFilter).length > 0) {
                dispatch(setLocationFilter(locationFilter));
            }
            if (!locationFilter.name) {
                if (locationFilter.show) {
                    fetchLocationData(locationFilter);
                } else {
                    dispatch(fetchLocationDetails(setLocationDetails, filter));
                }
            } else {
                setSearchQuery(locationFilter?.name || filter.name);
            }
        }
    }, [feedlotId, dispatch, storage]);

    return (
        <div className="pens-container">
            <h5 className="my-3">
                {locationConstants.locationDetails.heading}
            </h5>
            <div className="d-flex align-items-end">
                <Col xs={3} className="d-flex flex-column">
                    <label>{locationConstants.locationDetails.location}</label>
                    <input
                        className="text-secondary my-2"
                        type="text"
                        placeholder={
                            locationConstants.locationDetails.placeholder
                        }
                        maxLength={locationConstants.locationDetails.maxLength}
                        value={searchQuery ? searchQuery : ''}
                        onChange={e => setSearchQuery(e.target.value)}
                    />
                </Col>
                <Col xs={3}>
                    <Button
                        className="secondary my-2 mx-4"
                        onClick={resetFilter}
                    >
                        {locationConstants.clearFilter}
                    </Button>
                </Col>
                <Col xs={6} className="d-flex justify-content-end">
                    <Button className="my-2" onClick={handleCreateLocation}>
                        {locationConstants.createNewLocations.heading}
                    </Button>
                </Col>
            </div>
            <div
                className="o-switch btn-group show-toggle my-2"
                data-toggle="buttons"
                role="group"
            >
                {FmSettingsConstant.filterOptions.map(option => (
                    <label
                        key={option.value}
                        className={`btn btn-secondary py-1 ${
                            filter.show === option.value
                                ? FmSettingsConstant.active.value
                                : ''
                        }`}
                    >
                        <input
                            type="radio"
                            onChange={() =>
                                handleFilter(option.name, option.value)
                            }
                            checked={filter.show === option.value}
                        />
                        {option.label}
                    </label>
                ))}
            </div>
            <SettingsItemDetail<ILocationDetail>
                columnDetail={locationConstants.locationColumnDetail}
                itemDetail={locationDetails}
                handleEditDetail={handleEditLocationDetail}
                handleDeleteModalHandler={handleDeleteModalHandler}
                loader={loader}
                columnSize={2}
            />
            {isLocationModalOpen && (
                <LocationModal
                    showModal={isLocationModalOpen}
                    closeModalWithChanges={closeModalWithChanges}
                    editLocationDetails={selectedLocation}
                    showActiveField={true}
                />
            )}
            {isDeleteModalOpen && (
                <DeleteItemModal
                    item={selectedLocation}
                    handleDeleteItem={handleDeleteLocation}
                    showModal={isDeleteModalOpen}
                    itemName={locationConstants.locationDetails.name}
                ></DeleteItemModal>
            )}
        </div>
    );
};

export default LocationDetails;
