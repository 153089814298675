const StatementsIcon = (props: any) => {
    return (
        <img
            {...props}
            alt="feed_bills.png"
            src={require('./feed_bills.png')}
        />
    );
};

export default StatementsIcon;
