/* eslint-disable no-restricted-syntax */ // mutes warning for existence of type Date in this file. Type Date will exist in this file until it is deleted as all these methods are deprecated.
import { ChangeEvent } from 'react';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import moment from 'moment';
import dayjs from '../utils/dayjs';

/**
 * @deprecated Prefer the dayjs formatDate available in the dateTime helpers.
 */

export const formatDate_deprecated = (
    date: moment.MomentInput,
    dateFormat = 'MM-DD-YYYY',
) => {
    if (!date) return '';
    return moment(date).format(dateFormat);
};

export const setTimeZero_deprecated = (date: moment.MomentInput) => {
    return date
        ? moment(date).startOf('day').format('YYYY-MM-DDTHH:mm:ss')
        : '';
};

export const modifyDate_deprecated = (
    date: moment.MomentInput,
    year: number,
    options: { month?: number; dateFormat?: string } = {
        dateFormat: 'YYYY-MM-DD',
    },
) => {
    if (!date) return '';

    let updatedMoment = moment.utc(date).year(year);

    if (options?.month !== undefined && options.month !== null) {
        updatedMoment = moment(updatedMoment).month(options.month);
    }
    const dateFormat = options?.dateFormat;
    return updatedMoment.format(dateFormat);
};

export const addDurationInDate_deprecated = (
    date: moment.MomentInput,
    dateFormat = 'YYYY-MM-DD',
    days: string | number,
    durationType: moment.unitOfTime.DurationConstructor,
) => {
    if (!date) return '';
    if (!date) return '';
    return moment(date).add(days, durationType).format(dateFormat);
};

export const removeTimeZone_deprecated = (date: string | Date) => {
    if (!date) return '';
    date = date.toString();
    return date.split('T')[0];
};

export const addTimeZone_deprecated = (date: string | Date) => {
    if (!date) return '';
    return `${date}Z`;
};

export const getTimeAccordingToZone_deprecated = (date: string | Date) => {
    if (!date) return '';
    const timeZone = addTimeZone_deprecated(date);
    const parsedDate = dayjs(timeZone);
    const formattedTime = parsedDate.format('HH:mm:ss');
    return formattedTime;
};

/**
 * @deprecated Prefer the dayjs currentDate available in the dateTime helpers.
 */

export const currentDate_deprecated = (dateFormat = 'MM-DD-YYYY') => {
    return moment().format(dateFormat);
};

export const sliceDate_deprecated = (date?: string) => {
    if (!date) return '';

    if (date) {
        return date.slice(0, 5);
    }
};

export const formatTime_deprecated = (
    date: string | Date = new Date(),
    dateFormat = 'HH:mm',
) => {
    if (!date) return '';
    return moment(date).format(dateFormat);
};

export const dateDifference_deprecated = (
    date1: string | Date,
    date2: string | Date,
    type: moment.unitOfTime.Diff = 'days',
) => {
    const firstDate = moment(new Date(date1));
    const secondDate = moment(new Date(date2));

    return firstDate.diff(secondDate, type);
};

export const formatSecondIntoUtc_deprecated = sec => {
    return moment.utc(sec * 1000).format('HH:mm:ss');
};

/**
 * @deprecated Prefer the dayjs thisDateWithinCutoff available in the dateTime helpers.
 */

export const thisDateWithinCutoff_deprecated = (
    thisDate: moment.MomentInput,
    cutoffDate: moment.MomentInput,
) => {
    const formattedCutoffDate = formatDate_deprecated(cutoffDate, 'YYYY-MM-DD');
    const formattedRecordDate = formatDate_deprecated(thisDate, 'YYYY-MM-DD');
    const formattedCompare =
        moment(formattedRecordDate).isSameOrBefore(formattedCutoffDate);
    return formattedCompare;
};

export const getFirstAllowedDateBasedOnCutoff_deprecated = cutoffDate => {
    const firstAllowedDateBasedOnCutoff = formatDate_deprecated(
        new Date(cutoffDate).setDate(new Date(cutoffDate).getUTCDate() + 1),
        'YYYY-MM-DD',
    );
    return firstAllowedDateBasedOnCutoff;
};

export const genericChangeDateHandler_deprecated = (
    { target }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    firstAllowedDate: string,
    callbackIfDateAllowed: () => void,
) => {
    if (
        moment(target.value).isSameOrAfter(firstAllowedDate) ||
        target.value === '' ||
        !firstAllowedDate
    ) {
        callbackIfDateAllowed();
    }
};
