import { useAppSelector } from './../Redux/Store/index';
import { useEffect, useState } from 'react';
import Constants from '../utils/Constants';

const useLanguage = () => {
    const [language, setLanguage] = useState({ ...Constants.Languages[0] });
    const { selectedLanguage } = useAppSelector(state => state.common);

    useEffect(() => {
        setLanguage(selectedLanguage);
    }, [selectedLanguage]);

    return language;
};

export { useLanguage };
