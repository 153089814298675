import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { TRUCKING_COMPANY_INITIAL_STATE } from '../Constants/truckingCompanyInitialState';
import { IFilter } from '../../utils/Interface/TruckingCompanyInterface';

const truckingCompanySlice = createSlice({
    name: 'truckingCompany',
    initialState: { ...TRUCKING_COMPANY_INITIAL_STATE },
    reducers: {
        setTruckingCompanyFilter: (state, action: PayloadAction<IFilter>) => {
            state.filter = action.payload;
        },
        setTruckingCompanyDetailsLoader: (
            state,
            action: PayloadAction<boolean>,
        ) => {
            state.loader = action.payload;
        },
    },
});

export const { setTruckingCompanyFilter, setTruckingCompanyDetailsLoader } =
    truckingCompanySlice.actions;
export const TruckingCompanySlice = truckingCompanySlice.reducer;
