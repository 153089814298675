import { Breadcrumb } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { firstLetterUpper } from '../helpers';
import Constants from './Constants';
import { useOwnerContext } from '../Context/OwnerContext/OwnerContext';
import { financeManagerFeatures } from '../apps/financeManager/featureFlags/financeManagerFeatures';
import { useAppDispatch, useAppSelector } from '../Redux/Store';
import { setRoute, setShowModal } from '../Redux/Reducer/feedBillUpdate';

const BreadCrumbs = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { selectedOwner } = useOwnerContext();
    const { isFA536UiOptimization } = financeManagerFeatures;
    const pathnames = pathname.split('/').filter(x => x);
    const routePath = pathnames?.filter(element => isNaN(+element));
    const routePathValues = routePath?.map(
        element => Constants.headerConstants[element.toLowerCase()],
    );
    const { lastUpdate } = useAppSelector(state => state.feedBillUpdate);
    const dispatch = useAppDispatch();

    const getFirstName = (index: number, name?: string) => {
        if (name === undefined) {
            if (isFA536UiOptimization) {
                return (
                    routePath[index - 1] === 'editFeedBill' &&
                    firstLetterUpper('Feed Bill')
                );
            } else {
                return routePath[index - 1] === 'editFeedBill'
                    ? firstLetterUpper('Feed Bill')
                    : firstLetterUpper(routePath[index - 1]);
            }
        } else {
            return firstLetterUpper(name);
        }
    };

    const handleNavigate = (name: string) => {
        if (name === 'Feed Bills') {
            navigate('feedbills');
        } else {
            navigate(name.replaceAll(' ', ''));
        }
    };

    const handleHomeClick = () => {
        if (lastUpdate) {
            dispatch(setShowModal(true));
            dispatch(setRoute('/'));
        } else {
            navigate('/');
        }
    };

    return (
        <>
            {!(
                (pathname === '/lots' && selectedOwner.name !== 'All') ||
                false
            ) && (
                <Breadcrumb className="breadcrumb">
                    <Breadcrumb.Item onClick={handleHomeClick}>
                        Home
                    </Breadcrumb.Item>
                    {routePathValues?.map((name, index) => {
                        if (name === undefined) return null;

                        const isLast = index === routePathValues.length - 1;
                        const isSecondToLast =
                            index === routePathValues.length - 2;
                        const isLastItemUndefined =
                            routePathValues[routePathValues.length - 1] ===
                            undefined;

                        if (isLast || (isSecondToLast && isLastItemUndefined)) {
                            return (
                                <Breadcrumb.Item active={isLast} key={index}>
                                    {getFirstName(index, name)}
                                </Breadcrumb.Item>
                            );
                        } else {
                            return (
                                <Breadcrumb.Item
                                    key={index}
                                    onClick={() => handleNavigate(name)}
                                >
                                    {firstLetterUpper(name)}
                                </Breadcrumb.Item>
                            );
                        }
                    })}
                </Breadcrumb>
            )}
        </>
    );
};

export default BreadCrumbs;
