import { Link } from 'react-router-dom';
import SidebarLogoBuyPo from '../../Assets/sidebarlogos/SidebarLogoBuyPo';
import useAuthContext from '../../apps/common/modules/auth/hooks/useAuthContext';
import {
    SideNavItems,
    excludedSideNavItems,
} from '../../utils/constants/RoleConstants';
import LogInLogOutComponent from './LogInLogOutComponent';

export default function MobileNav() {
    const { getUsersRoles } = useAuthContext();
    const usersRoles = getUsersRoles();
    const buyPoMobileSideNavItems =
        SideNavItems?.buyPoSideNavItem?.subBuyPoItemsPrimary?.filter(
            item => !excludedSideNavItems.includes(item.label),
        );

    const buyPoMobileSideNav = () =>
        buyPoMobileSideNavItems.map((item, index) => {
            let component: JSX.Element | null = null;
            for (let i = 0; i < item.roles.length; i++) {
                if (usersRoles.includes(item.roles[i])) {
                    component = (
                        <li key={index} className="nav-item">
                            <Link to={item.redirectUrl} className="nav-link">
                                {item.label}
                            </Link>
                        </li>
                    );
                    break;
                }
            }
            return component;
        });

    return (
        <nav className="navbar mobile-top-navigation d-md-none">
            <div className="w-100">
                <div className="d-flex justify-content-between align-items-center">
                    <SidebarLogoBuyPo />
                    <div className="d-flex align-items-center">
                        <button
                            className="navbar-toggler"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapsibleNavbar"
                        >
                            <span className="navbar-toggler-icon">
                                <i className="fas fa-bars"></i>
                            </span>
                        </button>
                    </div>
                </div>
                <div
                    className="collapse navbar-collapse"
                    id="collapsibleNavbar"
                >
                    <ul className="navbar-nav">
                        {buyPoMobileSideNav()}
                        <LogInLogOutComponent />
                    </ul>
                </div>
            </div>
        </nav>
    );
}
