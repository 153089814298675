import { Button, Modal } from 'react-bootstrap';
import { IActivateItemModal } from '../interface/BuyPoInterface';
import { BuyPOGlobalInfoConstants } from '../constants/BuyPoConstants';

export const ActivateItemModal = ({
    showModal,
    closeModalHandler,
    itemName,
    item,
}: IActivateItemModal) => {
    return (
        <Modal
            className="px-2 pt-5 activate-modal"
            size="lg"
            show={showModal}
            centered
            animation
            onHide={() => closeModalHandler(false)}
        >
            <Modal.Header closeButton className="border-bottom-0">
                <Modal.Title className="text-dark">{item.heading}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    {itemName}
                    {item.text}
                </div>
            </Modal.Body>
            <Modal.Footer className="border-top-0">
                <Button
                    variant="secondary"
                    className="secondary"
                    onClick={() => closeModalHandler(false)}
                >
                    {BuyPOGlobalInfoConstants.cancel}
                </Button>
                <Button
                    variant="secondary"
                    onClick={() => closeModalHandler(true)}
                >
                    {BuyPOGlobalInfoConstants.activate}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
