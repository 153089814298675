import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const updateFeedBill = createSlice({
    name: 'updated',
    initialState: {
        lastUpdate: false,
        showModal: false,
        route: '',
    },
    reducers: {
        setLastUpdate: (state, action: PayloadAction<boolean>) => {
            state.lastUpdate = action.payload;
        },
        setShowModal: (state, action: PayloadAction<boolean>) => {
            state.showModal = action.payload;
        },
        setRoute: (state, action: PayloadAction<string>) => {
            state.route = action.payload;
        },
    },
});

export const { setLastUpdate, setShowModal, setRoute } = updateFeedBill.actions;
export default updateFeedBill.reducer;
