import { useCallback, useEffect, useMemo, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import useAuthContext from '../../apps/common/modules/auth/hooks/useAuthContext';
import { apiCall } from '../../Services/AxiosService';
import StorageService from '../../Services/storage/storage.service';
import Constants from '../../utils/Constants';
import { INavFeedlot } from '../../utils/Interface/NavInterface';
import { useAppDispatch, useAppSelector } from '../../Redux/Store';
import {
    setFeedlotToUpdate,
    setSelectedFeedlot,
} from '../../Redux/Reducer/common';
import { FeedlotManagerModelsResponseModelsFeedlotApiResponse } from '../../Redux/Apis/FMFeedlot/baseFMFeedlotApi';
import { commonFeatures } from '../../apps/common/featureFlags/commonFeatures';
import { authActions } from '../../apps/common/modules/auth/authSlice';

const { isCA9685_localAuthLoginScreen } = commonFeatures;

export default function FeedlotDropdown() {
    const dispatch = useAppDispatch();
    const [usersFeedlots, setUsersFeedlots] = useState<
        Required<FeedlotManagerModelsResponseModelsFeedlotApiResponse>[]
    >([]);
    const { selectedFeedlot } = useAppSelector(state => state.common);
    const storage = useMemo(() => new StorageService(), []);
    const { getUsersFeedlots, getAccessToken } = useAuthContext();

    const updateSelectedFeedlot = useCallback(
        async (
            feedlotDetail: Required<FeedlotManagerModelsResponseModelsFeedlotApiResponse>,
            newFeedlotSelected = false,
        ) => {
            storage.setValue(
                Constants.StorageItems.SELECTED_FEEDLOT,
                feedlotDetail,
                Constants.Storage.LOCAL,
            );
            if (newFeedlotSelected) {
                window.location.reload();
            } else {
                dispatch(setFeedlotToUpdate(feedlotDetail));
            }
        },
        [dispatch, storage],
    );

    const updateSelectedFeedlot_deprecated = useCallback(
        (
            feedlotDetail: Required<FeedlotManagerModelsResponseModelsFeedlotApiResponse>,
        ) => {
            dispatch(setSelectedFeedlot(feedlotDetail));
            storage.setValue(
                Constants.StorageItems.SELECTED_FEEDLOT,
                feedlotDetail,
                Constants.Storage.LOCAL,
            );
        },
        [dispatch, storage],
    );

    const updateSelectedFeedlotForUseEffect =
        isCA9685_localAuthLoginScreen ||
        localStorage.getItem(Constants.StorageItems.AUTH_TYPE) ===
            Constants.AuthType.LOCAL
            ? updateSelectedFeedlot
            : updateSelectedFeedlot_deprecated;

    useEffect(() => {
        const updateFeedlotDropdownAndSelection = async () => {
            dispatch(authActions.setAccessToken(getAccessToken()));
            const allFeedlots: Required<FeedlotManagerModelsResponseModelsFeedlotApiResponse>[] =
                await apiCall(
                    'get',
                    `${Constants.apiUrls.FEED_LOT_MANAGER}${Constants.apiUrls.FM_FEEDLOT}`,
                );

            const usersFeedlots = getUsersFeedlots(allFeedlots).sort((a, b) => {
                if (a.label && b.label) {
                    return a.label > b.label ? 1 : -1;
                } else {
                    return 0;
                }
            });
            setUsersFeedlots(usersFeedlots);

            const selectedFeedlotFromStorage: INavFeedlot = storage.getValue(
                Constants.StorageItems.SELECTED_FEEDLOT,
            );
            const matchingFeedlot = usersFeedlots.find(
                usersFeedlot =>
                    usersFeedlot.id === selectedFeedlotFromStorage?.id,
            );
            await updateSelectedFeedlotForUseEffect(
                matchingFeedlot ?? usersFeedlots?.[0] ?? {},
            );
        };
        updateFeedlotDropdownAndSelection();
    }, [
        dispatch,
        getAccessToken,
        getUsersFeedlots,
        storage,
        updateSelectedFeedlotForUseEffect,
    ]);

    return (
        <div className="mx-3">
            <Dropdown className="top-header-dropdown">
                {usersFeedlots.length > 1 ? (
                    <>
                        <Dropdown.Toggle className="p-0 d-flex align-items-center">
                            {selectedFeedlot?.labelColorHex && (
                                <div
                                    className="colored-circle mb-1 me-2"
                                    style={{
                                        backgroundColor:
                                            '#' +
                                            selectedFeedlot?.labelColorHex,
                                    }}
                                ></div>
                            )}
                            {selectedFeedlot.label || ''}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {usersFeedlots.map((feedlot, index) => (
                                <Dropdown.Item
                                    key={feedlot.id}
                                    onClick={async () => {
                                        isCA9685_localAuthLoginScreen
                                            ? await updateSelectedFeedlot(
                                                  feedlot,
                                                  true,
                                              )
                                            : updateSelectedFeedlot_deprecated(
                                                  feedlot,
                                              );
                                    }}
                                    className="p-0 px-1 d-flex align-items-center"
                                >
                                    {feedlot.labelColorHex ? (
                                        <div
                                            className="colored-circle mb-1 me-2"
                                            style={{
                                                backgroundColor:
                                                    '#' + feedlot.labelColorHex,
                                            }}
                                        ></div>
                                    ) : (
                                        <div className="ms-4"></div>
                                    )}
                                    {feedlot.label}
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </>
                ) : (
                    <Dropdown.Toggle
                        className="cursor-default p-0 d-flex align-items-center"
                        bsPrefix="p-0"
                    >
                        {selectedFeedlot?.labelColorHex && (
                            <div
                                className="colored-circle mb-1 me-1"
                                style={{
                                    backgroundColor:
                                        '#' + selectedFeedlot?.labelColorHex,
                                }}
                            ></div>
                        )}
                        {selectedFeedlot.label || ''}
                    </Dropdown.Toggle>
                )}
            </Dropdown>
        </div>
    );
}
