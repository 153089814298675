export const techConstants = {
    createNewTechs: {
        heading: 'Create Tech',
        form: {
            name: {
                name: 'name',
                label: 'Tech Name',
                maxLength: 50,
                placeholder: 'Enter Tech Name',
            },
        },
    },
    techDetails: {
        placeholder: 'Search',
        tech: 'Tech',
        heading: 'Manage Techs',
        maxLength: 50,
        name: 'Tech',
    },
    techColumnDetail: [
        { label: 'Tech Name', key: 'name' },
        { label: 'Status', key: 'active' },
    ],
    clearFilter: 'Clear Filter',
    editTech: 'Edit Tech - ',
    activateModalConstant: {
        heading: 'Activate Tech',
        text: ' is currently Inactive. Activate and use this Tech.',
    },
    techFilter: 'techFilter',
};
