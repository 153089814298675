export const RIDER_INITIAL_STATE = {
    filter: {
        name: '',
        show: 'active',
    },
    createRiderInitial: {
        name: '',
        active: true,
        feedLotId: '',
    },
    loader: true,
};
