const EditIcon = (props: any) => {
    return (
        <img
            className="cursor-pointer"
            {...props}
            alt="edit.png"
            src={require('./edit.png')}
        />
    );
};

export default EditIcon;
