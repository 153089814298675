import { IStaticPenRationSchedule, IVariableSplitRationSchedule } from '../utils/Interface/PenRationScheduleInterface';
import { IEditPenRationTemplate } from '../utils/Interface/PenRationTemplatesInterface';
import { addDurationInDate_deprecated } from '.';
import { feedlotManagerFeatures } from '../apps/feedlotManager/featureFlags/feedlotManagerFeatures';
import dayjs from '../utils/dayjs';

const { isCA9537On_PenRationScheduleRowDateFix } = feedlotManagerFeatures;

export const fetchRationScheduleDays = (rationSchedulesDays: number) => {
    return rationSchedulesDays;
};

export const handleDateAccordingToDays = (
    penRationSchedulesCopy:
        | IStaticPenRationSchedule[]
        | IEditPenRationTemplate[]
        | IVariableSplitRationSchedule[]
) => {
    penRationSchedulesCopy?.map((_penRationSchedules, index) => {
        if (index) {
            return (penRationSchedulesCopy[index]['date'] =
                addDurationInDate_deprecated(
                    penRationSchedulesCopy[index - 1]['date'],
                    'YYYY-MM-DD',
                    fetchRationScheduleDays(
                        +penRationSchedulesCopy[index - 1]['days'],
                    ),
                    'days',
                ));
        }
    });
};

export const rationSchedulesPushHandler = (
    penRationSchedulesCopy:
        | IStaticPenRationSchedule[]
        | IEditPenRationTemplate[]
        | IVariableSplitRationSchedule[],
    rowIndex: number,
) => {
    if (isCA9537On_PenRationScheduleRowDateFix) {
        penRationSchedulesCopy?.forEach((_penRationSchedules, index) => {
            if (index >= rowIndex) {
                penRationSchedulesCopy[index]['date'] = dayjs(
                    penRationSchedulesCopy[index - 1]['date'],
                )
                    .add(
                        fetchRationScheduleDays(
                            +penRationSchedulesCopy[index - 1]['days'],
                        ),
                        'day',
                    )
                    .format('YYYY-MM-DD');
            }
        });
    } else {
        penRationSchedulesCopy[penRationSchedulesCopy?.length - 1]['date'] =
            addDurationInDate_deprecated(
                penRationSchedulesCopy[penRationSchedulesCopy?.length - 2][
                    'date'
                ],
                'YYYY-MM-DD',
                fetchRationScheduleDays(
                    +penRationSchedulesCopy[penRationSchedulesCopy?.length - 2][
                        'days'
                    ],
                ),
                'days',
            );
    }
};
