export enum FeedCallTabOrder {
    bunkScoreDropdown = 1,
    dmChangePerHd = 2,
    afChangePerHd = 3,
    totalCall = 4,
    saveAndNextPen = 5,
    saveAndPreviousPen = 6,
}
export const saveAndPreviousPenId = 'feedCallV2-saveAndPreviousPen';
export const bunkScoreDropdownId = 'feedCallV2-bunkScoreDropdown';
