import { apiCall_v2 } from '../../Services/AxiosService';
import Constants from '../../utils/Constants';
import {
    ICreateLoader,
    IFetchLoaderDetails,
    IUpdateLoaderDetail,
    IFilter,
    IUpdateLoaderDetailOptions,
    IDeleteLoader,
} from '../../utils/Interface/LoaderInterface';
import { getToastContent } from '../../utils/ToastContent';
import { toastMessage } from '../Reducer/common';
import { setLoaderDetailsLoader } from '../Reducer/loaderSlice';
import { AppDispatch } from '../Store';

const getFilterUrl = (filter: IFilter) => {
    let filterUrl = `&filter=contains(Name, '${filter.name}')`;
    if (filter?.show && filter?.show !== 'all') {
        const isActive = filter.show === 'active';
        filterUrl += ` and active eq ${isActive}`;
    }
    return filterUrl;
};

export const fetchLoaderDetails: IFetchLoaderDetails =
    (feedlotId, setLoaderDetail, filter) => async (dispatch: AppDispatch) => {
        dispatch(setLoaderDetailsLoader(true));
        const filterUrl = filter ? getFilterUrl(filter) : '';
        const response = await apiCall_v2({
            method: 'get',
            url: `${Constants.apiUrls.FEED_LOT_MANAGER}/${feedlotId}${Constants.apiUrls.LOADER}/odata?flid=${feedlotId}${filterUrl}&orderBy=Name`,
        });
        setLoaderDetail(response ?? []);
        dispatch(setLoaderDetailsLoader(false));
    };

export const updateLoaderDetail: IUpdateLoaderDetail =
    (feedlotId, loaderDetail, options?: IUpdateLoaderDetailOptions) =>
    async (dispatch: AppDispatch) => {
        options?.setLoading?.(true);
        const response = await apiCall_v2({
            method: 'put',
            url: `${Constants.apiUrls.FEED_LOT_MANAGER}/${feedlotId}${Constants.apiUrls.LOADER}/${loaderDetail.loaderId}`,
            payload: loaderDetail,
            isResRequired: true,
        });

        options?.setLoading?.(false);
        if (!response) return;
        const status = Number(response.status);

        if (status === Constants.responseCode.DUPLICATE) {
            dispatch(
                toastMessage(
                    getToastContent(
                        response.status,
                        '',
                        Constants.customMessage.LOADER_DUPLICATE,
                    ),
                ),
            );
        } else if (status === Constants.responseCode.CONFLICT) {
            options?.handleActivateLoader?.();
        } else {
            dispatch(toastMessage(getToastContent(response.status)));
            options?.closeModalWithChanges?.(true, loaderDetail.loaderId);
        }
        options?.fetchLoaderData?.();
    };

export const createLoader: ICreateLoader =
    (
        feedlotId,
        loader,
        closeModalWithChanges,
        setLoading,
        handleActivateLoader,
    ) =>
    async (dispatch: AppDispatch) => {
        setLoading(true);
        const response = await apiCall_v2({
            method: 'post',
            url: `${Constants.apiUrls.FEED_LOT_MANAGER}/${feedlotId}${Constants.apiUrls.LOADER}`,
            payload: loader,
            isResRequired: true,
        });
        setLoading(false);
        if (!response) return;
        const status = Number(response.status);

        if (status === Constants.responseCode.DUPLICATE) {
            dispatch(
                toastMessage(
                    getToastContent(
                        status,
                        '',
                        Constants.customMessage.LOADER_DUPLICATE,
                    ),
                ),
            );
        } else if (status === Constants.responseCode.CONFLICT) {
            handleActivateLoader();
        } else {
            dispatch(toastMessage(getToastContent(status)));
            closeModalWithChanges(true, response.data);
        }
    };

export const fetchInactiveLoaderInfo =
    (feedlotId: number, loader: string) => async (dispatch: AppDispatch) => {
        const response = await apiCall_v2({
            method: 'get',
            url: `${Constants.apiUrls.FEED_LOT_MANAGER}/${feedlotId}${Constants.apiUrls.LOADER}/odata?filter=name eq '${loader}'`,
            isResRequired: true,
        });

        if (!response) return;
        const status = Number(response.status);

        if (
            response.status >= Constants.responseCode.SUCCESS &&
            response.status < Constants.responseCode.MULTIPLE_CHOICES
        ) {
            return response.data;
        } else {
            dispatch(toastMessage(getToastContent(status)));
        }
    };

export const deleteLoader: IDeleteLoader =
    (feedlotId, loader, options?: IUpdateLoaderDetailOptions) =>
    async (dispatch: AppDispatch) => {
        dispatch(setLoaderDetailsLoader(true));
        const response = await apiCall_v2({
            method: 'delete',
            url: `${Constants.apiUrls.FEED_LOT_MANAGER}/${feedlotId}${Constants.apiUrls.LOADER}/${loader.loaderId}`,
            payload: loader,
            isResRequired: true,
        });
        dispatch(setLoaderDetailsLoader(false));
        if (!response) return;
        dispatch(toastMessage(getToastContent(Number(response.status))));
        options?.fetchLoaderData?.();
    };
