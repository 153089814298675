export const truckConstants = {
    createNewTrucks: {
        heading: 'Create New Truck',
        form: {
            name: {
                name: 'name',
                label: 'Truck',
                maxLength: 50,
                placeholder: 'Enter Truck Name',
            },
            capacity: {
                name: 'capacity',
                label: 'Capacity (Lbs)',
                maxLength: 50,
                placeholder: 'Enter Capacity',
            },
            truckType: {
                name: 'name',
                label: 'Truck Type',
                maxLength: 50,
                placeholder: 'Select Truck Type',
                options: [
                    { id: 0, name: 'Truck' },
                    { id: 1, name: 'MixerTruck' },
                ],
            },
            cancel: 'Cancel',
            saveTrucks: 'Save Trucks',
        },
    },
    truckDetails: {
        placeholder: 'Search',
        maxLength: 50,
        truck: 'Truck',
        type: 'Type',
        capacity: 'Capacity',
        heading: 'Manage Trucks',
        name: 'truck',
    },
    truckColumnDetail: [
        { label: 'Truck', key: 'name' },
        { label: 'Status', key: 'active' },
        { label: 'Type', key: 'type' },
        { label: 'Capacity (Lbs)', key: 'capacity' },
    ],
    initialTruckDetailsError: {
        name: false,
        type: false,
        capacity: false,
    },
    activateModalConstant: {
        heading: 'Activate Truck',
        text: ' is currently Inactive. Activate and use this Truck.',
    },
    createTrucks: 'Create New Truck',
    clearFilter: 'Clear Filter',
    editTruck: 'Edit Truck - ',
    truckFilter: 'truckFilter',
};
