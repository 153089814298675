import { ActionTypes } from '../Actions';
import { DIAGNOSIS_INITIAL_STATE } from '../Constants/diagnosisInitialState';

const DiagnosisReducer = (state = DIAGNOSIS_INITIAL_STATE, action: any) => {
    switch (action.type) {
        case ActionTypes.SET_DIAGNOSIS:
            return {
                ...state,
                diagnosis: action.payload,
            };
        case ActionTypes.SET_DIAGNOSES:
            return {
                ...state,
                diagnoses: action.payload,
            };
        default:
            return state;
    }
};

export default DiagnosisReducer;
