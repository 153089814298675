import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { MIXING_BIN_INITIAL_STATE } from '../Constants/mixingBinInitialState';
import { IFilter } from '../../utils/Interface/MixingBinInterface';

const mixingBinSlice = createSlice({
    name: 'mixingBin',
    initialState: { ...MIXING_BIN_INITIAL_STATE },
    reducers: {
        setMixingBinFilter: (state, action: PayloadAction<IFilter>) => {
            state.filter = action.payload;
        },
        setMixingBinDetailsLoader: (state, action: PayloadAction<boolean>) => {
            state.loader = action.payload;
        },
    },
});

export const { setMixingBinFilter, setMixingBinDetailsLoader } =
    mixingBinSlice.actions;
export const MixingBinReducer = mixingBinSlice.reducer;
