import { Spinner } from 'react-bootstrap';
import { feedlotManagerFeatures } from '../../apps/feedlotManager/featureFlags/feedlotManagerFeatures';

const { isCA12130On_ProtectButtonClicksRTKQ } = feedlotManagerFeatures;

export default function FullPageLoader(props: {
    className?: string;
    newClass?: string;
}) {
    return (
        <div
            className={`mainLoading d-flex flex-column justify-content-center align-items-center ${
                props.newClass || ''
            }`}
            {...props}
        >
            <div>
                <Spinner
                    animation="border"
                    data-size="lg"
                    className="text-center"
                />
            </div>
            {isCA12130On_ProtectButtonClicksRTKQ ? 'Loading...' : ''}
        </div>
    );
}
