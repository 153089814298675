import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LOT_SUMMARY_INITIAL_STATE } from '../Constants/lotSummaryInitialState';
import { LotSummaryTab } from '../../utils/Interface/LotSummaryInterface';
import { isValidTab } from '../../helpers';

const lotSlice = createSlice({
    name: 'lot',
    initialState: LOT_SUMMARY_INITIAL_STATE,
    reducers: {
        setMovementHistoryDetail: (state, action) => {
            state.movementHistory = action.payload;
        },
        setHeadCountHistoryDetail: (state, action) => {
            state.headCountHistory = action.payload;
        },
        appendHeadCountHistoryDetail: (state, action) => {
            state.headCountHistory = [
                ...state.headCountHistory,
                ...action.payload,
            ];
        },
        setPensDetail: (state, action) => {
            state.pens = action.payload;
        },
        setPensTableLoading: (state, action) => {
            state.isPensTableLoading = action.payload;
        },
        appendPensDetail: (state, action) => {
            state.pens = [...state.pens, action.payload].flat();
        },
        setLotTreatments: (state, action) => {
            state.lotTreatments = action.payload;
        },
        appendLotTreatments: (state, action) => {
            state.lotTreatments = [...state.lotTreatments, ...action.payload];
        },
        setLotProcessing: (state, action) => {
            state.lotProcessing = action.payload;
        },
        appendLotProcessing: (state, action) => {
            state.lotProcessing = [...state.lotProcessing, ...action.payload];
        },
        setProcessingChargeouts: (state, action) => {
            state.processingChargeouts = action.payload;
        },
        setInvoicesDetail: (state, action) => {
            state.invoices = action.payload;
        },
        setDailyChargesDetail: (state, action) => {
            state.dailyCharge = action.payload;
        },
        setLotSummaryDetail: (state, action) => {
            state.lotSummary = action.payload;
        },
        appendLotSummaryDetail: (state, action) => {
            state.lotSummary = [...state.lotSummary, ...action.payload];
        },
        setGeneralSummaryDetail: (state, action) => {
            state.generalSummary = action.payload;
        },
        setCurrentNavTab: (state, action) => {
            state.currentNavTab = action.payload;
        },
        setSelectedNavTab: (state, action: PayloadAction<LotSummaryTab>) => {
            const currentTab = action.payload;
            const activeTab = isValidTab(currentTab, LotSummaryTab)
                ? currentTab
                : LotSummaryTab.ReceivingAndShippingHistory;
            state.selectedNavTab = activeTab;
        },
        setLotSummaryLoading: (state, action) => {
            state.isLotSummaryLoading = action.payload;
        },
        setLotDailyCharges: (state, action) => {
            state.lotDailyCharges = action.payload;
        },
        setLotExcelCharges: (state, action) => {
            state.lotExcelCharges = action.payload;
        },
        setIsDailyChargesLoading: (state, action) => {
            state.isDailyChargesLoading = action.payload;
        },
        setIsExcelChargesLoading: (state, action) => {
            state.isExcelDataLoading = action.payload;
        },
        setSexTypes: (state, action) => {
            state.sexTypes = action.payload;
        },
        setCattleTypes: (state, action) => {
            state.cattleTypes = action.payload;
        },
        setLotSummaryLot: (state, action) => {
            state.lotSummaryLot = action.payload;
        },
        setLotsPastInvoices: (state, action) => {
            state.lotsPastInvoices = action.payload;
        },
        setIsLotsInvoiceLoading: (state, action) => {
            state.isLotsInvoiceLoading = action.payload;
        },
        setLotNames: (state, action) => {
            state.lotNames = action.payload;
        },
        setTotalHeadCount: (state, action) => {
            state.totalHeadCount = action.payload;
        },
    },
});

export const lotActions = lotSlice.actions;
export const lotReducer = lotSlice.reducer;
