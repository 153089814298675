export const destinationConstants = {
    createNewDestinations: {
        heading: 'Create Destination',
        form: {
            name: {
                name: 'name',
                label: 'Destination Name',
                maxLength: 50,
                placeholder: 'Enter Destination Name',
            },
            address1: {
                name: 'address1',
                label: 'Address Line 1',
                maxLength: 50,
            },
            address2: {
                name: 'address2',
                label: 'Address Line 2',
                maxLength: 50,
            },
            coordinates: {
                name: 'coordinates',
                label: 'GPS Coordinates',
                maxLength: 50,
                placeholder: 'EX: +00.0000,-00.0000',
            },
            country: {
                name: 'country',
                label: 'Country',
                maxLength: 50,
                defaultValue: 'US',
            },
            state: {
                name: 'state',
                label: 'State',
            },
            zipCode: {
                name: 'postalCode',
                label: 'Zip Code',
                maxLength: 10,
                placeholder: '98786 or 98768-9876',
            },
            city: {
                name: 'city',
                label: 'City',
                maxLength: 50,
            },
            contactName: {
                name: 'contactName',
                label: 'Manager Name',
                maxLength: 50,
                placeholder: '',
            },
            email: {
                name: 'email',
                label: 'Email 1',
                maxLength: 50,
                placeholder: '',
            },
            altEmail: {
                name: 'altEmail',
                label: 'Email 2',
                maxLength: 50,
                placeholder: '',
            },
            contactNumber: {
                name: 'contactNumber',
                label: 'Phone Number 1',
                maxLength: 50,
                placeholder: '',
            },
            altContactNumber: {
                name: 'altContactNumber',
                label: 'Phone Number 2',
                maxLength: 50,
                placeholder: '',
            },
        },
    },
    destinationDetails: {
        placeholder: 'Search',
        destination: 'Destination',
        heading: 'Manage Destinations',
        maxLength: 50,
        name: 'Destination',
    },
    destinationColumnDetail: [
        { label: 'Destination Name', key: 'name' },
        { label: 'Status', key: 'active' },
        { label: 'City', key: 'city' },
        { label: 'State', key: 'state' },
    ],
    initialBinDetailsError: {
        name: false,
        coordinates: false,
        postalCode: false,
    },
    clearFilter: 'Clear Filter',
    editDestination: 'Edit Destination - ',
    activateModalConstant: {
        heading: 'Activate Destination',
        text: ' is currently Inactive. Activate and use this Destination.',
    },
    destinationFilter: 'destinationFilter',
};
