import { FC } from 'react';

const OnlineIcon: FC = props => {
    return (
        <img
            width={15}
            {...props}
            src={require(`./online_icon.png`)}
            alt="online_icon.png"
        />
    );
};

export default OnlineIcon;
