import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RIDER_INITIAL_STATE } from '../Constants/riderInitialState';
import { IFilter } from '../../utils/Interface/RiderInterface';

const riderSlice = createSlice({
    name: 'rider',
    initialState: { ...RIDER_INITIAL_STATE },
    reducers: {
        setRiderFilter: (state, action: PayloadAction<IFilter>) => {
            state.filter = action.payload;
        },
        setRiderDetailsLoader: (state, action: PayloadAction<boolean>) => {
            state.loader = action.payload;
        },
    },
});

export const { setRiderFilter, setRiderDetailsLoader } = riderSlice.actions;
export const RiderSlice = riderSlice.reducer;
