export const PEN_INITIAL_STATE = {
    filter: {
        name: '',
        show: 'active',
    },
    createPenInitial: {
        name: '',
        kind: '',
        headCapacity: '',
        active: true,
    },
    loader: true,
};
