import Constants from './Constants';

export const getToastContent = (
    status: number | string,
    responseType?: string,
    customMessage?: string | null,
) => {
    status = Number(status);

    let message = {
        showMessage: true,
        type: Constants.message.type.INFO,
        message:
            customMessage ?? Constants.message.commonLog.RECORD_UPDATED_SUCCESS,
    };

    if (status === Constants.responseCode.NO_CONTENT) {
        if (responseType === 'delete') {
            message = {
                showMessage: true,
                type: Constants.message.type.SUCCESS,
                message:
                    customMessage ??
                    Constants.message.commonLog.RECORD_DELETED_SUCCESS,
            };
        } else {
            message = {
                showMessage: true,
                type: Constants.message.type.SUCCESS,
                message:
                    customMessage ??
                    Constants.message.commonLog.RECORD_UPDATED_SUCCESS,
            };
        }
    } else if (status === Constants.responseCode.BAD_REQUEST) {
        message = {
            showMessage: true,
            type: Constants.message.type.ERROR,
            message: customMessage ?? Constants.message.commonLog.ERROR_400,
        };
    } else if (status === Constants.responseCode.UNAUTHORIZED) {
        message = {
            showMessage: true,
            type: Constants.message.type.ERROR,
            message: customMessage ?? Constants.message.commonLog.UNAUTHORIZE,
        };
    } else if (status === Constants.responseCode.DUPLICATE) {
        message = {
            showMessage: true,
            type: Constants.message.type.ERROR,
            message: customMessage ?? Constants.message.commonLog.DUPLICATE,
        };
    } else if (status === Constants.responseCode.VALIDATION) {
        message = {
            showMessage: true,
            type: Constants.message.type.ERROR,
            message: customMessage ?? Constants.message.commonLog.VALIDATION,
        };
    } else if (status === Constants.responseCode.SUCCESS) {
        message = {
            showMessage: true,
            type: Constants.message.type.SUCCESS,
            message:
                customMessage ??
                Constants.message.commonLog.RECORD_UPDATED_SUCCESS,
        };
    } else if (status === Constants.responseCode.SUCCESSFUL_POST) {
        message = {
            showMessage: true,
            type: Constants.message.type.SUCCESS,
            message:
                customMessage ??
                Constants.message.commonLog.RECORD_UPDATED_SUCCESS,
        };
    } else if (status === Constants.responseCode.NOT_FOUND) {
        message = {
            showMessage: true,
            type: Constants.message.type.ERROR,
            message:
                customMessage ?? Constants.message.commonLog.RECORD_NOT_EXIST,
        };
    } else if (status === Constants.responseCode.INTERNAL_SERVER_ERROR) {
        message = {
            showMessage: true,
            type: Constants.message.type.ERROR,
            message:
                customMessage ??
                Constants.message.commonLog.INTERNAL_SERVER_ERROR,
        };
    } else if (status === Constants.responseCode.CONFLICT) {
        message = {
            showMessage: true,
            type: Constants.message.type.ERROR,
            message: customMessage ?? Constants.message.commonLog.CONFLICT,
        };
    } else if (status === Constants.responseCode.NULL_OBJECT) {
        message = {
            showMessage: true,
            type: Constants.message.type.ERROR,
            message: customMessage ?? Constants.message.commonLog.NULL_OBJECT,
        };
    }

    return message;
};
