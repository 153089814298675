import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { YARDAGE_INITIAL_STATE } from '../Constants/yardageInitialState';

const yardageSlice = createSlice({
    name: 'yardage',
    initialState: { ...YARDAGE_INITIAL_STATE },
    reducers: {
        setYardageDetailsLoader: (state, action: PayloadAction<boolean>) => {
            state.loader = action.payload;
        },
    },
});

export const { setYardageDetailsLoader } = yardageSlice.actions;
export const YardageSlice = yardageSlice.reducer;
