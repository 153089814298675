import { useEffect, useState, useMemo } from 'react';
import { Button, Col } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../Redux/Store';
import { useModalStatus } from '../../commonHooks/useModalStatus';
import { useFeedlotId } from '../../commonHooks/useFeedlotId';
import { useDebouncedEffect } from '../useDebounceEffect';
import {
    deleteLoader,
    fetchLoaderDetails,
    updateLoaderDetail,
} from '../../Redux/Actions/loaderActions';

import { LOADER_INITIAL_STATE } from '../../Redux/Constants/loaderInitialState';
import LoaderModal from './LoaderModal';
import SettingsItemDetail from './FMSettings/SettingsItemDetail';
import { DeleteItemModal } from './FMSettings/DeleteItemModal';
import Constants from '../../utils/Constants';
import StorageService from '../../Services/storage/storage.service';
import { loaderConstants } from '../../utils/constants/LoaderConstants';
import { FmSettingsConstant } from '../../utils/constants/FmSettingsConstants';
import { IFilter, ILoaderDetail } from '../../utils/Interface/LoaderInterface';
import { setLoaderFilter } from '../../Redux/Reducer/loaderSlice';

const LoaderDetails = () => {
    const { filter, loader } = useAppSelector(state => state.loader);
    const storage = useMemo(() => new StorageService(), []);
    const feedlotId = useFeedlotId();
    const dispatch = useAppDispatch();
    const {
        isOpen: isLoaderModalOpen,
        open: openLoaderModal,
        close: closeLoaderModal,
    } = useModalStatus();
    const {
        isOpen: isDeleteModalOpen,
        open: openDeleteModal,
        close: closeDeleteModal,
    } = useModalStatus();
    const [searchQuery, setSearchQuery] = useState<string | undefined>(
        undefined,
    );
    const [loaderDetails, setLoaderDetails] = useState<ILoaderDetail[]>([]);
    const [selectedLoader, setSelectedLoader] = useState<ILoaderDetail>(
        LOADER_INITIAL_STATE.createLoaderInitial,
    );

    const fetchLoaderData = (loaderFilter: IFilter = filter): void => {
        if (feedlotId) {
            dispatch(
                fetchLoaderDetails(feedlotId, setLoaderDetails, loaderFilter),
            );
        }
    };

    const handleEditLoaderDetail = (loader: ILoaderDetail): void => {
        setSelectedLoader(loader);
        openLoaderModal();
    };

    const setLoaderFilterInStorage = (filterValues: IFilter) => {
        storage.setValue(
            loaderConstants.loaderFilter,
            filterValues,
            Constants.Storage.LOCAL,
        );
    };

    const handleFilter = (filterName: string, filterValue: string) => {
        const updatedFilter = { ...filter, [filterName]: filterValue };
        fetchLoaderData(updatedFilter);
        dispatch(setLoaderFilter(updatedFilter));
        setLoaderFilterInStorage(updatedFilter);
    };

    const closeModalWithChanges = (isEdited: boolean): void => {
        if (isEdited) {
            fetchLoaderData(filter);
        }
        closeLoaderModal();
    };

    const resetFilter = (): void => {
        const updatedFilter = LOADER_INITIAL_STATE.filter;
        fetchLoaderData(updatedFilter);
        setSearchQuery('');
        dispatch(setLoaderFilter(updatedFilter));
        setLoaderFilterInStorage(updatedFilter);
    };

    const handleCreateLoader = (): void => {
        if (feedlotId) {
            setSelectedLoader({
                ...LOADER_INITIAL_STATE.createLoaderInitial,
                feedLotId: feedlotId,
            });
        }
        openLoaderModal();
    };

    const handleDeleteModalHandler = (loader: ILoaderDetail) => {
        setSelectedLoader(loader);
        openDeleteModal();
    };

    const handleDeleteLoader = (shouldTakeAction: boolean) => {
        if (feedlotId && shouldTakeAction) {
            if (!selectedLoader.isFeedTaskAssociated || selectedLoader.active) {
                const isActiveAndAssociated =
                    selectedLoader.isFeedTaskAssociated &&
                    selectedLoader.active;
                const action = isActiveAndAssociated
                    ? updateLoaderDetail
                    : deleteLoader;
                const updatedLoaderDetail = isActiveAndAssociated
                    ? { ...selectedLoader, active: false }
                    : selectedLoader;
                dispatch(
                    action(feedlotId, updatedLoaderDetail, { fetchLoaderData }),
                );
            }
        }
        closeDeleteModal();
    };

    useDebouncedEffect(
        () => {
            if (searchQuery !== undefined) {
                handleFilter('name', searchQuery);
            }
        },
        [searchQuery],
        1000,
    );

    useEffect(() => {
        if (feedlotId) {
            const loaderFilter = storage.getValue(loaderConstants.loaderFilter);
            if (Object.keys(loaderFilter).length > 0) {
                dispatch(setLoaderFilter(loaderFilter));
            }
            if (!loaderFilter.name) {
                if (loaderFilter.show) {
                    fetchLoaderData(loaderFilter);
                } else {
                    dispatch(
                        fetchLoaderDetails(feedlotId, setLoaderDetails, filter),
                    );
                }
            } else {
                setSearchQuery(loaderFilter?.name || filter.name);
            }
        }
    }, [feedlotId, dispatch, storage]);

    return (
        <div className="pens-container">
            <h5 className="my-3">{loaderConstants.loaderDetails.heading}</h5>
            <div className="d-flex align-items-end">
                <Col xs={3} className="d-flex flex-column">
                    <label>{loaderConstants.loaderDetails.loader}</label>
                    <input
                        className="text-secondary my-2"
                        type="text"
                        placeholder={loaderConstants.loaderDetails.placeholder}
                        maxLength={loaderConstants.loaderDetails.maxLength}
                        value={searchQuery ? searchQuery : ''}
                        onChange={e => setSearchQuery(e.target.value)}
                    />
                </Col>
                <Col xs={3}>
                    <Button
                        className="secondary my-2 mx-4"
                        onClick={resetFilter}
                    >
                        {loaderConstants.clearFilter}
                    </Button>
                </Col>
                <Col xs={6} className="d-flex justify-content-end">
                    <Button className="my-2" onClick={handleCreateLoader}>
                        {loaderConstants.createNewLoaders.heading}
                    </Button>
                </Col>
            </div>
            <div
                className="o-switch btn-group show-toggle my-2"
                data-toggle="buttons"
                role="group"
            >
                {FmSettingsConstant.filterOptions.map(option => (
                    <label
                        key={option.value}
                        className={`btn btn-secondary py-1 ${
                            filter.show === option.value
                                ? FmSettingsConstant.active.value
                                : ''
                        }`}
                    >
                        <input
                            type="radio"
                            onChange={() =>
                                handleFilter(option.name, option.value)
                            }
                            checked={filter.show === option.value}
                        />
                        {option.label}
                    </label>
                ))}
            </div>
            <SettingsItemDetail<ILoaderDetail>
                columnDetail={loaderConstants.loaderColumnDetail}
                itemDetail={loaderDetails}
                handleEditDetail={handleEditLoaderDetail}
                handleDeleteModalHandler={handleDeleteModalHandler}
                loader={loader}
            />
            {isLoaderModalOpen && (
                <LoaderModal
                    showModal={isLoaderModalOpen}
                    closeModalWithChanges={closeModalWithChanges}
                    editLoaderDetails={selectedLoader}
                    showActiveField={true}
                />
            )}
            {isDeleteModalOpen && (
                <DeleteItemModal
                    item={selectedLoader}
                    handleDeleteItem={handleDeleteLoader}
                    showModal={isDeleteModalOpen}
                    itemName={loaderConstants.loaderDetails.loader}
                ></DeleteItemModal>
            )}
        </div>
    );
};

export default LoaderDetails;
