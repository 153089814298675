import { ISubRoutes } from '../../utils/Interface/FeedRouteCreationInterface';
import { FeedRouteCreationActionTypes } from '../Actions';
import { FEED_ROUTES_CREATION_INITIAL_STATE } from '../Constants/feedRouteCreationInitialState';

const FeedRouteCreationReducer = (
    state = FEED_ROUTES_CREATION_INITIAL_STATE,
    action,
) => {
    switch (action.type) {
        case FeedRouteCreationActionTypes.SET_FEED_ROUTE_CREATION_SUBROUTES:
            return {
                ...state,
                subRoutesList: action.payload,
            };
        case FeedRouteCreationActionTypes.SET_FEED_ROUTE_CREATION_DEFAULT_STATE:
            return {
                ...state,
                defaultSubRoutes: action.payload,
            };
        case FeedRouteCreationActionTypes.FETCH_FEED_ROUTE_CREATION_SUBROUTES_RATIONS:
            return {
                ...state,
                subRoutesRations: action.payload,
            };
        case FeedRouteCreationActionTypes.FETCH_FEED_ROUTE_CREATION_SUBROUTES_PENS:
            return {
                ...state,
                subRoutesPens: action.payload,
            };
        case FeedRouteCreationActionTypes.STATIC_DELETE_SUB_ROUTES:
            return {
                ...state,
                subRoutesList: state.subRoutesList.filter(
                    (value: ISubRoutes, index: number) => {
                        return index !== action.payload;
                    },
                ),
            };
        default:
            return state;
    }
};

export default FeedRouteCreationReducer;
