import { Modal } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../Redux/Store';
import { commonActions } from '../../Redux/Reducer/common';
import Constants from '../../utils/Constants';

const SupportModal = () => {
    const dispatch = useAppDispatch();
    const { showSupportModal } = useAppSelector(state => state.common);

    const handleClose = () => {
        dispatch(commonActions.setShowSupportModal(false));
    };

    return (
        <Modal show={showSupportModal} className="pt-5" onHide={handleClose}>
            <Modal.Header closeButton className="border-0">
                <Modal.Title className="text-dark bold">
                    <b>{Constants.supportModal.title}</b>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <p>
                        <b>{Constants.supportModal.phoneNumberLabel}</b>{' '}
                        {Constants.supportModal.phoneNumber}
                    </p>
                    <p>
                        <b>{Constants.supportModal.emailLabel}</b>{' '}
                        {Constants.supportModal.email}
                    </p>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default SupportModal;
