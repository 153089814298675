import { ChangeEventHandler, FC, FormEventHandler, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { TenantManagementModelsUserManagementRequestChangePasswordModel } from '../../../../../Redux/Apis/Users/baseUsersApi';
import { usersApi } from '../../../../../Redux/Apis/Users/usersApi';
import { useTranslation } from 'react-i18next';
import SpinnerSubmitButton from '../../../../../components/common/SpinnerSubmitButton';
import { passwordValidation } from '../../../../../utils/validation';
import { toast } from 'react-toastify';
import { useModalStatus } from '../../../../../commonHooks/useModalStatus';

export const ChangePasswordModalLink: FC = () => {
    const { isOpen, open, close } = useModalStatus();
    const { t } = useTranslation('auth');

    return (
        <>
            <ChangePasswordModal key={+isOpen} isOpen={isOpen} close={close} />
            <li onClick={open}>
                <div className="nav-link">
                    {t('changePasswordModal.heading')}
                </div>
            </li>
        </>
    );
};

export const ChangePasswordModal: FC<{
    isOpen: boolean;
    close: () => void;
}> = ({ isOpen, close }) => {
    const { t } = useTranslation('auth');
    const [triggerPasswordUpdate, { isLoading }] =
        usersApi.usePutApiTmUsersPasswordMutation();

    const [form, setForm] = useState<{
        oldPw: string;
        newPw1: string;
        newPw2: string;
    }>({ oldPw: '', newPw1: '', newPw2: '' });
    const [err, setErr] = useState<{
        oldPw: string;
        newPw1: string;
        newPw2: string;
    }>({ oldPw: '', newPw1: '', newPw2: '' });

    const handleChange: ChangeEventHandler<HTMLInputElement> = event => {
        const { value, name } = event.currentTarget;
        const formCopy = { ...form, [name]: value };
        setForm(formCopy);
    };

    const handleUpdatePassword: FormEventHandler = event => {
        event.preventDefault();
        const isValid = validateForm();
        if (!isValid) return;

        const tenantManagementModelsUserManagementRequestChangePasswordModel: TenantManagementModelsUserManagementRequestChangePasswordModel =
            { new: form.newPw1, previous: form.oldPw };
        triggerPasswordUpdate({
            tenantManagementModelsUserManagementRequestChangePasswordModel,
        })
            .unwrap()
            .then(() => {
                toast.success(t('changePasswordModal.toast.success'));
                close();
            })
            .catch(() => {
                toast.error(t('changePasswordModal.toast.error'));
            });
    };

    const validateForm = (): boolean => {
        const errCopy = { ...err };
        errCopy.oldPw = !form.oldPw
            ? t('changePasswordModal.currentPassword.error')
            : '';
        errCopy.newPw1 = !passwordValidation(form.newPw1)
            ? t('changePasswordModal.newPassword.errorFormat')
            : '';
        errCopy.newPw2 =
            form.newPw1 !== form.newPw2
                ? t('changePasswordModal.confirmNewPassword.error')
                : '';

        let isValid = Object.values(errCopy).every(error => !error);

        if (isValid) {
            errCopy.newPw1 =
                form.oldPw === form.newPw1
                    ? t('changePasswordModal.newPassword.errorMatch')
                    : '';
            isValid = Object.values(errCopy).every(error => !error);
        }

        setErr(errCopy);

        return isValid;
    };

    return (
        <Modal show={isOpen} onHide={close} centered animation>
            <Modal.Header>
                <Modal.Title>
                    <h5 className="modal-title">
                        {t('changePasswordModal.heading')}
                    </h5>
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form onSubmit={handleUpdatePassword} className="p-1">
                    <Form.Group>
                        <Form.Label className="mb-1">
                            {t('changePasswordModal.currentPassword.label')}
                        </Form.Label>
                        <Form.Control
                            type="password"
                            name="oldPw"
                            value={form.oldPw}
                            onChange={handleChange}
                            disabled={isLoading}
                            isInvalid={!!err.oldPw}
                        />
                        <Form.Control.Feedback type="invalid">
                            {err.oldPw}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <br />
                    <Form.Group>
                        <Form.Label className="mb-1">
                            {t('changePasswordModal.newPassword.label')}
                        </Form.Label>
                        <Form.Control
                            type="password"
                            name="newPw1"
                            value={form.newPw1}
                            onChange={handleChange}
                            disabled={isLoading}
                            isInvalid={!!err.newPw1}
                        />
                        <Form.Control.Feedback type="invalid">
                            {err.newPw1}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <br />
                    <Form.Group>
                        <Form.Label className="mb-1">
                            {t('changePasswordModal.confirmNewPassword.label')}
                        </Form.Label>
                        <Form.Control
                            type="password"
                            name="newPw2"
                            value={form.newPw2}
                            onChange={handleChange}
                            disabled={isLoading}
                            isInvalid={!!err.newPw2}
                        />
                        <Form.Control.Feedback type="invalid">
                            {err.newPw2}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <br />
                    <div className="d-flex justify-content-end">
                        <Button className="me-2 secondary" onClick={close}>
                            {t('changePasswordModal.buttons.cancel')}
                        </Button>
                        <SpinnerSubmitButton
                            type="submit"
                            buttonContent={t(
                                'changePasswordModal.buttons.submit',
                            )}
                            onClick={undefined}
                            submitPending={isLoading}
                            disabled={false}
                        />
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};
