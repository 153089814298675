export const BUYER_INITIAL_STATE = {
    filter: {
        name: '',
        show: 'active',
    },
    createBuyerInitial: {
        name: '',
        contactNumber: '',
        active: true,
    },
    loader: true,
};
