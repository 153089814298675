export const OWNER_INITIAL_STATE = {
    filter: {
        name: '',
        ownerId: 0,
        show: 'active',
    },
    createOwnerInitial: {
        name: '',
        contactNumber: '',
        email: '',
        address1: '',
        city: '',
        state: '',
        postalCode: '',
        totalHdCount: '',
        active: true,
    },
    loader: true,
};
