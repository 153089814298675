export const mixingBinConstants = {
    createNewMixingBins: {
        heading: 'Create Mixing Bin',
        form: {
            name: {
                name: 'name',
                label: 'Mixing Bin Name',
                maxLength: 50,
                placeholder: 'Enter Mixing Bin Name',
            },
            capacity: {
                name: 'capacity',
                label: 'Capacity',
                maxLength: 50,
                placeholder: 'Enter Capacity',
            },
        },
    },
    mixingBinDetails: {
        placeholder: 'Search',
        mixingBin: 'Mixing Bin',
        heading: 'Manage Mixing Bins',
        maxLength: 50,
        name: 'mixing bin',
    },
    mixingBinColumnDetail: [
        { label: 'Bin Name', key: 'name' },
        { label: 'Status', key: 'active' },
        { label: 'Capacity', key: 'capacity' },
    ],
    initialBinDetailsError: {
        name: false,
        capacity: false,
    },
    clearFilter: 'Clear Filter',
    editMixingBin: 'Edit Mixing Bin - ',
    activateModalConstant: {
        heading: 'Activate Mixing Bin',
        text: ' is currently Inactive. Activate and use this Mixing Bin.',
    },
    mixingBinFilter: 'mixingBinFilter',
};
