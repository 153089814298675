import { currentDate } from '../../../helpers';
import {
    IYardSheetExcelData,
    IYardSheetInitialState,
} from './yardSheetInterfaces';

export const YARD_SHEET_INITIAL_STATE: IYardSheetInitialState = {
    yardSheetExcelData: [],
    isYardSheetExcelDataLoading: false,
};

const yardSheetExcelHeaderRows: IYardSheetExcelData[] = [
    //NOTE: property names may not match values below as these data are for excel doc headers
    {
        owner: 'CONDENSED DAILY YARDSHEET',
        penName: '',
        lotName: '',
        cattleType: '',
        sex: 'DATE',
        averageDateIn: currentDate(),
        incomingPayWeight: '',
        currentWeight: '',
        averageDailyGain: '',
        daysOnFeed: 'Total In Pen',
        inPen: '',
        outOfPen: '',
        realizers: '',
        mortalities: '',
        shipped: '',
        ration: '',
        sevenDay: '',
    },
    {
        owner: '',
        penName: '',
        lotName: '',
        cattleType: '',
        sex: '',
        averageDateIn: '',
        incomingPayWeight: '',
        currentWeight: '',
        averageDailyGain: '',
        daysOnFeed: '',
        inPen: '',
        outOfPen: '',
        realizers: '',
        mortalities: '',
        shipped: '',
        ration: '',
        sevenDay: '',
    },
    {
        owner: '',
        penName: '',
        lotName: '',
        cattleType: '',
        sex: '',
        averageDateIn: '',
        incomingPayWeight: '',
        currentWeight: '',
        averageDailyGain: '',
        daysOnFeed: '',
        inPen: '',
        outOfPen: '',
        realizers: '',
        mortalities: '',
        shipped: '',
        ration: 'CONSUMPTION',
        sevenDay: '',
    },
    {
        owner: 'Owner',
        penName: 'Pen',
        lotName: 'Lot',
        cattleType: 'Breed',
        sex: 'Sex',
        averageDateIn: 'Avg In Date',
        incomingPayWeight: 'In PW',
        currentWeight: 'Cur Wt',
        averageDailyGain: 'ADG',
        daysOnFeed: 'Cur DOF',
        inPen: 'In Pen',
        outOfPen: 'Out of Pen',
        realizers: 'Real',
        mortalities: 'Dead',
        shipped: 'Ship',
        ration: 'Ratn',
        sevenDay: '7 day',
    },
];

export const YardSheetConstants = {
    excelName: 'YardSheet',
    yardSheetExcelHeaderRows,
    yardSheetModal: {
        exportYardSheet: 'Export Yard Sheet',
        filterResultsByOwner: 'Filter results by Owner',
        asExcel: 'As Excel',
        asPdfByLot: 'As PDF by Lot',
        asPdfByPen: 'As PDF by Pen',
    },
};
