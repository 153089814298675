import { useNavigate, useSearchParams } from 'react-router-dom';
import { ChangeEventHandler, FormEventHandler, useState } from 'react';
import { Form } from 'react-bootstrap';
import Constants from '../../../../../utils/Constants';
import { apiCall_v2 } from '../../../../../Services/AxiosService';
import useAuthContext from '../hooks/useAuthContext';
import { AuthFormContainer } from '../components/AuthFormContainer';
import { useTranslation } from 'react-i18next';
import { passwordValidation } from '../../../../../utils/validation';

export const TransientResetPasswordPage = () => {
    const { t } = useTranslation('auth');
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { isAuthenticated, localAuthLogin, logout } = useAuthContext();

    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [passwords, setPasswords] = useState<{
        password: string;
        passwordConfirm: string;
    }>({ password: '', passwordConfirm: '' });
    const [passwordsError, setPasswordsError] = useState<{
        password: boolean;
        passwordConfirm: boolean;
    }>({ password: false, passwordConfirm: false });

    const handleChange: ChangeEventHandler<HTMLInputElement> = event => {
        const { value, name } = event.currentTarget;
        const passwordCopy = { ...passwords, [name]: value };
        setPasswords(passwordCopy);
    };

    const handleSubmit: FormEventHandler<HTMLFormElement> = async event => {
        event.preventDefault();
        if (validateForm()) {
            setIsProcessing(true);
            await resetPassword();
            setIsProcessing(false);
        }
    };

    const validateForm = (): boolean => {
        const passwordsErrorCopy = { ...passwordsError };
        passwordsErrorCopy.password = !passwordValidation(passwords.password);
        passwordsErrorCopy.passwordConfirm =
            passwords.password !== passwords.passwordConfirm;
        setPasswordsError(passwordsErrorCopy);

        return Object.values(passwordsErrorCopy).every(
            error => error === false,
        );
    };

    const resetPassword = async () => {
        const res = await apiCall_v2({
            method: 'put',
            url: `${Constants.apiUrls.SET_USER_PASSWORD}`,
            isResRequired: true,
            showBackendErrorToast: true,
            payload: { new: passwords.password },
        });
        if (res?.status === Constants.responseCode.NO_CONTENT) {
            logout();
        }
    };

    const email = searchParams.get('state');
    const code = searchParams.get('code');

    if (email && code && !isAuthenticated(false)) {
        localAuthLogin({
            username: email,
            password: code,
            redirectPath: Constants.Routes.RESET_TOKEN,
            isTransient: true,
        });
    }

    return (
        <AuthFormContainer
            heading={t('passwordResetPage.heading')}
            handleSubmit={handleSubmit}
            submitButtonLabel={t('passwordResetPage.reset')}
            isSubmitPending={isProcessing}
            isSubmitButtonDisabled={false}
            handleCancel={() => navigate(Constants.Routes.USER_AUTH)}
            cancelButtonLabel={t('passwordResetPage.cancel')}
        >
            <Form.Group>
                <Form.Label className="mb-1">
                    {t('passwordResetPage.newPassword')}
                </Form.Label>
                <Form.Control
                    type="password"
                    name="password"
                    value={passwords.password}
                    onChange={handleChange}
                    disabled={isProcessing}
                    isInvalid={passwordsError.password}
                />
                <Form.Control.Feedback type="invalid">
                    {t('passwordResetPage.passwordRequirements')}
                </Form.Control.Feedback>
            </Form.Group>

            <br />
            <Form.Group>
                <Form.Label className="mb-1">
                    {t('passwordResetPage.confirmNewPassword')}
                </Form.Label>
                <Form.Control
                    type="password"
                    name="passwordConfirm"
                    value={passwords.passwordConfirm}
                    onChange={handleChange}
                    disabled={isProcessing}
                    isInvalid={passwordsError.passwordConfirm}
                />
                <Form.Control.Feedback type="invalid">
                    {t('passwordResetPage.passwordsMustMatch')}
                </Form.Control.Feedback>
            </Form.Group>
        </AuthFormContainer>
    );
};
