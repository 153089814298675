import { useCallback, useEffect, useState } from 'react';

const idleMaxTimeoutInHours = 8;
const timeToWaitToCheckForActivityInSeconds = 60;
const timeToWaitToCheckForExpirationInSeconds = 60 * 10;

const useIdleUserLogout = (
    logout: () => void,
    isAuthenticated: () => boolean,
) => {
    const [lastActiveTime, setLastActiveTime] = useState<Date>(new Date());

    const handleUserActive = useCallback(() => {
        setLastActiveTime(new Date());
    }, []);

    useEffect(() => {
        const timeout = setTimeout(() => {
            window.addEventListener('click', handleUserActive);
            window.addEventListener('mousemove', handleUserActive);
            window.addEventListener('scroll', handleUserActive);
            window.addEventListener('keypress', handleUserActive);
        }, timeToWaitToCheckForActivityInSeconds * 1000);

        return () => {
            window.removeEventListener('click', handleUserActive);
            window.removeEventListener('mousemove', handleUserActive);
            window.removeEventListener('scroll', handleUserActive);
            window.removeEventListener('keypress', handleUserActive);
            clearTimeout(timeout);
        };
    }, [handleUserActive, lastActiveTime]);

    useEffect(() => {
        const interval = setInterval(() => {
            const expirationDateTime = new Date(lastActiveTime);
            expirationDateTime.setHours(
                expirationDateTime.getHours() + idleMaxTimeoutInHours,
            );
            if (expirationDateTime < new Date() && isAuthenticated()) {
                logout();
            }
        }, timeToWaitToCheckForExpirationInSeconds * 1000);

        return () => {
            clearInterval(interval);
        };
    }, [isAuthenticated, lastActiveTime, logout]);
};

export default useIdleUserLogout;
