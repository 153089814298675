import { AnyAction } from '@reduxjs/toolkit';
import { ActionTypes } from '../Actions';
import { CREATE_RATION_INITIAL_STATE } from '../Constants/rationInitialSate';

const RationReducer = (
    state = CREATE_RATION_INITIAL_STATE,
    action: AnyAction,
) => {
    switch (action.type) {
        case ActionTypes.SET_RATION_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case ActionTypes.SET_RATION_DETAIL:
            return {
                ...state,
                rationDetail: action.payload,
            };
        case ActionTypes.SET_RATION_DETAIL_ERROR:
            return {
                ...state,
                rationErrorDetail: action.payload,
            };
        case ActionTypes.SET_RATION_INGREDIENTS:
            return {
                ...state,
                rationIngredients: action.payload,
            };
        case ActionTypes.SET_RATION_INGREDIENTS_ERROR:
            return {
                ...state,
                rationIngredientsError: action.payload,
            };
        case ActionTypes.SET_INGREDIENTS_TOTAL:
            return {
                ...state,
                ingredientTotalAmount: action.payload,
            };
        case ActionTypes.SET_PERCENTAGE_TOTAL:
            return {
                ...state,
                ingredientTotalPercentage: action.payload,
            };
        case ActionTypes.SET_DRY_MATTER_TOTAL:
            return {
                ...state,
                dryMatterTotal: action.payload,
            };
        case ActionTypes.SET_INGREDIENTS:
            return {
                ...state,
                ingredients: action.payload,
            };
        case ActionTypes.SET_ALL_RATIONS:
            return {
                ...state,
                allRations: action.payload,
            };
        case ActionTypes.SET_IS_RATION_DETAIL_CHANGED:
            return {
                ...state,
                isRationDetailChanged: action.payload,
            };
        case ActionTypes.SET_SUBMIT_PENDING_CREATE_RATION:
            return {
                ...state,
                submitPendingCreateRation: action.payload,
            };
        case ActionTypes.SET_ACTIVE_BUTTON_CONFIRMATION_MODAL:
            return {
                ...state,
                activeButtonConfirmationModal: action.payload,
            };
        default:
            return state;
    }
};

export default RationReducer;
