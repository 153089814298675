import { useEffect, useRef, useState } from 'react';

interface IntersectionObserverInit {
    root?: Element | Document | null;
    rootMargin?: string;
    threshold?: number | number[];
}

export const useElementOnScreen = (options: IntersectionObserverInit) => {
    const containerRef = useRef<HTMLDivElement | null>(null);
    const [isVisible, setIsVisible] = useState(false);

    const callbackFunction = (entries: IntersectionObserverEntry[]) => {
        const [entry] = entries;
        setIsVisible(entry.isIntersecting);
    };

    useEffect(() => {
        let observerRefValue: HTMLDivElement | null = null;
        const observer = new IntersectionObserver(callbackFunction, options);
        if (containerRef.current) {
            observer.observe(containerRef.current);
            observerRefValue = containerRef.current;
        }

        return () => {
            if (observerRefValue) observer.unobserve(observerRefValue);
        };
    }, [containerRef, options]);

    return [containerRef, isVisible];
};
