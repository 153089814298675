import { FC } from 'react';

const SidebarLogoNGAT: FC = props => {
    return (
        <img
            {...props}
            alt="sidebar_ngat.png"
            src={require(`./sidebar_ngat.png`)}
            title={`CC v${process.env.REACT_APP_VERSION || '0.0.0.0'}`}
        />
    );
};

export default SidebarLogoNGAT;
