import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { LOADER_INITIAL_STATE } from '../Constants/loaderInitialState';
import { IFilter } from '../../utils/Interface/LoaderInterface';

const loaderSlice = createSlice({
    name: 'loader',
    initialState: { ...LOADER_INITIAL_STATE },
    reducers: {
        setLoaderFilter: (state, action: PayloadAction<IFilter>) => {
            state.filter = action.payload;
        },
        setLoaderDetailsLoader: (state, action: PayloadAction<boolean>) => {
            state.loader = action.payload;
        },
    },
});

export const { setLoaderFilter, setLoaderDetailsLoader } = loaderSlice.actions;
export const LoaderReducer = loaderSlice.reducer;
