import { useEffect, useState } from 'react';
import { Button, Col } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../Redux/Store';
import { useModalStatus } from '../../commonHooks/useModalStatus';
import { useFeedlotId } from '../../commonHooks/useFeedlotId';
import { fetchYardageDetails } from '../../Redux/Actions/yardageActions';
import { yardageConstants } from '../../utils/constants/YardageConstants';
import { YARDAGE_INITIAL_STATE } from '../../Redux/Constants/yardageInitialState';
import { IYardageDetail } from '../../utils/Interface/YardageInterface';
import SettingsItemDetail from './FMSettings/SettingsItemDetail';
import YardageModal from './YardageModal';

const YardageDetails = () => {
    const { loader } = useAppSelector(state => state.yardage);
    const [yardageDetails, setYardageDetails] = useState<IYardageDetail[]>([]);
    const [selectedYardage, setSelectedYardage] = useState<IYardageDetail>(
        YARDAGE_INITIAL_STATE.createYardageInitial,
    );
    const {
        isOpen: isYardageModalOpen,
        open: openYardageModal,
        close: closeYardageModal,
    } = useModalStatus();
    const feedlotId = useFeedlotId();
    const dispatch = useAppDispatch();

    const closeModalWithChanges = (isEdited: boolean): void => {
        if (isEdited && feedlotId) {
            dispatch(fetchYardageDetails(feedlotId, setYardageDetails));
        }
        closeYardageModal();
    };

    const handleCreateYardage = (): void => {
        if (feedlotId) {
            setSelectedYardage({
                ...YARDAGE_INITIAL_STATE.createYardageInitial,
                feedLotId: feedlotId,
            });
        }
        openYardageModal();
    };

    useEffect(() => {
        if (feedlotId) {
            dispatch(fetchYardageDetails(feedlotId, setYardageDetails));
        }
    }, [feedlotId, dispatch]);

    return (
        <div className="pens-container">
            <div className="d-flex align-items-end">
                <h5 className="my-3">
                    {yardageConstants.yardageDetails.heading}
                </h5>
                <Col className="d-flex justify-content-end">
                    <Button className="my-2" onClick={handleCreateYardage}>
                        {yardageConstants.createNewYardage.heading}
                    </Button>
                </Col>
            </div>
            <SettingsItemDetail<IYardageDetail>
                columnDetail={yardageConstants.yardageColumnDetail}
                itemDetail={yardageDetails}
                loader={loader}
            />
            {isYardageModalOpen && (
                <YardageModal
                    showModal={isYardageModalOpen}
                    closeModalWithChanges={closeModalWithChanges}
                    yardageDetails={selectedYardage}
                />
            )}
        </div>
    );
};

export default YardageDetails;
