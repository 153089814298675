export const truckingCompanyConstants = {
    createNewTruckingCompany: {
        heading: 'Create Trucking Company',
        form: {
            name: {
                name: 'name',
                label: 'Trucking Company Name',
                maxLength: 50,
                placeholder: 'Enter Company Name',
            },
            contactName: {
                name: 'contactName',
                label: 'Manager Name 1',
                maxLength: 50,
                placeholder: '',
            },
            altContactName: {
                name: 'altContactName',
                label: 'Manager Name 2',
                maxLength: 50,
                placeholder: '',
            },
            email: {
                name: 'email',
                label: 'Email 1',
                maxLength: 50,
                placeholder: '',
            },
            altEmail: {
                name: 'altEmail',
                label: 'Email 2',
                maxLength: 50,
                placeholder: '',
            },
            contactNumber: {
                name: 'contactNumber',
                label: 'Phone Number 1',
                maxLength: 50,
                placeholder: '',
            },
            altContactNumber: {
                name: 'altContactNumber',
                label: 'Phone Number 2',
                maxLength: 50,
                placeholder: '',
            },
        },
    },
    truckingCompanyDetails: {
        placeholder: 'Search',
        truckingCompany: 'Trucking Company',
        heading: 'Manage Trucking Company',
        maxLength: 50,
    },
    truckingCompanyColumnDetail: [
        { key: 'name', label: 'Company Name' },
        { key: 'active', label: 'Status' },
        { key: 'contactName', label: 'Manager Name 1' },
        { key: 'altContactName', label: 'Manager Name 2' },
        { key: 'contactNumber', label: 'Phone number' },
        { key: 'email', label: 'Email Address' },
    ],
    initialCompanyDetailsError: {
        name: false,
        contactName: false,
        email: false,
        contactNumber: false,
    },
    clearFilter: 'Clear Filter',
    editTruckingCompany: 'Edit Trucking Company - ',
    activateModalConstant: {
        heading: 'Activate Trucking Company',
        text: ' is currently Inactive. Activate and use this Trucking Company.',
    },
    truckingCompanyFilter: 'truckingCompanyFilter',
};
