import { apiCall, apiCall_v2 } from '../../Services/AxiosService';
import Constants from '../../utils/Constants';
import {
    ICreateBuyer,
    IDeleteBuyer,
    IFetchBuyerDetails,
    IFilter,
    IUpdateBuyerDetail,
} from '../../utils/Interface/BuyerInterface';
import { getToastContent } from '../../utils/ToastContent';
import { setBuyerDetailsLoader } from '../Reducer/buyerSlice';
import { toastMessage } from '../Reducer/common';
import { AppDispatch } from '../Store';

const getFilterUrl = (filter: IFilter) => {
    let filterUrl = `&filter=contains(Name, '${filter.name}')`;
    if (filter?.show && filter?.show !== 'all') {
        const isActive = filter.show === 'active';
        filterUrl += ` and active eq ${isActive}`;
    }
    return filterUrl;
};

export const fetchBuyerDetails: IFetchBuyerDetails =
    (setBuyerDetail, filter) => async (dispatch: AppDispatch) => {
        dispatch(setBuyerDetailsLoader(true));
        let filterUrl = '';
        if (filter) {
            filterUrl = getFilterUrl(filter);
        }
        const response = await apiCall(
            'get',
            `${Constants.apiUrls.FEED_LOT_MANAGER}${Constants.apiUrls.BUYERS}/odata?${filterUrl}&orderBy=Name`,
        );
        setBuyerDetail(response);
        dispatch(setBuyerDetailsLoader(false));
    };

export const updateBuyerDetail: IUpdateBuyerDetail =
    (
        buyerDetail,
        fetchBuyerData?,
        closeModalWithChanges?,
        setLoading?,
        handleActivateBuyer?,
    ) =>
    async (dispatch: AppDispatch) => {
        setLoading?.(true);
        const response = await apiCall_v2({
            method: 'put',
            url: `${Constants.apiUrls.FEED_LOT_MANAGER}${Constants.apiUrls.BUYERS}/${buyerDetail.buyerId}`,
            payload: buyerDetail,
            isResRequired: true,
        });

        setLoading?.(false);
        if (!response) return;
        const status = Number(response.status);

        if (status === Constants.responseCode.DUPLICATE) {
            dispatch(
                toastMessage(
                    getToastContent(
                        response.status,
                        '',
                        Constants.customMessage.BUYER_DUPLICATE,
                    ),
                ),
            );
        } else if (status === Constants.responseCode.CONFLICT) {
            handleActivateBuyer?.();
        } else {
            dispatch(toastMessage(getToastContent(response.status)));
            closeModalWithChanges?.(true, buyerDetail.buyerId);
        }
        fetchBuyerData?.();
    };

export const createBuyer: ICreateBuyer =
    (buyer, closeModalWithChanges, setLoading, handleActivateBuyer) =>
    async (dispatch: AppDispatch) => {
        setLoading(true);
        const response = await apiCall_v2({
            method: 'post',
            url: `${Constants.apiUrls.FEED_LOT_MANAGER}${Constants.apiUrls.BUYERS}`,
            payload: buyer,
            isResRequired: true,
        });
        setLoading(false);
        if (!response) return;
        const status = Number(response.status);

        if (status === Constants.responseCode.DUPLICATE) {
            dispatch(
                toastMessage(
                    getToastContent(
                        status,
                        '',
                        Constants.customMessage.BUYER_DUPLICATE,
                    ),
                ),
            );
        } else if (status === Constants.responseCode.CONFLICT) {
            handleActivateBuyer();
        } else {
            dispatch(toastMessage(getToastContent(status)));
            closeModalWithChanges(true, response.data);
        }
    };

export const fetchInactiveBuyerInfo =
    (buyer?: string) => async (dispatch: AppDispatch) => {
        const response = await apiCall_v2({
            method: 'get',
            url: `${Constants.apiUrls.FEED_LOT_MANAGER}${Constants.apiUrls.BUYERS}/odata?filter=name eq '${buyer}'`,
            isResRequired: true,
        });
        if (!response) return;
        const status = Number(response.status);

        if (
            response.status >= Constants.responseCode.SUCCESS &&
            response.status < Constants.responseCode.MULTIPLE_CHOICES
        ) {
            return response.data;
        } else {
            dispatch(toastMessage(getToastContent(status)));
        }
    };

export const deleteBuyer: IDeleteBuyer =
    (buyer, fetchBuyerData?) => async (dispatch: AppDispatch) => {
        dispatch(setBuyerDetailsLoader(true));
        const response = await apiCall_v2({
            method: 'delete',
            url: `${Constants.apiUrls.FEED_LOT_MANAGER}${Constants.apiUrls.BUYERS}/${buyer.buyerId}`,
            payload: buyer,
            isResRequired: true,
        });
        dispatch(setBuyerDetailsLoader(false));
        if (!response) return;
        dispatch(toastMessage(getToastContent(Number(response.status))));
        fetchBuyerData?.();
    };
