import { IYardLayoutInitialState } from '../../utils/Interface/YardLayoutInterface';
import { yardLayoutConstant } from '../../utils/LabelConstant';

export const YARD_LAYOUT_INITIAL_STATE: IYardLayoutInitialState = {
    yardLayoutDetail: [],
    filter: {
        rationId: '',
        ownerId: '',
        lotId: '',
    },

    rations: [
        {
            rationName: yardLayoutConstant.Ration.SELECT_RATION,
            rationId: 0,
        },
    ],
    lots: [
        {
            lotName: yardLayoutConstant.Lot.SELECT_LOT,
            lotId: 0,
            ownerId: 0,
            ownerName: '',
            sex: '',
            sexId: 0,
        },
    ],
    totalHeadCount: 0,
    owners: [
        {
            ownerId: 0,
            ownerName: yardLayoutConstant.Owner.SELECT_OWNER,
        },
    ],
    loading: true,
};
