import { feedlotManagerFeatures } from '../../apps/feedlotManager/featureFlags/feedlotManagerFeatures';

const { isCA11435On_EIDOnExistingLists } = feedlotManagerFeatures;

export const HospitalConstants = {
    en: {
        history: {
            adminDate: {
                label: 'Admin Date',
            },
            diagnosis: {
                label: 'Diagnosis',
            },
            protocol: {
                label: 'Protocol',
            },
            rider: {
                label: 'Rider',
            },
            adminHistory: {
                label: 'Admin History',
            },
        },
        accordionHeader: isCA11435On_EIDOnExistingLists
            ? {
                  hospitalPen: {
                      label: 'Hospital Pen',
                  },
                  lot: {
                      label: 'Lot',
                  },
                  eid: {
                      label: 'EID',
                  },
                  animalId: {
                      label: 'Animal ID',
                  },
                  homePen: {
                      label: 'Home Pen',
                  },
              }
            : {
                  hospitalPen: {
                      label: 'Hospital Pen',
                  },
                  lot: {
                      label: 'Lot',
                  },
                  animalId: {
                      label: 'Animal ID',
                  },
                  homePen: {
                      label: 'Home Pen',
                  },
              },
        button: {
            createMortality: {
                label: 'Create Mortality',
            },
            move: {
                label: 'Move',
            },
        },
    },
};
