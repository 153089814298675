import { baseAccountsApi, GetApiTmAccountsApiArg } from './baseAccountsApi';

export const accountsApi = baseAccountsApi
    .injectEndpoints({
        endpoints: build => ({
            getApiTmAccounts: build.query<
                getApiTmAccountsResponse[],
                GetApiTmAccountsApiArg
            >({
                query: queryArg => ({
                    url: `/api/tm/accounts`,
                    params: {
                        accountIds: queryArg.accountIds,
                        include: queryArg.include,
                        filter: queryArg.filter,
                        sort: queryArg.sort,
                        limit: queryArg.limit,
                        offset: queryArg.offset,
                        includeDisabled: queryArg.includeDisabled,
                    },
                }),
                providesTags: ['Accounts'],
            }),
        }),
        overrideExisting: true,
    })
    .enhanceEndpoints({
        addTagTypes: [],
        endpoints: {},
    });

export interface getApiTmAccountsResponse {
    accountId: number;
    name: string;
}
