import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const appLoaderSlice = createSlice({
    name: 'appLoader',
    initialState: {
        showAppLoader: false,
        isRequestPending: false,
        allowOutsideClick: false,
    },
    reducers: {
        setShowAppLoader: (state, action: PayloadAction<boolean>) => {
            state.showAppLoader = action.payload;
        },
        setIsRequestPending: (state, action: PayloadAction<boolean>) => {
            state.isRequestPending = action.payload;
        },
        setAllowOutsideClick: (state, action: PayloadAction<boolean>) => {
            state.allowOutsideClick = action.payload;
        },
    },
});

export const appLoaderActions = appLoaderSlice.actions;
export const appLoaderReducer = appLoaderSlice.reducer;
