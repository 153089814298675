import { feedlotManagerFeatures } from '../../apps/feedlotManager/featureFlags/feedlotManagerFeatures';
import { useLanguage } from '../../commonHooks/useLocalization';
import { HospitalConstants } from '../../utils/constants/HospitalConstants';

const { isCA11435On_EIDOnExistingLists } = feedlotManagerFeatures;

const HospitalAccordionHeader = () => {
    const language = useLanguage();

    return isCA11435On_EIDOnExistingLists ? (
        <div className="col">
            <div className="row">
                <div className="col-2 px-2">
                    {
                        HospitalConstants[language.value].accordionHeader
                            .hospitalPen.label
                    }
                </div>
                <div className="col-2 px-2">
                    {
                        HospitalConstants[language.value].accordionHeader.lot
                            .label
                    }
                </div>
                <div className="col-2 px-2">
                    {
                        HospitalConstants[language.value].accordionHeader.eid
                            .label
                    }
                </div>
                <div className="col-2 px-2">
                    {
                        HospitalConstants[language.value].accordionHeader
                            .animalId.label
                    }
                </div>
                <div className="col-2 px-2">
                    {
                        HospitalConstants[language.value].accordionHeader
                            .homePen.label
                    }
                </div>
            </div>
        </div>
    ) : (
        <thead>
            <tr>
                <th className="col-1">
                    {
                        HospitalConstants[language.value].accordionHeader
                            .hospitalPen.label
                    }
                </th>
                <th className="col-1">
                    {
                        HospitalConstants[language.value].accordionHeader.lot
                            .label
                    }
                </th>
                <th className="col-1">
                    {
                        HospitalConstants[language.value].accordionHeader
                            .animalId.label
                    }
                </th>
                <th className="col-1">
                    {
                        HospitalConstants[language.value].accordionHeader
                            .homePen.label
                    }
                </th>
            </tr>
        </thead>
    );
};

export default HospitalAccordionHeader;
