import { Navigate, Outlet } from 'react-router-dom';
import useAuthContext from '../../apps/common/modules/auth/hooks/useAuthContext';

const UnauthenticatedRoute = () => {
    const auth = useAuthContext();
    const { isAuthenticated } = auth;

    return !isAuthenticated() ? <Outlet /> : <Navigate to="/" />;
};

export default UnauthenticatedRoute;
