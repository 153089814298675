import Axios from 'axios';
import saveAs from 'file-saver';
import { isNaN } from 'lodash';
import { toast } from 'react-toastify';
import { Buffer } from 'buffer';

type Method = 'get' | 'put' | 'post' | 'delete' | 'patch';

const axiosOptions = {
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 45000,
    withCredentials: true,
};

export const api = Axios.create(axiosOptions);

export const blobApi = Axios.create({
    ...axiosOptions,
    timeout: 60000,
    responseType: 'blob',
});

export const zipApi = Axios.create({
    ...axiosOptions,
    responseType: 'arraybuffer',
});

export const setBasic = function (username: string, password: string) {
    const basic = Buffer.from(`${username}:${password}`).toString('base64');
    api.defaults.headers.common['Authorization'] = `Basic ${basic}`;
    blobApi.defaults.headers.common['Authorization'] = `Basic ${basic}`;
    zipApi.defaults.headers.common['Authorization'] = `Basic ${basic}`;
};

export const setBearer = function (bearer: string) {
    api.defaults.headers.common['Authorization'] = `Bearer ${bearer}`;
    blobApi.defaults.headers.common['Authorization'] = `Bearer ${bearer}`;
    zipApi.defaults.headers.common['Authorization'] = `Bearer ${bearer}`;
};

export const resetAuthorization = function () {
    api.defaults.headers.common['Authorization'] = '';
    blobApi.defaults.headers.common['Authorization'] = '';
    zipApi.defaults.headers.common['Authorization'] = '';
};

/**
 * @deprecated The apiCall_v2 method should be preferred over this method for argument clarity and extensibility.
Once all instances of apiCall have been migrated to apiCall_v2 the apiCall method will be removed.
*/
export const apiCall = async (
    method: Method,
    url: string,
    payload = {},
    isResRequired = false,
    isResponseTypeBlob = false,
    isResponseTypeZip = false,
    showBackendErrorToast = false,
) =>
    await apiCall_v2({
        method,
        url,
        payload,
        isResRequired,
        isResponseTypeBlob,
        isResponseTypeZip,
        showBackendErrorToast,
    });

interface ApiCall {
    method: Method;
    url: string;
    payload?: object;
    isResRequired?: boolean;
    isResponseTypeBlob?: boolean;
    isResponseTypeZip?: boolean;
    showBackendErrorToast?: boolean;
}

export const apiCall_v2 = async ({
    method,
    url,
    payload = {},
    isResRequired = false,
    isResponseTypeBlob = false,
    isResponseTypeZip = false,
    showBackendErrorToast = false,
}: ApiCall) => {
    try {
        const axiosInstance = isResponseTypeBlob
            ? blobApi
            : isResponseTypeZip
              ? zipApi
              : api;
        const res = await axiosInstance[method](url, payload);

        if (res && isResRequired) {
            return res;
        } else if (res && res.status >= 200 && res.status < 300) {
            return res.data;
        } else {
            throw res;
        }
    } catch (error) {
        return handleError(error, showBackendErrorToast);
    }
};

type FileType = 'pdf' | 'xlsx';

interface ApiDownload extends ApiCall {
    fileName: string;
    fileType: FileType;
}

export const apiDownload = async ({
    method,
    url,
    payload = undefined,
    isResRequired = true,
    isResponseTypeBlob = true,
    isResponseTypeZip = false,
    showBackendErrorToast = false,
    fileName = 'download',
    fileType,
}: ApiDownload): Promise<undefined> => {
    const response = await apiCall_v2({
        method,
        url,
        payload,
        isResRequired,
        isResponseTypeBlob,
        isResponseTypeZip,
        showBackendErrorToast,
    });
    const fileBlob = new Blob([response.data], {
        type: 'application/octet-stream',
    });
    saveAs(fileBlob, `${fileName}.${fileType}`);
};

export const genericToastError = (
    message = 'There was an issue handling your request. Please contact a site administrator.',
) => {
    toast.error(message, {
        position: 'top-right',
        autoClose: 15000,
    });
};

export const handleError = (
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    { response }: any,
    showBackendErrorToast: boolean,
) => {
    if (showBackendErrorToast && response?.data?.title) {
        genericToastError(response.data.title);
    }
    const status = isNaN(+response?.data?.type)
        ? response?.status
        : response?.data?.type;

    const title = response?.data?.title;
    return { status, title };
};
