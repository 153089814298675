import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    IExistingFeedEntryPenDetail,
    IFeedEntryPenDetail,
    IFeedEntryPenDetailError,
    IFeedEntryRationDetails,
    IFeedEntryRationDetailsError,
    ILots,
    IPens,
    IRations,
} from '../../utils/Interface/ManualFeedEntryInterface';
import { MANUAL_FEED_ENTRY_INITIAL_STATE } from '../Constants/manualFeedEntryInitialState';

export const ManualFeedEntry = createSlice({
    name: 'ManualFeedEntry',
    initialState: { ...MANUAL_FEED_ENTRY_INITIAL_STATE },
    reducers: {
        setManualFeedEntryLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setLots: (state, action: PayloadAction<ILots[]>) => {
            state.lots = action.payload;
        },
        setRations: (state, action: PayloadAction<IRations[]>) => {
            state.rations = action.payload;
        },
        setPens: (state, action: PayloadAction<IPens[]>) => {
            state.pens = action.payload;
        },
        setManualFeedEntryRationDetails: (
            state,
            action: PayloadAction<IFeedEntryRationDetails>,
        ) => {
            state.feedEntryRationDetails = action.payload;
        },
        setManualFeedEntryRationDetailsError: (
            state,
            action: PayloadAction<IFeedEntryRationDetailsError>,
        ) => {
            state.feedEntryRationDetailsError = action.payload;
        },
        setManualFeedEntryPensDetails: (
            state,
            action: PayloadAction<IFeedEntryPenDetail[]>,
        ) => {
            state.feedEntryPensDetails = action.payload;
        },
        setManualFeedEntryExistingPensDetailsRow: (state, action) => {
            state.existingFeedEntryPensDetails[action.payload.index] =
                action.payload.data;
        },
        setManualFeedEntryExistingPensDetails: (
            state,
            action: PayloadAction<IExistingFeedEntryPenDetail[]>,
        ) => {
            state.existingFeedEntryPensDetails = action.payload;
        },
        setManualFeedEntryFetchedExistingPensDetails: (
            state,
            action: PayloadAction<IExistingFeedEntryPenDetail[]>,
        ) => {
            state.fetchedExistingFeedEntryPensDetails = action.payload;
        },
        setManualFeedEntryExistingPensDetailsError: (
            state,
            action: PayloadAction<IFeedEntryPenDetailError[]>,
        ) => {
            state.existingFeedEntryPensDetailsError = action.payload;
        },
        setShowEstimatedHdCountField: (
            state,
            action: PayloadAction<boolean>,
        ) => {
            state.showEstimatedHdCountField = action.payload;
        },
        setIsExistingEstHdCountModalReadonly: (
            state,
            action: PayloadAction<boolean>,
        ) => {
            state.isExistingEstHdCountModalReadonly = action.payload;
        },
        setMfeSelectedPenIndex: (state, action: PayloadAction<number>) => {
            state.selectedPenIndex = action.payload;
        },
        setIsRecordSubmitted: (state, action: PayloadAction<boolean>) => {
            state.isRecordSubmitted = action.payload;
        },
        setSubmitPending: (state, action: PayloadAction<boolean>) => {
            state.submitPending = action.payload;
        },
    },
});

export const {
    setManualFeedEntryLoading,
    setLots,
    setRations,
    setPens,
    setManualFeedEntryExistingPensDetails,
    setManualFeedEntryFetchedExistingPensDetails,
    setManualFeedEntryExistingPensDetailsError,
    setManualFeedEntryRationDetails,
    setManualFeedEntryRationDetailsError,
    setManualFeedEntryPensDetails,
    setManualFeedEntryExistingPensDetailsRow,
    setShowEstimatedHdCountField,
    setIsExistingEstHdCountModalReadonly,
    setMfeSelectedPenIndex,
    setIsRecordSubmitted,
    setSubmitPending,
} = ManualFeedEntry.actions;

const manualFeedEntryReducer = ManualFeedEntry.reducer;
export default manualFeedEntryReducer;
