import { useAppSelector } from '../Redux/Store';
import { useEffect, useState } from 'react';
import { feedlotManagerFeatures } from '../apps/feedlotManager/featureFlags/feedlotManagerFeatures';

const { isCA10586On_refactorFeedCall } = feedlotManagerFeatures;

const useFeedlotId_dep = () => {
    const [feedlotId, setFeedlotId] = useState<number>();
    const selectedFeedlot = useAppSelector(
        state => state.common.selectedFeedlot,
    );
    useEffect(() => {
        setFeedlotId(selectedFeedlot.id);
    }, [selectedFeedlot]);
    return feedlotId;
};

const useFeedlotId_CA10586 = () =>
    useAppSelector(state => state.common.selectedFeedlot.id);

const useFeedlotId = isCA10586On_refactorFeedCall
    ? useFeedlotId_CA10586
    : useFeedlotId_dep;

export { useFeedlotId };
