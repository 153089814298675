const InventoryIcon = (props: any) => {
    return (
        <img
            {...props}
            alt="inventory_icon.png"
            src={require('./inventory_icon.png')}
        />
    );
};

export default InventoryIcon;
