import { ChangeEventHandler, FormEventHandler, useState } from 'react';
import { Form } from 'react-bootstrap';
import Constants from '../../../../../utils/Constants';
import { apiCall_v2 } from '../../../../../Services/AxiosService';
import { useTranslation } from 'react-i18next';
import { AuthFormContainer } from '../components/AuthFormContainer';
import { emailValidation } from '../../../../../utils/validation';
import { useNavigate } from 'react-router-dom';

export const ForgotPasswordPage = () => {
    const { t } = useTranslation('auth');
    const navigate = useNavigate();

    const [email, setEmail] = useState<string>('');
    const [emailError, setEmailError] = useState<boolean>(false);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [isSuccessful, setIsSuccessful] = useState<boolean>(false);

    const handleChange: ChangeEventHandler<HTMLInputElement> = event => {
        setIsSuccessful(false);
        setEmail(event.currentTarget.value);
    };

    const handleSubmit: FormEventHandler<HTMLFormElement> = async event => {
        event.preventDefault();
        if (validateForm()) {
            setIsProcessing(true);
            await sendResetPasswordEmail();
            setIsProcessing(false);
        }
    };

    const validateForm = (): boolean => {
        const emailError = !emailValidation(email);
        setEmailError(emailError);

        return !emailError;
    };

    const sendResetPasswordEmail = async () => {
        const res = await apiCall_v2({
            method: 'post',
            url: `${Constants.apiUrls.RESET_PASSWORD}?username=${email}`,
            isResRequired: true,
            showBackendErrorToast: true,
        });
        if (res?.status === Constants.responseCode.NO_CONTENT) {
            setIsSuccessful(true);
        }
    };

    return (
        <AuthFormContainer
            heading={t('forgotPasswordPage.heading')}
            handleSubmit={handleSubmit}
            submitButtonLabel={t('forgotPasswordPage.send')}
            isSubmitPending={isProcessing}
            isSubmitButtonDisabled={isSuccessful}
            handleCancel={() => navigate(-1)}
            cancelButtonLabel={t('forgotPasswordPage.cancel')}
        >
            <Form.Group>
                <Form.Label className="mb-1">
                    {t('forgotPasswordPage.email')}
                </Form.Label>
                <Form.Control
                    type="text"
                    name="email"
                    value={email}
                    onChange={handleChange}
                    required
                    className="mb-2"
                    isInvalid={emailError}
                />
                <Form.Control.Feedback type="invalid">
                    {t('forgotPasswordPage.validEmail')}
                </Form.Control.Feedback>
                <Form.Text
                    className={`${isSuccessful ? '' : 'invisible'} text-muted`}
                >
                    {t('forgotPasswordPage.successMessage')}
                </Form.Text>
            </Form.Group>
        </AuthFormContainer>
    );
};
