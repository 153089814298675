import { Modal } from 'react-bootstrap';
import { CommonConstants } from '../../utils/constants/CommonConstants';
import {
    ConfirmationModalOptions,
    IConfirmationModal,
} from '../../utils/Interface/CommonInterface';
import { useLanguage } from '../../commonHooks/useLocalization';
import SpinnerSubmitButton from './SpinnerSubmitButton';

export const ConfirmationModal = ({
    showModal,
    confirmationModalHandler,
    confirmationMessage,
    cancelLabel,
    confirmLabel,
    disabled = false,
    activeButton,
}: IConfirmationModal) => {
    const language = useLanguage();

    return (
        <Modal
            className="px-2 pt-5"
            data-size="md"
            show={showModal}
            centered={true}
            animation={true}
            onHide={() => confirmationModalHandler(false)}
        >
            <Modal.Body>
                <Modal.Title className="text-secondary">
                    {confirmationMessage}
                </Modal.Title>
            </Modal.Body>

            <Modal.Footer>
                <SpinnerSubmitButton
                    disabled={disabled}
                    variant="secondary"
                    submitPending={activeButton === ConfirmationModalOptions.No}
                    buttonContent={
                        cancelLabel ?? CommonConstants[language.value].no
                    }
                    onClick={() => confirmationModalHandler(false)}
                />
                <SpinnerSubmitButton
                    disabled={disabled}
                    variant="secondary"
                    submitPending={
                        activeButton === ConfirmationModalOptions.Yes
                    }
                    buttonContent={
                        confirmLabel ?? CommonConstants[language.value].yes
                    }
                    onClick={() => confirmationModalHandler(true)}
                />
            </Modal.Footer>
        </Modal>
    );
};

export default ConfirmationModal;
