import StorageService from '../../Services/storage/storage.service';
import Constants from '../../utils/Constants';
import { ICustomer } from '../../utils/Interface/FinancialApp/CustomerInterface';
import { IOwner } from './ownerContextInterface';

export const allOwnersOption: IOwner = { id: '', name: 'All' };

export const mapCustomersToOwners = (customers: ICustomer[]): IOwner[] => {
    if (customers.length) {
        const owners: IOwner[] = customers.map(({ id, name }) => ({
            id,
            name,
        }));
        if (owners.length > 1) {
            owners.unshift(allOwnersOption);
        }
        return owners;
    } else {
        return [allOwnersOption];
    }
};

export const getSelectedOwnerFromStorage = (owners: IOwner[]): IOwner => {
    const ownerId = getSelectedOwnerIdFromStorage();
    const owner = getOwnerFromOwnerId(owners, ownerId);
    return owner;
};

export const getSelectedOwnerIdFromStorage = (): string => {
    const storage = new StorageService();
    const ownerId =
        storage.getValue('selectedOwnerId', Constants.Storage.LOCAL) ?? '';
    return ownerId;
};

export const setSelectedOwnerIdToStorage = (ownerId: string): void => {
    const storage = new StorageService();
    storage.setValue('selectedOwnerId', ownerId, Constants.Storage.LOCAL);
};

export const getOwnerFromOwnerId = (
    owners: IOwner[],
    ownerId: string,
): IOwner =>
    owners.find(owner => owner.id === ownerId) ?? owners[0] ?? allOwnersOption;
