import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IFilter } from '../../utils/Interface/VendorInterface';
import { VENDOR_INITIAL_STATE } from '../Constants/vendorInitialState';

const vendorSlice = createSlice({
    name: 'Vendor',
    initialState: { ...VENDOR_INITIAL_STATE },
    reducers: {
        setVendorFilter: (state, action: PayloadAction<IFilter>) => {
            state.filter = action.payload;
        },
        setVendorDetailsLoader: (state, action: PayloadAction<boolean>) => {
            state.loader = action.payload;
        },
    },
});

export const { setVendorFilter, setVendorDetailsLoader } = vendorSlice.actions;
export const VendorSlice = vendorSlice.reducer;
