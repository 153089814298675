import { createSlice } from '@reduxjs/toolkit';

const lotId = createSlice({
    name: 'id',
    initialState: '',
    reducers: {
        setId: (state, action) => {
            return action.payload;
        },
    },
});

export const { setId } = lotId.actions;
export default lotId.reducer;
