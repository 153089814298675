import { Route, Routes } from 'react-router-dom';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import {
    authenticatedRoutes,
    privateAppRoutes,
    publicRoutes,
    unauthenticatedRoutes,
} from '../constants/RoleConstants';
import UnauthenticatedRoute from './UnauthenticatedRoute';
import AuthenticatedRoute from './AuthenticatedRoute';
import PrivateRoute from './PrivateRoute';
import { IAuth } from '../Interface/AuthInterface';

export const getRoutesList = () => {
    return (
        <Routes>
            {createPublicRoutes()}
            {createUnauthenticatedRoutes()}
            {createAuthenticatedRoutes()}
            {createPrivateRoutes()}
        </Routes>
    );
};

export const handleEnvRedirect = (auth: IAuth): boolean => {
    let isRedirecting = false;
    const hostName = window.location.hostname.toLowerCase();
    const isProductionSite =
        !hostName.includes('az') && !hostName.includes('localhost');

    if (auth.hasDevRestriction() && isProductionSite) {
        isRedirecting = true;
        window.location.href = 'https://azdev.feedlotmgr.com';
    } else if (auth.hasBetaRestriction() && !hostName.includes('beta')) {
        isRedirecting = true;
        window.location.href = isProductionSite
            ? 'https://beta.feedlotmgr.com'
            : 'https://azbetatest.feedlotmgr.com';
    }

    return isRedirecting;
};

const createPublicRoutes = () =>
    publicRoutes.map((item, index) => (
        <Route key={index} path={item.path} element={item.component} />
    ));

const createUnauthenticatedRoutes = () =>
    unauthenticatedRoutes.map((item, index) => (
        <Route key={index} path={item.path} element={<UnauthenticatedRoute />}>
            <Route index element={item.component} />
        </Route>
    ));

const createAuthenticatedRoutes = () =>
    authenticatedRoutes.map((item, index) => (
        <Route key={index} path={item.path} element={<AuthenticatedRoute />}>
            <Route index element={item.component} />
        </Route>
    ));

const createPrivateRoutes = () =>
    Object.values(privateAppRoutes)
        .map(({ appRole, routes }) =>
            routes.map((route, index) => (
                <Route
                    key={index}
                    path={route.path}
                    element={
                        <PrivateRoute
                            path={route.path}
                            allowedRoles={route.roles}
                            appRole={appRole}
                        />
                    }
                >
                    <Route
                        index
                        element={
                            <ErrorBoundary
                                key={index}
                                FallbackComponent={ErrorFallback}
                            >
                                {route.component}
                            </ErrorBoundary>
                        }
                    />
                </Route>
            )),
        )
        .flat(1);

const ErrorFallback = ({ error }: FallbackProps) => {
    return (
        <div role="alert">
            <p>Something went wrong:</p>
            <pre>{error.message}</pre>
        </div>
    );
};
