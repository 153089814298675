import { useLanguage } from '../../commonHooks/useLocalization';
import { HospitalConstants } from '../../utils/constants/HospitalConstants';

const HospitalAdminHistoryHeader = () => {
    const language = useLanguage();

    return (
        <thead>
            <tr>
                <th className="col-1">
                    {HospitalConstants[language.value].history.adminDate.label}
                </th>
                <th className="col-1">
                    {HospitalConstants[language.value].history.diagnosis.label}
                </th>
                <th className="col-1">
                    {HospitalConstants[language.value].history.protocol.label}
                </th>
                <th className="col-1">
                    {HospitalConstants[language.value].history.rider.label}
                </th>
            </tr>
        </thead>
    );
};

export default HospitalAdminHistoryHeader;
