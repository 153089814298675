import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { BUYER_INITIAL_STATE } from '../Constants/buyerInitialState';
import { IFilter } from '../../utils/Interface/BuyerInterface';

const buyerSlice = createSlice({
    name: 'Buyer',
    initialState: { ...BUYER_INITIAL_STATE },
    reducers: {
        setBuyerFilter: (state, action: PayloadAction<IFilter>) => {
            state.filter = action.payload;
        },
        setBuyerDetailsLoader: (state, action: PayloadAction<boolean>) => {
            state.loader = action.payload;
        },
    },
});

export const { setBuyerFilter, setBuyerDetailsLoader } = buyerSlice.actions;
export const BuyerSlice = buyerSlice.reducer;
