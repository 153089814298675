import { useCallback, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import {
    setContractModalDetails,
    setContractModalDetailsError,
} from '../actions/ScaleTicketActions';
import { SCALE_TICKET_INITIAL_STATE } from '../constants/scaleTicketsInitialState';
import { useAppDispatch, useAppSelector } from '../../../../Redux/Store';
import { ScaleTicketModalConstants } from '../constants/ScaleTicketConstants';
import { IScaleTicketModal } from '../interfaces/ScaleTicketInterface';
import ContractFormFooter from '../../manageCommodities/components/ContractFormFooter';
import ContractFormBody from '../../manageCommodities/components/ContractFormBody';
import { useLocation } from 'react-router-dom';
import Constants from '../../../../utils/Constants';

const CreateContractModal = ({ show, onHide }: IScaleTicketModal) => {
    const { createScaleTicketDetails, editScaleTicketDetails } = useAppSelector(
        state => state.scaleTicket,
    );
    const [isEditModal, setIsEditModal] = useState<boolean>(false);

    const location = useLocation();
    const dispatch = useAppDispatch();

    const resetAllStates = useCallback(() => {
        dispatch(
            setContractModalDetails(
                SCALE_TICKET_INITIAL_STATE.contractModalDetails,
            ),
        );
        dispatch(
            setContractModalDetailsError(
                SCALE_TICKET_INITIAL_STATE.contractModalDetailsError,
            ),
        );
    }, [dispatch]);

    const setModalDetails = (
        commodityId: number,
        vendorId: number,
        isEdit: boolean,
    ) => {
        const contractModalDetailsCopy = {
            ...SCALE_TICKET_INITIAL_STATE.contractModalDetails,
            commodityId,
            vendorId,
        };
        dispatch(setContractModalDetails(contractModalDetailsCopy));
        setIsEditModal(isEdit);
        dispatch(
            setContractModalDetailsError(
                SCALE_TICKET_INITIAL_STATE.contractModalDetailsError,
            ),
        );
    };

    useEffect(() => {
        const { pathname } = location;
        if (
            pathname === Constants.Routes.CREATE_INBOUND_SCALE_TICKETS ||
            pathname === Constants.Routes.CREATE_OUTGOING_SCALE_TICKETS
        ) {
            setModalDetails(
                createScaleTicketDetails.scaleTicketBasicDetails.commodity,
                createScaleTicketDetails.scaleTicketBasicDetails.vendor,
                false,
            );
        } else if (
            pathname.includes(Constants.Routes.UPDATE_INBOUND_SCALE_TICKETS) ||
            pathname.includes(Constants.Routes.UPDATE_OUTGOING_SCALE_TICKETS)
        ) {
            setModalDetails(
                editScaleTicketDetails.commodity,
                editScaleTicketDetails.vendor,
                true,
            );
        } else {
            resetAllStates();
        }
    }, [
        location.pathname,
        createScaleTicketDetails.scaleTicketBasicDetails.commodity,
        createScaleTicketDetails.scaleTicketBasicDetails.vendor,
        editScaleTicketDetails.commodity,
        editScaleTicketDetails.vendor,
        resetAllStates,
    ]);

    return (
        <Modal
            className="px-2 pt-5"
            data-size="md"
            show={show}
            centered
            animation
            onHide={onHide}
        >
            <Modal.Header closeButton>
                <Modal.Title className="text-secondary">
                    <h5 className="modal-title">
                        {ScaleTicketModalConstants.contractModal.title}
                    </h5>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ContractFormBody />
            </Modal.Body>
            <Modal.Footer>
                <ContractFormFooter onHide={onHide} isEditModal={isEditModal} />
            </Modal.Footer>
        </Modal>
    );
};

export default CreateContractModal;
