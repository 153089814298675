import {
    EditScaleTicketConstants,
    ScaleTicketModalConstants,
} from '../../scaleTickets/constants/ScaleTicketConstants';
import { useAppDispatch, useAppSelector } from '../../../../Redux/Store';
import {
    createContractService,
    setContractModalDetailsError,
    setCreateScaleTicketDetails,
    setEditScaleTicketDetails,
    updateContract,
} from '../../scaleTickets/actions/ScaleTicketActions';
import { feedlotManagerFeatures } from '../../../../apps/feedlotManager/featureFlags/feedlotManagerFeatures';
import { useFeedlotId } from '../../../../commonHooks/useFeedlotId';
import { useNavigate, useParams } from 'react-router-dom';

const { isCA9800On_ContractsMVP } = feedlotManagerFeatures;

const ContractFormFooter = ({
    onHide,
    isEditModal,
}: {
    onHide?: () => void;
    isEditModal?: boolean;
}) => {
    const feedlotId = useFeedlotId();
    const navigate = useNavigate();
    const { contractId } = useParams();
    const dispatch = useAppDispatch();
    const {
        contractModalDetails,
        contractModalDetailsError,
        editScaleTicketDetails,
        createScaleTicketDetails,
        loading,
    } = useAppSelector(state => state.scaleTicket);

    const onSubmit = () => {
        const isValidMandatoryDetails = validateMandatoryDetails();

        if (isValidMandatoryDetails && feedlotId) {
            if (contractId) {
                dispatch(
                    updateContract(
                        feedlotId,
                        buildContractPayload(true),
                        +contractId,
                    ),
                );
            } else if (onHide) {
                dispatch(
                    createContractService(
                        isEditModal
                            ? buildContractPayload()
                            : buildContractPayload(true),
                        feedlotId,
                        isEditModal
                            ? updateEditScaleTicketDetails
                            : updateCreateScaleTicketDetails,
                        onHide,
                    ),
                );
            }
        }
    };

    const onCancel = () => {
        if (onHide) {
            onHide();
        } else {
            navigate(-1);
        }
    };

    const validateMandatoryDetails = () => {
        const contractModalDetailsErrorCopy = {
            ...contractModalDetailsError,
        };
        const contractModalDetailsFields = Object.keys(
            contractModalDetailsErrorCopy,
        );
        let isValid = true;

        contractModalDetailsFields.forEach(fieldName => {
            const isFreightAndZero =
                fieldName ===
                    ScaleTicketModalConstants.contractModal.apiFields
                        .freightType &&
                String(contractModalDetails.freightType) === '0';

            const isBlankText =
                typeof contractModalDetails[fieldName] === 'string' &&
                contractModalDetails[fieldName].trim() === '';

            const fieldAllowsZero =
                ScaleTicketModalConstants.contractModal.allowedZeroFields.includes(
                    fieldName,
                );

            const isMandatory =
                ScaleTicketModalConstants.contractModal.mandatoryFields.includes(
                    fieldName,
                );

            const isEmpty = !contractModalDetails[fieldName];

            const fieldIsNonZeroEmpty =
                isEmpty && !(+contractModalDetails[fieldName] === 0);

            if (
                isFreightAndZero ||
                ((isEmpty || isBlankText) &&
                    isMandatory &&
                    (!fieldAllowsZero || !fieldIsNonZeroEmpty))
            ) {
                contractModalDetailsErrorCopy[fieldName] = true;
                isValid = false;
            }
        });
        if (isCA9800On_ContractsMVP) {
            contractModalDetailsErrorCopy.price =
                !exactlyOnePriceOptionSelected();
            contractModalDetailsErrorCopy.notPriced =
                !exactlyOnePriceOptionSelected();
        }

        if (!isValid) {
            dispatch(
                setContractModalDetailsError(contractModalDetailsErrorCopy),
            );
        }

        return isValid;
    };

    const exactlyOnePriceOptionSelected = () => {
        return (
            (contractModalDetails.price || contractModalDetails.notPriced) &&
            !(contractModalDetails.price && contractModalDetails.notPriced)
        );
    };

    const updateEditScaleTicketDetails = (
        contractId: number,
        price: string | number,
        freightRate?: string | number,
    ) => {
        const editScaleTicketDetailsCopy = {
            ...editScaleTicketDetails,
            contractId,
            price,
            freightRate,
        };

        dispatch(setEditScaleTicketDetails(editScaleTicketDetailsCopy));
    };

    const updateCreateScaleTicketDetails = (
        contractId: number,
        price: string | number,
    ) => {
        const createScaleTicketDetailsCopy = {
            ...createScaleTicketDetails,
            scaleTicketBasicDetails: {
                ...createScaleTicketDetails.scaleTicketBasicDetails,
                contractId,
                price,
            },
        };

        dispatch(setCreateScaleTicketDetails(createScaleTicketDetailsCopy));
    };

    const buildContractPayload = (isUpdate = false) => {
        const scaleType = isEditModal
            ? editScaleTicketDetails.scaleType
            : createScaleTicketDetails.scaleTicketBasicDetails.scaleType;
        return {
            contractName: contractModalDetails.contractName,
            price: contractModalDetails.price,
            commodityId: isUpdate
                ? contractModalDetails.commodityId
                : editScaleTicketDetails.commodity,
            vendorId: isUpdate
                ? contractModalDetails.vendorId
                : editScaleTicketDetails.vendor,
            payee: contractModalDetails.payee,
            totalAmount: contractModalDetails.totalAmount,
            contractCreationDate: contractModalDetails.contractCreationDate,
            dmPercentage: contractModalDetails.dmPercentage,
            freight: contractModalDetails.freightType,
            rate: +contractModalDetails.rate,
            comment: contractModalDetails.comment,
            contractType:
                scaleType == EditScaleTicketConstants.scaleType.inbound
                    ? 'Inbound'
                    : 'Outbound',
            feedlotId,
        };
    };

    return (
        <>
            <button
                type="button"
                className="secondary me-4"
                data-bs-dismiss="modal"
                onClick={() => {
                    onCancel();
                }}
            >
                {ScaleTicketModalConstants.contractModal.button.cancel}
            </button>
            <button
                type="button"
                className="me-4"
                disabled={loading}
                onClick={() => {
                    onSubmit();
                }}
            >
                {ScaleTicketModalConstants.contractModal.button.submit}
            </button>
        </>
    );
};

export default ContractFormFooter;
