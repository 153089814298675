import { IOwner, IOwnerInitialState } from './ownerInterfaces';

export const createOwnerOption: IOwner = {
    id: 'Create Owner',
    name: 'Create Owner',
};

export const allOwnersOption: IOwner = { id: 'All Owners', name: 'All Owners' };

export const OWNERS_INITIAL_STATE: IOwnerInitialState = {
    owners: [],
    ownersWithCreate: [createOwnerOption],
};
