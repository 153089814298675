import { apiCall_v2 } from '../../Services/AxiosService';
import Constants from '../../utils/Constants';
import {
    ICreateTank,
    IDeleteTank,
    IFetchTankDetails,
    IFilter,
    IUpdateTankDetail,
    IUpdateTankDetailOptions,
} from '../../utils/Interface/TankInterface';
import { getToastContent } from '../../utils/ToastContent';
import { toastMessage } from '../Reducer/common';
import { setTankDetailsLoader } from '../Reducer/tankSlice';
import { AppDispatch } from '../Store';

const getFilterUrl = (filter: IFilter) => {
    let filterUrl = `&filter=contains(Name, '${filter.name}')`;
    if (filter?.show && filter?.show !== 'all') {
        const isActive = filter.show === 'active';
        filterUrl += ` and inactivated eq ${!isActive}`;
    }
    return filterUrl;
};

const formatResponse = response => {
    return response.map(tank => ({
        ...tank,
        isAssociated: !!tank.commodityId,
    }));
};

export const fetchTankDetails: IFetchTankDetails =
    (feedlotId, setTankDetail, filter) => async (dispatch: AppDispatch) => {
        dispatch(setTankDetailsLoader(true));
        const filterUrl = filter ? getFilterUrl(filter) : '';
        const response = await apiCall_v2({
            method: 'get',
            url: `${Constants.apiUrls.FEED_LOT_MANAGER}/${feedlotId}${Constants.apiUrls.TANKS}/odata?flid=${feedlotId}${filterUrl}&orderBy=Name`,
        });
        const formattedResponse = formatResponse(response);
        setTankDetail(formattedResponse ?? []);
        dispatch(setTankDetailsLoader(false));
    };

export const updateTankDetail: IUpdateTankDetail =
    (feedlotId, tankDetail, options?: IUpdateTankDetailOptions) =>
    async (dispatch: AppDispatch) => {
        options?.setLoading?.(true);
        const response = await apiCall_v2({
            method: 'put',
            url: `${Constants.apiUrls.FEED_LOT_MANAGER}/${feedlotId}${Constants.apiUrls.TANKS}/${tankDetail.tankId}`,
            payload: tankDetail,
            isResRequired: true,
        });

        options?.setLoading?.(false);
        if (!response) return;
        const status = Number(response.status);

        if (status === Constants.responseCode.DUPLICATE) {
            dispatch(
                toastMessage(
                    getToastContent(
                        response.status,
                        '',
                        Constants.customMessage.TANK_DUPLICATE,
                    ),
                ),
            );
        } else if (status === Constants.responseCode.CONFLICT) {
            options?.handleActivateTank?.();
        } else {
            dispatch(toastMessage(getToastContent(response.status)));
            options?.closeModalWithChanges?.(true);
        }
        options?.fetchTankData?.();
    };

export const createTank: ICreateTank =
    (feedlotId, tank, closeModalWithChanges, setLoading, handleActivateTank) =>
    async (dispatch: AppDispatch) => {
        setLoading(true);
        const response = await apiCall_v2({
            method: 'post',
            url: `${Constants.apiUrls.FEED_LOT_MANAGER}/${feedlotId}${Constants.apiUrls.TANKS}`,
            payload: tank,
            isResRequired: true,
        });
        setLoading(false);
        if (!response) return;
        const status = Number(response.status);

        if (status === Constants.responseCode.DUPLICATE) {
            dispatch(
                toastMessage(
                    getToastContent(
                        status,
                        '',
                        Constants.customMessage.TANK_DUPLICATE,
                    ),
                ),
            );
        } else if (status === Constants.responseCode.CONFLICT) {
            handleActivateTank();
        } else {
            dispatch(toastMessage(getToastContent(status)));
            closeModalWithChanges(true);
        }
    };

export const fetchInactiveTankInfo =
    (feedlotId: number, tank: string) => async (dispatch: AppDispatch) => {
        const response = await apiCall_v2({
            method: 'get',
            url: `${Constants.apiUrls.FEED_LOT_MANAGER}/${feedlotId}${Constants.apiUrls.TANKS}/odata?filter=name eq '${tank}'`,
            isResRequired: true,
        });

        if (!response) return;
        const status = Number(response.status);

        if (
            response.status >= Constants.responseCode.SUCCESS &&
            response.status < Constants.responseCode.MULTIPLE_CHOICES
        ) {
            return response.data;
        } else {
            dispatch(toastMessage(getToastContent(status)));
        }
    };

export const deleteTank: IDeleteTank =
    (feedlotId, tank, fetchTankData?) => async (dispatch: AppDispatch) => {
        dispatch(setTankDetailsLoader(true));
        const response = await apiCall_v2({
            method: 'delete',
            url: `${Constants.apiUrls.FEED_LOT_MANAGER}/${feedlotId}${Constants.apiUrls.TANKS}/${tank.tankId}`,
            payload: tank,
            isResRequired: true,
        });
        dispatch(setTankDetailsLoader(false));
        if (!response) return;
        dispatch(toastMessage(getToastContent(Number(response.status))));
        fetchTankData?.();
    };
