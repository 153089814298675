import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { DATABRICKS_INITIAL_STATE } from '../Constants/databricksInitialState';
import { RunDataModel } from '../../utils/Interface/DatabricksInterface';

const databricksSlice = createSlice({
    name: 'databricks',
    initialState: { ...DATABRICKS_INITIAL_STATE },
    reducers: {
        setRunData: (state, action: PayloadAction<RunDataModel>) => {
            state.runData = action.payload;
        },
        resetDatabricksState: () => {
            return DATABRICKS_INITIAL_STATE;
        },
    },
});

export const databricksActions = databricksSlice.actions;

export const { setRunData, resetDatabricksState } = databricksActions;

const databricksReducer = databricksSlice.reducer;
export default databricksReducer;
