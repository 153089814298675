import { apiCall_v2 } from '../../Services/AxiosService';
import Constants from '../../utils/Constants';
import {
    ICreateTech,
    IDeleteTech,
    IFetchTechDetails,
    IFilter,
    IUpdateTechDetail,
    IUpdateTechDetailOptions,
} from '../../utils/Interface/TechInterface';
import { getToastContent } from '../../utils/ToastContent';
import { toastMessage } from '../Reducer/common';
import { setTechDetailsLoader } from '../Reducer/techSlice';
import { AppDispatch } from '../Store';

const getFilterUrl = (filter: IFilter) => {
    let filterUrl = `&filter=contains(Name, '${filter.name}')`;
    if (filter?.show && filter?.show !== 'all') {
        const isActive = filter.show === 'active';
        filterUrl += ` and active eq ${isActive}`;
    }
    return filterUrl;
};

export const fetchTechDetails: IFetchTechDetails =
    (feedlotId, setTechDetail, filter) => async (dispatch: AppDispatch) => {
        dispatch(setTechDetailsLoader(true));
        const filterUrl = filter ? getFilterUrl(filter) : '';
        const response = await apiCall_v2({
            method: 'get',
            url: `${Constants.apiUrls.FEED_LOT_MANAGER}/${feedlotId}${Constants.apiUrls.TECH}/odata?flid=${feedlotId}${filterUrl}&orderBy=Name`,
        });
        setTechDetail(response ?? []);
        dispatch(setTechDetailsLoader(false));
    };

export const updateTechDetail: IUpdateTechDetail =
    (feedlotId, techDetail, options?: IUpdateTechDetailOptions) =>
    async (dispatch: AppDispatch) => {
        options?.setLoading?.(true);
        const response = await apiCall_v2({
            method: 'put',
            url: `${Constants.apiUrls.FEED_LOT_MANAGER}/${feedlotId}${Constants.apiUrls.TECH}/${techDetail.techId}`,
            payload: techDetail,
            isResRequired: true,
        });

        options?.setLoading?.(false);
        if (!response) return;
        const status = Number(response.status);

        if (status === Constants.responseCode.DUPLICATE) {
            dispatch(
                toastMessage(
                    getToastContent(
                        response.status,
                        '',
                        Constants.customMessage.TECH_DUPLICATE,
                    ),
                ),
            );
        } else if (status === Constants.responseCode.CONFLICT) {
            options?.handleActivateTech?.();
        } else {
            dispatch(toastMessage(getToastContent(response.status)));
            options?.closeModalWithChanges?.(true, techDetail.techId);
        }
        options?.fetchTechData?.();
    };

export const createTech: ICreateTech =
    (feedlotId, tech, closeModalWithChanges, setLoading, handleActivateTech) =>
    async (dispatch: AppDispatch) => {
        setLoading(true);
        const response = await apiCall_v2({
            method: 'post',
            url: `${Constants.apiUrls.FEED_LOT_MANAGER}/${feedlotId}${Constants.apiUrls.TECH}`,
            payload: tech,
            isResRequired: true,
        });
        setLoading(false);
        if (!response) return;
        const status = Number(response.status);

        if (status === Constants.responseCode.DUPLICATE) {
            dispatch(
                toastMessage(
                    getToastContent(
                        status,
                        '',
                        Constants.customMessage.TECH_DUPLICATE,
                    ),
                ),
            );
        } else if (status === Constants.responseCode.CONFLICT) {
            handleActivateTech();
        } else {
            dispatch(toastMessage(getToastContent(status)));
            closeModalWithChanges(true, response.data);
        }
    };

export const fetchInactiveTechInfo =
    (feedlotId: number, tech: string) => async (dispatch: AppDispatch) => {
        const response = await apiCall_v2({
            method: 'get',
            url: `${Constants.apiUrls.FEED_LOT_MANAGER}/${feedlotId}${Constants.apiUrls.TECH}/odata?filter=name eq '${tech}'`,
            isResRequired: true,
        });

        if (!response) return;
        const status = Number(response.status);

        if (
            response.status >= Constants.responseCode.SUCCESS &&
            response.status < Constants.responseCode.MULTIPLE_CHOICES
        ) {
            return response.data;
        } else {
            dispatch(toastMessage(getToastContent(status)));
        }
    };

export const deleteTech: IDeleteTech =
    (feedlotId, tech, options?: IUpdateTechDetailOptions) =>
    async (dispatch: AppDispatch) => {
        dispatch(setTechDetailsLoader(true));
        const response = await apiCall_v2({
            method: 'delete',
            url: `${Constants.apiUrls.FEED_LOT_MANAGER}/${feedlotId}${Constants.apiUrls.TECH}/${tech.techId}`,
            payload: tech,
            isResRequired: true,
        });
        dispatch(setTechDetailsLoader(false));
        if (!response) return;
        dispatch(toastMessage(getToastContent(Number(response.status))));
        options?.fetchTechData?.();
    };
