import { formatDate_deprecated } from '.';
import { IGenericObject } from '../utils/Interface/CommonInterface';

export const formatArrayForDropDown = (
    optionArray: any[],
    idFieldName: string,
    nameFieldName: string,
) => {
    return optionArray.map(option => ({
        ...option,
        id: option[idFieldName],
        name: option[nameFieldName],
    }));
};

export const formatValue = (
    fieldValue: any,
    fieldType?: string,
    prefix?: string,
    suffix?: string,
) => {
    if (!fieldType) return fieldValue ?? '';

    switch (fieldType) {
        case 'date':
            fieldValue = fieldValue ? formatDate_deprecated(fieldValue) : '';
            break;
        case 'boolean':
            fieldValue = fieldValue ? fieldValue : false;
            break;
        case 'prefix':
            fieldValue = fieldValue ? `${prefix}-${fieldValue}` : '';
            break;
        case 'suffix':
            fieldValue = fieldValue ? `${fieldValue} ${suffix}` : '';
            break;
        case 'currency':
            fieldValue = fieldValue ? setCurrencyToLocale(fieldValue) : '';
            break;
        default:
            fieldValue = fieldValue ? fieldValue : '';
            break;
    }

    return fieldValue;
};

export const replaceAmpersandSymbol = (string: string) => {
    const typeOfArgument = typeof string;

    if (typeOfArgument === 'string' && string) {
        string = string.replaceAll('&', '%26');
    }

    return string;
};

export const firstLetterUpper = (str: string) => {
    const newString = str?.replace(
        /(^\s*\w|[\.\!\?]\s*\w)/g, //eslint-disable-line no-useless-escape
        function (c) {
            return c.toUpperCase();
        },
    );

    return newString;
};

export const getUniqueList = (arr: Array<IGenericObject>, key) => {
    return [...new Map(arr.map(item => [item[key], item])).values()];
};

export const setCurrencyToLocale = (amount: number, maxFractionDigits = 2) => {
    return amount.toLocaleString('en', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: maxFractionDigits,
    });
};

export const formatIfNegative = value => {
    if (value < 0) {
        return `(${setCurrencyToLocale(value).replace('-', '')})`;
    }
    return setCurrencyToLocale(value);
};

export const getListOfString = (
    fieldName: string,
    arr?: Array<IGenericObject>,
) => {
    return arr?.map(item => item[fieldName] || '')?.join(', ') || 'N/A';
};

export const sortListAlphabetically = (
    array: IGenericObject[],
    key: string,
) => {
    return array.sort((previousValue, currentValue) => {
        const previousKeyValue = `${previousValue[key]}`.toUpperCase();
        const currentKeyValue = `${currentValue[key]}`.toUpperCase();

        return previousKeyValue.localeCompare(currentKeyValue);
    });
};
