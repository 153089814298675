export const TOKEN = {
    EMAILADDRESS_FIELD:
        'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress',
    EMAIL_FIELD: 'email',
    ROLE_FIELD: 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role',
    ACTOR_FIELD: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/actor',
    NAME_FIELD: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name',
    TID_FIELD: 'http://schemas.microsoft.com/ws/2008/06/identity/claims/tid',
    APP_FIELD: 'http://schemas.intimetec.com/ws/identity/claims/app',
    ENV_FIELD: 'http://schemas.intimetec.com/ws/identity/claims/env',
};

type EnvRole = 'Beta' | 'Development';

export const EnvRoles: Record<EnvRole, EnvRole> = {
    Beta: 'Beta',
    Development: 'Development',
};

export type AppRole =
    | 'CattleFinanceManager'
    | 'ShippingTracker'
    | 'FeedlotManager';

export const AppRoles: Record<AppRole, AppRole> = {
    CattleFinanceManager: 'CattleFinanceManager',
    ShippingTracker: 'ShippingTracker',
    FeedlotManager: 'FeedlotManager',
};

export const Policies = {
    nextGenAgTechLink: 'https://www.nextgenagtech.com',
    termsOfServiceRoute: '/termsofservice',
    termsOfServiceText: 'Terms of Service',
};
