import { IDATABRICKS_INITIAL_STATE } from '../../utils/Interface/DatabricksInterface';

export const DATABRICKS_INITIAL_STATE: IDATABRICKS_INITIAL_STATE = {
    runData: {
        job_id: 0,
        creator_user_name: '',
        state: {
            life_cycle_state: '',
            result_state: '',
            state_message: '',
        },
        start_time: null,
        end_time: null,
    },
};
