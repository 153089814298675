import { AnyAction } from '@reduxjs/toolkit';
import { ActionTypes } from '../../../../Redux/Actions';
import { FEED_CALL_ROUTES_INITIAL_STATE } from '../constants/feedCallRoutesInitialState';

const FeedCallRoutesReducer = (
    state = FEED_CALL_ROUTES_INITIAL_STATE,
    action: AnyAction,
) => {
    let stateCopy = { ...state };
    switch (action.type) {
        case ActionTypes.FETCH_FEED_CALL_ROUTES_PENS:
            stateCopy = {
                ...stateCopy,
                feedCallRoutesPens: action.payload,
                loading: false,
            };
            return stateCopy;
        case ActionTypes.FETCH_FEED_CALL_ROUTES:
            stateCopy = {
                ...stateCopy,
                loading: false,
                feedCallRoutes: action.payload,
            };
            return stateCopy;
        case ActionTypes.FETCH_FEED_CALL_ROUTES_DEFAULT_STATE:
            stateCopy = {
                ...stateCopy,
                loading: false,
                defaultFeedCallRoutes: action.payload,
            };
            return stateCopy;
        case ActionTypes.DELETE_FEED_CALL_ROUTES_ROW:
            stateCopy = {
                ...stateCopy,
                feedCallRoutes: state.feedCallRoutes.filter(
                    (_item, i) => i !== action.payload,
                ),
            };
            return stateCopy;
        case ActionTypes.ADD_NEW_FEED_CALL_ROUTES_ROW: {
            const newState = [...state.feedCallRoutes];
            newState.splice(action.payload, 0, {
                ...state.feedCallRoutesStaticRow,
            });
            newState[action.payload].backGround = '#DCDCDC';
            stateCopy = {
                ...stateCopy,
                loading: false,
                feedCallRoutes: newState,
            };
            return stateCopy;
        }
        case ActionTypes.FEED_CALL_ROUTES_SUBMIT_PENDING: {
            stateCopy = {
                ...stateCopy,
                submitPending: action.payload,
            };
            return stateCopy;
        }
        default:
            return stateCopy;
    }
};

export default FeedCallRoutesReducer;
