import { useEffect, useState, useMemo } from 'react';
import { Button, Col } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../Redux/Store';
import { useModalStatus } from '../../commonHooks/useModalStatus';
import { useFeedlotId } from '../../commonHooks/useFeedlotId';
import { useDebouncedEffect } from '../useDebounceEffect';
import {
    deleteTank,
    fetchTankDetails,
    updateTankDetail,
} from '../../Redux/Actions/tankActions';
import { setTankFilter } from '../../Redux/Reducer/tankSlice';
import { TANK_INITIAL_STATE } from '../../Redux/Constants/tankInitialState';
import TankModal from './TankModal';
import SettingsItemDetail from './FMSettings/SettingsItemDetail';
import { DeleteItemModal } from './FMSettings/DeleteItemModal';
import Constants from '../../utils/Constants';
import StorageService from '../../Services/storage/storage.service';
import { tankConstants } from '../../utils/constants/TankConstants';
import { FmSettingsConstant } from '../../utils/constants/FmSettingsConstants';
import { IFilter, ITankDetail } from '../../utils/Interface/TankInterface';

const TankDetails = () => {
    const { filter, loader } = useAppSelector(state => state.tank);
    const storage = useMemo(() => new StorageService(), []);
    const feedlotId = useFeedlotId();
    const dispatch = useAppDispatch();
    const {
        isOpen: isTankModalOpen,
        open: openTankModal,
        close: closeTankModal,
    } = useModalStatus();
    const {
        isOpen: isDeleteModalOpen,
        open: openDeleteModal,
        close: closeDeleteModal,
    } = useModalStatus();
    const [searchQuery, setSearchQuery] = useState<string | undefined>(
        undefined,
    );
    const [tankDetails, setTankDetails] = useState<ITankDetail[]>([]);
    const [selectedTank, setSelectedTank] = useState<ITankDetail>(
        TANK_INITIAL_STATE.createTankInitial,
    );

    const fetchTankData = (tankFilter: IFilter = filter): void => {
        if (feedlotId) {
            dispatch(fetchTankDetails(feedlotId, setTankDetails, tankFilter));
        }
    };

    const handleEditTankDetail = (tank: ITankDetail): void => {
        setSelectedTank(tank);
        openTankModal();
    };

    const setTankFilterInStorage = (filterValues: IFilter) => {
        storage.setValue(
            tankConstants.tankFilter,
            filterValues,
            Constants.Storage.LOCAL,
        );
    };

    const handleFilter = (filterName: string, filterValue: string) => {
        const updatedFilter = { ...filter, [filterName]: filterValue };
        fetchTankData(updatedFilter);
        dispatch(setTankFilter(updatedFilter));
        setTankFilterInStorage(updatedFilter);
    };

    const closeModalWithChanges = (isEdited: boolean): void => {
        if (isEdited) {
            fetchTankData(filter);
        }
        closeTankModal();
    };

    const resetFilter = (): void => {
        const updatedFilter = TANK_INITIAL_STATE.filter;
        fetchTankData(updatedFilter);
        setSearchQuery('');
        dispatch(setTankFilter(updatedFilter));
        setTankFilterInStorage(updatedFilter);
    };

    const handleCreateTank = (): void => {
        if (feedlotId) {
            setSelectedTank({
                ...TANK_INITIAL_STATE.createTankInitial,
                feedLotId: feedlotId,
            });
        }
        openTankModal();
    };

    const handleDeleteModalHandler = (tank: ITankDetail) => {
        setSelectedTank(tank);
        openDeleteModal();
    };

    const handleDeleteTank = (shouldTakeAction: boolean) => {
        if (feedlotId && shouldTakeAction) {
            if (!selectedTank.isAssociated || selectedTank.active) {
                const isActiveAndAssociated =
                    selectedTank.isAssociated && selectedTank.active;
                const action = isActiveAndAssociated
                    ? updateTankDetail
                    : deleteTank;
                const updatedTankDetail = isActiveAndAssociated
                    ? { ...selectedTank, active: false }
                    : selectedTank;
                dispatch(action(feedlotId, updatedTankDetail, fetchTankData));
            }
        }
        closeDeleteModal();
    };

    useDebouncedEffect(
        () => {
            if (searchQuery !== undefined) {
                handleFilter('name', searchQuery);
            }
        },
        [searchQuery],
        1000,
    );

    useEffect(() => {
        if (feedlotId) {
            const tankFilter = storage.getValue(tankConstants.tankFilter);
            if (Object.keys(tankFilter).length > 0) {
                dispatch(setTankFilter(tankFilter));
            }
            if (!tankFilter.name) {
                if (tankFilter.show) {
                    fetchTankData(tankFilter);
                } else {
                    dispatch(
                        fetchTankDetails(feedlotId, setTankDetails, filter),
                    );
                }
            } else {
                setSearchQuery(tankFilter?.name || filter.name);
            }
        }
    }, [feedlotId, dispatch, storage]);

    return (
        <div className="pens-container">
            <h5 className="my-3">{tankConstants.tankDetails.heading}</h5>
            <div className="d-flex align-items-end">
                <Col xs={3} className="d-flex flex-column">
                    <label>{tankConstants.tankDetails.tank}</label>
                    <input
                        className="text-secondary my-2"
                        type="text"
                        placeholder={tankConstants.tankDetails.placeholder}
                        maxLength={tankConstants.tankDetails.maxLength}
                        value={searchQuery ? searchQuery : ''}
                        onChange={e => setSearchQuery(e.target.value)}
                    />
                </Col>
                <Col xs={3}>
                    <Button
                        className="secondary my-2 mx-4"
                        onClick={resetFilter}
                    >
                        {tankConstants.clearFilter}
                    </Button>
                </Col>
                <Col xs={6} className="d-flex justify-content-end">
                    <Button className="my-2" onClick={handleCreateTank}>
                        {tankConstants.createNewTanks.heading}
                    </Button>
                </Col>
            </div>
            <div
                className="o-switch btn-group show-toggle my-2"
                data-toggle="buttons"
                role="group"
            >
                {FmSettingsConstant.filterOptions.map(option => (
                    <label
                        key={option.value}
                        className={`btn btn-secondary py-1 ${
                            filter.show === option.value
                                ? FmSettingsConstant.active.value
                                : ''
                        }`}
                    >
                        <input
                            type="radio"
                            onChange={() =>
                                handleFilter(option.name, option.value)
                            }
                            checked={filter.show === option.value}
                        />
                        {option.label}
                    </label>
                ))}
            </div>
            <SettingsItemDetail<ITankDetail>
                columnDetail={tankConstants.tankColumnDetail}
                itemDetail={tankDetails}
                handleEditDetail={handleEditTankDetail}
                handleDeleteModalHandler={handleDeleteModalHandler}
                loader={loader}
            />
            {isTankModalOpen && (
                <TankModal
                    showModal={isTankModalOpen}
                    closeModalWithChanges={closeModalWithChanges}
                    editTankDetails={selectedTank}
                    showActiveField={true}
                />
            )}
            {isDeleteModalOpen && (
                <DeleteItemModal
                    item={selectedTank}
                    handleDeleteItem={handleDeleteTank}
                    showModal={isDeleteModalOpen}
                    itemName={tankConstants.tankDetails.name}
                ></DeleteItemModal>
            )}
        </div>
    );
};

export default TankDetails;
